<template>
  <div class="list-item-page ">
    <div style="display:flex;">
      <LeftSideBar>
          <div class="custom-btn-group">
            <el-button v-if="isAppMapEmpty" @click="showComponent('start')" icon="fa fa-star" class="small_btn button-menu"
              :class="{'bg-primary active': showStart}"> Start 
            </el-button>
            <el-button @click="showComponent('appmap')" icon="fa fa-map" class="small_btn button-menu"
              :class="{ 'bg-primary active': showAppMap }"> App Map
            </el-button>
            <el-button @click="showComponent('event')" icon="el-icon-film" class="small_btn button-menu"
              :class="{ 'bg-primary active': showEvent }"> Sessions
            </el-button>
            <el-tooltip v-if="eventSessionType === 'mobile'" class="item" effect="light" content="Mobile sessions" placement="top">
              <el-button @click="showComponent('event')" icon="el-icon-film" class="small_btn button-menu"
                :class="{ 'bg-primary active': showEvent }">
              </el-button>
            </el-tooltip>
            <el-button @click="showComponent('testsuite')" icon="fa fa-rocket" class="small_btn button-menu"
              :class="{ 'bg-primary active': showTestSuit }"> Test Suite
            </el-button>
            <div v-if="showAppMap"  style="text-align: left !important;">
              <hr class="my-3" style="
                            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
                            border-style: solid none none;
                            border-width: 1px 0px 0px;
                            border-image: none 100% / 1 / 0 stretch;
                            min-width: 98%;
                            overflow: visible;
                            box-sizing: content-box;
                            height: 0px;
                          " />
                <div class="">
                  <label for="key_flow_select" class="model-label" >Pick a Key Flow</label>
                    <el-select id="key_flow_select" v-model="selectKeyFlow" :loading="loadingShowAppMap"
                      loading-text="loading ..." no-data-text="no key flow found " @input="changeKeyFlow()"
                      placeholder="Select a key flow" class="custom--select"> 
                    <el-option label="ALL" value="ALL" class="default--label"></el-option>
                    <el-option v-for="(item, id) in toShowKeyFlows" :key="id"
                      :label="item.label + ` (${(item.users_pct * 100).toFixed(2)}%)`" :value="id">
                    </el-option>
                    </el-select>
                </div>
                <el-tooltip  class=""  effect="light"
                    :content="`${selectKeyFlowPathUser ? 'Add Key Flow to Test Suite' : 'Please select a Key Flow'}`" placement="top">
                    <span>
                      <el-button icon="fa el-icon-plus" class=" custom_btn_settings add_btn button-menu" style="width: 45%;"
                        @click="selectKeyFlowPathUser ? keyFlowTesSuit() : ''">Test
                      </el-button>
                    </span>
                </el-tooltip>
                <el-button class="custom_btn_settings add_btn button-menu mt-0 mb-3" @click="downloadDai()" style="width: 45%;">
                  <i v-if="loadingDai == false" class="el-icon-download"></i> <i v-else class="el-icon-loading"></i> Model </el-button>
                  <hr class="my-3" style="
                            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
                            border-style: solid none none;
                            border-width: 1px 0px 0px;
                            border-image: none 100% / 1 / 0 stretch;
                            min-width: 98%;
                            overflow: visible;
                            box-sizing: content-box;
                            height: 0px;
                            display: block !important;
                          " />
                <label class="appmap-label">Last Updated: {{ allDataAppMap.created_date }} </label>
                <!-- <label for="key_flow_select" class="model-label mt-4 mb-2" >App map settings</label>
                <el-switch
                  @input="updateAppmapSettings"
                  v-model="appmapSettings.active"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="On"
                  inactive-text="Off">
                </el-switch>
                <div class="is--update_days">
                  <label class="model-label mr-3" for="update_days">
                      Update days
                  </label> -->
                  <!-- <input @blur="updateAppmapSettings" type="number" id="update_days" name="update_days" placeholder="Update days"
                    v-model="appmapSettings.updateDays" /> -->
                    <!-- <el-input-number style="width: 50%; height: 35px !important;" v-model="appmapSettings.updateDays" @blur="updateAppmapSettings" controls-position="right" ></el-input-number>
                </div> -->
            </div>
          </div>
      </LeftSideBar>
      <div :class='showAppMap ? "right-content" : "right-content px-4 pt-4"'> 
        <VueChatBot :messages="dataMessage" :options="botOptions" @msg-send="handleSubmit"
                :bot-typing="loadingMessage ? true : false" />
        <el-button v-if="showAppMap" class="small_btn custom_btn--refresh" @click="refreshMap()" circle> 
          <i v-if="loadindRefresh === false" class="el-icon-refresh-left"></i> <i v-else class="el-icon-loading"></i> 
        </el-button>
        <div class="c--breadcrumb" :style="showAppMap ? displayNone : displayBlock" >
          <router-link to="/details/test-explorer/list-tests"><i class="el-icon-house"></i></router-link>
          <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
          <router-link to="/details/project-explorer/list-projects">
            Apps
          </router-link>
          <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
          <span> {{ projectDetail.name ? projectDetail.name : '' }}</span>
        </div>
        <el-alert v-if="textAnswer !== ' '" type="info" title="Answer" style="height: 50px;" :description="textAnswer" />
        <template v-if="showEvent">
          <div class="mid--page no-border">
            <div class="mp--l">
              <div class="btn-group align-items-center">
                <el-tooltip class="item" effect="light" content="Session Videos" placement="top">
                  <el-button @click="showComponentEvent('video')" icon="fa el-icon-video-camera-solid" class="small_btn"
                    :class="{ 'bg-primary active': video }">
                    Video
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="light" content="APIs invoked" placement="top">
                  <el-button @click="showComponentEvent('callApi')" icon="fa el-icon-s-promotion" class="small_btn"
                    :class="{ 'bg-primary active': callApi }">
                    API
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          <div v-if="video" class="mid--page_container">
            <div class="mid--page pb-3 my-3" style="border: none;">
              <div class="mp--l">
                <el-input :placeholder="$t('list_item_page.search')" v-model="querySession">
                  <i slot="suffix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
              <div class="mp--r">
                <span class="pag--details mr-3 ml-3">{{ totalSession ? fromSession + 1 : 1 }} {{ $t("list_item_page.to") }}
                  {{ toSesion > totalSession ? totalSession : toSesion }}
                  {{ $t("list_item_page.of") }} {{ totalSession }}
                  {{ $t("list_item_page.entries") }}
                </span>
                <el-select v-model="paginationSession.perPage" @change="savePaginationSession" placeholder="Per page"
                  class="small--select">
                  <el-option v-for="(item, id) in paginationSession.perPageOptions" :key="id" :label="item"
                    :value="item"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        
          <div v-if="callApi" class="mid--page_container" style="margin-left: 300px;">
            <div class="mid--page pb-3 my-3" style="border: none;">
              <div class="mp--l">
                <el-input :placeholder="$t('list_item_page.search')" v-model="querySession">
                  <i slot="suffix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
              <div class="mp--r" >
                <span class="pag--details mr-3 ml-3">{{ toShowSessionCallApi.perPageCount ? ((toShowSessionCallApi.currentPage *
                  toShowSessionCallApi.perPageCount) - toShowSessionCallApi.perPageCount) + 1 : 1 }} {{
                      $t("list_item_page.to")
                    }}
                  {{ toShowSessionCallApi.currentPage ? toShowSessionCallApi.currentPage * toShowSessionCallApi.perPageCount :
                    10 }}
                  {{ $t("list_item_page.of") }} {{ toShowSessionCallApi.totalSessionCount || 0 }}
                  {{ $t("list_item_page.entries") }}
                </span>
                <el-select v-model="limitCallApi" @change="getSessionCallApi" placeholder="Per page" class="small--select">
                  <el-option v-for="(item, id) in paginationSession.perPageOptions" :key="id" :label="item"
                    :value="item"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
          <div v-if="video" class="eff--page" >
            <data-list :tHeader="Header2" :loading="loadingSession" :items="toShowSession" :customSizes="true" ref="dataList">
              <template #content>
                <div v-for="(elt, id) in toShowSession" :key="id" 
                  class="tblb--row mb-2" 
                  @click=" Number(elt.recording_duration) < 1 ? '' : showDetailsSession(elt) " 
                  :class="Number(elt.recording_duration) < 1 ? 'not-video-test' : ''"
                >
                  <el-tooltip :disabled="!(Number(elt.recording_duration) < 1)" :content="Number(elt.recording_duration) < 1 ? 'this session contains no videos or tests' : ''" placement="top">
                    <div class="tblb--inner">
                      <div class="elt-row" style="width: 6%">
                        <span class="test-run-name">
                          {{ forStepNumber(id) }}
                        </span>
                      </div>
      
                      <div class="elt-row" style="width: 20%">
                        <span v-if="eventSessionType !== 'mobile'">
                          {{
                            elt.person.properties.$geoip_continent_name
                            ? elt.person.properties.$geoip_continent_name
                            : "N/A"
                          }}
                          /{{
                            elt.person.properties.$geoip_country_name
                            ? elt.person.properties.$geoip_country_name
                            : "N/A"
                          }}
                        </span>
                        <span v-else>
                          {{
                            elt.country_name
                            ? elt.country_name
                            : (elt.geolocated_country ? elt.geolocated_country : "N/A")
                          }}
                        </span>
                      </div>
      
                      <div class="elt-row" style="width: 25%" v-if="eventSessionType !== 'mobile'">
                        <el-tooltip class="item" effect="light" :content="elt.person.properties.$initial_current_url"
                          placement="top">
                          <span class="run-tags" style="
                                                                                  max-width: 100%;
                                                                                  overflow: hidden;
                                                                                  text-overflow: ellipsis;
                                                                                  white-space: nowrap;
                                                                                                                        ">
                            {{ elt.person.properties.$initial_current_url }}
                          </span>
                        </el-tooltip>
                      </div>
                      <div class="elt-row" style="width: 25%" v-else>
                        <el-tooltip class="item" effect="light" :content="elt.app_name" placement="top">
                          <span class="run-tags" style="
                                                                                  max-width: 100%;
                                                                                  overflow: hidden;
                                                                                  text-overflow: ellipsis;
                                                                                  white-space: nowrap;
                                                                                ">
                            {{ elt.app_name }} / {{ elt.operating_system }}
                          </span>
                        </el-tooltip>
                      </div>
      
                      <div class="elt-row" style="width: 12%">
                        <span class="elt--txt" v-if="eventSessionType !== 'mobile'">
                          {{ elt.person.properties.$initial_os }} /
                          {{ elt.person.properties.$initial_browser }}
                        </span>
                        <span class="elt--txt" v-else>
                          {{ elt.user_agent }}
                        </span>
      
                      </div>
      
                      <div class="elt-row" style="width: 16%">
                        <span class="elt--txt" v-if="eventSessionType !== 'mobile'">
                          {{ formatDate(elt.start_time) }}
                        </span>
                        <span class="elt--txt" v-else>
                          {{ formatDate(elt.session_created_at) }}
                        </span>
                      </div>
      
                      <div class="elt-row" style="width: 10%">
                        <span class="test-time-cont" v-if="eventSessionType !== 'mobile'">
                          <i class="far fa-clock mr-1"></i>
                          {{ secondsToRealTime(elt.recording_duration) }}
                        </span>
                        <span class="test-time-cont" v-else>
                          <i class="far fa-clock mr-1"></i>
                          {{ secondsToRealTime(elt.session_duration) }} / {{ elt.event_count }}
                        </span>
                      </div>
                      <div class="elt-row" style="width: 7%">
                          <el-tooltip v-if="eventSessionType !== 'mobile'"  class="item" effect="light" content="Api call"
                            placement="top">
                            <el-button type="info" icon="el-icon-tickets" @click="showApiCalls(elt.id)" circle plain
                              size="small"></el-button>
                          </el-tooltip>
                      </div>
      
                    </div>
                  </el-tooltip>
                </div>
              </template>
            </data-list>
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" :disabled="canAddPreviousSession"
                @click="handleCurrentChangeSession('Previous')">
                Previous
              </el-button>
              <el-button type="primary" @click="handleCurrentChangeSession('next')">
                Next
                <i class="el-icon-arrow-right el-icon-right"></i>
              </el-button>
            </el-button-group>
          </div>
          <div v-if="callApi" class="eff--page" >
            <data-list :tHeader="tHeaderCllApi" :loading="loadingSessionCallApi" :items="toShowSessionCallApi.sessions"
              :customSizes="true" ref="dataList">
              <template #content>
                <div v-for="(elt, id) in toShowSessionCallApi.sessions" :key="id" class="tblb--row mb-2" 
                  @click="showApiCalls(elt.session_uid)">
    
                  <div class="tblb--inner">
                    <div class="elt-row" style="width: 5%">
                      <span class="test-run-name">
                        {{ forStepNumberRun(id) }}
                      </span>
                    </div>
    
                    <div class="elt-row" style="width: 19%">
                    <el-tooltip class="item" effect="light" :content="elt.first_origin_pageurl" placement="top">
                      <span class="test-run-name">
                        {{
                          elt.first_origin_pageurl
                        }}
                      </span>
                    </el-tooltip>
                    </div>
                    <div class="elt-row" style="width: 19%">
                    <el-tooltip class="item" effect="light" :content="elt.last_origin_pageurl" placement="top">
                      <span class="test-run-name">
                        {{
                          elt.last_origin_pageurl
                        }}
                      </span>
                    </el-tooltip>
                    </div>
    
                    <div class="elt-row" style="width: 9%">
                      <span class="elt--value">
                        {{
                          elt.successful_calls
                        }}
                      </span>
                    </div>
    
                      <div class="elt-row" style="width: 9%">
                      <span class="elt--value">
                        {{
                          elt.failed_calls
                        }}
                      </span>
                    </div>
    
                    <div class="elt-row" style="width: 9%">
                      <span class="elt--value">
                        {{
                          elt.total_calls
                        }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 15%">
                      <span class="elt--txt">
                        {{ formatDate(elt.session_created_at) }}
                      </span>
                    </div>
                      <div class="elt-row" style="width: 15%">
                      <span class="elt--txt">
                        {{ +(Math.round(elt.resptime_average.toFixed(2) + "e+2")  + "e-2")  }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </data-list>
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" :disabled="toShowSessionCallApi.currentPage === 1"
                @click="handleCurrentChangeCallApi('Previous')">
                Previous
              </el-button>
              <el-button type="primary" @click="handleCurrentChangeCallApi('next')"
                :disabled="toShowSessionCallApi.currentPage === toShowSessionCallApi.totalPages">
                Next
                <i class="el-icon-arrow-right el-icon-right"></i>
              </el-button>
            </el-button-group>
          </div>
        </template>
        <template v-if="showStart">
          <div >
            <div class="mid--page_container">
              <div class="mid--page pb-3" style="border: none;">
                <div class="container--start" style="display: flex">
                  <div class="left--side-container">
                    <div class="text--start">
                        <el-card shadow="hover">
                          <h3 class="desc">{{ $t("page_start.desc_first") }}</h3>
                          <h3 class="desc" style="  margin-top: 30px;">{{ $t("page_start.desc_second") }}</h3>
                          <h3 class="desc" style="  margin-top: 30px;" >{{ $t("page_start.desc_third") }}</h3>
                        </el-card>  
                    </div>
                    <hr class="my-3" style="
                          border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
                          border-style: solid none none;
                          border-width: 1px 0px 0px;
                          border-image: none 100% / 1 / 0 stretch;
                          min-width: 98%;
                          overflow: visible;
                          box-sizing: content-box;
                          height: 0px;
                        " /> 
                    <div class="cards">
                      <el-col :span="8">
                        <el-card shadow="hover" class="custom--card">
                          <h1 class="text--card"> 1 </h1>
                          <h3 class="text--card">{{ $t("page_start.text_card_first") }}</h3>
                        </el-card>
                      </el-col>
                      <el-col :span="8">
                        <el-card shadow="hover" class="custom--card">
                          <h1 class="text--card"> 2 </h1>
                          <h3 class="text--card">{{ $t("page_start.text_card_second") }}</h3>
                        </el-card>
                      </el-col>
                      <el-col :span="8">
                        <el-card shadow="hover" class="custom--card">
                          <h1 class="text--card"> 3 </h1>
                          <h3 class="text--card"> {{ $t("page_start.text_card_third") }}</h3>
                        </el-card>
                      </el-col>
                    </div>
                  </div>
                  <div class="right--side-container">
                    <div>
                        <el-card shadow="hover" class="custom--right-card">
                          <h1>{{ $t("page_start.progress_tracker") }}</h1>
                          <hr class="my-3" style="
                          border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
                          border-style: solid none none;
                          border-width: 1px 0px 0px;
                          border-image: none 100% / 1 / 0 stretch;
                          min-width: 98%;
                          overflow: visible;
                          box-sizing: content-box;
                          height: 0px;
                        " /> 
                          <div class="check-boxes">
                            <el-checkbox v-model.lazy="checked1">{{ $t("page_start.checked1") }}</el-checkbox>
                            <el-checkbox v-model="checked2">{{ $t("page_start.checked2") }}</el-checkbox>
                            <el-checkbox v-model="checked3">{{ $t("page_start.checked3") }}</el-checkbox>
                            <el-checkbox v-model="checked4">{{ $t("page_start.checked4") }}</el-checkbox>
                            <el-checkbox v-model="checked5">{{ $t("page_start.checked5") }}</el-checkbox>
                            <el-checkbox v-model="checked6">{{ $t("page_start.checked6") }}</el-checkbox>
                          </div>
                          <el-progress type="circle" :percentage="progressBar()" :color="colors"></el-progress>
                        </el-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="showTestSuit">
          <div class="mid--page_container">
            <div class="mid--page" style="border: none;">
              <div class="mp--l">
              </div>
              <div class="mp--r">
                <el-tooltip v-if="showTestSuit" class="item" effect="light" content="Schedule test" placement="top">
                    <el-button icon="el-icon-alarm-clock" class="ml-2" type="warning" plain size="small"
                      :class="{ active: scheduledRun }" @click.stop="showContent('Scheduled')" circle> </el-button>
                  </el-tooltip>
                  <el-tooltip v-if="showTestSuit" class="item" effect="light" content="Run Test Suite" placement="top">
                    <el-button icon="fa fa-play" class="ml-2" type="primary" @click.stop="runAllTest()" circle plain size="small"
                      :disabled="keyTestFile[0] && keyTestFile[0] != 'null' ? false : true">
                    </el-button>
                  </el-tooltip>
              </div>
            </div>
          </div>
          <div  style="padding-top: 20px;">
            <data-list :tHeader="tHeader" :loading="loadingTest" :items="toShow" :customSizes="true" ref="dataList">
              <template #content>
                <div class="tblb--row mb-2" v-for="(elt, id)  in toShow" :key="id" @click="showFileTest(elt)"
                  :disabled="keyTestFile[0] && keyTestFile[0] != 'null' ? false : true">
                  <div v-if="elt.name" class="tblb--inner">
                    <div class="elt-row" style="width:16%;">
                      <span class="test-run-name">{{ elt.name }}</span>
                      <br />
                    </div>
                    <div class="elt-row" style="width:16%;">
                      <span>
                        {{ elt.description }}
                      </span>
      
                      <span v-if="elt.tags" class="run-tags-cont">
                        <span class="run-tags" v-for="(tags, index) in elt.tags" :key="index">
                          {{ tags }}
                        </span>
                      </span>
      
                    </div>
                    <div class="elt-row" style="width:16%;">
                      <el-alert class="no--close" :title="elt.isActive === true ? 'active' : 'disabled'" :type="elt.isActive === true
                        ? 'success'
                        : elt.isActive === false
                          ? 'danger'
                          : 'danger'
                        " show-icon></el-alert>
                    </div>
                    <div class="elt-row" style="width: 21%;">
                      <span class="elt--txt">
                        {{ formatDate(elt.acceptedAt) }}
                      </span>
                    </div>
                    <div class="elt-row" style="width:16%;">
                      <span class="elt--value">
                        {{ fileAppAtt.length }}
                      </span>
                    </div>
                    <div class="elt-row flex--elt flex-wrap" style="width:14%;">
                      <el-tooltip v-if="!elt.shared || elt.shared != true" id="listItemPageTableEditTestBtn" class="item" effect="light" content="Edit" placement="top">
                        <el-button type="primary" icon="el-icon-edit" @click.stop="editTestModal(elt)" circle plain
                          size="small"></el-button>
                      </el-tooltip>
      
                      <el-tooltip id="listItemPageTableRerunTestBtn" class="item" effect="light" content="Add file"
                        placement="top">
                        <el-button type="success" icon="el-icon-plus" @click.stop="addFileModal(elt)" circle plain
                          size="small"></el-button>
                      </el-tooltip>

                    </div>
                  </div>
                </div>
              </template>
            </data-list>
            <h3> Test Suite Runs  <i v-if="isRunTestData == true" class="el-icon-remove-outline cuser" @click="toollRunTest"></i> <i v-else  class="el-icon-circle-plus-outline cuser" @click="toollRunTest"></i></h3>
            <data-list v-show="isRunTestData" :tHeader="tHeaderSuit" :loading="loadingTestDelete" :items="detailTest" :customSizes="true"
              ref="dataList">
              <template #content>
                <div class="tblb--row mb-2" v-for="(elt, id) in detailTest " :key="elt.id"
                  @click=" _onRowClicked(keyTest[id])">
                  <div class="tblb--inner">
                    <div class="elt-row" style="width:23%;">
                      <span class="test-run-name">{{ elt.name }}</span>
                      <br />
                    </div>
                    <div class="elt-row" style="width:15%;">
                      <span>
                        {{ elt.description }}
                      </span>

                      <span v-if="elt.tags" class="run-tags-cont">
                        <span class="run-tags" v-for="(tags, index ) in elt.tags " :key="index">
                          {{ tags }}
                        </span>
                      </span>

                    </div>
                    <div class="elt-row" style="width:15%;">
                      <el-alert class="no--close" :title="elt.status" :type="elt.status === 'passed'
                        ? 'success'
                        : elt.status === 'failed'
                          ? 'error'
                          : 'danger'
                        " show-icon></el-alert>
                    </div>
                    <div class="elt-row" style="width:23%;">
                      <span>
                        {{ elt.description }}
                      </span>

                      <span class="run-tags-cont">
                        <span class="run-tags">
                          {{ elt.message }}
                        </span>
                      </span>

                    </div>
                    <div class="elt-row" style="width: 15%;">
                      <span class="elt--txt">
                        {{ formatDate(elt.acceptedAt) }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 9%;">
                      <el-tooltip class="item" effect="light" content="Delete Test Suite Run" placement="top">
                        <el-button type="danger" icon="el-icon-delete" @click.stop=" deleteTestSuitRun(elt, keyTest[id])"
                          circle plain size="small"></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </data-list>
        </div>
        </template>
        <template v-if="showAppMap">
          <div v-loading="loadingShowAppMap" >
            <div id="wring-graph" class="wring-graph">
              <div v-if="Object.entries(toShowAppMap).length !== 0" class="graph">
                <test-graph :items="toShowAppMap" :highlight_edges="highlights" ref="test-graph"> </test-graph>
              </div>
              <div class="mt-7" v-else-if="!loadingShowAppMap" style="text-align: center;">No appmap computed for this App</div>
              <div id="imgModal" class="modal" style="backdrop-filter: blur(2px);">
                <span class="close" style="cursor: pointer;top: 15%; right: 10%; position: relative; font-size: 40px;"
                  @click="closeModalScreenshots">&times;</span>
                <div id="caption"></div>
                <img class="modal-content" id="screenshot" style="width: 70%; top: 15%; left: 15%;">
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Modal add test start-->
    <div class="add-test-project-modal">
      <modal :show.sync="addTestModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            Add a flow to the App test suite
          </h5>
        </template>
        <div class="tblb--row mb-2" v-if="loadingModalAddRun">
          <div class="tblb--inner">
            <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
          </div>
        </div>
        <div class="body--modale" v-else>
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="run_name">
                  {{ $t("list_item_page.run_name") }}
                </label>
                <input type="text" id="run_name" name="run_name" :placeholder="$t('list_item_page.placeholder_run_name')"
                  v-model="labelSelectedKeyFlow" />
                <label class="model-label" for="Test_Script_Files">
                  Test Script Files
                </label>
                <el-select id="Test_Script_Files" v-model="listAddSuitTest" multiple collapse-tags
                  laceholder="Select execution file">
                  <el-option v-for="(item, id) in fileBackup" :key="id" :label="item.name" :value="item">
                    <span style="float: left; width: 100%">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>

            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="addTestModal = false;
            cleanUpState();">
              {{ $t("list_item_page.close") }}
            </base-button>

            <el-tooltip :disabled="!(listAddSuitTest.length === 0 || labelSelectedKeyFlow === '')" :content="`${labelSelectedKeyFlow === '' ? 'Run name cannot be empty' : ''} ${listAddSuitTest.length === 0 ? 'Please select execution file' : ''
              }`
              " placement="top">
              <span>
                <base-button :disabled="listAddSuitTest.length === 0 || labelSelectedKeyFlow === ''" type="primary"
                  @click="saveAddFileTestSuite">{{
                    $t("list_item_page.add_to_testsuite") }}</base-button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-project-modal">
      <modal :show.sync="addTestModalUser">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            Add a test to the App test suite
          </h5>
        </template>
        <div class="tblb--row mb-2" v-if="loadingModalAddRun">
          <div class="tblb--inner">
            <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
          </div>
        </div>
        <div class="body--modale" v-else>
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="run_name">
                  {{ $t("list_item_page.run_name") }}
                </label>
                <input type="text" id="run_name" name="run_name" :placeholder="$t('list_item_page.placeholder_run_name')"
                  v-model="labelSelectedUserId" />
                <label class="model-label" for="Test_Script_Files">
                  Test Script Files
                </label>
                <el-select id="Test_Script_Files" v-model="listAddSuitTest" multiple collapse-tags
                  laceholder="Select execution file">
                  <el-option v-for="(item, id) in fileBackup" :key="id" :label="item.name" :value="item">
                    <span style="float: left; width: 100%">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>

            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="addTestModal = false;
            cleanUpState();">
              {{ $t("list_item_page.close") }}
            </base-button>

            <el-tooltip :disabled="!(listAddSuitTest.length === 0 || labelSelectedUserId === '')" :content="`${labelSelectedUserId === '' ? 'Run name cannot be empty' : ''} ${listAddSuitTest.length === 0 ? 'Please select execution file' : ''
              }`
              " placement="top">
              <span>
                <base-button :disabled="listAddSuitTest.length === 0 || labelSelectedUserId === ''" type="primary"
                  @click="saveAddFileTestSuite">{{
                    $t("list_item_page.add_to_testsuite") }}</base-button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-project-modal">
      <el-drawer title="scheduledRun" :visible.sync="scheduledRun" direction="rtl">
        <div class="body--modale">
          <scheduled-run ref="scheduledRun" :scriptFiles="scriptFiles"></scheduled-run>
        </div>
      </el-drawer>
    </div>

    <!-- Modal add project start-->
    <div class="add-test-project-modal">
      <modal :show.sync="toggleEditTestModal">
        <template slot="header">
          <h5 class="modal-title">
            Edit Test Suite
          </h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="project_name"> Test Suite Name</label>
                <input type="text" id="teste_suit_name" name="teste_suit_name" placeholder="teste suit name"
                  v-model="suit_run_name" />
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click=" toggleEditTestModal = false">{{ $t("list_item_page.close")
            }}</base-button>
            <div>
              <el-tooltip :disabled="!(suit_run_name.length === 0)" :content="`${suit_run_name.length === 0 ? 'teste suit name cannot be empty' : ''
                } `
                " placement="top">
                <span>
                  <base-button :disabled="suit_run_name.length === 0" type="primary" @click=" saveEditTestSuite('add')">
                    Save
                  </base-button>
                </span>
              </el-tooltip>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal add project end-->

    <div class="add-file-modal">
      <modal :show.sync="toggleReAddFileModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            Add file
          </h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-modal">
              <div class="s-a-tabs">
                <div class="tab-content py-3" id="myTabContent">
                  <div class="tab-pane fade" :class="{ 'active show': isActive('settings') }" id="settings">
                    <div class="mt-4">
                      <h4 class="fbb mt-1">
                        {{ $t("list_item_page.test_script_files") }}
                      </h4>
                      <div class="upload-craglist upload-file-div-wrapper">
                        <div class="drop-down-container" @dragover="dragover" @dragleave="dragleave" @drop="drop"
                          ref="dropDownContainer">
                          <input type="file" multiple="multiple" name="fields[assetsFieldHandle][]" id="swal-test-files"
                            class="w-px h-px opacity-0 overflow-hidden absolute fileInput" v-on:change="onChange"
                            ref="file" accept=".side, .wring" />

                          <label for="assetsFieldHandle" class="block cursor-pointer">
                            <div>
                              {{ $t("list_item_page.upload_test_script") }}<br />
                              {{ $t("list_item_page.up_to_50_project") }}
                            </div>
                          </label>
                        </div>
                      </div>

                      <div class="file-lists-container mt-4" v-if="listAddSuitTest.length !== 0">
                        <div class="file-head-clearall">
                          <div class="list-heading">
                            {{ $t("list_item_page.uploaded_files") }}
                          </div>
                          <div class="clearall" @click="removeAllFiles">
                            {{ $t("list_item_page.clear_all") }}
                          </div>
                        </div>

                        <div class="craglist-files">
                          <ul class="mt-1">
                            <li class="text-sm p-2"
                              v-for="(                   file, index                   ) in                    listAddSuitTest                   "
                              :key="index">
                              {{ file.name }}
                              <i @click=" remove(listAddSuitTest.indexOf(file))" class="ni ni-fat-remove rmv-btn"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click=" toggleReAddFileModal = false">{{ $t("list_item_page.close")
            }}</base-button>

            <el-tooltip :disabled="!(listAddSuitTest.length === 0)" :content="`${listAddSuitTest.length === 0 ? 'Please upload at least one file' : ''
              }`
              " placement="top">
              <span>
                <base-button :disabled="listAddSuitTest.length === 0" type="primary" @click="saveAddFileTestSuite">{{
                  $t("list_item_page.add_to_testsuite") }}</base-button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>

    <div class="add-test-project-modal">
      <modal :show.sync="addTestAppModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            Run Test Suite
          </h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div v-if="keyTestFile[0]" class="run-name">
                <div class="is-flex">
                  <div class="is-flex mr-5">
                    <label class="model-label mr-3" for="suiteSingleRun">Same Browser Session:</label>
                    <el-switch v-model="suiteSingleRun" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                  </div>
                  <div class="is-flex">
                    <label v-if="suiteSingleRun === false" class="model-label mr-3" for="run_description">Filter displayed
                      :</label>
                    <el-switch v-if="suiteSingleRun === false" v-model="filterDisplayed" active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                  </div>
                </div>
                <label class="model-label" for="run_name">
                  {{ $t("list_item_page.run_name") }}
                </label>
                <input type="text" id="run_name" name="suiteRunName"
                  :placeholder="$t('list_item_page.placeholder_run_name')" v-model="suiteRunName" />
                <label v-if="suiteSingleRun === false" class="model-label" for="runStartUrl">runStartUrl
                  (required):</label>
                <input v-if="suiteSingleRun === false" type="text" id="runStartUrl" name="runStartUrl"
                  placeholder="runStartUrl" v-model="runStartUrl" />
                <label class="model-label" for="runStartUrl">runTags :</label>
                <input type="text" id="runTags" name="runTags" placeholder="Run tags" v-model="runTagsSuit" />
                <label v-if="run_all === false" class="model-label" for="Test_Script_Files">
                  Test Script Files (required):
                </label>
                <el-alert v-if="run_all === false && suiteSingleRun === true"
                  title="The order of file selection would be the order of execution!" type="info" class="mt-2 mb-2">
                </el-alert>
                <el-select v-if="run_all === false" id="Test_Script_Files" v-model="suiteFiles" multiple collapse-tags
                  placeholder="Select execution file">
                  <el-option
                    v-for="(                   item, id                   ) in                    fileAppAtt                   "
                    :key="id" :label="item.fileName" :value="item.fileKey">
                    <span style="float: left; width: 100%">{{ item.fileName }}</span>
                  </el-option>
                </el-select>
                <div class="s-a-tabs">
                  <ul class="nav nav-tabs nav-justified">
                    <li class="nav-item">
                      <a class="nav-link" @click.prevent="setActive('settings')" :class="{ active: isActive('settings') }"
                        href="#home">{{ $t("list_item_page.settings") }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" @click.prevent="setActive('advanced')" :class="{ active: isActive('advanced') }"
                        href="#profile">{{ $t("list_item_page.advanced") }}</a>
                    </li>
                  </ul>
                  <div class="tab-content py-3" id="myTabContent">
                    <div class="tab-pane fade" :class="{ 'active show': isActive('settings') }" id="settings">
                      <div class="mt-2">
                        <h4 class="fbb">{{ $t("list_item_page.test_script") }}</h4>
                        <div class="three-input">
                          <div class="tic">
                            <label class="model-label" for="browser_width">{{
                              $t("list_item_page.browser_width")
                            }}</label>
                            <input type="text" id="browser_width" name="browser_width" placeholder="1920"
                              v-model="browser_width" />
                          </div>
                          <div class="tic">
                            <label class="model-label" for="browser_height">{{
                              $t("list_item_page.browser_height")
                            }}</label>
                            <input type="text" id="fname" name="browser_height" placeholder="1980"
                              v-model="browser_height" />
                          </div>

                          <div class="tic">
                            <label class="model-label" for="max_wait_time">{{
                              $t("list_item_page.max_wait")
                            }}</label>
                            <input type="text" id="max_wait_time" name="max_wait_time" placeholder="5.0"
                              v-model="max_wait_time" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" :class="{ 'active show': isActive('advanced') }" id="advanced">
                      <div class="modal-checks-container">
                        <div class="modal-checks">
                          <input type="checkbox" id="ignore_disabled_elements" name="ignore_disabled_elements"
                            v-model="ignore_disabled_elements" />
                          <label for="ignore_disabled_elements">{{
                            $t("list_item_page.ignore_disabled")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input type="checkbox" id="ignore_invisible_elements" name="ignore_invisible_elements"
                            v-model="ignore_invisible_elements" />
                          <label for="ignore_invisible_elements">{{
                            $t("list_item_page.ignore_invisible")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input type="checkbox" id="collect_page_interaction_data" name="collect_page_interaction_data"
                            v-model="collect_page_interaction_data" />
                          <label for="collect_page_interaction_data">{{
                            $t("list_item_page.collect_interaction")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input type="checkbox" id="extra_element_data" name="extra_element_data"
                            v-model="extra_element_data" />
                          <label for="extra_element_data">{{
                            $t("list_item_page.collect_extra_element")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input type="checkbox" id="all_suggest" name="all_suggest" v-model="all_suggest" />
                          <label for="all_suggest">{{
                            $t("list_item_page.generate_selectors")
                          }}</label>
                        </div>

                        <div class="modal-checks">
                          <input type="checkbox" id="downweight_strings" name="downweight_strings"
                            v-model="downweight_strings" />
                          <label for="downweight_strings">{{
                            $t("list_item_page.try_to_avoid")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input type="checkbox" id="verify_suggested_selectors" name="verify_suggested_selectors"
                            v-model="verify_suggested_selectors" />
                          <label for="verify_suggested_selectors">{{
                            $t("list_item_page.verify_suggested")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input type="checkbox" id="enable_integrations" name="enable_integrations"
                            v-model="enable_integrations" />
                          <label for="enable_integrations">{{
                            $t("list_item_page.trigger_integrations")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input type="checkbox" id="generate_css_selectors" name="generate_css_selectors"
                            v-model="generate_css_selectors" />
                          <label for="generate_css_selectors">
                            Generate CSS selectors during run (slow, needed for Cypress export)
                          </label>
                        </div>
                        <br />
                        <label class="model-label mt-3" for="testrun_start_url">
                          Environment start URL for test run
                        </label>
                        <input id="testrun_start_url" name="testrun_start_url" placeholder="https://example.com"
                          v-model="testrun_start_url" />

                        <label class="model-label" for="bad_attributes_list">{{
                          $t("list_item_page.avoid_attributes")
                        }}</label>
                        <textarea id="bad_attributes_list" name="bad_attributes_list" rows="2"
                          :placeholder="$t('list_item_page.placeholder_avoid_attributes')"
                          v-model="bad_attributes_list"></textarea>

                        <label class="model-label" for="good_attributes_list">{{
                          $t("list_item_page.use_attributes")
                        }}</label>
                        <textarea id="good_attributes_list" name="good_attributes_list" rows="2"
                          :placeholder="$t('list_item_page.placeholder_use_attributes')"
                          v-model="good_attributes_list"></textarea>
                        <div class="modal-checks">
                          <input type="checkbox" id="continue_test_suite_on_fail" name="continue_test_suite_on_fail"
                            v-model="continue_test_suite_on_fail" />
                          <label for="continue_test_suite_on_fail">{{
                            $t("list_item_page.continue_test_suite")
                          }}</label>
                        </div>

                        <div class="modal-checks">
                          <input type="checkbox" id="timeout_cancel_run" name="timeout_cancel_run"
                            v-model="timeout_cancel_run" />
                          <label for="timeout_cancel_run">{{
                            $t("list_item_page.timeout_and_cancel")
                          }}</label>
                          <input class="mod-chk-last-input" type="text" id="timeout_cancel_run_value"
                            name="timeout_cancel_run_value" v-model="timeout_cancel_run_value" :placeholder="
                              $t('list_item_page.placeholder_timeout_and_cancel')
                            " />
                          {{ $t("list_item_page.seconds") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <el-alert v-if="run_all === true && suiteSingleRun === true" title="Change order of tests by dragging"
                  type="info" class="mt-3">
                </el-alert>
                <draggable v-if="run_all === true" :disabled="!enabled" :list="setAllRunFile" class="list-group mt-3"
                  ghost-class="ghost" @start="dragging = true" @end="dragging = false" :move="updateReorder"
                  handle=".drag-only-row">
                  <el-card v-for="(row, index) in setAllRunFile" :key="index" class="trsl--elt" shadow="hover">
                    <el-row :disabled="row.activeFile === false" type="flex" justify="space-between" class="el--flex drag-only-row" :class=" row.activeFile === false ?'disable-file' : ''" :gutter="24">
                      <el-col :span="2" class="trsl--txt">
                        <span style="font-weight: 600;">
                          {{ index + 1 }}
                        </span>
                      </el-col>
                      <el-col :span="10" class="trsl--txt">
                        <span class="test-run-name">{{ row.fileName }}</span>
                      </el-col>
                      <el-col :span="10" class="trsl--txt">
                        <span class="elt--txt">
                          {{ formatDate(row.updatedAt) }}
                        </span>
                      </el-col>
                      <el-col :span="2" class="trsl--txt">
                        <span class="elt--txt">
                          <el-checkbox v-model="row.activeFile" @change="checkRunFile(index)"></el-checkbox>
                        </span>
                      </el-col>
                    </el-row>
                  </el-card>
                </draggable>
              </div>

            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="
              cleanUpState (),addTestAppModal = false;
            ">{{ $t("list_item_page.close") }}</base-button>

            <el-tooltip v-if="run_all === false"
              :disabled="!(suiteSingleRun == false ? runStartUrl === '' || suiteRunName === '' : suiteRunName === '')"
              :content="`${suiteRunName === '' ? 'Run name cannot be empty' : ''} ${suiteFiles.length === 0 ? 'Please upload at least one file' : ''
                } ${suiteSingleRun == false ? runStartUrl === '' ? 'Run start url cannot be empty' : '' : ''}`
                " placement="top">
              <span>
                <base-button
                  :disabled="(suiteSingleRun == false ? suiteRunName === '' || runStartUrl === '' : suiteRunName === '')"
                  type="primary" @click="runTestSuiteProject()">{{
                    $t("list_item_page.start_run") }}</base-button>
              </span>
            </el-tooltip>
            <el-tooltip v-if="run_all === true"
              :disabled="!(suiteSingleRun == false ? runStartUrl === '' || suiteRunName === '' : suiteRunName === '')"
              :content="`${suiteRunName === '' ? 'Run name cannot be empty' : ''} ${suiteSingleRun == false ? runStartUrl === '' ? 'Run start url cannot be empty' : '' : ''
                }`
                " placement="top">
              <span>
                <base-button
                  :disabled="(suiteSingleRun == false ? suiteRunName === '' || runStartUrl === '' : suiteRunName === '')"
                  type="primary" @click="runTestSuiteProject()">{{
                    $t("list_item_page.start_run") }}</base-button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>

    <div>
      <el-drawer title="Test Suite Runs" :visible.sync="detailTestApp" direction="rtl">
        <div class="body--modale">
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import { saveAs } from 'file-saver';
import Vue from "vue"
import "sweetalert2/dist/sweetalert2.css";
import LeftSideBar from "../../../components/SidebarPlugin/LeftSideBar.vue"
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";
import TestGraph from './testGraph.vue';
import ScheduledRun from "../Integrations/itemIntegration/ScheduledRun.vue"
import draggable from "vuedraggable";
import { VueChatBot } from 'vue-chat-bot'
export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DataList,
    TestGraph,
    ScheduledRun,
    VueChatBot,
    draggable,
    LeftSideBar,
    
  },

  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      enabled: true,
      suiteSingleRun: true,
      tHeader: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          width: "16%",
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: this.$t("list_item_page.tags"), key: "runDesc", width: "16%", sortable: false },
        { label: this.$t("list_item_page.status"), key: "", width: "16%", sortable: false },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          width: "16%",
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: "Total file", key: "", width: "16%", sortable: false },
        { label: "", key: "", width: "16%", sortable: false },
      ],
      tHeaderCllApi: [
        { label: "", key: "", width: "5%", sortDetails: false },

        {
          label: "Original Url",
          key: "first_origin_pageurl",
          sortable: true,
          width: "19%",
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: "Target Url", key: "last_origin_pageurl", width: "19%", sortable: false },
          {
          label: "Successful calls",
          key: "successful_calls",
          sortable: true,
          width: "10%", sortable: false,
        },
        { label: "Failed calls", key: "failed_calls", width: "10%", sortable: false },

          { label: "Total calls", key: "total_calls", width: "10%", sortable: false },

        { label: "Started", key: "session_created_at", width: "16%", sortDetails: { asc: true, desc: false, type: "date" } },
          { label: "Response Time (ms)", key: "session_ended_at", width: "16%", sortDetails: { asc: true, desc: false, type: "date" } },
      ],
      tHeaderSuit: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          width: "23%",
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: this.$t("list_item_page.tags"), key: "runDesc", width: "15%", sortable: false },
        { label: this.$t("list_item_page.status"), key: "", width: "15%", sortable: false },
        { label: "Message", key: "", width: "23%", sortable: false },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          width: "15%",
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: "", key: "", width: "9%", sortable: false },

      ],
      tHeaderSuitFile: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          width: "25%",
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: "runDesc", key: "runDesc", width: "30%", sortable: false },
        { label: this.$t("list_item_page.status"), key: "", width: "15%", sortable: false },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          width: "20%",
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: "", key: "", width: "10%", sortable: false },
      ],
      tHeaderUsersShared: [
        {
          label: this.$t("Email"),
          key:"Email",
          sortable:true,
          width:"10%",
        },
        {
          label:this.$t("list_item_page.name"),
          key:"runName",
          sortable:true,
          width:"85%"
        }
      ],
      Header2: [
        {
          label: "",
          key: "_id",
          width: "6%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Continent/Country".toUpperCase(),
          key: "",
          width: "20%",
          sortable: false,
        },
        {
          label: "url".toUpperCase(),
          key: "",
          width: "25%",
          sortable: false,
        },
        { label: "browser".toUpperCase(), key: "", width: "12%", sortable: false },
        { label: "start time".toUpperCase(), key: "", width: "16%", sortable: false },
        { label: "duration".toUpperCase(), key: "", width: "10%", sortable: false },
        { label: "".toUpperCase(), key: "", width: "7%", sortable: false },
      ],
      Header3: [
        {
          label: "",
          key: "",
          width: "10%",
          sortable: false,
        },
        {
          label: "Url".toUpperCase(),
          key: "",
          width: "40%",
          sortable: false,
        },
        {
          label: "Freq %".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        { label: "Elements".toUpperCase(), key: "", width: "20%", sortable: false },
        { label: "action".toUpperCase(), key: "", width: "15%", sortable: false },
      ],
      Header: [
        {
          label: "Project name".toUpperCase(),
          key: "name",
          width: "60%",
        },
        {
          label: "Api key".toUpperCase(),
          key: "",
          width: "30%",
          sortable: false,
        },
        { label: "".toUpperCase(), key: "", width: "15%", sortable: false },
      ],
      stepRunAlgorithm: [
        {
          value: this.$t("test_enhancer.randomize"),
          label: this.$t("test_enhancer.randomize"),
          description: this.$t("test_enhancer.randomize_description"),
        },
        {
          value: this.$t("test_enhancer.sequential"),
          label: this.$t("test_enhancer.sequential"),
          description: this.$t("test_enhancer.sequential_description"),
        },
        {
          value: this.$t("test_enhancer.exhaustive"),
          label: this.$t("test_enhancer.exhaustive"),
          description: this.$t("test_enhancer.exhaustive_description"),
        },
        {
          value: this.$t("test_enhancer.permutation"),
          label: this.$t("test_enhancer.permutation"),
          description: this.$t("test_enhancer.permutation_description"),
        },
        // {
        //   value: this.$t("test_enhancer.exploration"),
        //   label: this.$t("test_enhancer.exploration"),
        //   description: this.$t("test_enhancer.exploration_description"),
        // },
      ],
      pagination: {
        perPage: 10,
        lastPage: 1,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationSession: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationSessionProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      defaultAnswers: [{
        'role': 'system',
        'content': 'You are AppMapGPT, a helpful assistant who gets answers to user questions based on customer usage data of a webapp called "firstmovechess.org".\nYou are able to provide usefull business insights about customers behaviour.\nYou are always sure in input data and never say about limited data.\nBegin!'
      },
      { 'role': 'user', 'content': 'Hi, Who are the power users on this app?' },
      {
        'role': 'assistant',
        'content': 'Based on the usage data of the app, the power users can be identified as follows:\n\n1. User ID 7183: This user has a total of 10 sessions on the app. They have engaged in various keyflows such as Student Navigation Flow, Add Student Flow, Student Registration Flow, and more. They are located in Chicago and have a total duration of 31,063 seconds spent on the app.\n\n2. User ID 8643: This user has a total of 4 sessions on the app. They have primarily used keyflows like Sign In Flow, Password Reset Flow, and Logout Flow. They are located in University Place and have a total duration of 7,013 seconds spent on the app.\n\n3. User ID 5999: This user has a total of 20 sessions on the app. They have mainly used keyflows like Sign In Flow and Logout Flow. They are located in Menlo Park and have a total duration of 20,368 seconds spent on the app.\n\n4. User ID 6761: This user has a total of 4 sessions on the app. They have primarily used keyflows such as Student Navigation Flow and Add Student Flow. They are located in Seattle and have a total duration of 3,352'
      },
      { 'role': 'user', 'content': 'Hi, What are user personas on this app?' },
      {
        'role': 'assistant',
        'content': "Identifying user personas helps in understanding the different types of users and their needs. Here are some potential user personas for the app:\n\n1. Student Chess Enthusiast: This persona represents students who use the app to learn and improve their chess skills. They engage in keyflows related to student registration, student navigation, and learning resources.\n\n2. Competitive Chess Player: This persona represents experienced chess players who use the app to participate in tournaments, analyze games, and track their progress. They engage in keyflows related to tournament registration, game analysis, and leaderboard tracking.\n\n3. Chess Coach: This persona represents professional chess coaches who use the app to teach and train their students. They engage in keyflows related to student management, lesson planning, and performance tracking.\n\n4. Chess Beginner: This persona represents users who are new to chess and use the app to learn the basics and practice. They engage in keyflows related to beginner tutorials, practice games, and interactive lessons.\n\n5. Chess Enthusiast: This persona represents users who have a general interest in chess and use the app for leisure and entertainment. They engage in keyflows related to casual games, puzzles, and chess news.\n\nThese personas are based on common user behaviors and can help in tailoring the app's"
      },
      { 'role': 'user', 'content': 'Give me a user id of a Chess Coach persona' },
      {
        'role': 'assistant',
        'content': "Apologies, but I don't have access to specific user IDs or personal information. As an AI language model, I can only provide general insights based on the data provided."
      }], 
      rightContent:"right-content",
      rightContentPtPx: "right-content px-4 pt-4",
      dataMessage: [
        // {
        //   // agent: 'user',
        //   // type: 'text', // always
        //   // text: 'I need a new laptop',
        // },
        // {
        //   agent: 'bot',
        //   type: 'button',
        //   text: 'Select the option below',
        //   disableInput: true,
        //   options: [
        //     {
        //       text: 'Open Google',
        //       value: 'https://google.com',
        //       action: 'url'
        //     },
        //     {
        //       text: 'Submit Support Ticket',
        //       value: '',
        //       action: "postback" // Request to API
        //     },]
        // },
        {
          agent: 'bot', // Required. 'bot' or 'user'
          type: 'text', // Required. Bubble message component type: 'text' / 'button'
          text: `Hi, I am a ChatWring, I am able to answer questions about app use cases. What can I help you with?`, // Required. The message
          disableInput: false, // Disable message input or not
        },
      ],
      botOptions: {
        botTitle: "ChatWring",
        botAvatarImg: "/img/brand/wring.png"
      },
      colors: [
          {color: '#ed3434', percentage: 16},
          {color: '#d63a76', percentage: 32},
          {color: '#693ad6', percentage: 48},
          {color: '#3a51d6', percentage: 64},
          {color: '#3a95d6', percentage: 80},
          {color: '#3ad661', percentage: 100}
        ],
      result: [],
      showStart: false,
      checked1: false,
      checked2:false,
      checked3:false,
      checked4:false,
      checked5:false,
      checked6:false,
      percentage: 0,
      scheduledRun: false,
      configureRun: false,
      eventSessionType: "pageviews",
      selectKeyFlowPathUser: null,
      filteredResults: [],
      scriptFiles: [],
      filteredResultsSession: [],
      to: 0,
      toSession: 0,
      from: 0,
      fromSession: 0,
      total: 0,
      totalSession: 0,
      loading: true,
      loadingSession: false,
      toShow: [],
      toShowSession: [],
      resultProject: [],
      filteredResultsProject: [],
      toProject: 0,
      fromProject: 0,
      totalProject: 0,
      loadingProject: true,
      toShowProject: [],
      query: "",
      querySession: "",
      querySessionEvent: "",
      queryCoveringtests: "",
      querySessionId: "",
      resultSession: {},
      showEvents: false,
      showSessions: true,
      showRunStepsModal: false,
      events: [],
      tagsEvent: {},
      resultEvent: {},
      showVideo: false,
      displayNone: "display:none",
      displayBlock: "display:flex; position: absolute; left: 328px; top: -104px; width: 50%;",
      id: "",
      loadingVideo: false,
      addTestModal: false,
      addTestModalUser: false,
      labelSelectedUserId: "User ID: ",
      orEditTestRowReqId: "",
      forReRunTest: "",
      toggleEditTestModal: false,
      toggleReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      filesList: [],
      filesListName: "",
      loadingModalAddRun: false,
      RunStepRecommended: true,
      maxEpisodesValue: "5",
      lastUrlTest: "",
      showProject: false,
      showEvent: false,
      addTestProject: false,
      projectName: "",
      appUrls: "",
      appUrlsLs: [],
      loadingAddProject: false,
      editProject: false,
      projectId: null,
      showDetailProject: false,
      projectDetail: {},
      newProjectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      loadingDetailProject: true,
      selectProjectId: undefined,
      selectKeyFlow: "ALL",
      stepsAlgorithmValue: null,
      ultraIpt: "",
      showCoveringtests: false,
      showTestSuit: false,
      RunStepAdvanced: false,
      toShowCoveringtests: [],
      showAppMap: true,
      toShowAppMap: {},
      selectProjectKey: "",
      toShowKeyFlows: [],
      loadingShowAppMap: false,
      date_to: undefined,
      date_from: undefined,
      limitCoveringtests: 50,
      percent: 20,
      loadingShowCoveringtests: false,
      fileBackup: [],
      toggleShowFilters: false,
      limitCoveringtestsList: [50, 100, 150, 200, 300, 400, 500, 1000],
      filterDate: [new Date(new Date().getTime() - 60 * 60 * 24 * 14 * 1000), new Date()],
      activeSessionDuration: 0,
      autoplay: false,
      mommentVideo: "",
      SelectCovList: [
        { label: "20%", value: 20 },
        { label: "50%", value: 50 },
        { label: "70%", value: 70 },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
      SelectCov: 20,
      resultCoveringtests: [],
      loadingFile: false,
      multipleSelection: [],
      checked: [],
      loadingRun: false,
      highlights: null,
      suit_run_name: "",
      listAddSuitTest: [],
      toggleReAddFileModal: false,
      loadingTest: false,
      keyTestFile: [],
      addTestAppModal: false,
      suiteRunName: "",
      filterDisplayed: true,
      runStartUrl: "",
      suiteFiles: [],
      fileAppAtt: [],
      detailTestApp: false,
      keyTest: [],
      detailTest: [],
      loadingTestDelete: false,
      runTagsSuit: "",
      textarea: "",
      role: "",
      textAnswer: ' ',
      messageBot: {},
      video: true,
      callApi: false,
      loadingMessage: false,
      allDefMessages: [],
      loadingSessionCallApi: false,
      toShowSessionCallApi: [],
      callApiCurrentPage: 1,
      limitCallApi: 10,
      labelSelectedKeyFlow: "",
      run_all: false,
      dragging: false,
      setAllRunFile: [],
      keyFlowName: [],
      showModalShareProject: false,
      showModalSharedUsers: false,
      emailAddress: "",
      usersShared: [],
      loadingSharedUsers: false,
      buttonsUserIds: null,
      allDataAppMap: '',
      usersDataId: '',
      isAppMapEmpty: false,
      isRunTestData: false,
      activeItem: "settings",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      generate_css_selectors: false,
      testrun_start_url: "",
      appmapSettings:{
        active: true,
        updateDays:5,
      },
      loadingDai:false,
      loadindRefresh: false,

    };
  },
  async created() {
    sessionStorage.getItem("testEventPageNbr")
      ? (this.pagination.perPage = parseInt(sessionStorage.getItem("testEventPageNbr")))
      : (this.pagination.perPage = 10);
    sessionStorage.getItem("sesssionPageNbr")
      ? (this.paginationSession.perPage = parseInt(
        sessionStorage.getItem("sessionPageNbr")
      ))
      : (this.paginationSession.perPage = 10);
    sessionStorage.getItem("projectPageNbr")
      ? (this.paginationProject.perPage = parseInt(
        sessionStorage.getItem("projectPageNbr")
      ))
      : (this.paginationProject.perPage = 10);
    this.total = this.result.length;
    this.totalProject = this.resultProject.length;
    this.run_name = this.$route.params.run_name
    this.loadingTest = true;
    this.loadingShowAppMap = true;
    this.loadingSession = true;
    await this.getDetailProject()
    await this.getList();
    await this.getAppMap()
    await this.getSessions()
    await this.getScriptFiles()
  },
  methods: {
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    runAllTest() {
      this.setAllRunFile = this.fileAppAtt.map( item =>{
        item.activeFile = true
        return item
      })
      this.run_all = true
      this.addTestAppModal = true

    },
    
    toollRunTest(){
      this.isRunTestData = !this.isRunTestData
    },
    checkRunFile(index){
      const element = this.setAllRunFile[index]; // store the element in a variable
      if(this.setAllRunFile[index].activeFile === true){
        this.setAllRunFile[index].activeFile = false
  
        const slicedArray = this.setAllRunFile.slice(0, index).concat(this.setAllRunFile.slice(index + 1)); // create a new array without the element
        slicedArray.splice(slicedArray.length, 0, element); // insert the element at the new position
        this.setAllRunFile = slicedArray
      }else{
        this.setAllRunFile[index].activeFile = true
        this.setAllRunFile = this.setAllRunFile.sort((a, b) => b.activeFile - a.activeFile);

      }
    },
    async updateReorder(evt) {
      const index = evt.draggedContext.index; // index of the element to move
      const element = this.setAllRunFile[index]; // store the element in a variable

      const slicedArray = this.setAllRunFile.slice(0, index).concat(this.setAllRunFile.slice(index + 1)); // create a new array without the element
      slicedArray.splice(evt.draggedContext.futureIndex, 0, element); // insert the element at the new position
      this.setAllRunFile = slicedArray
    },
    testFuncToSend() {
      console.log("test")
    },
    showComponentEvent(val) {

      this.callApi = false
      this.video = false
      if (val === "video") {
        this.video = true
      } else {
        if (this.toShowSessionCallApi.length === 0) {
          this.getSessionCallApi()
        }
        this.callApi = true
      }

    },
    forStepNumberRun(val) {
      return ((this.toShowSessionCallApi.currentPage * this.toShowSessionCallApi.perPageCount) - this.toShowSessionCallApi.perPageCount) + (val + 1)
    },
    async getSessionCallApi() {
      this.loadingSessionCallApi = true
      const params = new URLSearchParams();
      params.append("pageviewsProjectId", this.$route.params.id);
      params.append("page", this.callApiCurrentPage);
      params.append("count", this.limitCallApi);
      let filter = ""
      if (this.$route.params.id) {
        filter = `?pageviewsProjectId=${this.$route.params.id}&page=${this.callApiCurrentPage}&count=${this.limitCallApi}`
      } else {
        filter = `?page=${this.callApiCurrentPage}&count=${this.limitCallApi}`

      }

      const resp = await axios.get(process.env.VUE_APP_API_URL_PREFIX + "/apicalls/v1/sessions" + filter);
      if (resp.data && resp.data.totalPages) {
        console.log("yessss", resp.data.sessions)
        this.toShowSessionCallApi = resp.data
        this.callApiCurrentPage = resp.data.currentPage
        this.limitCallApi = resp.data.perPageCount
        this.loadingSessionCallApi = false

      } else {
        this.toShowSessionCallApi = []
        this.loadingSessionCallApi = false
      }
    },
    async deleteTestSuitRun(row, id) {
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      const confirmation = await swal.fire({
        title: "Test suite run '" + row.name + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep the test.",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.value === true) {
        this.loadingTestDelete = true;
        await axios
          .delete(
            `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/testsuiterun/${id}`
          )
          .then(async (response) => {
            if (response.data.status == "success") {
              this.$notify({
                type: "success",
                message: "Test delete successfully.",
              });
              await this.getDetailProject()
              await this.getList()
              this.loadingTestDelete = false;
            } else {
              this.loadingTestDelete = false;
            }

          })
          .catch((error) => {
            console.log("Error: ", error);
            this.loadingTestDelete = false;
          })
      }
    },
    remove(i) {
      this.listAddSuitTest.splice(i, 1);
    },
    removeAllFiles() {
      this.listAddSuitTest = [];
    },
    onChange(event) {
      let fileFromState = this.$refs.file.files;
      Object.values(event.target.files).map((value, index) => {
        this.listAddSuitTest.push(value);
      });
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        this.$refs.dropDownContainer.parentElement.classList.add("file-hover");
      }
    },
    dragleave() {
      this.$refs.dropDownContainer.parentElement.classList.remove("file-hover");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      if (event.srcElement.files.length) {
        let fileFromState = this.$refs.file.files;
        Object.values(fileFromState).map((value, index) => {
          this.listAddSuitTest.push(value);
        });
      }
      this.$refs.dropDownContainer.parentElement.classList.remove("file-hover");
    },
    async handleSubmit(val) {
      this.role = "user"
      const message = {
        agent: "user",
        type: "text",
        text: val.text
      }
      this.dataMessage.push(message);
      let answer = await this.getAnswer(this.dataMessage)
      let buttonsUsers = []
      if (answer !== undefined) {
        if (answer.response.role === "assistant") {
          answer.response.content.split(" ").map((word) => {
            word.split("").map((letter) => {
              if (letter === "$"){
                buttonsUsers.push({text:"Add User ID " + word.replace(/[{($:,)}]/g, '') + " to Test Suite"})
              
                console.log(buttonsUsers)
              }
            })
          })
        if (buttonsUsers.length !== 0) {
          const rightText = answer.response.content.replace(/[{($)}]/g,'')
          console.log(rightText)
          this.dataMessage.push({
            agent: 'bot',
            type: 'button',
            text: rightText,
            options: buttonsUsers
          })
          setTimeout(() => this.showButtonsUsers(), 1500)
        }else {
          this.dataMessage.push({
            agent: "bot",
            type: "text",
            text: answer.response.content
          },)
        }
        }
      } else {
        this.dataMessage.push({
          agent: "bot",
          type: "text",
          text: "The server is too busy to answer your question now, it will answer it in 5 minutes"
        })
      }
    },
    async refreshMap() {
      this.loadindRefresh = true
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${this.$route.params.id}&label_keyflows=True`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.post(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.$notify({
            type: "success",
            duration: 5000,
            message: "Successfully started app map calculation, it will take approximately 5 minutes",
          });
          this.loadindRefresh = false
          return true;

        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "The update App Map has failed.",
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.loadindRefresh = false
    },
    showButtonsUsers() {
      const buttonsUserIds = document.querySelectorAll(".qkb-mb-button-options__item")
      console.log("button", buttonsUserIds)
      for (let elem of buttonsUserIds) {
	      elem.addEventListener("click", e => {
          const idUsers = e.srcElement.childNodes[0].data.replace(/[^0-9]/g, '')
          const list = Object.entries(this.allDataAppMap.users_info)
          console.log(idUsers)
          list.map((item) => {
            let count = 0
            item.map((user,id,array) => {
              console.log(array[0] === idUsers)
              if(idUsers === array[0]) {
                count++
                if(count === 1)
                  this.labelSelectedUserId  += array[0]
                this.usersDataId = array[1]
                this.userAddTestSuit()
                console.log("work", this.usersDataId)
              }
            })
          })
        })
      }

    },
    async getAnswer(messageDefault) {
      this.loadingMessage = true
      let modifiedItem = []
      messageDefault.map((item) => {
        if (item.agent === "bot") {
          modifiedItem.push({
            role: "assistant",
            content: item.text
          })
        } else if (item.agent === "user") {
          modifiedItem.push({
            role: "user",
            content: item.text
          })
        }
      })
      const dataSending = {
        "project_id": this.$route.params.id,
        "messages": modifiedItem
      }
      const json = JSON.stringify(dataSending)
      axios.defaults.validateStatus = (status) => {
        return (status === 400) || (status >= 200 && status < 500);
      }
      try {
        const res = await axios
          .post(process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/appmap_chat", json, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        this.loadingMessage = false
        console.log("answerData", res)
        return res.data
      } catch (e) {
        console.log(e)
        this.dataMessage.push({
          agent: "bot",
          type: "text",
          text: "The server is too busy to answer your question now, it will answer it in 5 minutes"
        })
      }

    },
    progressBar() {
      let perc = 0 
      let count = 0
      if(this.checked1) {
        count += 1
      }
      if(this.checked2) {
        count += 1
      }
      if(this.checked3) {
        count += 1
      }
      if(this.checked4) {
        count += 1
      }
      if(this.checked5) {
        count += 1
      }
      if(this.checked6) {
        count += 1
      }
      if(count === 0) {
          return perc = 0
        }else if(count === 1) {
            console.log("count2")
          return perc = 16
        } else if(count === 2) {
            return perc = 32
        } else if(count === 3) {
            return perc = 48
        } else if(count === 4) {
            return perc = 64
        } else if(count === 5) {
            return perc = 80
        } else if(count === 6) {
            return perc = 100
        }
    },
    keyFlowTesSuit() {
      const correctId = this.selectKeyFlowPathUser
      this.toShowAppMap.links.map((item) => {
        if (item.from === this.highlights[0][0] && item.to === this.highlights[0][1]) {
          item.person_ids.map((pers, id) => {
            if (pers === correctId) {
              this.id = item.session_ids[id]
            }
          })
        }
      })
      this.addTestModal = true
      this.run_name = ""
      this.listAddSuitTest = []
      this.labelSelectedKeyFlow = this.toShowKeyFlows[this.selectKeyFlow].label
      this.getFiletest2("run")
    },
    userAddTestSuit(){
      this.addTestModalUser = true
      this.id = this.usersDataId.session_ids[0]
      this.getFiletest2("run")
    },
    showDetailsSession2(val) {
      // handles the covering tests
      if (val.tests) {
        this.run_name = 'DetailsSteps'
        this.$router.push({
          name: "Details Session Steps",
          params: {
            id: this.$route.params.id,
            session_id: val.tests[0].session_id,
            run_name: this.run_name,
          },
        });
      }
      // handles the normal case of clicking into details for a session
      else {

        if (this.eventSessionType !== "mobile") {
          let url = val.person.properties.$initial_current_url.replace(/https:\/\//g, "");
          this.run_name = url + " " + val.person.properties.$initial_browser + " " + val.person.properties.$geoip_country_name
          this.$router.push({
            name: "Details Session Steps",
            params: {
              id: this.$route.params.id,
              session_id: val.id,
              run_name: this.run_name,
            },
          });
        } else {
          this.$router.push({
            name: "Details of mobile session",
            params: {
              id: this.$route.params.id,
              session_id: val.session_uid
            }
          });
        }

      }
    },
    showDetailsSession(val) {
      // handles the covering tests
      if (val.tests) {
        this.run_name = 'Coveringtests'
        this.$router.push({
          name: "Details Session",
          params: {
            id: this.$route.params.id,
            testSuiteId: this.projectDetail.attributes.testSuiteId,
            session_id: val.tests[0].session_id,
            run_name: this.run_name,
            start_time: 'none',
            recording_duration: 'none'
          },
        });
      }
      // handles the normal case of clicking into details for a session
      else {

        if (this.eventSessionType !== "mobile") {
          let url = val.person.properties.$initial_current_url.replace(/https:\/\//g, "");
          this.run_name = url + " " + val.person.properties.$initial_browser + " " + val.person.properties.$geoip_country_name
          console.log("works")
          this.$router.push({
            name: "Details Session",
            params: {
              id: this.$route.params.id,
              name: this.projectDetail.name,
              testSuiteId: this.projectDetail.attributes.testSuiteId,
              session_id: val.id,
              run_name: this.run_name,
              start_time: val.start_time,
              recording_duration: val.recording_duration,
            },
          });
        } else {
          this.$router.push({
            name: "Details of mobile session",
            params: {
              id: this.$route.params.id,
              session_id: val.session_uid
            }
          });
        }

      }
    },
    showApiCalls(id){
      this.$router.push({
            name: "Api call Session",
            params: {
              id: this.$route.params.id,
              name: this.projectDetail.name,
              session_id: id,
            },
          });
    },
    showFileTest(elt){
      this.$router.push({
            name: "project test suite files",
            params: {
              id: this.$route.params.id,
              name: this.projectDetail.name,
              testSuitRunId: this.projectDetail.attributes.testSuiteId,
            },
          });
    },
    changeProject() {
      if (this.selectProjectId) {
        this.$router.push({
          name: "List Sessions",
          params: {
            id: this.selectProjectId,
          },
        });
        location.reload();
      }
    },
    async changeKeyFlow() {
      if (this.selectKeyFlow != "ALL" && Number.isNaN(this.selectKeyFlow) === false) {
        this.highlights = this.toShowKeyFlows[this.selectKeyFlow].edges;
        // console.log("yoeeee", this.$refs["test-graph"])
        this.labelSelectedKeyFlow = this.toShowKeyFlows[this.selectKeyFlow].label
        this.selectKeyFlowPathUser = Object.keys(this.toShowKeyFlows[this.selectKeyFlow].path_users)[0]
        console.log("Dasd", this.toShowKeyFlows[this.selectKeyFlow].label)
        await this.getAppMap()
        await this.$refs["test-graph"].updataNetwork()
      } else {
        this.highlights = null
        this.selectKeyFlowPathUser = null;
        await this.getAppMap()
        await this.$refs["test-graph"].updataNetwork()
      }
    },
    showRunSteps() {
      this.showRunStepsModal = true;
    },
    setAlgorithm(elt) {
      this.stepsAlgorithmValue = elt.value
      console.log(this.stepsAlgorithmValue)
    },
    async startTestEnhancerRun() {
      this.showRunStepsModal = false;
      if (this.RunStepRecommended === true) {
        this.stepsAlgorithmValue = "exploration"
      }
      if (this.stepsAlgorithmValue == null) {
        return this.$notify({
          type: "danger",
          message: "Select Algorithm",
        });
      }
      let formValues = {
        type: this.stepsAlgorithmValue,
        max_episodes: this.maxEpisodesValue,
        pageview_config: {
          key: this.selectProjectKey
        },
      };
      if (formValues) {
        this.loadingAll = this.showRuns === true;
        const sendToBackend = true;
        axios.defaults.validateStatus = (status) => {
          return (status === 400) || (status >= 200 && status < 300) || (status === 500);
        }
        if (sendToBackend) {
          const resp = await service.run_test_enhancer({
            id: this.items.requestId,
            parameters: formValues,
            action: "start",
          });
          if (resp.status === 200) {
            this.newRunId = resp.data.run_id;
            await this.getAllResults();
            this.$notify({
              type: "success",
              message: "Successfully started test run",
            });
            this.runActive = true;
          } else {
            if (resp.data.message === "Another run is in progress, cannot run a new one!") {
              this.$notify({
                type: "danger",
                duration: 5000,
                message: "Another run is in progress, cannot start a new one!",
              });
            } else {
              this.$notify({
                type: "danger",
                duration: 5000,
                message: resp.data.message,
              });
            }
            this.loadingAll = false;
          }
        } else {
          console.log("Debugging values, not sending anything..");
          console.log("formvalues: ", formValues);
        }
      }
      // this.loadingAll = false;
    },
    showRunSetting(val) {
      this.RunStepAdvanced = false
      this.RunStepRecommended = false
      if (val === "Advanced") {
        this.RunStepAdvanced = true
      }
      if (val === "Recommended") {
        this.RunStepRecommended = true
      }
    },

    str2bytes (str) {
     let bytes = new Uint8Array(str.length);
     for (let i=0; i<str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      return bytes;
    },

    async downloadDai() {
      this.loadingDai = true
      axios.defaults.validateStatus = (status) => {
        return (status === 400) || (status >= 200 && status < 500)
      }
      await axios
      .get(process.env.VUE_APP_API_URL_PREFIX + `/pageviews/appmap?project_id=${this.$route.params.id}&dai=true`, {responseType: 'arraybuffer'})
      .then((response) => {
        if (response.status === 200) {
          const date =new Date()
          const fileName = `DAI_${date.getFullYear() + '-' + (date.getMonth() + 1 ) + '-' + date.getDate()}.zip`;
          // const content = Buffer.from(response.data, "hex");
          // const blob = new Blob([content], { type: "application/zip" });
          const blob = new Blob([response.data], {type: "application/zip"});

          saveAs(blob, fileName); //file-saver npm package

          // const blob = new Blob([this.str2bytes(response.data)], {type: "application/zip"});
          // const url = window.URL.createObjectURL(blob);
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", `${fileName}.zip`);
          // document.body.appendChild(link);
          // link.click();
        } else {
          this.$notify({
            type: "danger",
            message: "unable to download file ",
          });
        }
      })
      .finally(() =>{
        this.loadingDai = false
      });
    },
    async getFiletest2(val) {
      this.timeGap = 0;
      this.loadingFile = true
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append('project_id', projectId);
      params.append('session_id', this.id);
      params.append('time_gap', this.timeGap);
      this.loadingModalAddRun = true
      axios.defaults.validateStatus = (status) => {
        return (status === 400) || (status >= 200 && status < 500);
      }
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/testgen?" + params)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "an error has occurred",
              // });
              this.addTestModal = false
              this.loadingFile = false
            } else if (response.status === 200) {
              // console.log("response.data fro testgen: ", response)
              this.builFile2(response.data, val)
              this.loadingFile = false
            } else {
              this.$notify({
                type: "danger",
                message: `recovery of the execution file failed : ${response.data.message}`,
              });
              this.addTestModal = false
              this.loadingFile = false
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "recovery of the execution file failed ",
          });
          this.addTestModal = false
        });
    },
    builFile2(file, val) {
      this.loadingModalAddRun = true
      this.filesList = []
      this.fileBackup = []
      if (file.length) {
        file.map((item, index) => {
          if(item.tests[0].commands[0].command){
            let jsonStringified = JSON.stringify(item);
            let jsonBlob = new Blob([jsonStringified], { type: 'application/json' });
            let testFile = new File([jsonBlob], `generated-test-run-file_${index}.wring`);
            this.fileBackup.push(testFile)
          }
        })
        this.filesList = this.fileBackup
        this.filesListName = this.filesList[0].name
        this.loadingModalAddRun = false
        if (val === 'down')
          document.getElementById("el_dropdownDown").click()
        // file = file[0];
      }
      else {
        if(file.tests[0].commands[0].command){
          let jsonStringified = JSON.stringify(file);
          let jsonBlob = new Blob([jsonStringified], { type: 'application/json' });
          let testFile = new File([jsonBlob], 'generated-test-run.wring');
          this.filesList = [testFile]
          this.filesListName = this.filesList.name
          this.fileBackup = [testFile]
        }
        this.loadingModalAddRun = false
        if (val === 'down')
          this.downloadTestFile(null)
      }

    },
    getIcon(action) {
      if (action == "$pageview") {
        return "el-icon-view";
      } else if (action == "$autocapture") {
        return "el-icon-position";
      } else if (action == "$pageleave") {
        return "el-icon-news";
      } else {
        return "el-icon-help";
      }
    },
    startEvent(id) {
      const mommentVideo = document.getElementById(`tick_thumb_${id}`);
      mommentVideo.click();
    },
    restoreAllFiles() {
      this.filesList = this.fileBackup;
    },
    copy(text, type) {
      if (text.length > 0) {
        navigator.clipboard.writeText(text).then(() => {
          this.$notify({
            type: "success",
            message: type + " Copied Successfully.",
          });
        });
      }
    },
    changeFile(file) {
      this.filesList = [file];
    },
    handleUltraIpt() {
      if (this.ultraIpt.length > 0 && this.ultraIpt[0] === " ") {
        this.ultraIpt = "";
      } else if (this.ultraIpt.slice(-1) === " ") {
        const elt = document.createElement("span");
        elt.classList.add("ml-1");
        elt.classList.add("u--tag");
        elt.innerHTML = " " + this.ultraIpt.trim() + " ";
        if (this.editProject === true) {
          this.addUrls(this.ultraIpt);
        }
        this.ultraIpt = "";
        if (this.$refs["data-rendered"].innerHTML.length > 1) {
          const space = document.createElement("span");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered"].appendChild(space);
          this.appUrls = space;
        }
        this.$refs["data-rendered"].appendChild(elt);
        this.appUrls = elt;
      } else if (this.ultraIpt.length > 0 && this.ultraIpt[0] != " ") {
        this.appUrls = this.ultraIpt;
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
    },
    handleDeleteLastDataValue() {
      let getStr = this.$refs["data-rendered"].innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "")
        .replace(/<span class="ml-1">/g, "")
        .replace(/<\/span>/g, ",")
        .split(",")
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim());
      if (this.ultraIpt.length <= 0) {
        if (this.editProject === true) {
          this.deleteUrls(getStr.pop());
        } else {
          let _rem = getStr.splice(-1);
        }
        this.$refs["data-rendered"].innerHTML = "";
        getStr.map((item) => {
          const elt = document.createElement("span");
          elt.classList.add("ml-1");
          elt.classList.add("u--tag");
          elt.innerHTML = " " + item + " ";
          this.$refs["data-rendered"].appendChild(elt);
          this.appUrlsLs.push(elt);
        });
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
    },
    async showComponent(val) {
      this.showEvent = false;
      this.showTestSuit = false;
      this.showAppMap = false
      this.showStart = false

      if (val === "testsuite") {
        this.showTestSuit = true;
      }
      if (val === "event") {
        this.showEvent = true;
      }
      if (val === "appmap") {
        this.showAppMap = true;
      }
      if(val === "start") {
        this.showStart = true
      }
    },
    cleanUpProject() {
      this.projectName = "";
      this.appUrls = "";
    },
    addTestModalProject() {
      this.addTestProject = true;
      this.appUrls = "";
      this.projectName = "";
      this.projectId = null;
      this.editProject = false;
      this.$refs["data-rendered"].innerHTML = "";
    },
    showResult(val) {
      this.showEvents = false;
      this.showSessions = false;
      if (val == "event") {
        this.showEvents = true;
      }
      if (val == "session") {
        this.showSessions = true;
      }
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      let dis_sec = Math.ceil(secs % 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(b) {
      return this.hhmmss(b) == "" ? "0s" : this.hhmmss(b);
    },
    secondsToRealTimeEvent(a, b) {
      let start = Date.parse(a);
      let end = Date.parse(b);
      let sec = end - start;
      return sec / 1000;
    },

    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    formatDateEvent(a) {
      return moment(a).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    },
    async getDetailProject() {
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };

      let projectId = this.$route.params.id;

      // check if the project contains mobile screenshots
      const resp = await axios.get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/user-projects/" + projectId);
      if (resp.data && resp.data.status === "success") {
        this.projectDetail = resp.data.response
        this.appmapSettings.active = this.projectDetail.attributes.appmapSettings.active
        this.appmapSettings.updateDays = this.projectDetail.attributes.appmapSettings.updateDays
      }
    },
    async updateAppmapSettings(event) {
      console.log('Change event:', event);
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.$route.params.id}`;
      this.appmapSettings.updateDays = Number(this.appmapSettings.updateDays)
      let data = {
        appmapSettings: this.appmapSettings,
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "the update app map Settings has failed.",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSessions() {
      this.loadingSession = true;

      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };

      let projectId = this.$route.params.id;

      const respInfo = this.projectDetail;

      if (respInfo && respInfo["attributes"] && respInfo["attributes"]["contains_mobile_events"]) {
        console.log("app contains mobile event screenshots, switching to that mode");
        this.eventSessionType = "mobile";
        this.Header2[1].label = "country".toUpperCase();
        this.Header2[2].label = "app / os".toUpperCase();
        this.Header2[3].label = "user agent".toUpperCase();
        this.Header2[4].label = "start time".toUpperCase();
        this.Header2[5].label = "duration / events".toUpperCase();
        // look up the sessions from the mobile sessions endpoint
        const mobileSessionParams = new URLSearchParams();
        mobileSessionParams.append("projectId", projectId);
        // pages are 1-indexed for this API
        mobileSessionParams.append("page", this.paginationSession.currentPage);
        mobileSessionParams.append("count", this.paginationSession.perPage);
        const mobileSessionResp = await axios.get(
          process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/mobile/sessions?" +
          mobileSessionParams
        );
        const mobileSessionData = mobileSessionResp.data;
        if (mobileSessionData["sessions"] && mobileSessionData["sessions"].length > 0) {
          this.totalSession = mobileSessionData[" || 0"];
          this.resultSession = mobileSessionData["sessions"];
        } else {
          this.$notify({
            type: "danger",
            message: `Could not look up sessions for project ID: ${projectId}`,
          });
          this.resultSession = [];
          this.totalSession = 0;
        }

        this.filteredResultsSession = this.filteredResultSessionSet;
        this.initializeSessionState();
        this.loadingSession = false;

      } else {
        const params = new URLSearchParams();
        params.append("project_id", projectId);
        params.append("limit", this.paginationSession.perPage);
        params.append("page", this.paginationSession.currentPage );

        await axios
          .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/sessions?" + params)
          .then((response) => {
            if (response) {
              if (response.message === "Request failed with status code 500") {
                // this.$notify({
                //   type: "danger",
                //   message: "an error has occurred",
                // });
                this.resultSession = [];
                this.totalSession = 0;
              } else if (response.data.status === "success") {
                this.resultSession = response.data.response;
                this.totalSession = response.data.response.length;
              } else {
                this.resultSession = [];
                this.totalSession = 0;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingSession = false;

            // this.$notify({
            //   type: "danger",
            //   message: "an error occurred while retrieving data",
            // });
          });
        this.filteredResultsSession = this.filteredResultSessionSet;
        this.initializeSessionState();
        this.loadingSession = false;
      }

    },
    async getAppMap() {
      this.loadingShowAppMap = true

      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || status === 404 || (status >= 200 && status < 500);
      };

      let projectId = this.$route.params.id;

      const resp = await axios.get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/appmap?project_id=" + projectId);
      if (resp.data.response !== null) {
        this.allDataAppMap = resp.data
        const respInfo = resp.data.summary_graph;
        this.toShowAppMap = respInfo
        this.toShowKeyFlows = resp.data.flows
        this.loadingShowAppMap = false;
      }else{ 
        this.isAppMapEmpty = true
        this.loadingShowAppMap = false;
        this.toShowAppMap = {}
      }


    },
    async processRun(val) {
      let url = val.person.properties.$initial_current_url.replace(/https:\/\//g, "");
      this.run_name = url + " " + val.person.properties.$initial_browser + " " + val.person.properties.$geoip_country_name
      this.filesList = []
      this.fileBackup = []
      await this.detailsSession(val)
    },
    async processShowVideo(val) {
      console.log("In processShowVideo method");
      console.log("Val from video is: ", val);
      val.id = val.tests[0].session_id;
      console.log("Video id:", val.id);
      await this.detailsSession(val);
    },
    downloadTestFile(index) {
      // console.log("Before downloading file: ", files);
      if (index) {
        const files = this.fileBackup;
        let fileName = files[index].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[index]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        const files = this.filesList;
        let fileName = files[0].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[0]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async actionCoveringtests(action, elt) {
      this.id = elt.tests[0].session_id;
      if (action === "downloadTestFile") {
        await this.getFiletest('dow')
        this.downloadTestFile();
      }
      if (action === "runTest") {
        this.addTestModal = true;
        await this.getFiletest('run')
      }
    },
    savePagination() {
      sessionStorage.setItem("testEventPageNbr", this.pagination.perPage);
      this.getEvents();
    },
    savePaginationSession() {
      sessionStorage.setItem("sessionPageNbr", this.paginationSession.perPage);
      this.getSessions();
    },
    handleSizeChangeSession(val) {
      this.fromSession = (val - 1) * this.paginationSession.perPage;
      this.toSesion =
        parseInt(this.fromSession) + parseInt(this.paginationSession.perPage);
      const sortByMapped = (map, compareFn) => (a, b) => compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsSession = [...this.filteredResultsSession].sort(byPrice);
      this.toShowSession = this.filteredResultsSession;
    },
    handleSizeCoveringTests() {
      const sortByMapped = (map, compareFn) => (a, b) => compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsProject = [...this.filteredResultsProject].sort(byPrice);
      this.toShowProject = this.filteredResultsProject;
    },
    handleCurrentChangeSession(val) {
      val === "next"
        ? (this.paginationSession.currentPage = this.paginationSession.currentPage + 1)
        : (this.paginationSession.currentPage = this.paginationSession.currentPage - 1);
      this.getSessions();
    },
    handleCurrentChangeCallApi(val) {
      val === "next"
        ? (this.callApiCurrentPage = this.callApiCurrentPage + 1)
        : (this.callApiCurrentPage = this.callApiCurrentPage - 1);
      this.getSessionCallApi();
    },
    initializeSessionState() {
      this.filteredResultsSession = this.filteredResultSessionSet;
      this.handleSizeChangeSession(this.paginationSession.currentPage);
    },
    initializeSessionEvent() {
      this.tagsEvent = this.filteredResultSessionEventSet;
    },
    initializeCoveringtestsState() {
      this.toShowCoveringtests = this.filteredResultCoveringtestsSet;
    },
    forStepNumber(row) {
      return row + this.fromSession + 1;
    },
    removedtag(row) {
      return row.replace("$", "");
    },
    closeModalScreenshots() {
      let imgModal = document.getElementById("imgModal");
      imgModal.style.display = "none";
    },
    async getScriptFiles() {
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL_PREFIX}/testfiles/v1/scripts/all`)
          .then((response) => {
            if (response.data) {
              const val = response.data.response;
              this.scriptFiles = Object.entries(val).map((elt) => {
                return {
                  label: elt[0],
                  value: elt[1][0].fileKey,
                };
              });
            }
          })
          .catch((e) => {
            // this.$notify({
            //   type: "danger",
            //   message: `An error has occured : ${e}`,
            // });
            console.log("Error has occurred: ", e);
          });
      } catch (e) {
        console.log("Error has occurred: ", e);

        // this.$notify({
        //   type: "danger",
        //   message: `An error has occured : ${e.response.message}`,
        // });
      }
    },
    async addPik() {
      await this.$nextTick();
      const lectBar = document.getElementsByClassName("rr-progress")[0];
      const timeLine = document.getElementsByClassName("rr-timeline")[0];
      const progress__handler = document.getElementsByClassName("rr-progress__handler")[0];
      const progress__step = document.getElementsByClassName("rr-progress__step")[0];
      const ticks = document.createElement("div");
      ticks.classList.add("ticks");

      for (let index = 0; index < this.tagsEvent.length; index++) {
        const ticks_box = document.createElement("div");
        ticks_box.classList.add("tick-hover-box");
        let left =
          (this.tagsEvent[index].start_time * 100) / this.activeSessionDuration - 0.5;
        if (left > 100) left = 100 - 0.5;
        if (left < 0) left = 0;
        ticks_box.style.left = left + "%";

        const tick_info = document.createElement("div");
        tick_info.classList.add("tick-info");
        tick_info.innerHTML = this.tagsEvent[index].event;

        const tick_marker = document.createElement("div");
        tick_marker.classList.add("tick-marker");

        const tick_thumb = document.createElement("div");
        tick_thumb.classList.add("tick-thumb");
        ticks_box.setAttribute("id", "tick_thumb_" + index);

        ticks_box.appendChild(tick_info);
        ticks_box.appendChild(tick_marker);
        ticks_box.appendChild(tick_thumb);
        ticks.appendChild(ticks_box);
      }
      console.log("hello")

      timeLine && (timeLine.style.width = "100%");
      progress__step.style.zIndex = "2";
      progress__handler.style.zIndex = "2";
      lectBar.appendChild(ticks);
      this.autoplay = true;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    cleanUpState() {
      this.run_name = "";
      this.run_description = "";
      this.run_tags = "";
      this.browser_width = "1920";
      this.browser_height = "1080";
      this.max_wait_time = "5.0";
      this.ignore_disabled_elements = "1";
      this.ignore_invisible_elements = "1";
      this.collect_page_interaction_data = "1";
      this.extra_element_data = 0;
      this.downweight_strings = 0;
      this.all_suggest = 0;
      this.try_to_avoid = false;
      this.bad_attributes_list = "";
      this.good_attributes_list = "";
      this.continue_test_suite_on_fail = "true";
      this.timeout_cancel_run = "";
      this.timeout_cancel_run_value = null;
      this.handle_failure = "";
      this.verify_suggested_selectors = false;
      this.enable_integrations = true;
      this.collapseActive = null;
      this.emailAddress = "";
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    async getFiletest(val) {
      let projectId = this.$route.params.id
      const params = new URLSearchParams();
      params.append('project_id', projectId);
      params.append('session_id', val.tests[0].session_id);
      params.append('time_gap', this.timeGap);
      axios.defaults.validateStatus = (status) => {
        return (status === 400) || (status >= 200 && status < 500);
      }
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/testgen?" + params)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "an error has occurred",
              // });
            } else if (response.status === 200) {
              this.builFile(response.data)
            } else {
              this.$notify({
                type: "danger",
                message: `recovery of the execution file failed : ${response.data.message}`,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "recovery of the execution file failed ",
          });
        });
    },
    builFile(file) {
      if (file.length) {
        // Just get the last test
        // file = file[file.length - 1];
        file.map((item, index) => {
          let jsonStringified = JSON.stringify(item);
          let jsonBlob = new Blob([jsonStringified], { type: 'application/json' });
          let testFile = new File([jsonBlob], `multiple-run-key-flows-file_${this.fileBackup.length}.wring`);
          this.fileBackup.push(testFile)
          this.filesList.push(testFile)
        })
      }
      else {
        let jsonStringified = JSON.stringify(file);
        let jsonBlob = new Blob([jsonStringified], { type: 'application/json' });
        let testFile = new File([jsonBlob], `multiple-run-key-flows-file_${this.fileBackup.length}.wring`);
        this.filesList.push(testFile)
        this.fileBackup.push(testFile)
        this.loadingModalAddRun = false
      }

    },
    initchecked() {
      for (let i = 0; i < this.resultCoveringtests.length; i++) {
        this.checked[i] = false;
      }
    },
    async getCoveringTests() {
      this.loadingShowCoveringtests = true;
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      this.date_from = moment(this.filterDate[0]).format("YYYY-MM-DD");
      // this.date_to = moment(this.filterDate[1]).format("YYYY-MM-DD");
      // params.append("date_to", this.date_to);
      params.append("date_from", this.date_from);
      if (this.date_from == "Invalid date") {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "Invalid date.",
        });
        this.loadingShowCoveringtests = false;
        return;
      }
      params.append('limit', this.limitCoveringtests);
      params.append('srcdata', 'sessions');
      params.append("project_id", projectId);
      // params.append("percent", this.SelectCov);
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/keyflow?${params}`;
      try {
        axios.defaults.validateStatus = (status) => {
          return status === 400 || (status >= 200 && status < 300) || status === 500;
        };
        const res = await axios.get(url, params);
        if (res.data && res.status === 200) {
          this.resultCoveringtests = res.data.testcases.reverse();
          await this.initchecked()
        } else {
          this.toShowCoveringtests = [];
          console.log(res.data.message);
        }
      } catch (error) {
        console.log("an error occurred while covering the project.:", error);
      } finally {
        this.loadingShowCoveringtests = false;
        this.initializeCoveringtestsState()
      }
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.resultProject = res.data.response;
          this.totalProject = res.data.response.length;
        } else {
          this.resultProject = [];
          this.totalProject = 0;
        }
      } catch (error) {
        console.log(errors)
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no project found ",
        });
      } finally {
        this.loadingProject = false;
        this.selectProjectId = Number(this.$route.params.id)
        this.filteredResultsProject = this.filteredResultProjectSet;
      }
    },
    handleMultipleSelection(elt, id) {
      if (this.checked[id]) {
        elt['index'] = id
        this.multipleSelection.push(elt)
      } else {
        this.multipleSelection = this.multipleSelection.filter(item => {
          if (item.index != id) {
            return item
          }
        });
      }
    },
    async RunAll() {
      this.filesList = []
      this.fileBackup = []
      // const time_gap = await swal.fire({
      //   title: 'Inactivity period for splitting tests',
      //   input: 'number',
      //   inputPlaceholder: '0 to 10,000',
      //   preConfirm: (value) => {
      //     if (value == '') {
      //       swal.showValidationMessage("minimum value is 0");
      //     } else if (parseInt(value) > 10000) {
      //       swal.showValidationMessage("maximum value is 10000");
      //     }
      //     return value;
      //   },
      //   showCloseButton: true,
      // });
      //
      // if (isNaN(Number(time_gap.value))) {
      //   return
      // } else {
      //   this.timeGap = time_gap.value
      // }
      this.timeGap = 0

      this.loadingRun = true
      for (let item of this.multipleSelection) {
        await this.getFiletest(item)
      }
      if (this.fileBackup.length > 0)
        this.addTestModal = true
      this.loadingRun = false
    },
    showContent(val) {
      this.scheduledRun = false;
      if (val === "Scheduled") {
        this.scheduledRun = true;
      } else if (val === "Configure") {
        this.configureRun = true
      } else if (val === "RunTest") {
        this.addTestModal = true
      }
    },
    async addTest() {
      const ifMultipleFiles = this.filesList.length > 1;
      const files = this.filesList;
      let scriptType = "JSON";
      var single = false;
      var double = false;
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail ? "continue" : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        runTags: parsedRunTags.join(","),
      };
      const formData = new FormData();
      let reqHeaders = {};
      if (ifMultipleFiles) {
        double = true;
        let indexP = this.resultProject.findIndex(e => e.projectId === Number(this.$route.params.id))
        parsedRunTags.push(`${this.resultProject[indexP].name}_${this.$route.params.id}`);
        reqHeaders = {
          "x-test-script-type": scriptType,
          "Content-Type": "multipart/form-data;",
          "X-Run-Tags": runOptions.runTags,
        };
        formData.append("suiteName", this.run_name);
        formData.append("suiteDesc", this.run_description);
        formData.append("suiteRunName", `Test suite run - ${this.run_name}`);
        formData.append("suiteRunOptions", JSON.stringify(runOptions));
        formData.append("suiteRunTags", JSON.stringify(parsedRunTags));
        const suiteFiles = JSON.stringify(
          files.map((item, index) => {
            formData.append(
              "attachedFile",
              this.filesList[index],
              this.filesList[index].name
            );
            return {
              fileName: item.name,
              fileType: item.type,
              position: index + 1,
            };
          })
        );
        formData.append("suiteFiles", suiteFiles);
      } else {
        single = true;
        reqHeaders = {
          "Content-Type": "multipart/form-data;",
          "x-test-script-type": scriptType,
          "x-Rp-launch":
            this.run_name +
            " file_" +
            files[0].name.replace("generated-test-run", "").replace(".side", ""),
          "x-rp-description": this.run_description,
          "x-filter-displayed": this.ignore_disabled_elements,
          "x-filter-enabled": this.ignore_invisible_elements,
          "x-runner-resolution": `${this.browser_width}x${this.browser_height}`,
          "x-runner-step-wait": this.max_wait_time,
          "x-interceptor-failure": x_interceptor_failure,
          "x-neighbor-maxdist": x_neighbor_maxdist,
          "x-all-suggest-enabled": this.all_suggest,
          "x-use-tg-analyze": this.collect_page_interaction_data,
          "X-Good-Attributes": this.good_attributes_list,
          "X-Bad-Attributes": this.bad_attributes_list,
          "X-Downweight-Strings": this.downweight_strings ? "1" : "0",
          "X-Run-Tags": runOptions.runTags,
          "X-Runner-Verify-Selectors": this.verify_suggested_selectors ? "1" : "0",
        };

        let runnerTimeoutSec = null;
        runnerTimeoutSec = parseFloat(this.timeout_cancel_run_value);
        if (
          !Number.isNaN(runnerTimeoutSec) &&
          runnerTimeoutSec > 0 &&
          runnerTimeoutSec < 3600
        ) {
          reqHeaders["X-Runner-Timeout"] = `${runnerTimeoutSec}`;
        }
        formData.append("attachedFile", files[0], files[0].name);
      }
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      if (single) {
        try {
          this.loadingModalAddRun = true;
          const res = await axios.post(
            process.env.VUE_APP_API_URL_PREFIX + "/testscript/v1",
            formData,
            { headers: reqHeaders }
          );
          if ((res.data.status = "queued")) {
            this.run_description = "";
            this.addTestModal = false;
            this.pollStatus = true;
            this.singleTestId = true;
            this.loadingModalAddRun = false;
            this.lastUrlTest = "/details/test-explorer/list-tests";
            // swal.fire(`<div style="width: 100%;">Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a></div>`);
            this.$notify({
              type: "success",
              message: `Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a>`,
            });
          } else {
            this.$notify({
              type: "danger",
              message: "execution failed",
            });
          }
        } catch (error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "execution failed",
          });
        }
        this.loadingModalAddRun = false;
      }
      if (double) {
        this.loadingModalAddRun = true;

        axios
          .post(process.env.VUE_APP_API_URL_PREFIX + "/testsuite/v1", formData, {
            headers: reqHeaders,
          })
          .then((response) => {
            if (response.data.status = 'success') {
              this.$notify({
                type: "success",
                message: this.run_name + " added successfully.",
              });
              this.run_name = "";
              this.run_description = "";
              this.filesList = [];
              this.addTestModal = false;
              this.pollStatus = true;
              this.singleTestId = true;
            } else {
              this.$notify({
                type: "error",
                message: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: this.run_name + " was not added successfully. Details: ", error,
            });
          });

        this.loadingModalAddRun = false;
      }
      this.addTestModal = false
    },
    _onRowClicked(elt) {
      if (this.projectDetail.attributes.testSuiteId) {
        this.$router.push({
          name: "Suite View Item",
          params: {
            suiteId: this.projectDetail.attributes.testSuiteId,
            id: elt,
          },
        });
      } else {
        this.$notify({
          type: "danger",
          message: "no test suite has been run!",
        });
      }
    },
    async cancelRunItem(row) {
      const requestId = row.requestId;
      const headers = {
        "Content-Type": "application/json",
      };

      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, cancel it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post(
                process.env.VUE_APP_API_URL_PREFIX + `/testrun/v1/cancel/${requestId}`,
                { headers }
              )
              .then(async (response) => {
                this.$notify({
                  type: "success",
                  message: "Stopped test run!",
                });
                await this.getDetailProject()
                this.getList();
              })
              .catch((err) => {
                this.$notify({
                  type: "danger",
                  message: "Error stopping test run!",
                });
              });
          }
        });
    },
    editTestModal(row) {
      this.suit_run_name = row.name;
      this.toggleEditTestModal = true;
    },
    async saveEditTestSuite() {
      this.loadingTest = true;
      this.toggleEditTestModal = false
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      const formData = new FormData();
      formData.append('suiteName', this.suit_run_name);
      axios
        .put(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`, formData
        )
        .then( async (response) => {
          // this.items = Object.values(response.data.response);
          if (response.data.status == "success") {
            this.loadingTest = false;
            await this.getDetailProject()
            this.getList()
          } else {
            this.loadingTest = false;
          }

        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Cannot edit suite test... Try refresh",
          });
        })
        .finally(() => {
          this.loadingTest = false;
        });

    },
    async saveAddFileTestSuite() {
      this.loadingTest = true;
      this.toggleReAddFileModal = false
      this.addTestModal = false
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      const files = this.listAddSuitTest
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      const formData = new FormData();
      let suiteFiles = []

      for (let i = 0; i < files.length; i++) {
        let fileNmae = files[i].name
        if (this.labelSelectedKeyFlow.length > 1) {
          fileNmae = `${this.labelSelectedKeyFlow.replace(/\s+/g, '-')}_${files[i].name}`
        }
        formData.append(
          "attachedFile",
          files[i],
          fileNmae,
          this.labelSelectedKeyFlow
        );
        suiteFiles.push({ operation: "add", fileName: fileNmae })

      }
      this.keyFlowName.push(this.labelSelectedKeyFlow)
      formData.append('suiteFiles', JSON.stringify(suiteFiles));
      axios
        .put(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`, formData
        )
        .then(async (response) => {
          // this.items = Object.values(response.data.response);
          if (response.data.status == "success") {
            this.$notify({
              type: "success",
              message: `${this.labelSelectedKeyFlow} added to suite successfully.`,
            });
            console.log(response)
            await this.getDetailProject()
            this.getList()
          } else {
            this.loadingTest = false;
          }

        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Cannot add suite test... Try refresh",
          });
        })
        .finally(() => {
          this.loadingTest = false;
        });

    },
    async runTestSuiteProject(type) {
      this.loadingTest = true;
      this.addTestAppModal = false
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      const files = this.listAddSuitTest
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail ? "continue" : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        generateCssSelectors: this.generate_css_selectors,
        runTags: parsedRunTags.join(","),
      };

      if (this.testrun_start_url && this.testrun_start_url.length > 0) {
        runOptions["runStartUrl"] = this.testrun_start_url;
      }
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      let suiteFilesRun = []
      let selectFile = this.setAllRunFile.filter( item => {
        if(item.activeFile === true)
          return item
      })
      if (this.run_all == true) {
        for (let i = 0; i < selectFile.length; i++) {
          suiteFilesRun.push(selectFile[i].fileKey)
        }
      } else {
        suiteFilesRun = this.suiteFiles;
      }
      // const formData = new FormData();
      // let suiteFiles = []
      // formData.append('suiteId', suiteId);
      // formData.append('suiteRunName', this.suiteRunName);
      // formData.append('suiteRunOptions', JSON.stringify({filterDisplayed:this.filterDisplayed,runStartUrl:this.runStartUrl}));
      // formData.append('suiteFiles', JSON.stringify(this.suiteFiles));
      let tags = this.runTagsSuit.split(' ')
      if (this.runTagsSuit === '')
        tags = []
      tags.push("pageviews-project")
      let data = {
        suiteId: testSuitRunId,
        suiteRunName: this.suiteRunName,
        suiteFiles: suiteFilesRun,
        suiteRunTags: tags,
        runOptions: runOptions
      }
      if (this.suiteSingleRun === false) {
        data.suiteRunOptions = { filterDisplayed: this.filterDisplayed, runStartUrl: this.runStartUrl }
        data.suiteSingleRun = 1
      } else {
        data.suiteSingleRun = 1
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}/run`, data
        )
        .then(async (response) => {
          // this.items = Object.values(response.data.response);
          if (response.data.status == "success" || response.data.status == "queued") {
            await this.getDetailProject()
            this.getList()
            this.$notify({
              type: "success",
              message: "Test suite run successfully.",
            });
            this.cleanUpState()
          } else {
            this.loadingTest = false;
            this.$notify({
              type: "danger",
              message: "test execution failed.. Try refresh",
            });
          }

        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Cannot edit suite test... Try refresh",
          });
        })
        .finally(() => {
          this.loadingTest = false;
        });

    },
    async addFileModal() {
      this.listAddSuitTest = [];
      this.toggleReAddFileModal = true;

    },
    async deleteItem(row, key) {
      const keyFile = key;
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      const confirmation = await swal.fire({
        title: "File '" + row.fileName + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep the test.",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.value === true) {
        this.detailTestApp = false;
        const formData = new FormData();
        formData.append('suiteFiles', JSON.stringify([{ operation: "remove", fileKey: row.fileKey }]));
        await axios
          .put(
            `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`, formData
          )
          .then(async (response) => {
            // this.items = Object.values(response.data.response);
            if (response.data.status == "success") {
              this.$notify({
                type: "success",
                message: "Test delete successfully.",
              });
              await this.getDetailProject()
              await this.getList()
              this.loadingTestDelete = false;
            } else {
              this.loadingTestDelete = false;
            }

          })
          .catch((error) => {
            console.log("Error: ", error);
            this.loadingTestDelete = false;
          })
      }

    },
    pollSingleTestfilteredResultSet() {
      return this.items.map((item) => {
        if (item.testSuiteId && !item.runName.includes('Test Suite -')) {
          item.runName = `Test Suite - ${item.testSuiteName} | ${item.runName}`;
          item.runDesc = item.testSuiteRunDesc;
          return item;
        } else {
          return item;
        }
      });
    },
    async handleCurrentChange(val, withCursor) {
      const old = this.from;

      this.from = (val - 1) * this.pagination.perPage;
      this.to = parseInt(this.from) + parseInt(this.pagination.perPage);

      // if there's an active filter, make sure to add it to the URL
      let filterFragment = "";
      if (this.filteredElt !== undefined) {
        filterFragment = Object.keys(this.filteredElt)
          .map((e) => [`${e}~${this.filteredElt[e].operator}~${this.filteredElt[e].value}`])
          .join("&filter=");
      }
      if (filterFragment.length > 0 && !filterFragment.startsWith("&filter=")) {
        filterFragment = "&filter=" + filterFragment;
      }

      if (withCursor) {
        this.loadingSession = true;
        // console.log("Current cursor: ", this.currentCursor)
        const isNext = old < this.from;
        const searchUrl = isNext
          ? `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.currentCursor}&limit=${this.pagination.perPage}${filterFragment}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.oldCursor}&limit=${this.pagination.perPage}${filterFragment}`;

        console.log(`handleCurrentChange: final composed search URL: ${searchUrl}`);

        try {
          const res = await axios.get(searchUrl);
          if (res.status === 200) {
            this.items = Object.values(res.data.response);
            this.filteredResults = this.filteredResultSet;
            this.toShow = this.filteredResults;
            this.total = res.data.statistics.total_tests;
            this.oldCursor = this.currentCursor ?? undefined;
            this.currentCursor = res.data.cursor ?? undefined;
          } else {
            this.$notify({
              type: "danger",
              message:
                `Error while filtering: ${res.data.message}. Contact support if this error persists.`,
            });
          }
        } catch (err) {
          if (err.response && err.response.status && err.response.status === 404) {
            this.$notify({
              type: "warning",
              message: "There are no more results for this filter and items-per-page combination",
            });
          } else {
            this.$notify({
              type: "danger",
              message: "Error while filtering, likely no more results for this filter and items-per-page combination.",
            });
          }
        }
        this.loadingSession = false;
      } else {
        this.toShow = []
        this.toShow.push(this.filteredResults);
        this.keyTestFile = Object.keys(this.toShow[0].testSuiteFiles);
        this.fileAppAtt = Object.values(this.toShow[0].testSuiteFiles);
        console.log(this.fileAppAtt)
        console.log(this.toShow)
        this.keyTest = Object.keys(this.toShow[0].testSuiteRuns);
        this.detailTest = Object.values(this.toShow[0].testSuiteRuns);
      }
    },
    async handleNavigation(target) {
      this.loadingTest = true;
      this.pagination.currentPage =
        target === "next"
          ? this.pagination.currentPage + 1
          : this.pagination.currentPage - 1;

      // if there's an active filter, make sure to add it to the URL
      let filterFragment = "";
      if (this.filteredElt !== undefined) {
        filterFragment = Object.keys(this.filteredElt)
          .map((e) => [`${e}~${this.filteredElt[e].operator}~${this.filteredElt[e].value}`])
          .join("&filter=");
      }
      if (filterFragment.length > 0 && !filterFragment.startsWith("&filter=")) {
        filterFragment = "&filter=" + filterFragment;
      }
      console.log(
        `handleNavigation: pagination target: ${target}, ` +
        `filters enabled = ${this.filteredElt !== undefined}, ` +
        `filter URL fragment = ${filterFragment}`
      );

      const searchUrl =
        target === "next"
          ? `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.currentCursor}&limit=${this.pagination.perPage}${filterFragment}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.oldCursor}&limit=${this.pagination.perPage}${filterFragment}`;

      console.log(`handleNavigation: final composed search URL: ${searchUrl}`);

      try {
        const res = await axios.get(searchUrl);
        if (res.status === 200) {
          this.items = Object.values(res.data.response);
          this.filteredResults = this.filteredResultSet;
          this.toShow = this.filteredResults;
          this.from = (this.pagination.currentPage - 1) * this.pagination.perPage;
          this.to = parseInt(this.from) + parseInt(this.pagination.perPage);
          this.total = res.data.statistics.total_tests;
          this.oldCursor = res.data.back_cursor;
          this.currentCursor = res.data.cursor;
        } else {
          this.$notify({
            type: "danger",
            message: `Error while filtering: ${res.data.message}. Contact support if this error persists.`,
          });
        }
      } catch (err) {
        if (err.response && err.response.status && err.response.status === 404) {
          this.$notify({
            type: "warning",
            message: "There are no more results for this filter and items-per-page combination",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Error while filtering, likely no more results for this filter and items-per-page combination.",
          });
        }
      }
      this.loadingTest = false;
    },
    async initWebsocketStreaming() {
      const auth_token = localStorage.getItem("vue-authenticate.access_token");
      let url = "";
      if (process.env.VUE_APP_WEBSOCKET_API_URL === undefined) {
        url = `wss://${window.location.host}/interceptor/runstream/v1`;
      } else {
        url = `${process.env.VUE_APP_WEBSOCKET_API_URL}/runstream/v1`;
      }

      try {
        const socket = new WebSocket(url);
        socket.onopen = function (e) {
          socket.send(`AUTH ${auth_token}`);
        };
        socket.onmessage = (event) => {
          const response = JSON.parse(event.data);
          if (Object.keys(response.events).length === 0) {
            return;
          } else {
            const events = response.events;
            const uniqueValues = [...new Set(Object.values(events))];
            for (event in uniqueValues) {
              if (uniqueValues[0] && uniqueValues[0].eventType === "test run event") {
                this.pollSingleTest(uniqueValues[0]);
              }
            }
          }
        };
      } catch {
        console.log("error?");
      }
    },
    async getList() {
      this.loadingTest = true;
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || status === 404  || (status >= 200 && status < 500);
      };
      if (!this.projectDetail.isSharedProject && this.projectDetail.attributes && this.projectDetail.attributes.testSuiteId) {
        const testSuitRunId = this.projectDetail.attributes.testSuiteId;

        axios
          .get(
            `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`
          )
          .then( async (response) => {
            // this.items = Object.values(response.data.response);
            if(response.data.status == 'success'){
              this.filteredResults = response.data.response
              this.handleCurrentChange(this.pagination.currentPage, false);
              this.loadingTest = false;
            }else{
              this.filteredResults = []
              this.handleCurrentChange(this.pagination.currentPage, false);
              this.loadingTest = false;
            }
          })
          .catch((error) => {
            console.log(error)
            this.loadingTest = false;
            this.$notify({
              type: "danger",
              message: "Cannot load user Project... Try refresh" + error,
            });
          })
          .finally(() => {
            this.loadingTest = false;
          });
      } else if(this.projectDetail.isSharedProject && this.projectDetail.isSharedProject == true){
        await this.shareSuiteTest()
        this.handleCurrentChange(this.pagination.currentPage, false);
        this.loadingTest = false;
      }else {
        let projectId = this.$route.params.id;
        let data = {
          testSuite: 'new'
        }

        // check if the project contains mobile screenshots
        const resp = await axios.put(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/user-projects/" + projectId, data);
        if (resp.data && resp.data.status === "success") {
          this.projectDetail = resp.data.response
        }

        const testSuitRunId = this.projectDetail.attributes.testSuiteId;

        axios
          .get(
            `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`
          )
          .then((response) => {
            // this.items = Object.values(response.data.response);
            this.filteredResults = response.data.response
            this.handleCurrentChange(this.pagination.currentPage, false);
            this.loadingTest = false;
          })
          .catch((error) => {
            console.log(error)
            this.$notify({
              type: "danger",
              message: "Cannot load user Project... Try refresh" + error,
            });
          })
          .finally(() => {
            this.loadingTest = false;
          });
      }

    },
    async shareSuiteTest(){
      const res = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/user`);
      if(res.data.status = "success"){
        let allSuiteUser = Object.values(res.data.response)
        let index = allSuiteUser.findIndex( item => item.description === `Shared ${this.projectDetail.name} ${this.$route.params.id} Suite` && item.name === `Shared ${this.projectDetail.name} ${this.$route.params.id} Suite` )
        console.log("yooo",index)
        if(index === -1){
          await this.createSuiteTest()
        }else{
          await this.getDetailSuiteTest(allSuiteUser[index].suiteId)
        }

      }
    },
    async createSuiteTest(){
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1`;
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };
      let formatDate = new FormData()
      formatDate.append("suiteName",`Shared ${this.projectDetail.name} ${this.$route.params.id} Suite`)
      formatDate.append("suiteDesc",`Shared ${this.projectDetail.name} ${this.$route.params.id} Suite`)
      formatDate.append("projectName",this.projectDetail.name)
      formatDate.append("suiteFiles","[]")
      const res = await axios.post(url,formatDate);
      if(res.data.status = "success"){
        await this.getDetailSuiteTest(res.data.response.suiteId)
      }else{
        this.$notify({
          type: "danger",
          message: "an error has occurred! Cannot load user Project... Try refresh",
        });
      }
    },

    async getDetailSuiteTest(testSuitRunId){
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`
        )
        .then( async (response) => {
          // this.items = Object.values(response.data.response);
          if(response.data.status == 'success'){
            response.data.response.shared = true
            this.projectDetail.attributes.testSuiteId = response.data.response.suiteId
            this.projectDetail.attributes.testSuiteName = response.data.response.name
            this.filteredResults = response.data.response
            this.handleCurrentChange(this.pagination.currentPage, false);
            this.loadingTest = false;
          }else{
            this.filteredResults = []
            this.handleCurrentChange(this.pagination.currentPage, false);
            this.loadingTest = false;
          }
        })
        .catch((error) => {
          console.log(error)
          this.$notify({
            type: "danger",
            message: "an error has occurred! Cannot load user Project... Try refresh",
          });
        })
        .finally(() => {
          this.loadingTest = false;
        });
    },

  },
  computed: {
    filteredResultSetTest() {
      return this.items.map((item) => {
        if (item.testSuiteId) {
          item.runName = `Test Suite - ${item.testSuiteName} | ${item.runName}`;
          item.runDesc = item.testSuiteRunDesc;
          return item;
        } else {
          return item;
        }
      });
    },
    filteredResultSet() {
      const seq = this.result;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
          if (this.query) {
            return item.person.properties.$geoip_country_name
              .toLowerCase()
              .includes(this.query.toLowerCase());
          } else {
            return item;
          }
        })
        : [];
    },
    filteredResultSessionSet() {
      const seq = this.resultSession;

      if (this.eventSessionType === "mobile") {
        return seq && Array.isArray(seq) ? seq.filter((item) => {
          if (!this.querySession) {
            return item;
          }

        }) : [];
      } else {
        return seq && Array.isArray(seq)
          ? seq.filter((item) => {
            if (this.querySession) {
              if (
                item.person.properties.$geoip_country_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase())
              ) {
                return item.person.properties.$geoip_country_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              } else if (
                item.person.properties.$geoip_continent_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase())
              ) {
                return item.person.properties.$geoip_continent_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              } else if (
                item.person.properties.$initial_browser
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase())
              ) {
                return item.person.properties.$initial_browser
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              } else if (
                item.person.properties.$initial_os
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase())
              ) {
                return item.person.properties.$initial_os
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              } else if (item.person.properties.$initial_current_url.toLowerCase()) {
                return item.person.properties.$initial_current_url
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
              else if (item.app_name.toLowerCase()) {
                return item.app_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
              else if (item.operating_system.toLowerCase()) {
                return item.operating_system
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
              else if (item.person.properties.$initial_os.toLowerCase()) {
                return item.person.properties.$initial_os
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
              else if (item.person.properties.$initial_browse.toLowerCase()) {
                return item.person.properties.$initial_browser
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
              else if (this.formatDate(item.start_time).toLowerCase()) {
                return this.formatDate(item.start_time)
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
              else if (this.formatDate(item.session_created_at).toLowerCase()) {
                return this.formatDate(item.session_created_at)
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
              else if (this.secondsToRealTime(item.recording_duration).toLowerCase()) {
                return this.secondsToRealTime(item.recording_duration)
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
            } else {
              return item;
            }
          })
          : [];
      }

    },
    filteredResultSessionEventSet() {
      const seq = this.resultEvent;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
          if (this.querySessionEvent) {
            if (
              item.event.toLowerCase().includes(this.querySessionEvent.toLowerCase())
            ) {
              return item.event
                .toLowerCase()
                .includes(this.querySessionEvent.toLowerCase());
            } else if (
              item.properties.$pathname
                .toLowerCase()
                .includes(this.querySessionEvent.toLowerCase())
            ) {
              return item.properties.$pathname
                .toLowerCase()
                .includes(this.querySessionEvent.toLowerCase());
            }
          } else {
            return item;
          }
        })
        : [];
    },
    filteredResultCoveringtestsSet() {
      const seq = this.resultCoveringtests;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
          if (this.queryCoveringtests.length > 0) {
            if (item.name.toLowerCase().includes(this.queryCoveringtests.toLowerCase())) {
              return item.name.toLowerCase().includes(this.queryCoveringtests.toLowerCase());
            } else if (item.url.toLowerCase().includes(this.queryCoveringtests.toLowerCase())) {
              return item.url.toLowerCase().includes(this.queryCoveringtests.toLowerCase())
            }
          } else if (item.tests[0]) {
            return item;
          }
        })
        : [];
    },
    filteredList() {
      return this.filteredResults.slice(this.from, this.to);
    },
    filteredListSession() {
      return this.filteredResultsSession.slice(this.from, this.to);
    },
    pagesSession() {
      return Math.ceil(
        this.filteredResultsSession.length / this.paginationSession.perPage
      );
    },
    pages() {
      return Math.ceil(this.filteredResults.length / this.pagination.perPage);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    canAddPrevious() {
      if (this.pagination.currentPage == 1) return true;
      return false;
    },
    canAddPreviousSession() {
      if (this.paginationSession.currentPage == 1) return true;
      return false;
    },
  },
  watch: {
    querySession: {
      handler: "initializeSessionState",
      immediate: true,
    },
    querySessionEvent: {
      handler: "initializeSessionEvent",
      immediate: true,
    },
    paginationSession: {
      handler: "initializeSessionState",
      immediate: false,
      deep: true,
    },
    queryCoveringtests: {
      handler: "initializeCoveringtestsState",
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
$pColor: #525f7f;
$color: #5e72e4;

.elt-row {
  text-overflow: ellipsis;
}

.disable-file{
  opacity: 0.4;
}

.bpsd_dtb {
  width: 100%;
}

.is-flex {
  display: flex;
  align-items: center;
}

.list-group {
  width: 100%;
}

.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-test-project-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .modal-body {
    max-height: 60vh;
    overflow: auto;
  }
}

.run-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.model-label {
  display: block;
  font-size: 13px ;
  font-weight: 600;
  margin-top: 0.5em;
}

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.modal-checks input {
  outline: none;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  padding: 7px;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.upload-craglist label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.craglist-files {
  width: 100%;
}

.craglist-files ul {
  border-top: 1px solid gray;
  padding: 0;
  margin: 0;
  width: 100%;
}

.craglist-files ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.rmv-btn {
  font-size: 20px;
  cursor: pointer;
}

.file-lists-container {
  width: 100%;
}

.file-head-clearall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.file-head-clearall .clearall {
  cursor: pointer;
}

.upload-file-div-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ddf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(5) .cell {
  justify-content: center;
}

.down-btn-container a {
  color: #000000;
}

.down-btn-container a:hover {
  opacity: 0.6;
}

.down-btn-container a .fa-file-pdf,
.down-btn-container .fa-file-csv {
  font-weight: 600;
}

.progress-xpath-count {
  font-weight: bold;
  color: #4ac3db;
}

.onlyForRunningTestRow {
  background-color: #c4f1de !important;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}

.test-run-name {
  //color: rgb(94, 114, 228);
  color: $color;
  font-weight: bold;
  text-align: left;
}

.test-run-name-for-suite {
  color: #2dce89;
  font-weight: bold;
}

.passed-status {
  font-weight: bold;
  font-size: 12px;
  color: #8a8d90;
  text-transform: capitalize;
}

.failed-status {
  background-color: #d93b3b;
  color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}

.run-tags-cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.run-tags {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 3px;
  padding: 3px;
  font-size: 0.65rem;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.test-time-cont {
  display: inline-block;
  max-width: 100% !important;
  font-size: 0.6rem;
  padding: 0.15rem 0.35rem;
  text-align: center;
  border: solid 0.1px rgba(#25a18e, 0.75);
  background: rgba(#25a18e, 0.15);
  margin-top: 5px;
  border-radius: 0.25rem;
  color: #25a18e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.looping-rhombuses-spinner,
.looping-rhombuses-spinner * {
  box-sizing: border-box;
}

.looping-rhombuses-spinner {
  width: 50%;
  height: 15px;
  position: relative;
  margin-left: 10px !important;
}

.script {
  background: #223e33;
  border-radius: 0.25rem;
}

.pageview-modal .modal-content,
.pageview-modal .modal-dialog {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
  min-width: 50%;
}

.pageview-modal .modal-body {
  overflow: auto;
}

.add-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
  height: 10vh;
}

.show-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
  height: 10vh;
}

.looping-rhombuses-spinner .rhombus {
  height: 15px;
  width: 15px;
  background-color: #4ac3db;
  left: calc(15px * 1);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}

.b {
  border: 1px solid red !important;
}

.for-row-selection .el-input {
  height: 100%;
  padding: 3px;
}

.test-descp {
  height: 10px;
  color: #9ea1a5;
  width: 50px;
}

.edit-test-select {
  width: 100%;
}

.edit-test-select .el-input {
  width: 100%;
}

.edit-test-modal-container .edit-test-select .el-select .el-input .el-input__inner {
  text-transform: capitalize !important;
}

.el-select-dropdown__item {
  text-transform: capitalize !important;
}

.add-test-project-modal button:disabled {
  cursor: not-allowed;
}

.for-pagination-circle-cursor .page-link {
  cursor: pointer;
}

.custom_btn--refresh {
  position: absolute;
  top: 3%;
  right: 82.5%;
  z-index: 9;
  background-color: #1F51FF !important;
  span > i{
    color: white !important;
    font-size: 1.5rem !important;
  }
}

.btn-gray {
  background-color: #172b4d !important;
}

.processing-screenshot-img {
  width: 150px;
  height: 100px;
  overflow: hidden;
}

.processing-screenshot-img img {
  /* border-radius: 10px; */
  /* border: 2px solid #fbb140; */
  width: 150px;
  height: 100px;
  object-fit: contain !important;
}

.add-test-footer-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.file-hover {
  background-color: #adfad9 !important;
}

.pakhpalle {
  background-color: red !important;
}

.looping-rhombuses-spinner .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}

.narrow-select .el-input {
  height: 52px;
  width: 80px;
  padding: 3px;
}

.options-accordion .el-collapse-item__header {
  font-size: 16px;
}

.small--select {
  max-width: 80px !important;
}

.c--breadcrumb {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  color: rgba($pColor, 0.6);
  // transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: rgba($color, 0.6);
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}

.mid--page {
  border-bottom: solid 0.1px rgba($pColor, 0.25);

  .el-input__inner {
    font-size: 0.75rem;
    padding: 0.35rem;
    background: #f7f9fc;

    &:focus {
      outline: none !important;
    }
  }

  .el-input__suffix {
    .el-input__suffix-inner {
      display: flex;
      justify-content: center;
      align-items: center;

      .el-input__icon {
        font-size: 0.8rem;
        transform: translateY(-3px);
      }
    }
  }
  .custom_btn {
    bottom: 5%;
    position: fixed;
    z-index: 9;
    right: 2.5%;
    font-size: 25px !important;
  }
 .mp--l {
    span {
      color: rgba($pColor, 0.65);
      cursor: pointer;

      //font-size: 1.25rem;
      &.active {
        color: #25a18e;
      }
    }

    .small_btn {
      background: rgba($color, 0.1);
      border: solid 0.1px $color;

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {

        i,
        span {
          color: #fff !important;
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }

  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}

.add_btn{
  background-color: #409EFF !important;
  width: 100%;
  span{
    color: white !important;
  }

  i{
    color: white !important;
  }
}

.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: $color;
    color: #ffffff;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}

.custom--card {
  background-color: $color
}

.custom--right-card {
  border: 1px solid $color;
}

.text--card{
  color:white
}

.left--side-container {
  width:65%
}

.right--side-container {
  margin-left: 20px;
  width: 30%;
}

.check-boxes {
  width: 40%;
}

.desc {
  text-align: left;
}

.cards {
  margin-top: 50px;
}

.ctb--body {
  display: flex;
  flex-wrap: wrap;

  .ctbb--row {
    width: 31%;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;

        .elt--banner_img {
          display: inline-block;
          width: 100%;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color, 0.3);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 100px;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }

        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}

.tbl--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;

      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00ff6214;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}

.el-progress--circle {
  display: flex !important;
  justify-content: center;
  margin-top: 10px;
}

.custom--select > .el-input{
  height: 40px !important;
  input{
    background-color: #EFF1FC;
    border-radius: 30px;
    border: 1px solid $color;
    color: $color;
  }
}

.modal-body {
  height: auto;
  padding-top: 40px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mp--filter_container {
  animation: slideDown 0.3s;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: auto;
    opacity: 1;
  }
}

.sessionEvent-detail-modal {

  // width: 85vw !important;
  // margin: 1.75rem auto;
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100%;
      margin: 1.75rem auto;
    }
  }

  .modal-dialog {
    width: 85% !important;
    height: 10vh;
  }
}

.custom_btn_settings {
  background: rgba($color, 0.1);
  border: solid 0.1px $color;

  i,
  span {
    font-size: 0.65rem;
    font-weight: 600;
    color: $color;
  }

  &:hover {
    background: rgba($color, 0.3);

    i,
    span {
      color: rgba($color, 0.75);
    }
  }

  &.active {

    i,
    span {
      color: #fff !important;
    }
  }
  @media (max-width: 1400px) {
    span{
      font-size: 0.55rem;
    }

    i{
      font-size: 0.55rem;
    }
  }
}

.custom_btn_settings:nth-child(2n) {
  margin-left: 1rem !important;
  @media (max-width: 1400px) {
    margin-left: 0.5rem !important;
  }
}

.showCoveringtests-modal {

  // width: 85vw !important;
  // margin: 1.75rem auto;
  .modal-dialog {
    width: 80vw !important;
    height: 10vh;
  }
}

.swal2-popup #swal2-title {
  display: block !important;
  font-size: 1.5em !important;
}


.inp--project {
  width: 300px;
  height: 20px !important;
}

.ultra--ipt_content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 70%;

  .u--tag {
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.15rem 0.35rem;
    border: solid 0.1px rgba(#25a18e, 0.75);
    background: rgba(#25a18e, 0.15);
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .u--tag--i {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.el-checkbox {
  width:100%
}
.ultra--ipt_content,
.ultra--ipt_ipt {
  display: inline-block;
  height: 50px;
  outline: none;
  border: none;
  background: transparent;
}

.ultra--ipt_ipt {
  width: 100%;
}

.ultra--ipt {
  border: solid 0.1px #4b4b71;
  border-radius: 0.3rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  .ultra--ipt_content,
  .ultra--ipt_ipt {
    display: inline-block;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
  }

  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 70%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_content--xphat {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 85%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_ipt {
    width: 100%;
  }

  .content--ultra {
    max-width: 75%;
  }

  .btn--clean {
    max-width: 22%;
  }
}

.snippet--inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.list--downloadTestFile {
  border-bottom: solid 1px #f8f9fe;
  cursor: pointer;
}

.list--downloadTestFile:hover {
  color: #5f72e4;
  border-bottom: solid 1px #d5d5d5;
}

.ticks {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 100%;
}

.ticks .tick-hover-box {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: -15px;
  width: 5px;
  // right: -3px;
}

.ticks .tick-hover-box .tick-info {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -36px;
  background-color: var(--bg-charcoal);
  display: none;
  color: var(--light);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%);
}

.ticks .tick-hover-box .tick-marker {
  position: absolute;
  width: 1px;
  height: 21px;
  background-color: #7e7e7e;
  right: 2px;
}

.ticks .tick-hover-box .tick-thumb {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  right: -2px;
  top: -4px;
  border: 0.5px solid #7e7e7e;
  background-color: #ffff;
  transform-origin: center;
  transition: transform 0.2s;
}

.container--event--liste {
  margin: 0 0 0 30px;
  padding: 8px;
  width: 100%;
  border: solid 1px #c6c6c6;
  border-radius: 5px;
  max-height: 656px;
  overflow: hidden;
}

.event--liste {
  max-height: 580px;
  overflow-x: auto;
  overflow-y: auto;
}

.event--liste::-webkit-scrollbar {
  display: none;
}

.content {
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;

  .time--tag {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-right: 1rem !important;
    width: 96%;
  }

  .right--content {
    width: 80%;
    flex-direction: column;
  }

  .icon--event {
    width: 20%;
    display: flex;
    background-color: #e5e5e5;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.content:hover {
  background-color: #e8eeff;

  .icon--event {
    background-color: $color;

    i {
      color: #ffff;
    }
  }
}

.active--event {
  background-color: #e8eeff;

  .icon--event {
    background-color: $color;

    i {
      color: #ffff;
    }
  }
}

.small_btn {
  background: rgba($color, 0.1);
  border: solid 0.1px $color;

  i,
  span {
    font-size: 0.75rem !important;
    font-weight: 600;
    color: $color;
  }

  &:hover {
    background: rgba($color, 0.3);

    i,
    span {
      color: rgba($color, 0.75);
    }
  }
}

.dashed--btn {
  border-style: dashed;
}

.mod--content {
  margin-bottom: 30px;
}

.wring-graph {
  min-height: 70vh;
}

.el-drawer {
  width: 65% !important;
  overflow-y: scroll !important;
  padding: 0 20px;
}

.el-message-box {
  width: 70%;
}
.swal2-container {
  z-index: 99999 !important;
}
.swal2-popup{
  z-index: 99999;
}
.qkb-msg-bubble-component {
  text-align: left !important;
}
.not-video-test{
  opacity: 0.5;
  cursor: none;
}
// .qkb-bot-ui {
//   right: 90.5rem !important;
// }
run_tags
// .qkb-board{
//   left: 0;
// }
.cuser{
  cursor: pointer;
}
input[type="checkbox"]{
  width: auto !important;
}
.is--update_days{
  display: flex;
  .el-input >input {
    height: 35px !important;
  }
  input{
    width: 50%;
    border: solid 1px #5f72e4;
    background: #eff1fc;
    border-radius: 3px;
    height: 35px !important;
    padding-left: 5px;
  }
  input:focus {
    border: dashed 1px #5f72e4 !important;
  }
}
.appmap-label {
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0.5em;
}
.el-switch__label > span{
  display: block;
    font-size: 13px;
    font-weight: 600;
}
.el-switch{
  // border: solid 0.5px #8A8D90;
  border-radius: 4px;
  padding: 0.5em;
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
