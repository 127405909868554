<template>
  <div>
    <div style="display: flex;">

      <LeftSideBar>
          <div class="custom-btn-group align-items-center ">
            <el-button id="intergrationsCi" @click="showContent('installation')" icon="el-icon-pie-chart" class="tab--btn button-menu-integration"
              :class="{ active: showInstallation }">
              Installation
            </el-button>
            <el-button id="intergrationsCi" @click="showContent('report')" icon="el-icon-pie-chart" class="tab--btn button-menu-integration"
              :class="{ active: showReport }">
              {{ $t('integrations.ci') }}
            </el-button>
            <el-button id="intergrationsTriggers" @click="showContent('trigger')" icon="el-icon-guide" class="tab--btn button-menu-integration"
              :class="{ active: showTrigger }">
              {{ $t('integrations.triggers') }}
  
            </el-button>
            <el-button id="intergrationsScheduled" @click="showContent('Scheduled')" icon="el-icon-odometer"
              class="tab--btn button-menu-integration" :class="{ active: scheduledRun }">
              {{ $t('integrations.scheduled_runs') }}
            </el-button>
          </div>
      </LeftSideBar>
      <!-- <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
        <router-link to="/details/test-explorer/list-tests"><i class="el-icon-house"></i></router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <span>{{ $t('integrations.integrations') }}</span>
      </div> -->
      <div class="right-content">
        <template v-if="showInstallation">
          <ConfigurationsContent />
        </template>
        <template v-if="showReport">
          <div class="integration--content">
            <div v-for="elt in availableIntegrations" :key="elt.id" class="elt--integration mb-4 py-3" :id="elt.keyId"
              @click="handleShowModal(elt.name)" :style="`border-color: ${elt.color}; background-color: ${elt.colorTa}`">
              <div class="ei--img">
                <img :src="`/img/integrations/${elt.img}`" :alt="elt.name" :style="`width: ${elt.imgWidth}`" />
              </div>
              <span class="ei--name" :style="`color: ${elt.color}`">{{
                elt.name
              }}</span>
            </div>
          </div>
    
          <data-list :tHeader="tHeader" class="mt-4" :loading="loading" :customSizes="true"
            :items="integrationData.user_integrations">
            <template #content>
              <template>
                <div class="tblb--row mb-2" v-if="loading">
                  <div class="tblb--inner">
                    <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
                  </div>
                </div>
                <template v-else>
                  <template v-if="canShowData(integrationData)">
                    <div v-for="(elt, id) in integrationData.user_integrations" :key="id" class="tblb--row mb-2">
                      <div class="tblb--inner">
                        <div class="elt-row" style="width: 30%">
                          <span class="test-run-name">{{
                            elt.integration_name
                          }}</span>
                        </div>
                        <div class="elt-row" style="width: 50%">
                          <span>{{
                            DescriptionFormatter(elt, elt.ui_identifier_name)
                          }}</span>
                        </div>
                        <div class="elt-row" style="width: 20%">
                          <span class="test-time-cont" style="max-width: 100%">
                            {{ elt.integration_enabled ? "Active" : "Not Active" }}
                          </span>
                        </div>
                        <div class="elt-row flex--elt" style="width: 15%">
                          <el-tooltip content="Edit" placement="top">
                            <el-button id="intergrationsEdit" type="primary" icon="el-icon-edit"
                              @click.stop="processEditRequest(elt)" circle plain size="small"></el-button>
                          </el-tooltip>
                          <el-tooltip content="Delete" placement="top">
                            <el-button id="intergrationsDeleteTestBtn" type="danger" icon="el-icon-delete"
                              @click="processDeleteRequest(elt)" circle plain size="small"></el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="tblb--row mb-2" v-else>
                    <div class="tblb--inner px-3 py-3">No Data ...</div>
                  </div>
                </template>
              </template>
            </template>
          </data-list>
        </template>
        <template v-if="showTrigger">
          <div class="integration--content">
            <div v-for="elt in availableWebhooks" :key="elt.id" class="elt--integration mb-4 py-3"
              @click="handleShowWebhookModal(elt.val)"
              :style="`border-color: ${elt.color}; background-color: ${elt.colorTa}`">
              <div class="ei--img">
                <img :src="`/img/integrations/${elt.img}`" :alt="elt.name" :style="`width: ${elt.imgWidth}`" />
              </div>
              <span class="ei--name" :style="`color: ${elt.color}`">{{
                elt.name
              }}</span>
            </div>
          </div>
    
          <data-list :tHeader="tHeader2" class="mt-4" :loading="loading2" :items="webhookData">
            <template #content>
              <template>
                <div class="tblb--row mb-2" v-if="loading">
                  <div class="tblb--inner">
                    <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
                  </div>
                </div>
                <template v-else>
                  <template v-if="webhookData.length > 0">
                    <div v-for="(elt, id) in webhookData" :key="id" class="tblb--row mb-2">
                      <div class="tblb--inner">
                        <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                          <span class="test-run-name">{{ elt.triggerOrigin }}</span>
                        </div>
                        <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                          <span>{{ elt.name }}</span>
                        </div>
                        <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                          <el-tooltip class="item" :content="parseDate(elt.updatedAt)" placement="top">
                            <span class="test-time-cont" style="max-width: 100%">
                              <i class="far fa-clock mr-1"></i>
                              {{ parseDate(elt.updatedAt) }}
                            </span>
                          </el-tooltip>
                        </div>
                        <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                          <el-tooltip v-if="getlastRun(elt).runDate.length > 1" class="item"
                            :content="parseDate(getlastRun(elt).runDate)" placement="top">
                            <a class="test-time-cont" style="max-width: 100%" :href="
                              '/details/test-explorer/view-test/' +
                              getlastRun(elt).requestId
                            ">
                              <i class="far fa-clock mr-1"></i>
                              {{ parseDate(getlastRun(elt).runDate) }}
                            </a>
                          </el-tooltip>
                          <span v-else>{{ "N/A" }}</span>
                        </div>
                        <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                          <span v-if="getlastRun(elt).runDate.length > 1">
                            <el-alert class="no--close" :title="getlastRun(elt).runStatus" :type="
                              getlastRun(elt).runStatus === 'passed'
                                ? 'success'
                                : elt.status === 'failed'
                                  ? 'error'
                                  : 'danger'
                            " show-icon></el-alert>
                          </span>
                          <span v-else>{{ "N/A" }}</span>
                        </div>
                        <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                          <div v-if="getFivePengingSchedulled(elt.triggeredRuns).length > 0">
                            <div v-for="(etr, id) in getFivePengingSchedulled(
                              elt.triggeredRuns
                            )" :key="id">
                              <el-tooltip v-if="etr.runExecutedAt" class="item" :content="parseDate(etr.runExecutedAt)"
                                placement="top">
                                <a class="test-time-cont test-time-v-color" style="max-width: 99%" :href="
                                  '/details/test-explorer/view-test/' +
                                  getlastRun(elt).requestId
                                ">
                                  <i class="far fa-clock mr-1"></i>
                                  {{ parseDate(etr.runExecutedAt) }}
                                </a>
                              </el-tooltip>
                            </div>
                          </div>
                          <span v-else>{{ "N/A" }}</span>
                        </div>
                        <div class="elt-row flex--elt" :style="'width: calc(100% /' + tHeader2.length + ')'">
                          <el-tooltip content="Info" placement="top">
                            <el-button type="success" icon="el-icon-view" @click.stop="handleViewWebhook(elt)" circle plain
                              size="small"></el-button>
                          </el-tooltip>
                          <el-tooltip content="Edit" placement="top">
                            <el-button type="primary" icon="el-icon-edit" @click.stop="handleEditWebhook(elt, id)" circle
                              plain size="small"></el-button>
                          </el-tooltip>
                          <el-tooltip content="Delete" placement="top">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="handleDeleteWebhook(elt)" circle plain
                              size="small"></el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="tblb--row mb-2" v-else>
                    <div class="tblb--inner px-3 py-3">No Data ...</div>
                  </div>
                </template>
              </template>
            </template>
          </data-list>
        </template>
        <template>
          <div v-if="scheduledRun" class="mt-4">
            <scheduled-run ref="scheduledRun" :scriptFiles="scriptFiles"></scheduled-run>
          </div>
        </template>
      </div>
    </div>

    <div>

    <div class="is-scrollable webhook--modal">
      <modal :show.sync="toggleWebhookModal" v-loading="addWebhookLoading">
        <template slot="header">
          <h5 class="modal-title">Add new webhook trigger</h5>
        </template>
        <div>
          <div class="mod--content">
            <el-form class="small-spaces" @submit.native.prevent="addWebhook">
              <el-form-item label="Name" required>
                <el-input v-model="editedWebhook.name" placeholder="Enter a name for this webhook"></el-input>
              </el-form-item>
              <el-form-item label="Description">
                <el-input type="textarea" placeholder="Enter a description for this webhook"
                  v-model="editedWebhook.description"></el-input>
              </el-form-item>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Test script file" required>
                    <el-select class="select-primary" placeholder="Select the test script file for this webhook"
                      v-model="editedWebhook.fileKey">
                      <el-option v-for="(item, id) in scriptFiles" class="select-primary" :value="item.value"
                        :label="item.label" :key="id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Origin" required>
                    <el-select class="select-primary" placeholder="Select the origin of this webhook"
                      v-model="editedWebhook.triggerOrigin">
                      <el-option v-for="(item, id) in triggerOriginList" class="select-primary" :value="item.value"
                        :label="item.label" :key="id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-collapse class="mt-3">
                <el-collapse-item title="Test run options">
                  <template slot="title">
                    <i class="header-icon el-icon-setting"></i>
                    <span class="collapse--title ml-2">Test run options</span>
                  </template>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Run name">
                        <el-input placeholder="Enter a name for this run option"
                          v-model="editedWebhook.runOptions.runName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Run tags">
                        <el-input placeholder="Enter tags for this run option"
                          v-model="editedWebhook.runOptions.runTags"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Description">
                        <el-input type="textarea" placeholder="Enteer a description for this run option"
                          v-model="editedWebhook.runOptions.runDesc"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Browser Width">
                        <el-input v-model="editedWebhook.runOptions.browserWidth"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Browser Height">
                        <el-input v-model="editedWebhook.runOptions.browserHeight"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Step wait time">
                        <el-input v-model="editedWebhook.runOptions.stepWait"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Run timeout (sec)">
                        <el-input v-model="editedWebhook.runOptions.runnerUserTimeout"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" class="my-3">
                      <el-form-item class="no-space">
                        <el-checkbox label="Ignore disabled elements"
                          v-model="editedWebhook.runOptions.ignoreDisabled"></el-checkbox>
                      </el-form-item>
                      <el-form-item class="no-space">
                        <el-checkbox label="Ignore invisible elements"
                          v-model="editedWebhook.runOptions.ignoreInvisible"></el-checkbox>
                      </el-form-item>
                      <el-form-item class="no-space">
                        <el-checkbox label="Collect extra element data & try to fix broken selectors (slow)"
                          v-model="editedWebhook.runOptions.slowHeal"></el-checkbox>
                      </el-form-item>
                      <el-form-item class="no-space">
                        <el-checkbox label="Generate selectors for all elements on each visited page (slow)"
                          v-model="editedWebhook.runOptions.allSuggest"></el-checkbox>
                      </el-form-item>
                      <el-form-item class="no-space">
                        <el-checkbox label="Collect page-interaction event data for test case augmentation"
                          v-model="editedWebhook.runOptions.tgAnalyze"></el-checkbox>
                      </el-form-item>
                      <el-form-item class="no-space">
                        <el-checkbox label="Verify suggested selectors" v-model="
                          editedWebhook.runOptions.runSelectorVerification
                        "></el-checkbox>
                      </el-form-item>
                      <el-form-item class="no-space">
                        <el-checkbox label="Try to avoid string/text methods when generating XPath selectors" v-model="
                          editedWebhook.runOptions.xpathDownweightStrings
                        "></el-checkbox>
                      </el-form-item>
                      <el-form-item class="no-space">
                        <el-checkbox label="Generate CSS selectors during run (slow, needed for Cypress export)" v-model="
                          editedWebhook.runOptions.generateCssSelectors
                        "></el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Environment start URL for test run">
                        <el-input placeholder="https://example.com"
                          v-model="editedWebhook.runOptions.runStartUrl"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Avoid these attributes when generating selectors">
                        <el-input placeholder="Enter attributes to avoid, separated by spaces, e.g. id class href"
                          v-model="editedWebhook.runOptions.badAttributeList"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Use these attributes when generating selectors">
                        <el-input placeholder="Enter attributes to use, separated by spaces, e.g. id class href"
                          v-model="editedWebhook.runOptions.goodAttributeList"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </el-collapse>
              <div class="edit-accordion-btns mt-4 flex">
                <div>
                  <base-button type="primary" native-type="submit">
                    Add Webhook
                  </base-button>
                </div>
                <div>
                  <base-button type="secondary" @click="toggleWebhookModal = false">
                    Cancel
                  </base-button>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </modal>
    </div>

    <div>
      <modal :show.sync="toggleWebhookCompleteModal">
        <template slot="header">
          <h5 class="modal-title">Webhook setup complete</h5>
        </template>
        <div class=" mod--content">
          <el-alert type="warning" show-icon class="mb-3">
            <template slot="title">
              Make sure to copy your <code>Trigger Url</code> and
              <code>Triger Secret</code>
            </template>
          </el-alert>
          <p>
            Please set up your webhook call using the trigger URL and the secret
            below. If this is a generic POST webhook, use
            <code>"X-Tg-Webhook-Secret"</code> in the header of the POST request
            to trigger the test run. For Github and Gitlab, set up the webhook
            using your repository settings.
          </p>

          <el-form label-position="right" label-width="130px" class="small-spaces">
            <el-form-item label="Trigger URL">
              <el-input v-model="webhookComplete.triggerUrl" :readonly="true">
                <template slot="append">
                  <el-tooltip content="Copy trigger URL" placement="top">
                    <span class="h--copy" style="cursor: pointer" @click="handleCopy2(webhookComplete.triggerUrl)">
                      <i class="el-icon-copy-document"></i>
                    </span>
                  </el-tooltip>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="Trigger Secret">
              <el-input v-model="webhookComplete.triggerSecret" :readonly="true">
                <template slot="append">
                  <el-tooltip content="Copy trigger Secret" placement="top">
                    <span class="h--copy" style="cursor: pointer" @click="handleCopy2(webhookComplete.triggerSecret)">
                      <i class="el-icon-copy-document"></i>
                    </span>
                  </el-tooltip>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <hr />
          <p>
            The webhook can be triggered using: <br />

            <el-alert type="info" class="mb-3" :closable="false" effect="dark">
              <template slot="title">
                <code style="color: #fff" class="mt-2">
                    curl -X POST -H "X-Tg-Webhook-Secret: ${trigger_secret}"
                    ${trigger_url}
                  </code>
              </template>
            </el-alert>
          </p>
          <div class="edit-accordion-btns mt-4">
            <base-button type="primary" @click="toggleWebhookCompleteModal = false">
              OK
            </base-button>
          </div>
        </div>
      </modal>
    </div>

    <!-- Add Github Integration Modal-->
    <div class="show-integration-modal">
      <modal :show.sync="showGithubIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">Github Integration</h5>
        </template>
        <div>
          <div class="re-run-modal">
            <div class="run-name">
              <label class="model-label">Github Repo Name*</label>
              <input type="text" placeholder="Enter github repo name" v-model="repo_name" required="true" /><br />

              <label class="model-label">Github Repo Owner*</label>
              <input type="text" placeholder="Enter a github repo owner name" v-model="repo_owner"
                required="true" /><br />
              <label class="model-label">Github Access Token*</label>
              <input type="text" placeholder="Enter a github access token" v-model="access_token" required="true" /><br />
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn flex">
            <div>
              <base-button type="secondary" @click="closeGithubIntegrationModal">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="addGithubIntegration">Add</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>

    <!-- Add Gitlab Integration Modal-->
    <div class="show-integration-modal">
      <modal :show.sync="showGitlabIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">Gitlab Integration</h5>
        </template>
        <div>
          <div class="re-run-modal">
            <div class="run-name">
              <label class="model-label">Gitlab Organization*</label>
              <input type="text" placeholder="Enter Gitlab organization name" v-model="glab_org_name"
                required="true" /><br />

              <label class="model-label">Gitlab Project Name*</label>
              <input type="text" placeholder="Enter a Gitlab project name" v-model="glab_project_name"
                required="true" /><br />
              <label class="model-label">Gitlab Access Token*</label>
              <input type="text" placeholder="Enter a Gitlab access token" v-model="glab_access_token"
                required="true" /><br />
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn flex">
            <div>
              <base-button type="secondary" @click="closeGitlabIntegrationModal">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="addGitlabIntegration">Add</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>

    <!-- Add Salesforce Integration Modal-->
    <div class="show-integration-modal">
      <modal :show.sync="toggleSalesforceIntegrationModal" v-loading="loadingSalesforceIntegration">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            Salesforce Integration {{ editedSalesforceIndex > -1 ? "Edit" : "Add" }}
          </h5>
        </template>
        <div>
          <div class="re-run-modal">
            <div class="run-name">
              <label class="model-label">Username*</label>
              <input type="text" placeholder="Enter Salesforce username" v-model="editedSalesforceIntegration.username"
                required="true" /><br />

              <label class="model-label">Password*</label>
              <input type="password" placeholder="Enter the Salesforce password"
                v-model="editedSalesforceIntegration.password" required="true" /><br />
              <label class="model-label">Access Token*</label>
              <input type="text" placeholder="Enter the Salesforce access token"
                v-model="editedSalesforceIntegration.token" required="true" /><br />
              <div v-if="editedSalesforceIndex > -1">
                <label class="model-label mt-3" for="glab_integration_status">
                  Integration Status*
                </label>
                <el-switch v-model="editedSalesforceIntegration.integration_enabled" active-color="#13ce66"
                  inactive-color="#ff4949"></el-switch>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn flex">
            <div>
              <base-button type="secondary" @click="toggleSalesforceIntegrationModal = false">
                Cancel
              </base-button>
            </div>
            <div>
              <base-button type="primary" :disabled="!canAddSalesforce" @click="processAddSalesforce">
                {{ editedSalesforceIndex > -1 ? "Save" : "Save" }}
              </base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>

    <!-- Add Jenkins Integration Modal-->
    <div class="jenkins-integration-modal">
      <modal :show.sync="showJenkinsIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            Jenkins Integration
          </h5>
        </template>
        <div>
          <div class="jenkins-modal">
            <div class="">
              <!-- Add text with markdown to add jenkins installations instructions-->
              <p>
                The Wring Integration with Jenkins allows you to automate
                your Jenkins jobs while leveraging the Wring Interceptor. You
                can get started by adding the following to a build step in the
                build environment.
              </p>
              <div class="integration-script jenkins-integration-script">
                <div class="copy-code-btn">
                  <i class="ni ni-ungroup c-icon" v-on:click="copyCode('jenkins')"></i>
                </div>
                <!-- Don't format below this line till </pre> -->
                <pre><code id="jenkins_code">
  #!/bin/bash
  git clone git@github.com:your_github_username/your_repo_name.git
  cd /repo/testlocation
  # Download and install the Selenium interceptor package you wish to use (example below is for Javascript)
  curl -L -O https://wringapiutils.s3.us-west-2.amazonaws.com/interceptor-packages/selenium-webdriver-20.11.0.tgz
  npm install selenium-webdriver-20.11.0.tgz

  # Make sure the Wring token is stored as a Jenkins secret and accessible
  # using the environment variable $TG_TOKEN
  # You can use other environment variables to customize interceptor behavior:
  # https://aichemydev.github.io/Wring_docs/docs/tutorial-extras/customizing-interceptor

  # Run your Selenium tests as normal.</code></pre>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="closeJenkinsIntegrationModal">Cancel</base-button>
          </div>
        </template>
      </modal>
    </div>

    <!-- Add CircleCI Integration Modal start-->
    <div class="ci-circle-integration-modal">
      <modal :show.sync="showCircleCIIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            CircleCI Integration
          </h5>
        </template>
        <div>
          <div class="ci-circle-modal">
            <div class="">
              <!-- Add text with markdown to add jenkins installations instructions-->
              <p>Leverage the Wring Interceptor with your CircleCI jobs.</p>
              <div class="integration-script ci-circle-integration-script">
                <div class="copy-code-btn">
                  <i class="ni ni-ungroup c-icon" id="clipboard_copy" data-copytarget="#exampleFormControlTextarea3"
                    v-on:click="copyCode('ci_circle')"></i>
                </div>
                <!-- Don't format below this line till </pre> -->
                <pre>
  <code id="ci_circle_code">
  # Use the latest 2.1 version of CircleCI pipeline process engine.
  # See: https://circleci.com/docs/2.0/configuration-reference
  version: 2.1

  # Orbs are reusable packages of CircleCI configuration that you may share across projects, enabling you to create encapsulated, parameterized commands, jobs, and executors that can be used across multiple projects.
  # See: https://circleci.com/docs/2.0/orb-intro/
  orbs:
    # See the orb documentation here: https://circleci.com/developer/orbs/orb/circleci/python
    python: circleci/python@1.2
    browser-tools: circleci/browser-tools@1.2.3

  # Define a job to be invoked later in a workflow.
  # See: https://circleci.com/docs/2.0/configuration-reference/#jobs
  jobs:
    build-and-test: # This is the name of the job, feel free to change it to better match what you're trying to do!
      # These next lines defines a Docker executors: https://circleci.com/docs/2.0/executor-types/
      # The executor is the environment in which the steps below will be executed - below will use a python 3.8 container
      # Change the version below to your required version of python
      docker:
        - image: cimg/python:3.8
      # Checkout the code as the first step.
      # CircleCI will report the results back to your VCS provider.
      steps:
        - checkout
        # This is where we install browsers which will be used later when running your tests.
        - browser-tools/install-browser-tools:
            chrome-version: 85.0.4183.102
            firefox-version: 80.0.1
        - run:
            command: |
              google-chrome --version
              firefox --version
            name: Check install
        # From the browser-tools orb, we can install the necessary drivers to run tests on a browser.
        # For more information on this orb, checkout this link: https://circleci.com/developer/orbs/orb/circleci/browser-tools
        - browser-tools/install-chrome
        - browser-tools/install-chromedriver

        # Now, we will configure the Wring interceptor. Make sure to add your TG_TOKEN to your project secrets.
        # For a full list of interceptor options check out the documentation:
        # https://aichemydev.github.io/Wring_docs/docs/tutorial-extras/customizing-interceptor
        - run:
            name: Download and Configure Wring Interceptor
            command: |
              curl -L -O https://wringapiutils.s3.us-west-2.amazonaws.com/interceptor-packages/selenium-20.11.0-py2.py3-none-any.whl
              pip install selenium-20.11.0-py2.py3-none-any.whl --force-reinstall -U
              export TG_TOKEN=$TG_TOKEN
        - python/install-packages:
            pkg-manager: pip
            # app-dir: ~/project/package-directory/  # If your requirements.txt isn't in the root directory.
            # pip-dependency-file: test-requirements.txt  # if you have a different name for your requirements file, maybe one that combines your runtime and test requirements.
        - run:
            name: Run tests
            command: python your_test_file.py

  # Invoke jobs via workflows
  # See: https://circleci.com/docs/2.0/configuration-reference/#workflows
  workflows:
    sample: # This is the name of the workflow, feel free to change it to better match your workflow.
      # Inside the workflow, you define the jobs you want to run.
      jobs:
        - build-and-test
                          </code>
                          </pre>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="closeCircleCIIntegrationModal">Cancel</base-button>
          </div>
        </template>
      </modal>
    </div>
    <!-- Add CircleCI Integration Modal end-->

    <!-- Add Travis CI Integration Modal start-->
    <div class="travis-ci-integration-modal">
      <modal :show.sync="showTravisCIIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            TravisCI Integration
          </h5>
        </template>
        <div>
          <div class="travis-ci-modal">
            <div class="">
              <!-- Add text with markdown to add jenkins installations instructions-->
              <p>Leverage the Wring Interceptor with your TravisCI jobs.</p>
              <div class="integration-script travis-ci-integration-script">
                <div class="copy-code-btn">
                  <i class="ni ni-ungroup c-icon" id="clipboard_copy" data-copytarget="#exampleFormControlTextarea3"
                    v-on:click="copyCode('travis_ci')"></i>
                </div>
                <!-- Don't format below this line till </pre> -->
                <pre>
  <code id="travis_code">
  sudo: required
  language: python
  python:
    "3.8"
  addons:
    chrome: stable

  before_install:
  - curl -L -O https://wringapiutils.s3.us-west-2.amazonaws.com/interceptor-packages/selenium-20.11.0-py2.py3-none-any.whl

  before_script:
    - LATEST_CHROMEDRIVER_VERSION=`curl -s "https://chromedriver.storage.googleapis.com/LATEST_RELEASE"`
    - curl "https://chromedriver.storage.googleapis.com/${LATEST_CHROMEDRIVER_VERSION}/chromedriver_linux64.zip" -O
    - unzip chromedriver_linux64.zip -d ~/bin
    - export PATH=$PATH:$PWD/chromedriver

  # Make sure the TG_TOKEN environment variable is set to the Wring token
  # You can use other environment variables to customize interceptor behavior:
  # https://aichemydev.github.io/Wring_docs/docs/tutorial-extras/customizing-interceptor
  env:
    global:
    - export TG_ANALYZER=1

  install:
    - pip install selenium-20.11.0-py2.py3-none-any.whl --force-reinstall -U
    - pip install -r requirements.txt

  script:
    python your_test_file.py</code></pre>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showTravisCIIntegrationModal = false">Cancel</base-button>
          </div>
        </template>
      </modal>
    </div>
    <!-- Add Travis CI Integration Modal end-->

    <!-- Add Gitlab Integration Modal-->
    <div class="show-integration-modal">
      <modal :show.sync="showGitlabIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">Gitlab Integration</h5>
        </template>
        <div>
          <div class="re-run-modal">
            <div class="run-name">
              <label class="model-label">Gitlab Organization*</label>
              <input type="text" placeholder="Enter Gitlab organization name" v-model="glab_org_name"
                required="true" /><br />

              <label class="model-label">Gitlab Project Name*</label>
              <input type="text" placeholder="Enter a Gitlab project name" v-model="glab_project_name"
                required="true" /><br />
              <label class="model-label">Gitlab Access Token*</label>
              <input type="text" placeholder="Enter a Gitlab access token" v-model="glab_access_token"
                required="true" /><br />
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn flex">
            <div>
              <base-button type="secondary" @click="closeGitlabIntegrationModal">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="addGitlabIntegration">Add</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>

    <!-- Github Integration Edit Modal-->
    <div class="show-integration-modal">
      <modal :show.sync="showEditGithubIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="editGithubIntegrationModal">
            Edit Github Integration
          </h5>
        </template>
        <div>
          <div class="re-run-modal">
            <div class="run-name">
              <label class="model-label">Github Repo Name*</label>
              <input type="text" placeholder="Enter github repo name" v-model="repo_name" required="true" /><br />

              <label class="model-label">Github Repo Owner*</label>
              <input type="text" placeholder="Enter a github repo owner name" v-model="repo_owner"
                required="true" /><br />
              <label class="model-label">Github Access Token*</label>
              <input type="text" placeholder="Enter a github access token" v-model="access_token" required="true" /><br />
              <div>
                <label class="model-label mt-3" for="git_integration_status">Integration Status*</label>

                <el-switch v-model="git_integration_status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn flex">
            <div>
              <base-button type="danger" @click="deleteGithubIntegration">Delete</base-button>
            </div>
            <div>
              <base-button type="secondary" @click="closeEditGithubIntegrationModal">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="editGithubIntegration">Update</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>

    <!-- Gitlab Integration Edit Modal-->
    <div class="show-integration-modal">
      <modal :show.sync="showEditGitLabIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="editGitlabIntegrationModal">
            Edit Gitlab Integration
          </h5>
        </template>
        <div>
          <div class="re-run-modal">
            <div class="run-name">
              <label class="model-label">Gitlab Organization*</label>
              <input type="text" placeholder="Enter Gitlab organization name" v-model="glab_org_name"
                required="true" /><br />

              <label class="model-label">Gitlab Project Name*</label>
              <input type="text" placeholder="Enter a Gitlab project name" v-model="glab_project_name"
                required="true" /><br />
              <label class="model-label">Gitlab Access Token*</label>
              <input type="text" placeholder="Enter a Gitlab access token" v-model="glab_access_token"
                required="true" /><br />
              <div>
                <label class="model-label mt-3" for="glab_integration_status">Integration Status*</label>

                <el-switch v-model="glab_integration_status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn flex">
            <div>
              <base-button type="danger" @click="deleteGitlabIntegration">Delete</base-button>
            </div>
            <div>
              <base-button type="secondary" @click="showEditGitLabIntegrationModal = false">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="editGitlabIntegration">Update</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>

    <!-- Atlassian Modal Start -->
    <div class="show-integration-modal">
      <modal :show.sync="showAtlassianModal">
        <template slot="header">
          <h5 class="modal-title" id="editSlackIntegrationModal">
            Atlassina Dummy Text
          </h5>
        </template>
        <div class="list-of-jira-projects">
          <label class="model-label" for="atlassianProject">Type</label>
          <el-select class="select-danger" placeholder="Select Project" v-model="atlassianProject">
            <el-option v-for="(option, index) in atlassianProjectList" class="select-danger" :value="option"
              :label="index" :key="index">
            </el-option>
          </el-select>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <div>
              <base-button type="secondary" @click="showAtlassianModal = false">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="atlassianIntegrationAction">Update</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!-- Atlassian Modal End -->

    <!-- Atlassian Edit Modal Start -->
    <div class="show-integration-modal">
      <modal :show.sync="showEditAtlassianIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="editSlackIntegrationModal">
            Edit Atlassian Integration
          </h5>
        </template>
        <div class="list-of-jira-projects">
          <label class="model-label" for="atlassianProject">Type</label>
          <el-select class="select-danger" placeholder="Select Project" v-model="atlassianProject">
            <el-option v-for="(option, index) in atlassianProjectList" class="select-danger" :value="option"
              :label="index" :key="index">
            </el-option>
          </el-select>
          <label class="model-label mt-3" for="atlassianIntegrationEnabled">Integration Status</label>

          <el-switch v-model="atlassianIntegrationEnabled" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn flex">
            <div>
              <base-button type="secondary" @click="showEditAtlassianIntegrationModal = false">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="editAtlassianIntegrationAction">Update</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!-- Atlassian Edit Modal End -->

    <!-- Slack Integration Edit Modal start-->
    <div class="show-integration-modal">
      <modal :show.sync="showEditSlackIntegrationModal">
        <template slot="header">
          <h5 class="modal-title" id="editSlackIntegrationModal">
            Edit Slack Integration
          </h5>
        </template>
        <div>
          <label class="model-label mt-3" for="slack_integration_status">Integration Status</label>

          <el-switch v-model="slack_integration_status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn flex">
            <div>
              <base-button type="secondary" @click="closeEditSlackIntegrationModal">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="editSlackIntegration">Update</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!-- Slack Integration Edit Modal end-->

    <!--Redirect countdown Modal Start -->
    <div class="show-integration-modal">
      <modal :show.sync="showRedirectCountdownModal" @close="cancelRedirect">
        <template slot="header">
          <h5 class="modal-title" id="editSlackIntegrationModal">
            Redirecting
          </h5>
        </template>
        <div class="redirect-wrapper">
          Redirecting to {{ redirectingUrl }} in
          <br />
          <span class="redirect-count">{{ redirectCount }}</span>
          <!-- <br /> -->
          seconds
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <div>
              <base-button type="secondary" @click="cancelRedirect">Cancel</base-button>
            </div>
            <div>
              <base-button type="primary" @click="confirmRedirect(redirectingUrl)">Confirm</base-button>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!-- Redirect countdown Modal End -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import swal from "sweetalert2";
import DataList from "@/components/Custom/DataList.vue";
import { Select, Option, Table, TableColumn } from "element-ui";
import ScheduledRun from "./itemIntegration/ScheduledRun";
import ConfigurationsContent from "../../ConfigurationsPage/ConfigurationsContent.vue";
import LeftSideBar from "../../../components/SidebarPlugin/LeftSideBar.vue"
export default {
  name: "Integrations",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    DataList,
    ConfigurationsContent,
    ScheduledRun,
    LeftSideBar
  },

  data() {
    return {
      redirectCount: 30,
      scheduledRun: false,
      log: console.log,
      showGithubIntegrationModal: false,
      showGitlabIntegrationModal: false,
      showEditGithubIntegrationModal: false,
      showEditGitLabIntegrationModal: false,
      showJenkinsIntegrationModal: false,
      showCircleCIIntegrationModal: false,
      showEditSlackIntegrationModal: false,
      showEditAtlassianIntegrationModal: false,
      showTravisCIIntegrationModal: false,
      showRedirectCountdownModal: false,
      toggleSalesforceIntegrationModal: false,
      redirectingUrl: "",
      run_name: "",
      run_description: "",
      run_status: "",
      repo_owner: "",
      repo_name: "",
      access_token: "",
      glab_org_name: "",
      glab_project_name: "",
      glab_access_token: "",
      glab_integration_status: false,
      git_integration_status: false,
      slack_integration_status: true,
      unique_integration_identifier: "",
      integrationData: [],
      webhookData: [],
      atlassianUrl: `${process.env.VUE_APP_API_URL_PREFIX
        }/v1/jira/oauth2/authorize?walToken=${localStorage.getItem(
          "vue-authenticate.access_token"
        )}`,
      slackUrl: `${process.env.VUE_APP_API_URL_PREFIX
        }/v1/slack/oauth2/authorize?walToken=${localStorage.getItem(
          "vue-authenticate.access_token"
        )}`,
      atlassianRedirect: "false",
      showAtlassianModal: false,

      typeSelectOptions: [
        { value: "address", label: "Address" },
        { value: "credit_card", label: "Credit card" },
        { value: "currency", label: "Currency" },
        { value: "date", label: "Date" },
        { value: "email", label: "Email" },
        { value: "firstName", label: "First name" },
        { value: "float", label: "Float" },
        { value: "integer", label: "Integer" },
        { value: "lastName", label: "Last name" },
        { value: "password", label: "Password" },
        { value: "phoneNumber", label: "Phone number" },
        { value: "set", label: "Set" },
      ],
      atlassianProject: "",
      atlassianProjectList: {},
      atlassianIntegrationEnabled: false,
      showReport: false,
      showTrigger: false,
      showScheduled: false,
      toggleView: false,
      availableIntegrations: [
        {
          name: "Github",
          color: "#151013",
          colorTa: "#1510130D",
          keyId: "intergrationsGithub",
          img: "github.svg",
          imgWidth: "80%",
        },
        {
          name: "Atlassian - JIRA",
          color: "#0052cc",
          colorTa: "#0052cc0D",
          keyId: "intergrationsAtlassianJIRA",
          img: "jira.svg",
          imgWidth: "80%",
        },
        {
          name: "Gitlab",
          color: "#e24329",
          colorTa: "#e243290D",
          keyId: "intergrationsGitlab",
          img: "gitlab.svg",
          imgWidth: "140%",
        },
        {
          name: "Slack",
          color: "#df1d5a",
          colorTa: "#df1d5a0D",
          keyId: "intergrationsSlack",
          img: "slack.svg",
          imgWidth: "70%",
        },
        {
          name: "Jenkins",
          color: "#335061",
          colorTa: "#3350610D",
          keyId: "intergrationsJenkins",
          img: "jenkins.svg",
          imgWidth: "70%",
        },
        {
          name: "Circle CI",
          color: "#161616",
          colorTa: "#1616160D",
          keyId: "intergrationsCircleCI",
          img: "circleci.svg",
          imgWidth: "85%",
        },
        {
          name: "Travis CI",
          color: "#cb3349",
          colorTa: "#cb33490D",
          keyId: "intergrationsTravisCI",
          img: "travisci.svg",
          imgWidth: "80%",
        },
        {
          name: "Salesforce",
          color: "#22a0e0",
          colorTa: "#22a0e00D",
          keyId: "intergrationsSalesforce",
          img: "salesforces.svg",
          imgWidth: "200%",
        },
      ],
      availableWebhooks: [
        {
          name: "Github",
          val: "github",
          color: "#151013",
          colorTa: "#1510130D",
          img: "github.svg",
          imgWidth: "80%",
        },
        {
          name: "Gitlab",
          val: "gitlab",
          color: "#e24329",
          colorTa: "#e243290D",
          img: "gitlab.svg",
          imgWidth: "140%",
        },
        {
          name: "POST Request",
          val: "generic",
          color: "#0097a7",
          colorTa: "#0097a70D",
          img: "pr.png",
          imgWidth: "80%",
        },
      ],
      timeout: "",
      loading: false,
      loading2: false,
      toggleWebhookModal: false,
      tHeader: [
        {
          label: this.$t("integrations.name"),
          key: "_id",
          width: "30%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: this.$t("integrations.description"),
          key: "",
          width: "50%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: this.$t("integrations.status"), key: "", width: "20%", sortable: false },
        { label: this.$t("integrations.actions"), key: "", width: "10%", sortable: false },
      ],
      tHeader2: [
        { label: this.$t("integrations.origin"), key: "", sortable: false },
        { label: this.$t("integrations.name"), key: "", sortable: false },
        { label: this.$t("integrations.added_updated"), key: "", sortable: false },
        { label: this.$t("integrations.last_at"), key: "", sortable: false },
        { label: this.$t("integrations.last_status"), key: "", sortable: false },
        { label: this.$t("integrations.last_five"), key: "", sortable: false },
        { label: this.$t("integrations.actions"), key: "", sortable: false },
      ],
      newWebhook: {
        name: "",
        description: "",
        triggerType: "webhook",
        triggerOrigin: "",
        fileKey: "",
        runOptions: {
          runName: "",
          runDesc: "",
          runTags: "",
          browserWidth: 1920,
          browserHeight: 1080,
          stepWait: 5.0,
          ignoreInvisible: true,
          ignoreDisabled: true,
          slowHeal: false,
          allSuggest: false,
          tgAnalyze: true,
          runnerUserTimeout: 3600.0,
          runSelectorVerification: false,
          runStartUrl: "",
          badAttributeList: "",
          goodAttributeList: "",
          xpathDownweightStrings: false,
          generateCssSelectors: false,
        },
      },
      editedWebhook: {
        name: "",
        description: "",
        triggerType: "webhook",
        triggerOrigin: "",
        fileKey: "",
        runOptions: {
          runName: "",
          runDesc: "",
          runTags: "",
          browserWidth: 1920,
          browserHeight: 1080,
          stepWait: 5.0,
          ignoreInvisible: true,
          ignoreDisabled: true,
          slowHeal: false,
          allSuggest: false,
          tgAnalyze: true,
          runnerUserTimeout: 3600.0,
          runSelectorVerification: false,
          runStartUrl: "",
          badAttributeList: "",
          goodAttributeList: "",
          xpathDownweightStrings: false,
          generateCssSelectors: false,
        },
      },
      editedIndex: -1,
      scriptFiles: [],
      triggerOriginList: [
        { label: "Github", value: "github" },
        { label: "Gitlab", value: "gitlab" },
        { label: "POST Request", value: "generic" },
      ],
      addWebhookLoading: false,
      loadingSalesforceIntegration: false,
      toggleWebhookCompleteModal: false,
      webhookComplete: {
        triggerId: undefined,
        triggerUrl: undefined,
        triggerSecret: undefined,
      },
      editedSalesforceIndex: -1,
      editedSalesforceIntegration: {
        username: "",
        password: "",
        token: "",
        integration_enabled: "",
      },
      newSalesforceIntegration: {
        username: "",
        password: "",
        token: "",
        integration_enabled: "",
      },
      showInstallation: true
    };
  },

  methods: {
    getFivePengingSchedulled(val) {
      const sortDesc = (a, b) => b - a;
      let ret = val.filter((val) => val.runStatus === "scheduled");
      let maxReturn = [];
      for (let i = 0; i < val.length; i++) {
        maxReturn.push(val[i]);
      }
      return maxReturn.sort(sortDesc);
    },
    getlastRun(val) {

      let createDate = new Date(val.createdAt);
      let requestId = "";
      let runDate = "";
      let runStatus = "";
      val.triggeredRuns.forEach((el) => {
        if (new Date(el.runExecutedAt) > new Date(createDate)) {
          requestId = el.runTestRequestId;
          runDate = el.runExecutedAt;
          runStatus = el.runStatus;
        }
      });

      return { requestId: requestId, runDate: runDate, runStatus: runStatus };
    },
    parseDate(val) {
      return moment(val).format("MMM Do YYYY, h:mm:ss a");
    },

    async handleCopy(val) {
      try {
        await navigator.clipboard.writeText(val);
        this.$notify({
          type: "success",
          message: `Copied`,
        });
      } catch ($e) {
        this.$notify({
          type: "danger",
          message: `Cannot copy`,
        });
      }
    },

    handleCopy2(el) {
      if (el.length > 0) {
        navigator.clipboard.writeText(el).then(() => {
          this.$notify({
            type: "success",
            message: "Copied to the Clipboard!",
          });
        });
      }
      const ipt = this.$refs["input"].$el.children[0];
      ipt.select();
      document.execCommand("copy");
      this.$notify({
        type: "success",
        message: "Copied to the Clipboard!",
      });
    },

    showContent(val) {
      this.showTrigger = false;
      this.showReport = false;
      this.scheduledRun = false;
      this.showInstallation = false;
      if (val === "report") {
        this.showReport = true;
      } else if (val === "Scheduled") {
        this.scheduledRun = true;
      } else if (val === "installation") {
        this.showInstallation = true;
      } else {
        this.showTrigger = true;
      }
    },

    handleViewWebhook(elt) {
      this.webhookComplete = Object.assign(
        {},
        {
          triggerId: elt.triggerId,
          triggerUrl: undefined,
          triggerSecret: elt.triggerSecret,
          triggerUrl: elt.triggerUrl,
        }
      );
      this.toggleWebhookCompleteModal = true;
    },

    handleEditWebhook(elt, id) {
      this.editedWebhook = Object.assign(
        {},
        { ...elt, runOptions: elt.providedRunOptions }
      );
      this.editedIndex = id;
      this.toggleWebhookModal = true;
    },

    async getScriptFiles() {
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL_PREFIX}/testfiles/v1/scripts/all`)
          .then((response) => {
            if (response.data) {
              const val = response.data.response;
              this.scriptFiles = Object.entries(val).map((elt) => {
                return {
                  label: elt[0],
                  value: elt[1][0].fileKey,
                };
              });
            }
          })
          .catch((e) => {
            // this.$notify({
            //   type: "danger",
            //   message: `An error has occured : ${e}`,
            // });
            console.log("Error has occurred: ", e);
          });
      } catch (e) {
        console.log("Error has occurred: ", e);

        // this.$notify({
        //   type: "danger",
        //   message: `An error has occured : ${e.response.message}`,
        // });
      }
    },

    async addWebhook() {
      if (this.editedIndex === -1) {
        try {
          this.addWebhookLoading = true;
          await axios
            .post(
              `${process.env.VUE_APP_API_URL_PREFIX}/webhooks/v1/${this.editedWebhook.triggerOrigin}`,
              this.editedWebhook
            )
            .then((response) => {
              if (response.data) {
                this.$notify({
                  type: "success",
                  message: response.data.message,
                });

                this.webhookComplete = Object.assign(
                  {},
                  response.data.response
                );
                this.editedWebhook = Object.assign({}, this.newWebhook);
                this.toggleWebhookModal = false;
                this.toggleWebhookCompleteModal = true;
              }
            })
            .catch((e) => {
              console.log("Error has occurred: ", e);

              // this.$notify({
              //   type: "danger",
              //   message: `An error has occured : ${e.response.message}`,
              // });
            });
        } catch (e) {
          console.log("Error has occurred: ", e);

          // this.$notify({
          //   type: "danger",
          //   message: `An error has occured : ${e.response.message}`,
          // });
        } finally {
          this.addWebhookLoading = false;
          this.editedIndex = -1;
          await this.populateWebhookTable();
        }
      } else {
        try {
          this.addWebhookLoading = true;
          await axios
            .put(
              `${process.env.VUE_APP_API_URL_PREFIX}/webhooks/v1/${this.editedWebhook.triggerOrigin}/${this.editedWebhook.triggerId}`,
              this.editedWebhook
            )
            .then((response) => {
              if (response.data) {
                this.$notify({
                  type: "success",
                  message: response.data.message,
                });
                this.editedWebhook = Object.assign({}, this.newWebhook);
                this.toggleWebhookModal = false;
              }
            })
            .catch((e) => {
              console.log("Error has occurred: ", e);

              // this.$notify({
              //   type: "danger",
              //   message: `An error has occured : ${e.response.message}`,
              // });
            });
        } catch (e) {
          console.log("Error has occurred: ", e);

          // this.$notify({
          //   type: "danger",
          //   message: `An error has occured : ${e.response.message}`,
          // });
        } finally {
          this.addWebhookLoading = false;
          this.editedIndex = -1;
          await this.populateWebhookTable();
        }
      }
    },

    async handleDeleteWebhook(elt) {
      const confirmDeletaion = await swal.fire({
        title: "Webhook " + elt.name + " will be removed?",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep the webhook",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmDeletaion.isConfirmed) {
        try {
          this.loading2 = true;
          await axios
            .delete(
              `${process.env.VUE_APP_API_URL_PREFIX}/webhooks/v1/${elt.triggerOrigin}/${elt.triggerId}`
            )
            .then((response) => {
              if (response.data) {
                this.$notify({
                  type: "success",
                  message: response.data.message,
                });
              }
            })
            .catch((e) => {
              console.log("Error has occurred: ", e);

              // this.$notify({
              //   type: "danger",
              //   message: `An error has occured : ${e.response.message}`,
              // });
            });
        } catch (e) {
          console.log("Error has occurred: ", e);

          // this.$notify({
          //   type: "danger",
          //   message: `An error has occured : ${e.response.message}`,
          // });
        } finally {
          this.loading2 = false;
          this.editedIndex = -1;
          await this.populateWebhookTable();
        }
      }
    },

    async populateIntegrationsTable() {
      this.loading = true;
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", {
          params: {
            request_type: "all",
          },
        })
        .then((response) => {
          if (response.message != "Request failed with status code 404") {
            this.integrationData = response.data;
          } else if (
            response.message === "Request failed with status code 404"
          ) {
            this.integrationData = [];
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log("Error has occurred: ", e);

          // this.$notify({
          //   type: "danger",
          //   message: `An error has occured : ${error.response.message}`,
          // });
        });
    },

    async populateWebhookTable() {
      this.loading2 = true;
      await axios
        .get(`${process.env.VUE_APP_API_URL_PREFIX}/webhooks/v1/all`)
        .then((response) => {
          if (response.data) {
            this.webhookData = Object.values(response.data.response);
            // this.$notify({
            //   type: "success",
            //   message: response.data.message,
            // });
          }
          this.loading2 = false;
        })
        .catch((e) => {
          console.log("Error has occurred: ", e);

          // this.$notify({
          //   type: "danger",
          //   message: `An error has occured : ${e.response.message}`,
          // });
          this.loading2 = false;
        });
    },

    canShowData(elt) {
      return elt && elt.user_integrations && elt.user_integrations.length > 0;
    },
    copyCode(code) {
      let copyMaterial = "";
      if (code === "travis_ci") {
        copyMaterial = document.getElementById("travis_code").innerHTML;
      } else if (code === "ci_circle") {
        copyMaterial = document.getElementById("ci_circle_code").innerHTML;
      } else if (code === "jenkins") {
        copyMaterial = document.getElementById("jenkins_code").innerHTML;
      }
      if (navigator.clipboard.writeText(copyMaterial)) {
        this.$notify({
          type: "success",
          message: "Copied to the Clipboard!",
        });
      }
    },
    doSomething(a) {
      console.log(a);
    },
    confirmRedirect(url) {
      this.showRedirectCountdownModal = false;
      url === "https://www.slack.com" && window.location.replace(this.slackUrl);
      url === "https://www.atlassian.com" &&
        window.location.replace(this.atlassianUrl);
    },
    cancelRedirect() {
      this.showRedirectCountdownModal = false;
      this.redirectCount = 30;
      clearTimeout(this.timeout);
    },
    countdownRedirect(link) {
      if (this.redirectCount > 0) {
        this.timeout = setTimeout(() => {
          this.redirectCount -= 1;
          this.countdownRedirect();
          if (this.redirectCount === 0) {
            this.showRedirectCountdownModal = false;
            link === "Github" && window.location.replace(this.slackUrl);
            link === "Atlassian" && window.location.replace(this.atlassianUrl);
          }
        }, 1000);
      }
    },
    async editAtlassianIntegrationAction() {
      if (this.atlassianProject === "") {
        this.$notify({
          type: "danger",
          message: "Please select any project",
        });
      } else {
        const payload = {
          action: "addOrUpdate",
          integration_name: "atlassian",
          integration_enabled: this.atlassianIntegrationEnabled,
          integration_details: {
            project_id: this.atlassianProject,
          },
          unique_integration_identifier: "atlassian",
        };
        try {
          await axios
            .post(
              `${process.env.VUE_APP_API_URL_PREFIX}/integrations/user`,
              payload
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === "success") {
                this.showAtlassianModal = false;
                this.$notify({
                  type: "success",
                  message: `${response.data.message}`,
                });
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `${error.response.message}`,
          });
        }
      }
    },
    async atlassianIntegrationAction() {
      if (this.atlassianProject === "") {
        this.$notify({
          type: "danger",
          message: "Please select any project",
        });
      } else {
        const payload = {
          action: "addOrUpdate",
          integration_name: "atlassian",
          integration_enabled: true,
          integration_details: {
            project_id: this.atlassianProject,
          },
          unique_integration_identifier: "atlassian",
        };
        try {
          await axios
            .post(
              `${process.env.VUE_APP_API_URL_PREFIX}/integrations/user`,
              payload
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === "success") {
                this.showAtlassianModal = false;
                this.atlassianProject = "";
                this.$notify({
                  type: "success",
                  message: `${response.data.message}`,
                });
                console.log("repopulating integrations table");
                this.populateIntegrationsTable();
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `${error.response.message}`,
          });
        }
      }
    },
    showModalForAtlassian() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL_PREFIX}/v1/integrations/jira/projects`
        )
        .then((response) => {
          console.log("response for atlassian projects list", response);
          if (response.data) {
            this.atlassianProjectList = response.data;
            this.showAtlassianModal = true;
          } else {
            this.atlassianProjectList = {};
          }
        })
        .catch((err) => {
          console.log("atlassion jira error", err);
        });
    },
    addJiraIntegration() {
      console.log("jira");
    },
    addGithubIntegration() {
      let repo_name = this.repo_name;
      let repo_owner = this.repo_owner;
      let access_token = this.access_token;

      let data = {
        action: "addOrUpdate",
        integration_name: "github",
        integration_details: {
          github_repo_name: repo_name,
          github_repo_owner: repo_owner,
          github_access_token: access_token,
        },
        unique_integration_identifier: repo_name,
        integration_enabled: true,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", data, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            console.log("response: ", response.response)
            if (response && response.response && response.response.status === 500) {
              this.$notify({
                type: "danger",
                message: "Failed to Github Integration, please check credentials",
              });
              // console.log("repopulating integrations table");
            } else {
              this.$notify({
                type: "success",
                message: "Github Integration added successfully.",
              });
              console.log("repopulating integrations table");
              this.populateIntegrationsTable();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          if (error.response.status === 500) {
            swal.fire(
              JSON.stringify(
                "Data Validation Failed: Please make sure that you have entered a valid token"
              )
            );
          } else {
            swal.fire(JSON.stringify("Error Adding Integration"));
          }
        });
      // close modal after adding integration if successful
      this.showGithubIntegrationModal = false;
    },

    handleShowModal(val) {
      console.log(val);
      if (val === "Github") {
        this.showGithubIntegrationModal = true;
      } else if (val === "Gitlab") {
        this.showGitlabIntegrationModal = true;
      } else if (val === "Jenkins") {
        this.showJenkinsIntegrationModal = true;
      } else if (val === "Circle CI") {
        this.showCircleCIIntegrationModal = true;
      } else if (val === "Travis CI") {
        this.showTravisCIIntegrationModal = true;
      } else if (val === "Atlassian - JIRA") {
        this.showRedirectCountdownModal = true;
        this.redirectingUrl = "https://www.atlassian.com";
        this.countdownRedirect("Atlassian");
      } else if (val === "Slack") {
        this.showRedirectCountdownModal = true;
        this.redirectingUrl = "https://www.slack.com";
        this.countdownRedirect("Slack");
      } else if (val === "Salesforce") {
        this.toggleSalesforceIntegrationModal = true;
      }
    },
    async processAddSalesforce() {
      const date = new Date("2015-03-25T12:00:00Z");
      const data = {
        action: "addOrUpdate",
        integration_name: "salesforce",
        integration_details: {
          salesforce_user_name: this.editedSalesforceIntegration.username,
          salesforce_password: this.editedSalesforceIntegration.password,
          salesforce_access_token: this.editedSalesforceIntegration.token,
        },
        unique_integration_identifier: this.editedSalesforceIntegration.username + Date.parse(date),
        integration_enabled:
          this.editedSalesforceIndex < 0
            ? true
            : this.editedSalesforceIntegration.integration_enabled,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      try {
        this.loadingSalesforceIntegration = true;
        const action = this.editedSalesforceIndex < 0 ? "added" : "edited";
        const uri = process.env.VUE_APP_API_URL_PREFIX + "/integrations/user";
        await axios.post(uri, data, { headers: headers }).then((response) => {
          if (response) {
            if (response.data.status === 'success') {
              this.$notify({
                type: "success",
                message: `Salesforce Integration ${action} successfully`,
              });
              this.loadingSalesforceIntegration = false;
              this.toggleSalesforceIntegrationModal = false;
              this.editedSalesforceIntegration = Object.assign(
                {},
                this.newSalesforceIntegration
              );
              this.populateIntegrationsTable();
            } else if (response.message.includes('Request failed with status code 500')) {
              this.$notify({
                type: "danger",
                message: 'Request failed ',
              });
              this.loadingSalesforceIntegration = false;
            } else {
              this.$notify({
                type: "danger",
                message: response.data.message,
              });
              this.loadingSalesforceIntegration = false;
            }
          }
        });
      } catch (e) {
        const action = this.editedSalesforceIndex < 0 ? "add" : "edit";
        this.$notify({
          type: "danger",
          message: `Unable to ${action} this Salesforce integration check the integration information`,
        });
        this.loadingSalesforceIntegration = false;
      } finally {
        this.loadingSalesforceIntegration = false;
      }
    },

    handleShowWebhookModal(val) {
      this.editedWebhook.triggerOrigin = this.triggerOriginList.find(
        (elt) => elt.value === val
      ).value;
      this.toggleWebhookModal = true;
    },

    addGitlabIntegration() {
      let glab_org_name = this.glab_org_name;
      let glab_project_name = this.glab_project_name;
      let glab_access_token = this.glab_access_token;
      let data = {
        action: "addOrUpdate",
        integration_name: "gitlab",
        integration_details: {
          gitlab_org_name: glab_org_name,
          gitlab_project_name: glab_project_name,
          gitlab_access_token: glab_access_token,
        },
        unique_integration_identifier: glab_project_name,
        integration_enabled: true,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", data, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: "Gitlab Integration added successfully.",
            });
            console.log("repopulating integrations table");
            this.populateIntegrationsTable();
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          if (error.response.status === 500) {
            swal.fire(
              JSON.stringify(
                "Data Validation Failed: Please make sure that you have entered a valid token"
              )
            );
          } else {
            swal.fire(JSON.stringify("Error Adding Integration"));
          }
        });
      // close modal after adding integration if successful
      this.showGitlabIntegrationModal = false;
    },

    processEditRequest(value) {
      if (value.integration_name === "github") {
        this.openEditGithubIntegrationModal(value);
      } else if (value.integration_name === "gitlab") {
        this.openEditGitlabIntegrationModal(value);
      } else if (value.integration_name === "slack") {
        this.openEditSlackIntegrationModal(value);
      } else if (value.integration_name === "atlassian") {
        this.openEditAtlassianIntegrationModal(value);
      } else if (value.integration_name === "salesforce") {
        const data = {
          username: value.salesforce_user_name,
          password: value.salesforce_password,
          token: value.salesforce_access_token,
          integration_enabled: value.integration_enabled,
        };
        this.editedSalesforceIndex = value.id;
        this.editedSalesforceIntegration = Object.assign({}, data);
        this.toggleSalesforceIntegrationModal = true;
      }
    },

    processDeleteRequest(value) {
      if (value.integration_name === "github") {
        this.deleteGithubIntegration(value);
      } else if (value.integration_name === "gitlab") {
        this.deleteGitlabIntegration(value);
      } else if (value.integration_name === "salesforce") {
        this.deleteSalesforceIntegration(value);
      } else if (value.integration_name === "Atlassian") {
        this.deleteAtlassianIntegration(value);
      } else if (value.integration_name === "Atlassian") {
        this.deleteSpecificIntegration(value);
      }
    },

    openEditAtlassianIntegrationModal(value) {
      axios
        .get(
          `${process.env.VUE_APP_API_URL_PREFIX}/v1/integrations/jira/projects`
        )
        .then((response) => {
          console.log("response for atlassian projects list", response);
          if (response.data) {
            this.atlassianProjectList = response.data;
          } else {
            this.atlassianProjectList = {};
          }
        })
        .catch((err) => {
          console.log("atlassion project jira error", err);
        });
      // console.log("atlassian", value);
      this.showEditAtlassianIntegrationModal = true;
      this.atlassianIntegrationEnabled = value.integration_enabled;
    },

    openEditGithubIntegrationModal(value) {
      // Set the values of the modal
      this.showEditGithubIntegrationModal = true;
      this.repo_name = value.github_repo_name;
      this.repo_owner = value.github_repo_owner;
      this.access_token = value.github_access_token;
      this.git_integration_status = value.integration_enabled;
    },

    openEditGitlabIntegrationModal(value) {
      // Set the values of the modal
      this.showEditGitLabIntegrationModal = true;
      this.glab_org_name = value.gitlab_org_name;
      this.glab_project_name = value.gitlab_project_name;
      this.glab_access_token = value.gitlab_access_token;
      this.glab_integration_status = value.integration_enabled;
    },
    openEditSlackIntegrationModal(value) {
      // Set the values of the modal
      this.showEditSlackIntegrationModal = true;
      this.slack_integration_status = value.integration_enabled;
      this.unique_integration_identifier = value.unique_integration_identifier;
    },

    editGithubIntegration(value) {
      // get values from the modal and update the integration
      let repo_name = this.repo_name;
      let repo_owner = this.repo_owner;
      let access_token = this.access_token;
      let git_integration_status = this.integration_status;

      let data = {
        action: "addOrUpdate",
        integration_name: "github",
        integration_details: {
          github_repo_name: repo_name,
          github_repo_owner: repo_owner,
          github_access_token: access_token,
        },
        unique_integration_identifier: repo_name,
        integration_enabled: git_integration_status,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      console.log("Making Axios Request");
      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", data, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: "Github Integration updated successfully.",
            });
            console.log("repopulating integrations table");
            this.populateIntegrationsTable();
            // close modal after adding integration if successful
            this.showEditGithubIntegrationModal = false;
            console.log("editGithubIntegration method called successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          if (error.response.status === 500) {
            swal.fire(
              JSON.stringify(
                "Data Validation Failed: Please make sure that you have entered a valid token"
              )
            );
          } else {
            swal.fire(JSON.stringify("Error Adding Integration"));
          }
        });
    },

    editGitlabIntegration(value) {
      // get values from the modal and update the integration
      let glab_org_name = this.glab_org_name;
      let glab_project_name = this.glab_project_name;
      let glab_access_token = this.glab_access_token;
      let glab_integration_status = this.glab_integration_status;

      let gitlabData = {
        action: "addOrUpdate",
        integration_name: "gitlab",
        integration_details: {
          gitlab_org_name: glab_org_name,
          gitlab_project_name: glab_project_name,
          gitlab_access_token: glab_access_token,
        },
        unique_integration_identifier: glab_project_name,
        integration_enabled: glab_integration_status,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      console.log("Making Axios Request");
      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/integrations/user",
          gitlabData,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: "Gitlab Integration updated successfully.",
            });
            console.log("repopulating integrations table");
            this.populateIntegrationsTable();
            // close modal after
            this.showEditGitLabIntegrationModal = false;
            console.log("editGitlabIntegration method called successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          if (error.response.status === 500) {
            swal.fire(
              JSON.stringify(
                "Data Validation Failed: Please make sure that you have entered a valid token"
              )
            );
          } else {
            swal.fire(JSON.stringify("Error Adding Integration"));
          }
        });
    },

    async editSlackIntegration() {
      const payload = {
        action: "updateStatus",
        integration_name: "slack",
        integration_enabled: this.slack_integration_status,
        unique_integration_identifier: this.unique_integration_identifier,
      };
      try {
        await axios
          .post(
            `${process.env.VUE_APP_API_URL_PREFIX}/integrations/user`,
            payload
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === "success") {
              this.showEditSlackIntegrationModal = false;
              this.$notify({
                type: "success",
                message: `${response.data.message}`,
              });
              console.log("repopulating integrations table");
              this.populateIntegrationsTable();
            }
          });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `${error.response.message}`,
        });
      }
    },

    deleteGithubIntegration(value) {
      // get values from the modal and delete the integration
      let repo_name = this.repo_name;

      let data = {
        action: "delete",
        integration_name: "github",
        unique_integration_identifier: repo_name,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      console.log("Making Axios Request");
      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", data, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: "Github Integration Deleted successfully.",
            });
            console.log("repopulating integrations table");
            this.populateIntegrationsTable();
            // close modal after adding integration if successful
            this.showEditGithubIntegrationModal = false;
            console.log("delete method called successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          swal.fire(JSON.stringify("Error Deleting Integration"));
        });
    },

    deleteGitlabIntegration(value) {
      // get values from the modal and delete the integration
      let gitlab_org_name = this.glab_org_name;
      let gitlab_project_name = this.glab_project_name;

      let data = {
        action: "delete",
        integration_name: "gitlab",
        unique_integration_identifier: gitlab_project_name,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      console.log("Making Axios Request");
      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", data, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: "Gitlab Integration Deleted successfully.",
            });
            console.log("repopulating integrations table");
            this.populateIntegrationsTable();
            // close modal after adding integration if successful
            this.showEditGitLabIntegrationModal = false;
            console.log("delete method called successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          swal.fire(JSON.stringify("Error Deleting Integration"));
        });
    },

    deleteSalesforceIntegration(value) {
      // get values from the modal and delete the integration
      let data = {
        action: "delete",
        integration_name: "salesforce",
        unique_integration_identifier: value.unique_integration_identifier,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", data, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: "Salesforce Integration Deleted successfully.",
            });
            console.log("repopulating integrations table");
            this.populateIntegrationsTable();
            // close modal after adding integration if successful
            this.showEditSalesforcesIntegrationModal = false;
            console.log("delete method called successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          swal.fire(JSON.stringify("Error Deleting Integration"));
        });
    },
    deleteAtlassianIntegration(value) {
      // get values from the modal and delete the integration
      let data = {
        action: "delete",
        integration_name: "atlassian",
        unique_integration_identifier: value.unique_integration_identifier,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", data, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: "atlassian Integration Deleted successfully.",
            });
            console.log("repopulating integrations table");
            this.populateIntegrationsTable();
            // close modal after adding integration if successful
            this.showEditSalesforcesIntegrationModal = false;
            console.log("delete method called successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          swal.fire(JSON.stringify("Error Deleting Integration"));
        });
    },
    deleteSpecificIntegration(value) {
      // get values from the modal and delete the integration
      let data = {
        action: "delete",
        integration_name: value.integration_name,
        unique_integration_identifier: value.unique_integration_identifier,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", data, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: "atlassian Integration Deleted successfully.",
            });
            console.log("repopulating integrations table");
            this.populateIntegrationsTable();
            // close modal after adding integration if successful
            this.showEditSalesforcesIntegrationModal = false;
            console.log("delete method called successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Debugging values, not sending anything..");
          swal.fire(JSON.stringify("Error Deleting Integration"));
        });
    },

    closeGithubIntegrationModal() {
      this.showGithubIntegrationModal = false;
    },
    closeEditGithubIntegrationModal() {
      this.showEditGithubIntegrationModal = false;
    },

    closeGitlabIntegrationModal() {
      this.showGitlabIntegrationModal = false;
    },

    closeEditGitlabIntegrationModal() {
      this.showEditGitlabIntegrationModal = false;
    },

    closeJenkinsIntegrationModal() {
      this.showJenkinsIntegrationModal = false;
    },

    closeCircleCIIntegrationModal() {
      this.showCircleCIIntegrationModal = false;
    },

    closeEditSlackIntegrationModal() {
      this.showEditSlackIntegrationModal = false;
    },

    DescriptionFormatter(row, value) {
      let integration_name = row.integration_name;
      integration_name =
        integration_name.charAt(0).toUpperCase() + integration_name.slice(1);
      return integration_name + " integration for " + value;
    },
  },

  async created() {
    let a = this.$route.query.success;
    let b = this.$route.query.integration_type;
    if (a === "true" && b === "atlassian") {
      await this.showModalForAtlassian();
    }

    await Promise.all([
      this.getScriptFiles(),
      this.populateIntegrationsTable(),
      this.populateWebhookTable(),
    ]);
  },
  computed: {
    canAddSalesforce() {
      const emptyValue = this.editedSalesforceIntegration.username.length > 0
        && this.editedSalesforceIntegration.password.length > 0
        && this.editedSalesforceIntegration.token.length > 0
      return emptyValue;
    },
  }
};
</script>

<style lang="scss">
$pColor: #525f7f;
$color: #5e72e4;

.collapse--title,
.header-icon {
  font-size: 1rem;
  font-weight: 600;
  color: rgba(75, 75, 113, 1);
}

.webhook--modal {
  .modal {
    overflow-y: auto;
    padding: 2vh 0 !important;
  }

  .modal-dialog {
    width: 800px !important;

    .small-spaces {
      .el-form-item__label {
        font-weight: 700;
        font-size: 0.9rem;
      }

      .el-form-item {
        margin-bottom: 10px;

        &.no-space {
          margin: 0;

          .el-form-item__content {
            line-height: 0;

            label {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.copy-code-btn {
  position: relative;
  top: 0.75rem;
  // border: 1px solid red;
  margin: 0 !important;
}

.c-icon {
  position: relative;
  left: 95%;
}

.redirect-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.redirect-count {
  font-size: 4rem;
}

.integration-script {
  width: 100%;
  background-color: #464547;
  // padding: 0 !important;
  color: #ffffff;
  // overflow-y: scroll;
  word-break: break-all;
}

.ci-circle-integration-script pre {
  color: #ffffff;
  font-size: 12px;
  margin-top: -2rem !important;
  padding: 0 7px;
  height: 45rem;
}

.button-menu-integration {
  display: flex !important;
  i{
    margin-left: 15% !important;
  }
}

.jenkins-integration-script pre {
  color: #ffffff;
  font-size: 12px;
  margin-top: -1.8rem !important;

  padding: 0 7px;
  height: auto;
}

.travis-ci-integration-script pre {
  color: #ffffff;
  font-size: 12px;
  margin-top: -3rem !important;
  padding: 0 7px;
  height: auto;
  // border: 1px solid red;
}

// .travis-ci-integration-script pre code {
//   padding: 0 !important;
//   margin: 0 !important;
//   border: 1px solid red;
// }

.integration-card {
  cursor: pointer;
}

.integration-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.el-select {
  height: auto !important;
  width: 100%;
}

.integ-table .el-table__body {
  background-color: #ffffff !important;
}

.list-of-jira-projects .el-select .el-input .el-input__inner {
  height: calc(1.5em + 1.25rem + 2px) !important;
}

.container-fluid > .mid--page > .mp--l > .btn-group > button > span {
  @media (max-width: 600px) {
    font-size: 0.6rem !important;
  }
}

.container-fluid > .mid--page > .mp--l > .btn-group > button {
  @media (max-width: 600px) {
    font-size: 0.6rem !important;
  }
}

.integration--content {
  display: flex;
  flex-wrap: wrap;

  //border: 1px solid black;
  @media (max-width: 600px) {
    justify-content: space-evenly;
    padding: 0rem 0.5rem 0rem;
  }

  @media (max-width: 600px) {
    justify-content: space-evenly;
    padding: 0rem 0.5rem 0rem;
  }


  .elt--integration {
    border: solid 2px;
    width: 24%;
    margin-right: 1%;
    background: #ffffff;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    @media (max-width: 600px) {
      width: 45%;
      border: solid 1.5px;
    }

    @media (max-width: 600px) {
      width: 45%;

      border: solid 1.5px;
    }

    .ei--img {
      padding: 0.5rem 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ei--name {
      text-align: center;
      font-weight: 700;
      font-size: 1.1rem;

      @media (max-width: 750px) {
        font-size: 0.9rem;
      }
    }

    &:hover {
      filter: grayscale(0.8);
      transform: scale(0.98);
    }
  }

  .small_btn {
    background: rgba($color, 0.1);
    border: solid 0.1px $color;

    i,
    span {
      font-size: 0.75rem !important;
      font-weight: 600;
      color: $color;
    }
  }
}

.imput--date--time {
  border: solid 1px black !important;
  width: 100% !important;
  height: 5.9vh !important;
}

.modal-body {
  height: auto;
}
</style>
