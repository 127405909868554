<template>
  <div>
    <data-list :tHeader="tHeader" :loading="loading" :items="[getOverview()]">
      <template #content>
        <div
          v-for="elt in [getOverview()]"
          :key="elt.id"
          class="tblb--row mb-2"
        >
          <div class="tblb--inner">
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <el-alert
                class="no--close"
                :title="elt.status"
                :type="
                  elt.status === 'passed'
                    ? 'success'
                    : elt.status === 'failed'
                    ? 'error'
                    : 'danger'
                "
                show-icon
              ></el-alert>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span class="test-time-cont">
                <i class="far fa-clock mr-1"></i>
                {{ secondsToRealTime(elt) }}
              </span>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span>{{ elt.trainedSelectorCount }}</span>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span>{{ failedPaths(elt) }}</span>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span>{{ elt.healedSelectorCount }}</span>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span class="test-time-cont" style="max-width: 100%">
                <i class="far fa-clock mr-1"></i>
                {{ convertRunTimeToTimeSaveMS(elt) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </data-list>

    <card>
      <div class="row my-5">
        <!--Pie Chart -->
        <div class="col-lg-6">
          <v-chart
            class="chart"
            :autoresize="true"
            :loading="chartLoading"
            :option="overviewPieChart"
          />
        </div>

        <!--Bar Chart -->
        <div class="col-lg-6">
          <div class="video-first text-center mt-1" v-loading="loadingVideo">
            <el-collapse v-model="activeNames">
              <el-collapse-item v-if="canShowVid" title="Video" name="1">
                <div class="video-player">
                  <video-player
                    class="vjs-custom-skin"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    @ready="playerReadied"
                  ></video-player>
                </div>
              </el-collapse-item>
              <el-collapse-item
                v-else
                title="Video is not available for the test run"
                name="2"
              >
                <div class="video-player"></div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-12">
          <h2>{{ $t('overview.test_run_options') }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="test-run-left">
            <div class="test-run-row">
              <p>{{ $t('overview.fast_heal_mode') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.use_fast_heal === false &&
                  "disabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.interceptor_failure_handling') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.handle_failure
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.collect_page_interaction') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.use_image_comparison
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.test_run_origin') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.test_run_origin
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.element_neighbor_max') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.neighbor_maxdist
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.test_step_wait') }}</p>
              &nbsp;:&nbsp;x
              <span>{{
                items.runOptions && items.runOptions.runner_step_wait
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="test-run-right">
            <div class="test-run-row">
              <p>{{ $t('overview.interceptor_screenshots') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.take_screenshots === true &&
                  "enabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.browser_window_size') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.runner_resolution
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.ignore_disabled_elements') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.filter_disabled_elements === true &&
                  "enabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.ignore_invisible_elements') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.filter_invisible_elements === true &&
                  "enabled"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";

import "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import moment from "moment";
import axios from "axios";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import DataList from "@/components/Custom/DataList.vue";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "Overview",
  props: {
    items: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
      description: "Loading state",
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VChart,
    DataList,
    videoPlayer,
  },
  data() {
    return {
      videoError: "",
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      activeNames: [],
      chartLoading: true,
      overviewPieChart: {
        title: {
          text: this.$t('overview.time_saved'),
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [this.$t('overview.enhancements'), "Auto Heal"],
        },
        series: [
          {
            name: "Enhancement vs Heal",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              {
                value: 0,
                name: "Enhancements",
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 0,
                name: "Auto Heal",
                itemStyle: {
                  color: "#4ac3db",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      },
      overviewBarChart: {
        title: {
          text: "Before & After Wring",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ",
        },
        xAxis: {
          type: "category",
          data: ["#Failures", "#Heals"],
        },
        yAxis: {},
        series: [
          {
            type: "bar",
            name: "Before Wring",
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 1,
                itemStyle: {
                  color: "#FCA311",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
          {
            type: "bar",
            name: "After Wring",
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "#4ac3db",
                },
              },
              {
                value: 1,
                itemStyle: {
                  color: "#4ac3db",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      },
      colorPalette: "red",
      playerOptions: {
        height: "300",
        width: "500",
        autoplay: false,
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
      },
      tHeader: [
        { label: this.$t('overview.test_status'), sortable: false },
        { label: this.$t('overview.run_time'), sortable: false },
        { label: this.$t('overview.steps'), sortable: false },
        { label: this.$t('overview.failures'), sortable: false },
        { label: this.$t('overview.auto_healed') , sortable: false },
        { label: this.$t('overview.time_saved') , sortable: false },
      ],
      canShowVid: false,
      loadingVideo: false,
    };
  },
  methods: {
    playerReadied(player) {
      player.currentTime(5);
    },
    async getVideo() {
      const aioToken = localStorage.getItem("vue-authenticate.access_token");
      const requestId = this.$route.params.id;
      const videoUrl = `${this.baseUrl}/video-presigned/v1?walServerRequestid=${requestId}&walToken=${aioToken}`;

      try {
        this.loadingVideo = true;
        const res = await axios.get(videoUrl)

        if(res.status === 200) {
          this.activeNames = ["1"];
          this.canShowVid = true;
          this.playerOptions.sources[0].src = res.data.response;
        } else {
          console.log('an error has occured')
        }
      } catch (e) {
        console.log("Catch2: ", e);
      } finally {
        this.loadingVideo = false;
      }
    },
    forTransparentTr() {
      return {
        background: "transparent !important",
        color: "#ffffff",
        fontWeight: "bold",
      };
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      var dis_sec = Math.ceil(secs % 60);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      // return `${this.pad(hours)}h ${this.pad(minutes)}m ${this.pad(secs)}s`;
      return result;
      // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
    },
    convertRunTimeToTimeSaveMS(a) {
      let actualRunTime = Number(a.finishedAt - a.startedAt);
      let healedCount = Number(a.healedSelectorCount);
      let suggestedCount = Number(a.suggestedSelectorCount);
      let trainCount = Number(a.trainedSelectorCount);
      let x = suggestedCount + trainCount;
      const totalHealSecs = healedCount * 3600;
      const totalSuggestedSecs = x * 300;
      let z = actualRunTime + totalHealSecs + totalSuggestedSecs;
      // const time = moment(z * 1000).format("HH mm");
      const time = this.hhmmss(z);
      return time;
    },
    secondsToRealTime(a) {
      let sec = Number(a.finishedAt - a.startedAt);
      return this.hhmmss(sec);
    },
    failedPaths(a) {
      let healedCount = Number(a.healedSelectorCount);
      let xpathCount = Number(a.xpathCount);
      let trainCount = Number(a.trainedSelectorCount);
      var total = xpathCount - trainCount - healedCount;
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    getOverview() {
      if (this.items) {
        let resData = this.item;

        var trained = this.items.trainedSelectorCount;
        if (trained > this.items.xpathCount) {
          trained = this.items.xpathCount;
        }

        this.overviewBarChart.series[0].data[0].value = this.items.failedPaths;
        this.overviewBarChart.series[1].data[0].value =
          this.items.failedPaths - this.items.healedCount;
        this.overviewBarChart.series[0].data[1].value = 0;
        this.overviewBarChart.series[1].data[1].value = trained;

        this.overviewPieChart.series[0].data[0].value = trained;
        this.overviewPieChart.series[0].data[1].value =
          this.items.xpathCount - this.items.trainedSelectorCount;
        if (this.items.trainedSelectorCount || this.items.xpathCount) {
          this.chartLoading = false;
        }
        return this.items;
      } else return [];
    },
    formatColDate(row, col, value, index) {
      const time = moment(value * 1000).format("HH mm");
      if (time != "Invalid date") {
        return time;
      }
    },
    proccessRunOptions(obj) {
      let runOptions = obj;
      return Object.keys(runOptions).reduce((result, key) => {
        result[key] = {
          name: obj[key],
          type: "foo",
        };
        return result;
      }, {});
    },
  },
  async created() {
    await this.getVideo();
  },
  mounted() {},
};
</script>

<style lang="css" src="@/assets/css/video-player.css" />
<style lang="scss">
.b {
  border: 1px solid red;
}
.el-table__row {
  cursor: pointer;
}
.button-aligned {
  margin-bottom: 0;
}
.click-here {
  cursor: pointer;
}
.click-here a {
  color: black;
  text-decoration: none;
}

.test-run-left,
.test-run-right {
  border: 2px solid gray;
  height: 100%;
  padding: 5px;
}

.test-run-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}

.test-run-row p {
  margin: 0;
  color: black;
  font-size: 13px;
  font-weight: 600;
}

.ovw-table thead th .cell {
  justify-content: center;
}

.table-header-gradient {
  background-image: linear-gradient(to right, #f56646, #fbb140) !important;
}

@media screen and (max-width: 815px) {
  .modal-dialog {
    max-width: 80% !important;
    width: 80% !important;
  }
  .modal-dialog img {
    width: 100%;
  }
}

.vjs-error-display {
  position: relative;

  &::after {
    content: "Wrong video url";
    position: absolute;
    top: 0; right: 0;
    width: 100%;
    height: 100%;
    background: #f5454e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-style: italic;
    font-weight: 600;
  }

  .vjs-modal-dialog-content {
    display: none;
  }
}
</style>
