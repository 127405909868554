<template>
  <div class="wrapper">
    <onboarding ref="onboarding" :steps="allStep" :showonbarding="showOnboarding" />
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <div class="is-content-nav">
          <div class="is-item-nav">
            <el-button @click="showLeftBar()">
                <i class="el-icon-s-fold" v-if="toShowLeftBar === false"></i>
                <i class="el-icon-s-unfold" v-if="toShowLeftBar === true"></i>
            </el-button>
            <div class="is-item-nav mr-5 ml-3" style="flex-direction: column;">
              <!-- <img src="/img/brand/wring.png" width="40" /> -->
              <a class="navbar-brand" href="#" >
                <!-- <img :src="logo" class="navbar-brand-img" alt="Sidebar logo" /> -->
                <span class="nav-nb--title" >WRING</span>
              </a>
            </div>
            <sidebar-item id="DashboardDashboard" style="margin-left: 2rem;" :link="{
              name: $t('nav.dashboard'),
              icon: 'ni ni-chart-pie-35 text-primary',
              path: '/dashboard',
            }" />
    
            <sidebar-item id="DashboardLiveEvent" :link="{
              name: $t('nav.apps'),
              icon: 'ni ni-app text-primary',
              path: '/details/project-explorer/list-projects',
            }" />
            <sidebar-item id="DashboardRunList" :link="{
              name: $t('nav.testruns'),
              icon: 'ni ni-bullet-list-67 text-primary',
              path: '/details/test-explorer/list-tests',
            }" />
            <sidebar-item id="DashboardVirtualizeApi" :link="{
              name: $t('nav.virtualizeApi'),
              icon: 'ni ni-planet text-primary',
              path: '/details/project-explorer/virtualize-api-projects',
            }" />
            <sidebar-item id="DashboardIntergrations" :link="{
              name: $t('nav.integrations'),
              icon: 'ni ni-sound-wave text-primary',
              path: '/details/integrations',
            }" />
          </div>
  
          <ul class="navbar-nav align-items-center mr-5">
            <base-dropdown menu-on-right class="nav-item no--hover" tag="li" title-tag="a" title-classes="nav-link pr-0">
              <a href="#" class="flex" @click.prevent slot="title-container" >
                <div class="media align-items-center">
                  <span class="avatar-image" id="topbarPofile">
                    <div class="if-img" v-if="user.profile_pic">
                      <img :src="user.profile_pic" />
                    </div>
                    <div class="if-img-noavatar" v-else>
                      {{ getAvatarInitials() }}
                    </div>
                  </span>
                </div>
              </a>
              <template>
                <div class="dropdown-header noti-title">
                  <h6 class="text-overflow m-0">
                    {{ $t("dashboard_navbar.welcome") }} {{ avatarText }} !
                  </h6>
                </div>
                <a :href="logOut" class="elt--dropdown" >
                  <i class="ni ni-single-02"></i>
                  <span>{{ $t("dashboard_navbar.my_profile") }}</span>
                </a>
                <a class="elt--dropdown" href="/details/subscription">
                  <i class="ni ni-briefcase-24 "></i>
                  <span>{{ $t("dashboard_navbar.subscription") }}</span>
                </a>
                <a class="elt--dropdown" href="https://aichemydev.github.io/Wring_docs/docs/intro">
                  <i class="fas fa-question-circle"></i>
                  <span>{{ $t("dashboard_navbar.documentation") }}</span>
                </a>
                <a class="elt--dropdown" href="https://github.com/aichemydev/wring-support/issues ">
                  <i class="fas  fa-flag"></i>
                  <span>{{ $t("dashboard_navbar.report") }}</span>
                </a>
                <a class="elt--dropdown" @click.stop="isClick = true" >
                  <li id="topbarLangue">
                    <el-dropdown trigger="click" class="el-dropdown-link_2" @command="switchLg">
                      <span type="primary" class="el-dropdown-link--inner">
                        {{ $t("dashboard_navbar.language")
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="myDropdown__menu__2">
                        <el-dropdown-item command="en" class="myDropdown__item__2">{{ $t("dashboard_navbar.english")
                        }}</el-dropdown-item>
                        <el-dropdown-item command="es" class="myDropdown__item__2">{{ $t("dashboard_navbar.spanish")
                        }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </a>
                <div class="dropdown-divider"></div>
                <a class="elt--dropdown red" @click.prevent="logout()" to="">
                  <i class="ni ni-user-run"></i>
                  <span>{{ $t("dashboard_navbar.logout") }}</span>
                </a>
              </template>
            </base-dropdown>
          </ul>
        </div>
        <div class="sidebar--botom">
          <!-- <el-button id="DashboardHelppage" type="primary" icon="el-icon-warning-outline" @click="openonbrading()">
            Help
          </el-button> -->
        </div>
      </template>
    </side-bar>
    <div class="main-content" >
      <!-- <dashboard-navbar v-if="this.$route.path === isDashboard " :type="$route.meta.navbarType"></dashboard-navbar> -->

      <div @click="$sidebar.displaySidebar(false)" >
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import onboarding from "../../components/onboarding.vue";
import axios from "axios";
import Vuex from "vuex";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    onboarding,
  },
  data() {
    return {
      roles: [],
      year: new Date().getFullYear(),
      overflowHidden: "height: 86vh; overflow: hidden;",
      overflowAuto: "height: 86vh; overflow: auto;",
      logo: {
        type: String,
        default: "/favicon.png",
        description: "Sidebar app logo",
      },
      user: [],
      logOut:"https://auth.wring.dev/realms/devwringdev/account/#/",
      isClick: false,
      showPageview: true,
      stepsOnboarding: [
        {
          titel: "Welcome",
          desc: "Wring is a test automation solution that uses deep learning to auto heal your tests. With Wring's proprietary machine learning algorithms, we help you keep your tests healthy and up-to-date in order to support new features quickly and efficiently.",
          position: "right",
          img: "/favicon.png",
          element: null,
        },
        // {
        // titel: "Dashboard",
        // desc: "The Dashboard provides a quick overview of your tests, including key metrics like test cases and assertions, run time and execution time saved.",
        // position: "right",
        // element: "DashboardDashboard",
        // },
        {
          titel: "Test Runs",
          desc: "The test explorer allows you to see all the tests you have added to Wring. ",
          position: "right",
          element: "DashboardRunList",
        },
        {
          titel: "Live event",
          desc: "Live Events or as we call it - 'Pageviews' is a tool that allows you to record user actions on a website and generate a test case from these actions. ",
          position: "right",
          element: "DashboardLiveEvent",
        },
        {
          titel: "Intergrations",
          desc: "The integrations page shows available integrations as cards located at the top of the integrations page.",
          position: "right",
          element: "DashboardIntergrations",
        },
        // {
        // titel: "Installation",
        // desc: "You'll find quick instructions for installing Wring Interceptor for Selenium and Cypress in the languages that we support.",
        // position: "right",
        // element: "DashboardInstallation",
        // },
        {
          titel: "User profile",
          desc: "You'll be able to check your profile and update your data there.",
          position: "right",
          element: "DashboardUserProfile",
        },
        {
          titel: "Subscription",
          desc: "To check for our Subscription plan, please visit Subscription tab. ",
          position: "top",
          element: "DashboardSubcription",
        },
        {
          titel: "Report Problems",
          desc: "If you find any bug/problem with our platform, please write an issue for us. ",
          position: "top",
          element: "star-button",
        },
        {
          titel: "Documentation",
          desc: "To learn more on how to use our platform, please check our documentation site. ",
          position: "top",
          element: "docs-button",
        },
        {
          titel: "Help",
          desc: "As a new user, we thought it would be good to walk you through our platform. ",
          position: "top",
          element: "DashboardHelppage",
        },
        {
          titel: "Language",
          desc: "Our platform supports two languages, more to come. ",
          position: "bottom",
          element: "topbarLangue",
        },
        {
          titel: "Profile",
          desc: "You can view your profile with just a click away. ",
          position: "bottom",
          element: "topbarPofile",
        },
      ],
      listItemStep: [
        {
          titel: "Table List",
          desc: "You will be able to view your tests listed there.",
          position: "right",
          element: "listItemPageTableListe",
        },
        {
          titel: "Table Card",
          desc: "You will be able to have a card view of your tests",
          position: "right",
          element: "listItemPageTableCard",
        },
        {
          titel: "Table Thumb",
          desc: "You will be able to have your tests listed with screenshots.",
          position: "right",
          element: "listItemPageTableThumb",
        },
        {
          titel: "Filter",
          desc: "Filter allows you to search easily across tests.",
          position: "right",
          element: "listItemPageTableFilter",
        },
        {
          titel: "Add test",
          desc: "You can also add tests via your selenium/Cypress code, or the Wring Observer.",
          position: "right",
          element: "listItemPageTableAddtest",
        },
        {
          titel: "Number of elements ",
          desc: "You can choose how many tests you want to see/check.",
          position: "bottom",
          element: "listItemPageTableNumberElement",
        },
        {
          titel: "Edit test",
          desc: "You can change the value of a field after you create it!",
          position: "bottom",
          element: "listItemPageTableEditTestBtn",
        },
        {
          titel: "Rerun test",
          desc: "Rerun allows you to run the same test again.",
          position: "bottom",
          element: "listItemPageTableRerunTestBtn",
        },
        {
          titel: "Delete test",
          desc: "You can delete permanently your test.",
          position: "bottom",
          element: "listItemPageTableDeleteTestBtn",
        },
      ],
      listProjectStep: [
        {
          titel: "PageView Project",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "right",
          element: "listProjecPageViewtProject",
        },
        {
          titel: "PageView Event",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "right",
          element: "listProjectPageViewEvent",
        },
        {
          titel: "Filter",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "right",
          element: "listProjectFilter",
        },
        {
          titel: "Add Project ",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "listProjectAddProject",
        },
        {
          titel: "Edit test",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "listProjectEdit",
        },
        {
          titel: "Project Detail",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "listProjectDetail",
        },
        {
          titel: "Delete Project",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "listProjectDeleteTestBtn",
        },
      ],
      integrationStep: [
        {
          titel: "CI integrations",
          desc: "The CI Integrations displays all active integrations per user. Different actions are available for different integrations",
          position: "right",
          element: "intergrationsCi",
        },
        {
          titel: "Triggers",
          desc: "The Wring platform can execute test runs based on a trigger from an external service. ",
          position: "right",
          element: "intergrationsTriggers",
        },
        {
          titel: "Scheduled Runs",
          desc: "Wring is the easiest way to schedule your test runs for every day, every week, or any other days you want.",
          position: "right",
          element: "intergrationsScheduled",
        },
        {
          titel: " Github ",
          desc: "The Wring integration with Github enables fast and easy reporting to speed up issue creation. When a test that has been run on the Wring platform, fails, you can be immediately notified in your teams Github.",
          position: "bottom",
          element: "intergrationsGithub",
        },
        {
          titel: " Atlassian - JIRA ",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "intergrationsAtlassianJIRA",
        },
        {
          titel: " Gitlab ",
          desc: "The Wring Gitlab integration can be used to keep track of your test runs in Gitlab. ",
          position: "bottom",
          element: "intergrationsGitlab",
        },
        {
          titel: " Slack ",
          desc: "The Wring Reporter Slack app keeps your team up to date with the status of your tests to a slack channel.",
          position: "bottom",
          element: "intergrationsSlack",
        },
        {
          titel: " Jenkins ",
          desc: "Automate your tests with Jenkins while taking advantage of the advanced healing and intelligence offered by Wring by integrating Jenkins with the Wring Interceptor.",
          position: "top",
          element: "intergrationsJenkins",
        },
        {
          titel: " Circle CI ",
          desc: "Use the Wring Interceptor with your CircleCI builds! Getting Started with CircleCI is easy.",
          position: "top",
          element: "intergrationsCircleCI",
        },
        {
          titel: " Travis CI ",
          desc: "Use the Wring Interceptor with Travis CI in a couple simple steps.",
          position: "top",
          element: "intergrationsTravisCI",
        },
        {
          titel: "Salesforce",
          desc: "Add your Salesforce data integration from your own account.",
          position: "top",
          element: "intergrationsSalesforce",
        },
        {
          titel: "Edit",
          desc: "You can change the value of a field after you create it!",
          position: "bottom",
          element: "intergrationsEdit",
        },
        {
          titel: "Delete ",
          desc: "You can delete permanently your tests.",
          position: "bottom",
          element: "intergrationsDeleteTestBtn",
        },
      ],
      installation: [
        {
          titel: "Selenium",
          desc: "The Selenium Tab allows you to use the selenium packages Wring supports. ",
          position: "right",
          element: "installationSelenium",
        },
        {
          titel: "Cypress",
          desc: "The Cypress Tab allows you to use the cypress packages Wring supports. ",
          position: "right",
          element: "installationCypress",
        },
        {
          titel: "Observer",
          desc: "You will be able to  download Wring Smart Observer here. ",
          position: "right",
          element: "installationObserver",
        },
        {
          titel: "Windows",
          desc: "Wring supports Selenium/Cypress and allows you to easily run them in Windows.",
          position: "right",
          element: "installationWindows",
        },
        {
          titel: "Linux MacOs",
          desc: "Wring supports Selenium/Cypress and allows you to easily run them in Linux/MacOS",
          position: "right",
          element: "installationLinuxMacOs",
        },
        {
          titel: "Java Interceptor Packages ",
          desc: "Here, you can get started with Java Interceptor Package. ",
          position: "bottom",
          element: "installationJava",
        },
        {
          titel: "Python Interceptor Packages ",
          desc: "Here, you can get started with Python Interceptor Package. ",
          position: "bottom",
          element: "installationPython",
        },
        {
          titel: "Javascript Intercetor Packages ",
          desc: "Here, you can get started with Javascript Interceptor Package. ",
          position: "top",
          element: "installationJavascript",
        },
      ],
      listUserProfil: [
        {
          titel: "Edit User info",
          desc: "Change your informations(name/image/email)",
          position: "right",
          element: "userProfileEdit",
        },
        {
          titel: "Edit user password",
          desc: "Change/Update your password!.",
          position: "left",
          element: "userProfileEditPassword",
        },
      ],
      showOnboarding: 'false',
      stepStat: 0,
      isDashboard: "/dashboard",
      toShowLeftBar: false,

    };
  },
  async created() {
    process.env.VUE_APP_USER_PROFILE_URL  ? this.logOut = process.env.VUE_APP_USER_PROFILE_URL : this.logOut = "https://auth.wring.dev/realms/devwringdev/account/"
    await this.getData();
    this.vallidePageview()
    this.activePath = this.$route.name;
  },
  watch: {
    $route(from, to) {
      this.activePath = from.name;
    },
  },
  methods: {
    setFooter(){
      let rContent = document.getElementsByClassName("right-content")[0]
      console.log("yeeeeeee",rContent)
      let footer =  document.createElement("footer")
      footer.classList.add("footer")
      footer.classList.add("mt-2")
      footer.classList.add("px-4")
      footer.innerHTML = `<div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6">
          <div class="copyright text-center text-lg-right text-muted">
            © ${ this.year }
          </div>
        </div>
      </div>`
      rContent.appendChild(footer)

    },
    showLeftBar() {
      const leftBar = document.querySelector(".left-side-bar")
      console.log(leftBar)
      if(this.toShowLeftBar === false && leftBar !== null) { 
        leftBar.style.display = "none"
        this.toShowLeftBar = true
      } else if(leftBar !== null){
        leftBar.style.display = "flex"
        this.toShowLeftBar = false 
      }
    },
    initOnboarding() {
      if (!localStorage.getItem('wringHelpTooltips')) {
        localStorage.setItem('wringHelpTooltips', "true")
      }
      this.showOnboarding = localStorage.getItem('wringHelpTooltips')
      if (this.routeName === "List Items") {
        this.stepsOnboarding = this.stepsOnboarding.concat(this.listProjectStep)
      }

    },
    switchLg(lg) {
      this.$i18n.locale = lg;
      localStorage.setItem("aichemy--current_language", lg);
    },
    getAvatarInitials() {
      if (this.avatarText) {
        const avatarArray = this.avatarText.split(" ");

        if (avatarArray.length > 1)
          return avatarArray.map((word) => word[0]).join("");
        return this.avatarText.substring(0, 2);
      }
    },
    goToProfile() {
      this.$router.push({
        name: "User Profile",
      });
    },
    async getData() {
      try {
        axios
          .get(process.env.VUE_APP_API_URL_PREFIX + `/auth/v1/users/me`)
          .then((response) => {
            if (
              response &&
              response.message === "Request failed with status code 402"
            ) {
              console.log("navbar 402");
              localStorage.removeItem("vue-authenticate.access_token");
              localStorage.removeItem("testgold.aioToken");
              localStorage.setItem(
                "login--message",
                "User trial period has ended. Please contact support@testgold.dev."
              );
              window.location.reload();
            }

            if (response.data) {
              this.user = response.data.response
              this.vallidePageview()
              this.avatarText = this.user.full_name
                ? this.user.full_name
                : "N/A";
            }
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `An error has occured ${e.response.message}`,
        });
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Oops, Cannot logout ! If problem persist, please contact support",
        });
      }
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    async vallidePageview() {
      console.log(this.user.tier_limits.userActionsAllowedServices.includes("pageview-events"))
      if (this.user.tier_limits.userActionsAllowedServices.includes("pageview-events")) {
        this.showPageview = true
      } else {
        this.showPageview = false
      }
    },
    // async getData() {
    //   const res = await axios.get(process.env.VUE_APP_API_URL_PREFIX + `/auth/v1/users/me`)
    //   if (res.data.status === "success") {
    //     this.user = res.data.response
    //     this.vallidePageview()
    //   } else {
    //     window.location.reload();
    //   }
    // },
    openonbrading() {
      this.showOnboarding = "true"
      this.$refs.onboarding.initializeshowonbarding()

    }
  },
  mounted() {
    this.initScrollbar();
    setTimeout(() => {
      this.initOnboarding()
      // this.setFooter()
    }, 2000);
  },
  computed: {
    allStep() {
      if (this.$route.name === "List Items") {
        return this.stepsOnboarding.concat(this.listItemStep)
      } else if (this.$route.name === "List Poject") {
        return this.stepsOnboarding.concat(this.listProjectStep)
      } else if (this.$route.name === "Integrations") {
        return this.stepsOnboarding.concat(this.integrationStep)
      } else if (this.$route.name === "Installation") {
        return this.stepsOnboarding.concat(this.installation)
      } else if (this.$route.name === "User Profile") {
        return this.stepsOnboarding.concat(this.listUserProfil)
      } else {
        return this.stepsOnboarding;
      }
    },
  },
};
</script>
<style lang="scss">
body.g-sidenav-hidden {

  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}

body.g-sidenav-show {

  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}

html,
body {
  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-track {
    background: #5e72e499 !important;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #5e72e4 !important;
  }
}

.sidebar--botom {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  // flex-direction: column;
}

.git--btn {
  padding: 0 !important;
  margin-left: 51.5vw !important;
  margin-right: 0 !important;
  a {
    margin: 0 !important;
    padding: 1rem !important;
    // border-radius: 0.4rem;
    // box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    // background-color: #212529;
    border: solid 0px !important;
    border-radius: 0 !important;
    color: #000000 !important;

    i {
      font-size: 1.1rem !important;
    }

    &:hover {

      i,
      .nav-link-text {
        color: initial !important;
      }
    }
  }
}

.doc--btn {
  padding: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  a {
    margin: 0 !important;
    padding: 1rem !important;
    border: solid 0px !important;
    border-radius: 0 !important;
    // box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

    i {
      font-size: 1.1rem !important;
    }

    &:hover {

      i,
      .nav-link-text {
        color: initial !important;
      }
    }
  }
}

.disabled-link {
  pointer-events: none;
  position: relative;
}

.conming--soon--menu {
  // display: flex;
  align-items: center;

  a {
    padding-right: 0.4em !important;
    margin-bottom: -15px !important;
  }
}

.disabled-link::before {
  content: 'Upgrade to Premium';
  margin-left: 55px;
  border: 1px solid red !important;
  padding: .2em;
  background: #f9ebeb !important;
  color: red !important;
  border-radius: 2px;
  font-size: .5rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  right: 16px;
  bottom: 40%;
}
.el-dropdown-link {
  cursor: pointer;
  color: white;
  justify-content: center;
  transform: translateY(-1rem);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-weight: bold;
  top: 16px;
  font-size: 13px;

  &:hover {
    border: 1px solid white;
    border-radius: 0.5rem;
  }
}

.el-dropdown:hover {
  color:white;
} 
.el-dropdown-link_2 {
  cursor: pointer;
  color: #6e6e6d;
  justify-content: center;
  transform: translateY(-1rem);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-weight: bold;
  top: 16px;
  font-size: 13px;

  &:hover {
    border: 1px solid #6e6e6d;
    border-radius: 0.5rem;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
  top: 2px;
}

.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none !important;
}

.myDropdown__menu {
  border: 2px solid white;
  background-color: orange;
  width: 98px;
  transform: translateY(-5px) translateX(0px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1rem;
  padding: 0;
}

.myDropdown__item {
  color: #616161;
  border: 3px solid #cfcfcf;
  background-color: #f5f5f5;
  width: 100%;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom:  1px solid #cfcfcf;
  }
}

.myDropdown__menu__2 {
  border: 3px solid #cfcfcf;
  background-color: #f5f5f5;
  width: 98px;
  transform: translateY(-5px) translateX(0px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1rem;
  padding: 0;
}

.myDropdown__item__2 {
  color: #616161;
  background-color: #ebeced;
  width: 100%;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #cfcfcf;
  }
}

.avatar-image i {
  color: #5e72e4;
}

.if-img {
  width: 50px;
  height: 50px;
  border-radius: 0.3rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.if-img-noavatar {
  width: 50px;
  height: 40px;
  border-radius: 0.3rem;
  background-color: #5e72e4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.no--hover {
  .nav-link {
    transform: translate3d(0, 0, 0);
    &:hover {
      transform: translate3d(0, 0, 0);
    }
  }
}

.dropdown-menu.show {
  width: 20% !important;
  padding-bottom: 0 !important;
  overflow: hidden;
}

.dropdown-divider {
  margin: 0;
}

.elt--dropdown {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  width: 100%;
  padding: .75rem;
  color: initial;

  &:hover {
    background-color: #5e72e4;
    color: #ffffff;
    #topbarLangue{
      .el-dropdown{
        color: white;
      }
    }
  }

  &.red {
    color: #f5365c;

    &:hover {
      background-color: #f5365c;
      color: #ffffff;
    }
  }
}
.is-content-nav{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.is-item-nav{
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-link{
  margin-bottom: 0 !important;
}
.navbar-brand{
  height: auto !important;
  padding: 0 !important;
  margin-right: 0;
  font-size: 1em !important;
}
.footer{
  padding: 1em;
  background: #ffffff;
}
</style>
