export default {
  nav: {
    dashboard: "Dash",
    apps: "Apps",
    testruns: "Test Runs",
    integrations: "Connect",
    virtualizeApi: "Virtualize",
    installation: "Installation",
    profile: "User profile",
    docs: "Read the docs",
    liveEvents: 'Live events',
    subscription: 'Subscription',
    github: "Report problems",
  },
  footer: {
    made: "",
    by: "",
  },
  dashboard: {
    average_runtime: "Average Run Time",
    since_last_month: "Since last month",
    since_last_week: "Since last week",

    longest_test_run: "Longest Test Run",
    test_case_healed: "Test Cases Healed",
    suggestions: "Suggestions",


    tests_by_app: "Test Runs By App",
    events_gen: "Events Generated",
    key_flows_gen: "Key Flows",
    bugs_discovered: "Bugs Discovered",
    bug_finder_runs: "Bug Finder Runs",
    latest_session_video: "Latest Session Video",
    latest_key_flow: "Latest Key Flow",


    auto_heals: "Auto-heals",
    time_saved: "Time saved",
    test_heals: "Test heals",
    singular_hour: " Hour",
    plural_hour: " Hours",
    singular_minute: " Minute",
    plural_minute: " Minutes",
    singular_second: " Second",
    plural_second: " Seconds",
    auto_healed: "Auto-Healed",
    suggested: "Suggested",
  },
  user_edit_card: {
    edit_profile: "Update your info",
    edit_password: "Change password",
    picture: "Picture",
    remove: "Remove",
    select_image: "Select image",
    change: "Change",
    name: "Name",
    email: "Email",
    old_password: "Old password",
    new_password: "New password",
    submit: "Submit",
    save: "Save",
  },
  page_start: {
    desc_first: "Welcome to your Wring Workspace!",
    desc_second: "Starting with Wring super easy. Just follow these 3 steps to start testing your applications autonomously.",
    desc_third: "Use the best Generative AI enterprise platform to power your applications!",
    text_card_first:"Create an App entry and add the JS Snippet to your app.",
    text_card_second: "See the live sessions and key flows recorded on Wring.",
    text_card_third: "Use Wring to automatically convert these to tests!",
    progress_tracker: "Progress Tracker",
    checked1: "First App created",
    checked2: "Javascript snippet generated",
    checked3: "Live sessions being recorded",
    checked4: "App Map generated for you app",
    checked5: "First test added from Key Flows or Live Sessions",
    checked6: "Run your first Test Suite",

  },
  uer_card: {
    level: "Level",
    usage: "Usage",
    subscription_plan: "Subscription:",
  },
  profile_page: {
    profile: "Profile",
  },
  social_traffic_table: {
    test_generation_highlights: "#Recent Tests",
    test_name: "Test Suite",
    paths: "Paths",
    empty: "No Data",
  },
  list_item_page: {
    test_explorer: "Test Runs",
    search: "Search ...",
    filter: "Filter",
    no_items_found: "No items found",
    total_steps: "Total steps",
    created: "Created",
    tags: "Tags",
    name: "Name",
    status: "Status",
    entries: "entries",
    of: "of",
    to: "to",
    add_test: "Add Test",
    run_katalon_selenium: "Run Katalon or Selenium IDE test scripts",
    generate_test: "Generate Tests",
    run_name: "Run name (required):",
    email_address:"Email",
    placeholder_email_address:"Enter Email",
    send_email_address:"Share App",
    placeholder_run_name: "Enter a name for this test run",
    placeholder_test_suit_run_name: "Signin Flow",
    description: "Description:",
    placeholder_run_description: "Enter an optional description or comments for this test run",
    run_tags: "Run tags:",
    placeholder_run_tags: "Enter optional tags for this run, separated by commas",
    settings: "Settings",
    advanced: "Advanced",
    test_script: "Test Script Options",
    browser_width: "Browser Width",
    browser_height: "Browser Height",
    max_wait: "Max Wait Time (sec)",
    test_script_files: "Test Script Files",
    upload_test_script: "Upload test script files",
    up_to_50: "(up to 50 .xml, .side, or .wring files)",
    uploaded_files: "Uploaded Files",
    clear_all: "Clear all",
    ignore_disabled: "Ignore disabled elements",
    ignore_invisible: "Ignore invisible elements",
    collect_interaction: "Collect page-interaction event data for test case augmentation",
    collect_extra_element: "Collect extra element data & try to fix broken selectors (slow)",
    generate_selectors: "Generate selectors for all elements on each visited page (slow)",
    try_to_avoid: "Try to avoid string/text methods when generating XPath selectors",
    verify_suggested: "Verify suggested selectors",
    trigger_integrations: "Trigger integrations (GitHub, GitLab, Jira) on test failure",
    avoid_attributes: "Avoid these attributes when generating selectors",
    placeholder_avoid_attributes: "Enter attributes to avoid, separated by spaces, e.g. id class href",
    use_attributes: "Use these attributes when generating selectors",
    placeholder_use_attributes: "Enter attributes to use, separated by spaces, e.g. id class href",
    continue_test_suite: "Continue test suite execution if a component test fails",
    timeout_and_cancel: "Time out and cancel this run in",
    placeholder_timeout_and_cancel: "max 3600.0",
    seconds: "seconds",
    close: "Close",
    start_run: "Start Run",
    add_to_testsuite: "Add to Test Suite",
    screenshot_not_availabe: "Screenshot not available"
  },
  data_list: {
    no_items_found: "No items found",
  },
  integrations: {
    integrations: "Integrations",
    ci: "CI Integrations",
    triggers: "Triggers",
    scheduled_runs: "Schedules",
    description: "Description",
    name: "Name",
    status: "Status",
    actions: "Actions",
    origin: "Origin",
    added_updated: "Added/Updated at",
    last_at: "Last at",
    last_status: "Last status",
    last_five: "Last five",
  },
  scheduled_run: {
    scheduled_test: "Scheduled Test",
    name: "Name",
    schedule: "Schedule",
    last_run: "Last run",
    next_run: "Next Run",
    is_active: "Is active",
    last_updated: "Last Updated",
  },
  configurations_content: {
    quick_setup: "Quick Setup",
    click_on_your_os: "Click on your OS of choice",
    copy_tg_token: "Copy the command below and run it on your terminal window to set TG_TOKEN",
    windows: "Windows PowerShell",
    linux_macos: "Linux/MacOS",
    copy_cypress_token: 'Copy the command below and run it on your terminal window to set CYPRESS_TG_TOKEN and CYPRESS_TG_ENABLED',
    quickly_interceptor_packages: "Wring Interceptor packages",
    download_java_3_14_0: "Download JAVA JAR - 3.14.0",
    download_java_3_141_59: "Download JAVA JAR - 3.141.59",
    include_this_selenium: "Include this Selenium JAR in the Java classpath when you run your test instead of the normal Selenium JAR. This JAR contains our Selenium Interceptor, as well as its dependencies.",
    examples: "Examples",
    download_python_wheel: "Download Python wheel",
    install_this_python_wheel: "Install this Python wheel to override your normal Selenium package dependency:",
    pip_install_selenium: "pip install --upgrade --force-reinstall selenium-20.11.0-py2.py3-none-any.whl",
    download_npm_package: "Download NPM package",
    install_this_npm: "Install this NPM package to override your normal Selenium package dependency:",
    npm_install_selenium: "npm install selenium-webdriver-20.11.0.tgz",
    npm_install_aichemy: "npm install @aichemy/wring-cypress-plugin",
    for_cypress_settings: "For Cypress settings visit this link:",
    js_examples: "JS Examples",
    ts_examples: "TS Examples",
    you_are_on_the: "You are on the",
    version: "version",
    service_versions: "Service versions",
    ui_version: "UI version is:",
    api_version: "API version is:",
  },
  user_profile: {
    installation: "Installation",
  },
  subscription_view: {
    subscription: "Subscription plan",
    current_plan: "Current plan limits and usage",
    plan_billing: "Billing details",
    update_plan: "Plan management",
    plan_usage: "Plan usage"
  },
  dashboard_navbar: {
    welcome: "Welcome",
    my_profile: "My profile",
    subscription: "Subscription Plans",
    logout: "Logout",
    report: "Report a Bug",
    language: "Language",
    english: "English",
    spanish: "Spanish",
    documentation: "Documentation"
  },
  view_item_page: {
    test_explorer: "Test Runs",
    overview: "Overview",
    run_steps: "Run steps",
    Run_the_simulator: "Run the Expander",
    advanced: "Details",
    enhancer: "Expander",
    rerun: "Rerun test",
    share: "Share",
    download_scripts: "Download",
    view_websocket_logs: "View websocket logs",
    view_runner_log: "View runner log",
    download_csv_report: "Download CSV Report",
    download_pdf_report: "Download PDF report for this test",
    select_selenium_test: "Select Selenium test script",
    download_selenium_test: "Download Selenium test script",
    download_python_script: "Download Python script",
    download_selenium_pom: "Download Selenium POM for suggested selectors",
    download_selenium_script: "Download Selenium test script",
    download_selenium_pom_verified: "Download Selenium POM using verified selectors",
    download_generated_cypress_verified: "Download generated Cypress test script with verified selectors",
    download_generated_cypress: "Download generated Cypress test script",
    download_generated_sensetalk: "Download generated Sensetalk test script",
    search: "Search ...",
    file_variables: "File Variables",
    share_this_item: "Share this item",
    permissions: "Permissions",
    read_only: "Read only",
    edit_item_properties: "Edit Item properties",
    rerun_this_item: "Rerun this item",
    generate_shareable_url: "Generate shareable URL",
    rerun_test_with: "Rerun test with current steps",
    run_name: "Run name (required):",
    placeholder_run_name: "Enter a name for this test run",
    description: "Description:",
    placeholder_run_description: "Enter an optional description or comments for this test run",
    run_tags: "Run tags:",
    placeholder_run_tags: "Enter optional tags for this run, separated by commas",
    settings: "Settings",
    test_script: "Test Script Options",
    browser_width: "Browser Width",
    browser_height: "Browser Height",
    max_wait: "Max Wait Time (sec)",
    test_script_files: "Test Script Files",
    upload_test_script: "Upload test script files",
    up_to_50: "(up to 50 .xml, .side, or .wring files)",
    uploaded_files: "Uploaded Files",
    clear_all: "Clear all",
    ignore_disabled: "Ignore disabled elements",
    ignore_invisible: "Ignore invisible elements",
    collect_interaction: "Collect page-interaction event data for test case augmentation",
    collect_extra_element: "Collect extra element data & try to fix broken selectors (slow)",
    generate_selectors: "Generate selectors for all elements on each visited page (slow)",
    try_to_avoid: "Try to avoid string/text methods when generating XPath selectors",
    verify_suggested: "Verify suggested selectors",
    trigger_integrations: "Trigger integrations (GitHub, GitLab, Jira) on test failure",
    avoid_attributes: "Avoid these attributes when generating selectors",
    placeholder_avoid_attributes: "Enter attributes to avoid, separated by spaces, e.g. id class href",
    use_attributes: "Use these attributes when generating selectors",
    placeholder_use_attributes: "Enter attributes to use, separated by spaces, e.g. id class href",
    continue_test_suite: "Continue test suite execution if a component test fails",
    timeout_and_cancel: "Time out and cancel this run in",
    placeholder_timeout_and_cancel: "max 3600.0",
    seconds: "seconds",
    close: "Close",
    start_rerun: "Start Rerun",
    websocket_live_logs: "WebSocket Live Logs",
    logs_status: "Logs status:",
    test_runner_console_logs: "Test runner console logs",
    console_log_for_stdout: "Console log for STDOUT",
    no_logs_available_as_script: "No logs available",
    console_log_for_stderr: "Console log for STDERR",

  },
  overview: {
    test_status: "Test status",
    run_time: "Run Time",
    steps: "Steps",
    failures: "Failures",
    auto_healed: "Auto Healed",
    time_saved: "Time Saved",
    enhancements: "Enhancements",
    test_run_options: "Test Run Options",
    fast_heal_mode: "Fast-heal mode",
    interceptor_failure_handling: "Interceptor failure handling",
    collect_page_interaction: "Collect page-interaction events",
    test_run_origin: "Test run origin",
    element_neighbor_max: "Element neighbor max distance (px)",
    test_step_wait: "Test step wait time (seconds)",
    interceptor_screenshots: "Interceptor screenshots",
    browser_window_size: "Browser window size",
    ignore_disabled_elements: "Ignore disabled elements",
    ignore_invisible_elements: "Ignore invisible elements",
  },
  test_run_steps: {
    search: "Search ...",
    to: "to",
    of: "of",
    entries: "entries",
    step: "Step",
    no_screenshot: "No Screenshot found",
    edit: "Edit",
    save: "Save",
    update: "Update",
    add: "Add",
    delete: "Delete",
    action_to_perform: "Action to perform",
    selector_type: "Selector type",
    selector_to_use: "Selector to use",
    input_value: "Input value",
    enable_step: "Enable Step",
    cancel: "Cancel",
    insert_new_step: "Insert new step before existing step",
    new_step: "New Step",
    actions: "Actions",
    choose_selector_type: "Choose Selector Type",
    enter_selector: "Enter Selector",
    enter_value: "Enter value",
    proceed_step_edition: "Proceed Step Edition",
    add_a_new_step: "Add a new Step",
  },
  suggestions: {
    step: "Step",
    fix: "Fix",
    page_url: "Page url",
    quikly_suggest: "AI Suggests",
    details: "",
    to: "to",
    of: "of",
    entries: "entries",
    more_details: "More Details",
    see_more: "See more",
    original_path: "Original Path",
    suggested_selectors: "Suggested Selectors",
    improvement: "Improvement",

  },
  test_enhancer: {
    setup: "Setup",
    runs: "Runs",
    search: "Search ...",
    to: "to",
    of: "of",
    entries: "entries",
    step: "Step",
    description: "Description",
    operations: "",
    date_run: "Date run",
    failures: "#Failures",
    episodes: "#Episodes",
    agent_type: "Agent type ",
    status: "Status ",
    runs_list: "Runs List",
    Data_file_upload: "Data File Upload",
    Preview_file: "Preview file ",
    name: "Name",
    saved_at: "saved_at",
    varaiable_value: "varaiable value",
    actions: "Action",
    add_file: "Add File",
    cancel: "Cancel",
    Run_the_simulator: "Run the Expander",
    algorithm: "Algorithm",
    maximum_number_of_tests: "Maximum number of tests to Generate",
    randomize: "Randomize",
    randomize_description: "Randomize data used in testing.",
    sequential: "Sequential",
    sequential_description: "Apply  data provided in data file sequentially.",
    exhaustive: "Exhaustive",
    exhaustive_description: "Covers all data combinations. Larger episode sizes provide better results.",
    permutation: "Permutation",
    permutation_description: "Try intelligent permutations of explorable steps.",
    exploration: "Exploration",
    exploration_description: "Perform exploration runs. Auto verifications will be computed.",
    pageview_config: "Pageview config" ,
    pageview_config_value: "pageview_config" ,
    pageview_config_description: "Key should be life events project token." ,
    run: "Run",
    could_not_start: "Could not start test run",
    preparing_test_enhancer: "Preparing the test expander ...",
    no_file_variable: "No variable data received from files",
    no_variable: "No Variable",
    Setup_dynamic_variables: "Setup Dynamic Variables",
    no_variable_data: "No Variable Data",
    variable: "Variable",
    type: "Type",
    data: "Data",
    is_active: "Active",
    source: "Source",
    add_variable:"add variable",

  },
};
