<template>
  <div>
    <card>
      <div class="row">
        <div class="col-xl-6">
          <div class="row py-2">
            <div class="col-xl-4">
              <base-button
                id="installationSelenium"
                :class="{ active: this.selenium }"
                @click="toggleContent('showSelenium')"
                block
                type="primary"
                outline
              >
                Selenium
              </base-button>
            </div>
            <div class="col-xl-4">
              <base-button
                id="installationCypress"
                :class="{ active: this.showCypress }"
                @click="toggleContent('showCypress')"
                block
                type="primary"
                outline
              >
                Cypress
              </base-button>
            </div>
            <div class="col-xl-4">
              <base-button
                id="installationObserver"
                :class="{ active: this.WringSmartObserver }"
                @click="toggleContent('WringSmartObserver')"
                block
                type="primary"
                outline
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                Wring Smart Observer
              </base-button>
            </div>
          </div>

          <h2 class="my-2">{{ $t("configurations_content.quick_setup") }}</h2>

          <template v-if="selenium">
            <ol class="sm-text">
              <li>{{ $t("configurations_content.click_on_your_os") }}</li>
              <li>
                {{ $t("configurations_content.copy_tg_token") }}
              </li>
            </ol>
            <div
              class="d-flex flex-row align-items-center justify-content-between my-2"
            >
              <div class="d-flex flex-row align-items-center">
                <base-button
                  id="installationWindows"
                  v-bind:class="{
                    active: this.operatingSystem == 'windows',
                  }"
                  v-on:click="setOperatingSystem('windows')"
                  size="sm"
                  outline
                  type="primary"
                  >{{ $t("configurations_content.windows") }}</base-button
                >
                <base-button
                  id="installationLinuxMacOs"
                  v-bind:class="{
                    active: this.operatingSystem != 'windows',
                  }"
                  v-on:click="setOperatingSystem('linux')"
                  size="sm"
                  outline
                  type="primary"
                  >{{ $t("configurations_content.linux_macos") }}</base-button
                >
              </div>
              <div>
                <i
                  class="ni ni-ungroup"
                  id="clipboard_copy"
                  data-copytarget="#exampleFormControlTextarea3"
                  v-on:click="copyTextArea"
                ></i>
              </div>
            </div>
            <div class="mt-2 token-text-area">
              <textarea
                readonly
                class="form-control"
                id="exampleFormControlTextarea3"
                rows="14"
                v-if="operatingSystem == 'windows'"
                v-model="getSeleniumWindowCommand"
              >
              </textarea>

              <textarea
                readonly
                class="form-control"
                id="exampleFormControlTextarea3"
                rows="14"
                v-if="operatingSystem == 'linux'"
                v-model="getSeleniumLinuxMacCommand"
              >
              </textarea>
            </div>
          </template>

          <template v-else-if="showCypress">
            <ol class="sm-text">
              <li>{{ $t("configurations_content.click_on_your_os") }}</li>
              <li>
                {{ $t("configurations_content.copy_cypress_token") }}
              </li>
            </ol>
            <div
              class="d-flex flex-row align-items-center justify-content-between my-2"
            >
              <div class="d-flex flex-row align-items-center">
                <base-button
                  id="installationWindows"
                  v-bind:class="{
                    active: this.operatingSystem == 'windows',
                  }"
                  v-on:click="setOperatingSystem('windows')"
                  size="sm"
                  outline
                  type="primary"
                  >Windows PowerShell</base-button
                >
                <base-button
                  id="installationLinuxMacOs"
                  v-bind:class="{
                    active: this.operatingSystem != 'windows',
                  }"
                  v-on:click="setOperatingSystem('linux')"
                  size="sm"
                  outline
                  type="primary"
                  >Linux/MacOS</base-button
                >
              </div>
              <div>
                <i
                  class="ni ni-ungroup"
                  id="clipboard_copy"
                  data-copytarget="#exampleFormControlTextarea3"
                  v-on:click="copyTextArea"
                ></i>
              </div>
            </div>
            <div class="mt-2 token-text-area">
              <textarea
                readonly
                class="form-control"
                id="exampleFormControlTextarea3"
                rows="14"
                v-if="operatingSystem == 'windows'"
                v-model="getCypressWindowCommand"
              ></textarea>
              <textarea
                readonly
                class="form-control"
                id="exampleFormControlTextarea3"
                rows="14"
                v-if="operatingSystem == 'linux'"
                v-model="getCypressLinuxMacCommand"
              ></textarea>
            </div>
          </template>

          <template v-else-if="WringSmartObserver">
            <div class="sm-text">
              <b>Wring Smart Observer </b>is a popular solution to quickly generate selectors in preparation for testing a webpage. In the following pages, we will discuss how to effectively use the record to enhance , automate , and strengthen your testing processes. Wring is your automated testing solution, that addresses all problems and provides the leading NoCode solution for testing. It generates robust locators that don’t break, and auto heals them as the application changed.
            </div>
          </template>
        </div>

        <div class="col-xl-6">
          <h2>
            {{ $t("configurations_content.quickly_interceptor_packages") }}
          </h2>
          <template v-if="selenium">
            <div class="border-top border-dark">
              <div id="installationJava" class="row mt-2 px-2">
                <div class="col-xl-3">
                  <div>Java</div>
                </div>
                <div class="col-xl-9">
                  <div class="d-flex flex-column sm-text">
                    <a :href="downloadFile('java')" target="_blank">{{
                      $t("configurations_content.download_java_3_14_0")
                    }}</a>
                    <a :href="downloadFile('java')" target="_blank">{{
                      $t("configurations_content.download_java_3_141_59")
                    }}</a>
                  </div>
                  <div class="mt-2">
                    <p class="sm-text">
                      {{ $t("configurations_content.include_this_selenium") }}
                    </p>
                  </div>

                  <div class="mt-2">
                    <p class="sm-text">
                      <a
                        href="https://github.com/aichemydev/selenium-java-examples"
                        target="_blank"
                        >{{ $t("configurations_content.examples") }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="border-top border-dark">
              <div id="installationPython" class="row mt-2 px-2">
                <div class="col-xl-3 sm-text">
                  <div>Python</div>
                </div>
                <div class="col-xl-9">
                  <div class="d-flex flex-column sm-text">
                    <!-- v-bind:href="installationDwnUrl + forPython + aioToken" -->
                    <a :href="downloadFile('python')" target="_blank">
                      {{
                        $t("configurations_content.download_python_wheel")
                      }}</a
                    >
                  </div>
                  <div class="mt-2">
                    <p class="sm-text">
                      {{
                        $t("configurations_content.install_this_python_wheel")
                      }}
                    </p>
                    <p>
                      <span class="for-mono-font">{{
                        $t("configurations_content.pip_install_selenium")
                      }}</span>
                    </p>
                  </div>

                  <div class="mt-2">
                    <p class="sm-text">
                      <a
                        href="https://github.com/aichemydev/selenium-python-examples"
                        target="_blank"
                        >{{ $t("configurations_content.examples") }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="border-top border-dark">
              <div id="installationJavascript" class="row mt-2 px-2">
                <div class="col-xl-3">
                  <div>Javascript</div>
                </div>
                <div class="col-xl-9">
                  <div class="d-flex flex-column sm-text">
                    <a :href="downloadFile('js')" target="_blank">{{
                      $t("configurations_content.download_npm_package")
                    }}</a>
                  </div>
                  <div class="mt-2">
                    <p class="sm-text">
                      {{ $t("configurations_content.install_this_npm") }}
                    </p>
                    <p>
                      <span class="for-mono-font">{{
                        $t("configurations_content.npm_install_selenium")
                      }}</span>
                    </p>
                  </div>

                  <div class="mt-2">
                    <p class="sm-text">
                      <a
                        href="https://github.com/aichemydev/selenium-js-examples"
                        target="_blank"
                        >{{ $t("configurations_content.examples") }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="showCypress">
            <div class="border-top border-dark">
              <div class="row mt-2 px-2">
                <div class="col-xl-3">
                  <div>Javascript</div>
                </div>
                <div class="col-xl-9">
                  <div class="sm-text">
                    <p class="sm-text">
                      <span class="for-mono-font">{{
                        $t("configurations_content.npm_install_aichemy")
                      }}</span>
                    </p>
                  </div>

                  <div class="mt-2 sm-text">
                    <span class="">{{
                      $t("configurations_content.for_cypress_settings")
                    }}</span>
                    <a
                      href="https://www.npmjs.com/package/@aichemy/wring-cypress-plugin"
                      >https://www.npmjs.com/package/@aichemy/wring-cypress-plugin</a
                    >
                  </div>

                  <div class="mt-2 sm-text">
                    <div>
                      <a
                        href="https://github.com/aichemydev/cypress-js-examples"
                        target="_blank"
                      >
                        {{ $t("configurations_content.js_examples") }}</a
                      >
                    </div>
                    <div>
                      <a
                        href="https://github.com/aichemydev/cypress-ts-examples"
                        target="_blank"
                      >
                        {{ $t("configurations_content.ts_examples") }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="WringSmartObserver">
            <div class="border-top border-dark">
              <div class="row mt-2 px-2">
                <div class="col-xl-3">
                  <div>Wring Smart Observer</div>
                </div>
                <div class="col-xl-9">
                  <div class="sm-text">
                    <p class="sm-text">
                      <a
                        class="sm-text"
                        href="https://wringapiutils.s3.us-west-2.amazonaws.com/interceptor-packages/wring-smart-observer-1.0.0.zip"
                      >
                        Download Wring Smart Observer 
                      </a>
                    </p>
                  </div>

                  <div class="mt-2 sm-text">
                    <span class="">
                      For more info, visit this link: 
                    </span>
                    <a
                      href="https://aichemydev.github.io/Wring_docs/docs/TG%20Smart%20Observer/Get%20started%20-%20Build"
                      >https://aichemydev.github.io/Wring_docs/docs/TG%20Smart%20Observer/Get%20started%20-%20Build</a
                    >
                  </div>

                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </card>
    <div class="">
      <card>
        <div class="pt-4">
          <div>
            <h4 class="text">
              {{ $t("configurations_content.service_versions") }}
            </h4>
          </div>
          <div>
            <h3>
              <small class="text-muted"
                >{{ $t("configurations_content.ui_version") }} {{ ui }},
              </small>
            </h3>
          </div>
          <div>
            <h3>
              <small class="text-muted"
                >{{ $t("configurations_content.api_version") }} {{ api }}</small
              >
            </h3>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ConfigurationContent",
  data() {
    return {
      forPython: "selenium-20.11.0-py2.py3-none-any.whl?walToken=",
      installationDwnUrl: `${process.env.VUE_APP_API_URL_PREFIX}/download/secured/`,
      aioToken: localStorage.getItem("testgold.aioToken"),
      selenium: true,
      operatingSystem:
        navigator.appVersion.indexOf("Win") != -1 ? "windows" : "linux",
      tg_token: localStorage.getItem("testgold.aioToken"),
      ui: process.env.GIT_REVISION ? process.env.GIT_REVISION : "unknown",
      api: "unkown",
      tierName: "unkown",
      WringSmartObserver: false,
      showCypress :false ,
    };
  },
  created() {
    this.getApi();
  },
  methods: {
    getApi() {
      axios
        .get(process.env.VUE_APP_API_URL_PREFIX + `/version/v1`)
        .then((response) => {
          this.api = response.data.response.apiVersion;
        })
        .catch(() => {
          console.error;
          this.api = "unkown";
        });
    },

    downloadFile(type) {
      if (type === "java") {
        return "https://wringapiutils.s3.us-west-2.amazonaws.com/interceptor-packages/selenium-combined-client-with-deps-20.11.0.jar";
      } else if (type === "python") {
        return "https://wringapiutils.s3.us-west-2.amazonaws.com/interceptor-packages/selenium-20.11.0-py2.py3-none-any.whl";
      } else if (type === "js") {
        return "https://wringapiutils.s3.us-west-2.amazonaws.com/interceptor-packages/selenium-webdriver-20.11.0.tgz";
      }
    },
    toggleContent(para) {
      this.WringSmartObserver = false
      this.showCypress = false
      this.selenium = false
      if(para === "showSelenium"){
        this.selenium = true
      }else if(para === "showCypress"){
        this.showCypress = true
      }else if(para === "WringSmartObserver"){
        this.WringSmartObserver = true
      }
    },
    setOperatingSystem(name) {
      this.operatingSystem = name;
    },
    copyTextArea(e) {
      var targetElement = e.target;
      var copiedTarget = targetElement.dataset.copytarget;
      var element = document.querySelector(copiedTarget);

      console.log(element);
      element.select();
      document.execCommand("copy");
      this.$notify({
        type: "success",
        message: "Copied to the Clipboard!",
      });
    },
  },
  computed: {
    getSeleniumWindowCommand() {
      return `$env:TG_TOKEN='${this.tg_token}'`;
    },
    getSeleniumLinuxMacCommand() {
      return `export TG_TOKEN='${this.tg_token}'`;
    },
    getCypressWindowCommand() {
      return `$env:CYPRESS_TG_TOKEN='${this.tg_token}'`;
    },
    getCypressLinuxMacCommand() {
      return `export CYPRESS_TG_TOKEN='${this.tg_token}'`;
    },
  },
};
</script>

<style>
.sm-text > li{
  text-align: start;
}
textarea {
  resize: none;
}

#exampleFormControlTextarea3 {
  font-size: 12px;
  font-family: monospace;
  white-space: pre-wrap;
}

#exampleFormControlTextarea3::selection {
  background-color: transparent;
}

.wid {
  max-width: 50%;
}

#clipboard_copy {
  cursor: pointer;
}

.sm-text {
  font-size: 14px;
  word-break: normal;
}
.for-mono-font {
  font-family: monospace;
  font-size: 12px;
}
.token-text-area textarea {
  background-color: #464547 !important;
  color: #fff;
  white-space: pre-wrap;
  word-break: break-all;
}
.token-text-area textarea:focus {
  background-color: #464547 !important;
  color: #fff;
}


.sm-text{
  text-align: start;
}
</style>
