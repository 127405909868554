var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid right-content"},[_c('div',{staticClass:"c--breadcrumb py-4"},[_c('router-link',{attrs:{"to":"/details/test-explorer/list-tests"}},[_c('i',{staticClass:"el-icon-house"})]),_vm._m(0),_c('router-link',{attrs:{"to":"/details/project-explorer/list-projects"}},[_vm._v(" Apps ")]),_vm._m(1),_c('router-link',{attrs:{"to":`/details/project-explorer/view-project/${_vm.$route.params.id}`}},[_vm._v(" "+_vm._s(_vm.$route.params.name)+" ")]),_vm._m(2),_c('span',[_vm._v(" Project test suite files")])],1),[_c('div',{staticClass:"body--modale"},[_c('div',{staticClass:"modal-add-containt"},[_c('div',{staticClass:"add-test-project-modal"},[_c('data-list',{ref:"dataListTestSuite",attrs:{"tHeader":_vm.tHeaderSuitFile,"loading":_vm.loading,"items":_vm.file,"customSizes":true},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.file),function(elt,id){return _c('div',{key:elt.id,staticClass:"tblb--row mb-2"},[_c('div',{staticClass:"tblb--inner"},[_c('div',{staticClass:"elt-row",staticStyle:{"width":"25%"}},[_c('span',{staticClass:"test-run-name"},[_vm._v(_vm._s(elt.runName.replace('Step', 'Test')))]),_c('br')]),_c('div',{staticClass:"elt-row",staticStyle:{"width":"30%"}},[_c('span',[_vm._v(" "+_vm._s(elt.runDesc)+" ")]),_c('span',{staticClass:"run-tags-cont"},[_c('span',{staticClass:"run-tags"},[_vm._v(" "+_vm._s(elt.fileName)+" ")])])]),_c('div',{staticClass:"elt-row",staticStyle:{"width":"15%"}},[_c('el-alert',{staticClass:"no--close",attrs:{"title":elt.isActive === true ? 'active' : 'disabled',"type":elt.isActive === true
                      ? 'success'
                      : elt.isActive === false
                        ? 'danger'
                        : 'danger',"show-icon":""}})],1),_c('div',{staticClass:"elt-row",staticStyle:{"width":"20%"}},[_c('span',{staticClass:"elt--txt"},[_vm._v(" "+_vm._s(_vm.formatDate(elt.updatedAt))+" ")])]),_c('div',{staticClass:"elt-row",staticStyle:{"width":"10%"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Delete file on test","placement":"top"}},[_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","circle":"","plain":"","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(elt)}}})],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Download test suite file","placement":"top"}},[_c('el-button',{attrs:{"type":"secondary","icon":"el-icon-download","circle":"","plain":"","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadTestSuiteFile(elt)}}})],1)],1)])])})},proxy:true}])})],1)])])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"el-icon-d-arrow-right mx-1 cb--divider"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"el-icon-d-arrow-right mx-1 cb--divider"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"el-icon-d-arrow-right mx-1 cb--divider"})])
}]

export { render, staticRenderFns }