import { render, staticRenderFns } from "./detailsMobileSession.vue?vue&type=template&id=12e3a34b&scoped=true&"
import script from "./detailsMobileSession.vue?vue&type=script&lang=js&"
export * from "./detailsMobileSession.vue?vue&type=script&lang=js&"
import style0 from "./detailsMobileSession.vue?vue&type=style&index=0&id=12e3a34b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12e3a34b",
  null
  
)

export default component.exports