<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand"
    v-bind:class="$route.name == 'Dashboard' ? 'bg-gradient-info' : ''"
  >

      <!-- Need to change above line -->
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-dark': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import axios from "axios";

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    profileImage() {
      return this.avatar ? this.avatar : "/img/placeholder.jpg";
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      title: "User",
      avatarText: null,
      user: {},
      activePath: undefined,
      logOut:"https://auth.wring.dev/realms/devwringdev/account/#/",
    };
  },
  methods: {
    switchLg(lg) {
      this.$i18n.locale = lg;
      localStorage.setItem("aichemy--current_language", lg);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    getAvatarInitials() {
      if (this.avatarText) {
        const avatarArray = this.avatarText.split(" ");

        if (avatarArray.length > 1)
          return avatarArray.map((word) => word[0]).join("");
        return this.avatarText.substring(0, 2);
      }
    },
    goToProfile() {
      this.$router.push({
        name: "User Profile",
      });
    },
    async getData() {
      try {
        axios
          .get(process.env.VUE_APP_API_URL_PREFIX + `/auth/v1/users/me`)
          .then((response) => {
            if (
              response &&
              response.message === "Request failed with status code 402"
            ) {
              console.log("navbar 402");
              localStorage.removeItem("vue-authenticate.access_token");
              localStorage.removeItem("testgold.aioToken");
              localStorage.setItem(
                "login--message",
                "User trial period has ended. Please contact support@testgold.dev."
              );
              window.location.reload();
            }

            if (response.data) {
              this.user = Object.assign({}, response.data.response);
              this.avatarText = this.user.full_name
                ? this.user.full_name
                : "N/A";
            }
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `An error has occured ${e.response.message}`,
        });
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Oops, Cannot logout ! If problem persist, please contact support",
        });
      }
    },
  },
  async created() {
    process.env.VUE_APP_USER_PROFILE_URL  ? this.logOut = process.env.VUE_APP_USER_PROFILE_URL : this.logOut = "https://auth.wring.dev/realms/devwringdev/account/"
    await this.getData();
    this.activePath = this.$route.name;
  },
  watch: {
    $route(from, to) {
      this.activePath = from.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: white;
  justify-content: center;
  transform: translateY(-1rem);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-weight: bold;
  top: 16px;
  font-size: 13px;

  &:hover {
    border: 1px solid white;
    border-radius: 0.5rem;
  }
}

.el-dropdown-link_2 {
  cursor: pointer;
  color: #6e6e6d;
  justify-content: center;
  transform: translateY(-1rem);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-weight: bold;
  top: 16px;
  font-size: 13px;

  &:hover {
    border: 1px solid #6e6e6d;
    border-radius: 0.5rem;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
  top: 2px;
}

.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none !important;
}

.myDropdown__menu {
  border: 2px solid white;
  background-color: orange;
  width: 98px;
  transform: translateY(-5px) translateX(0px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1rem;
  padding: 0;
}

.myDropdown__item {
  color: #616161;
  border: 3px solid #cfcfcf;
  background-color: #f5f5f5;
  width: 100%;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom:  1px solid #cfcfcf;
  }
}

.myDropdown__menu__2 {
  border: 3px solid #cfcfcf;
  background-color: #f5f5f5;
  width: 98px;
  transform: translateY(-5px) translateX(0px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1rem;
  padding: 0;
}

.myDropdown__item__2 {
  color: #616161;
  background-color: #ebeced;
  width: 100%;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #cfcfcf;
  }
}

.avatar-image i {
  color: #5e72e4;
}

.if-img {
  width: 50px;
  height: 50px;
  border-radius: 0.3rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.if-img-noavatar {
  width: 50px;
  height: 40px;
  border-radius: 0.3rem;
  background-color: #5e72e4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.no--hover {
  .nav-link {
    transform: translate3d(0, 0, 0);
    &:hover {
      transform: translate3d(0, 0, 0);
    }
  }
}

.dropdown-menu.show {
  padding-bottom: 0 !important;
  overflow: hidden;
}

.dropdown-divider {
  margin: 0;
}

.elt--dropdown {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  width: 100%;
  padding: .75rem;
  color: initial;

  &:hover {
    background-color: #5e72e4;
    color: #ffffff;
  }

  &.red {
    color: #f5365c;

    &:hover {
      background-color: #f5365c;
      color: #ffffff;
    }
  }
}
</style>
