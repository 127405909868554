<template>
  <div>
    <div style="display:flex;">
      <LeftSideBar>
          <div class="custom-btn-group align-items-center">
            <el-button
              @click="showComponent('overview')"
              icon="el-icon-pie-chart"
              class="tab--btn button-menu button-menu-item truncate"
              :class="{ active: showOverview }"
            >
              {{ $t("view_item_page.overview") }}
            </el-button>
            <el-button
              @click="showComponent('suggestions')"
              icon="el-icon-sunrise-1"
              class="tab--btn button-menu button-menu-item truncate"
              :class="{ active: showSuggestions }"
            >
              {{ $t("view_item_page.advanced") }}
            </el-button>
            <el-button
              v-if="
                subPlan &&
                subPlan['userActionsAllowedServices'] &&
                subPlan['userActionsAllowedServices'].indexOf('simulator') !== -1
              "
              @click="showComponent('testenhancer')"
              icon="el-icon-s-ticket"
              class="tab--btn button-menu button-menu-item truncate"
              :class="{ active: showTestEnhancer }"
            >
              {{ $t("view_item_page.enhancer") }}
            </el-button>
          </div>
      </LeftSideBar>
      <div class="right-content mt-2 py-2">
        <div>
          <div class="mid--page no-border">
            <div class="mp--r">
              <div class="c--breadcrumb" style="width: auto;" >
                <router-link to="/details/test-explorer/list-tests"
                  ><i class="el-icon-house"></i
                ></router-link>
                <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
                <router-link to="/details/test-explorer/list-tests">{{
                  $t("view_item_page.test_explorer")
                }}</router-link>
                <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
                <span v-if="items.runName">{{ items.runName }}</span>
                <span v-else class="s--icon"><i class="el-icon-loading"></i></span>
              </div>
            </div>
            <div class="mp--l">
              <div v-show="showOverview" class="fl--center">
                <button
                  v-if="items.status === 'processing'"
                  @click="openWebSocketModal()"
                  class="ic--btn mr-2"
                >
                  <el-tooltip
                    :content="$t('view_item_page.view_websocket_logs')"
                    placement="top"
                  >
                    <small>
                      <i class="fas fa-code"></i>
                    </small>
                  </el-tooltip>
                </button>
                <button
                  v-if="showRunnerLogButton || items.status !== 'processing'"
                  @click="openScriptModal()"
                  class="ic--btn mr-2"
                >
                  <el-tooltip
                    :content="$t('view_item_page.view_runner_log')"
                    placement="top"
                  >
                    <small>
                      <i class="fas fa-terminal"></i>
                    </small>
                  </el-tooltip>
                </button>
              </div>
              <!-- Overview -->
    
              <div v-show="showSuggestions" class="down-btn-container">
                <span class="mr-2">
                  <!-- <base-dropdown
                    v-if="
                      items.runOptions &&
                      (items.runOptions.test_run_origin === 'submitted-katalon-xml' ||
                        items.runOptions.test_run_origin === 'submitted-selenium-ide')
                    "
                  > -->
                  <!-- <div class="subdownbtns px-2"> -->
                  <!-- <button
                        @click="downloadTestFile('generated-python-script')"
                        class="ic--btn small no-border mr-2"
                      >
                        <el-tooltip
                          :content="$t('view_item_page.download_python_script')"
                          placement="top"
                        >
                          <img
                            src="../../../assets/images/selenium.png"
                            alt="Selenium"
                          />
                        </el-tooltip>
                      </button>
                      <button
                        @click="downloadTestFile('generated-seleniumpy-pom')"
                        class="ic--btn small no-border mr-2"
                      >
                        <el-tooltip
                          :content="$t('view_item_page.download_selenium_pom')"
                          placement="top"
                        >
                          <img
                            src="../../../assets/images/selenium_blue.png"
                            alt="Selenium Pom"
                          />
                        </el-tooltip>
                      </button> -->
                  <!-- <button
                        @click="downloadTestFile('generated-pypom-script')"
                        class="ic--btn small no-border mr-2"
                      >
                        <el-tooltip
                          :content="$t('view_item_page.download_selenium_script')"
                          placement="top"
                        >
                          <img
                            src="../../../assets/images/selenium2.png"
                            alt="Selenium Script"
                          />
                        </el-tooltip>
                      </button> -->
                  <!-- <span v-if="items.runTags">
                        <button
                          v-if="items.runTags[0] === 'suggested selectors verified'"
                          @click="downloadTestFile('verified-seleniumpy-pom')"
                          class="ic--btn small no-border mr-2"
                        >
                          <el-tooltip
                            :content="
                              $t('view_item_page.download_selenium_pom_verified')
                            "
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/verified-icon-blue.png"
                              alt="Verified"
                            />
                          </el-tooltip>
                        </button>
                      </span>
                      <span v-if="items.runTags">
                        <button
                          v-if="items.runTags[0] === 'suggested selectors verified'"
                          @click="downloadTestFile('verified-pypom-script')"
                          class="ic--btn small no-border mr-2"
                        >
                          <el-tooltip
                            content="Download test script using verified Selenium POM and verified selectors"
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/verified-icon-blue.png"
                              alt="Verified"
                            />
                          </el-tooltip>
                        </button>
                      </span> -->
                  <!-- </div> -->
                  <!-- </base-dropdown> -->
                </span>
              </div>
              <!-- Suggestions -->
    
              <div v-show="showTestEnhancer" class="down-btn-container">
                <button
                  v-show="lookedSalesforceIntegration"
                  @click="openListSalesforceVariables()"
                  class="ic--btn mr-2"
                >
                  <el-tooltip content="Import Salesforce Data" placement="top">
                    <i class="fa fa-cloud" aria-hidden="true"></i>
                  </el-tooltip>
                </button>
                <!-- <button @click="downloadEnhancerReport()" class="ic--btn mr-2">
                  <el-tooltip content="Download Test Enhancer Report" placement="top">
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </el-tooltip>
                </button> -->
                <button @click="openListFileVariables()" class="ic--btn mr-2">
                  <el-tooltip
                    :content="$t('view_item_page.file_variables')"
                    placement="top"
                  >
                    <i class="fa fa-folder" aria-hidden="true"></i>
                  </el-tooltip>
                </button>
                <button @click="openVariables()" class="ic--btn mr-2">
                  <el-tooltip content="Variables" placement="top">
                    <i class="fa fa-key" aria-hidden="true"></i>
                  </el-tooltip>
                </button>
                <button @click="showRunSteps()" class="ic--btn mr-2">
                  <el-tooltip
                    :content="$t('view_item_page.Run_the_simulator')"
                    placement="top"
                  >
                    <i
                      id="enhancer_start_stop_btn"
                      v-bind:class="[runActive ? 'fa-stop' : 'fa-play', 'fa']"
                      aria-hidden="true"
                    ></i>
                  </el-tooltip>
                </button>
              </div>
              <!-- Test Enhancer end-->
    
              <div v-show="showOverview">
                <el-button
                  @click="showRerunModalWithCurrentSteps()"
                  icon="el-icon-refresh-left"
                  class="tab--btn ml-2"
                >
                  {{ $t("view_item_page.rerun") }}
                </el-button>
                <el-button
                  @click="handleShare('testrun')"
                  icon="el-icon-share"
                  class="tab--btn"
                >
                  {{ $t("view_item_page.share") }}
                </el-button>
              </div>
              <!-- Rus steps end-->
            </div>
          </div>
          <div class="second-card">
            <div class="row">
              <div class="w-100" id="content">
                <div v-if="showSuggestions" class="mx-3 mt-3">
                  <suggestions ref="suggestions" :items="items">
                    <button
                      v-if="items.status === 'processing'"
                      @click="openWebSocketModal()"
                      class="ic--btn mr-2"
                    >
                      <el-tooltip
                        :content="$t('view_item_page.view_websocket_logs')"
                        placement="top"
                      >
                        <small>
                          <i class="fas fa-code"></i>
                        </small>
                      </el-tooltip>
                    </button>
                    <el-dropdown
                      trigger="click"
                      type="primary"
                      class="custom--dropdown"
                    >
                      <button class="ic--btn custom--btn-dropdown">
                        <el-tooltip
                          :content="$t('view_item_page.download_scripts')"
                          placement="top"
                        >
                          <i class="fa fa-download" aria-hidden="true"></i>
                        </el-tooltip>
                      </button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="custom--dropdown--item">
                          <button
                            v-if="
                              items.runOptions &&
                              (items.runOptions.test_run_origin ===
                                'submitted-katalon-xml' ||
                                items.runOptions.test_run_origin ===
                                  'submitted-selenium-ide')
                            "
                            class="ic--btn ml-2 mr-2 mb-2"
                            @click="downloadTestFile('generated-pypom-script')"
                          >
                            <el-tooltip
                              :content="
                                $t('view_item_page.download_selenium_script')
                              "
                              placement="top"
                            >
                              <img
                                src="../../../assets/images/selenium.png"
                                alt="Selenium"
                              />
                            </el-tooltip>
                          </button>
                        </el-dropdown-item>
                        <el-dropdown-item class="custom--dropdown--item">
                          <button
                            v-if="
                            items.runOptions &&
                            (items.runOptions.test_run_origin === 'submitted-katalon-xml' ||
                              items.runOptions.test_run_origin === 'submitted-selenium-ide')
                          "
                            @click="downloadTestFile('generated-sensetalk-script')"
                            class="ic--btn ml-2 mr-2 mb-2"
                          >
                            <el-tooltip
                              :content="$t('view_item_page.download_generated_sensetalk')"
                              placement="top"
                            >
                             <img
                                src="../../../assets/images/eggplant_logo.svg"
                                alt="Sensetalk"
                              />
                            </el-tooltip>
                          </button>
                        </el-dropdown-item>
                        <el-dropdown-item class="custom--dropdown--item">
                          <button
                            v-if="
                              items.runOptions &&
                              (items.runOptions.test_run_origin ===
                                'submitted-katalon-xml' ||
                                items.runOptions.test_run_origin ===
                                  'submitted-selenium-ide')
                            "
                            @click="downloadTestFile('generated-cypress-script')"
                            class="ic--btn ml-2 mr-2 mb-2"
                          >
                            <el-tooltip
                              :content="
                                $t('view_item_page.download_generated_cypress')
                              "
                              placement="top"
                            >
                              <img
                                src="../../../assets/images/cypress_blue.png"
                                alt="Katalon"
                              />
                            </el-tooltip>
                          </button>
                        </el-dropdown-item>
                        <el-dropdown-item class="custom--dropdown--item">
                          <button
                            @click="downloadTestFile('csv')"
                            class="ic--btn ml-2 mr-2 mb-2"
                          >
                            <el-tooltip
                              :content="$t('view_item_page.download_csv_report')"
                              placement="top"
                            >
                              <i class="fas fa-file-csv"></i>
                            </el-tooltip>
                          </button>
                        </el-dropdown-item>
                        <el-dropdown-item class="custom--dropdown--item">
                          <button
                            @click="downloadTestFile('report-pdf')"
                            class="ic--btn ml-2 mr-2 mb-2"
                          >
                            <el-tooltip
                              :content="$t('view_item_page.download_pdf_report')"
                              placement="top"
                            >
                              <i class="fa fa-file-pdf" aria-hidden="true"></i>
                            </el-tooltip>
                          </button>
                        </el-dropdown-item>
                        <el-dropdown-item class="custom--dropdown--item">
                          <button
                            v-if="
                              items.runOptions &&
                              items.runOptions.test_run_origin ===
                                'submitted-katalon-xml'
                            "
                            @click="downloadTestFile('katalonxml')"
                            class="ic--btn ml-2 mr-2 mb-2"
                          >
                            <el-tooltip
                              content="Download your original Katalon test script XML"
                              placement="top"
                            >
                              <img
                                src="../../../assets/images/Katalon.png"
                                alt="Katalon"
                              />
                            </el-tooltip>
                          </button>
                        </el-dropdown-item>
                        <!-- <el-dropdown-item class="custom--dropdown--item">
                          <button
                            v-if="
                              items.runOptions &&
                              items.runOptions.test_run_origin ===
                                'submitted-selenium-ide'
                            "
                            @click="downloadTestFile('JSON')"
                            class="ic--btn ml-2 mr-2 mb-2"
                          >
                            <el-tooltip
                              content="Download your original observer script file"
                              placement="top"
                            >
                              <img
                                src="../../../assets/images/selenium-script-blue.png"
                                alt="Observer script file"
                              />
                            </el-tooltip>
                          </button>
                        </el-dropdown-item> -->
                        <el-dropdown-item class="custom--dropdown--item">
                          <span v-if="items.runTags">
                            <button
                              v-if="
                                items.runTags[0] === 'suggested selectors verified'
                              "
                              @click="downloadTestFile('verified-cypress-script')"
                              class="ic--btn ml-2 mr-2 mb-2"
                            >
                              <el-tooltip
                                :content="
                                  $t(
                                    'view_item_page.download_generated_cypress_verified'
                                  )
                                "
                                placement="top"
                              >
                                <img
                                  src="../../../assets/images/verified-icon-blue.png"
                                  alt="Verified"
                                />
                              </el-tooltip>
                            </button>
                          </span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <button
                      v-if="showRunnerLogButton || items.status !== 'processing'"
                      @click="openScriptModal()"
                      class="ic--btn mr-2"
                    >
                      <el-tooltip
                        :content="$t('view_item_page.view_runner_log')"
                        placement="top"
                      >
                        <small>
                          <i class="fas fa-terminal"></i>
                        </small>
                      </el-tooltip>
                    </button>
                    <div v-show="showSuggestions">
                      <el-tooltip
                        :content="$t('view_item_page.rerun')"
                        placement="top"
                      >
                        <el-button
                          @click="showRerunModalWithCurrentSteps()"
                          class="ic--btn custom--btn-dropdown"
                        >
                          <i class="fas fa-repeat" aria-hidden="true"></i>
                          <!-- {{ $t("view_item_page.rerun") }} -->
                        </el-button>
                      </el-tooltip>
                      <el-tooltip
                        :content="$t('view_item_page.share')"
                        placement="top"
                      >
                        <el-button
                          @click="handleShare('testrun')"
                          class="ic--btn custom--btn-dropdown"
                        >
                        <i class="fas  fa-share-alt"></i>
                          <!-- {{ $t("view_item_page.share") }} -->
                        </el-button>
                      </el-tooltip>
                    </div>
                  </suggestions>
                </div>
    
                <div v-if="showTestEnhancer" class="mx-3 mt-3">
                  <test-enhancer
                    ref="testEnhancer"
                    :get="get"
                    :id="id"
                    :loadingVar="loading2"
                    :loadingSalesforce="loadingSalesforce"
                    :RLJson="RLJson"
                  ></test-enhancer>
                </div>
    
                <div v-if="showOverview" class="mx-3">
                  <overview
                    ref="overview"
                    :items="items"
                    :loading="loading"
                  ></overview>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Console modal start -->
    <div class="console-modal-container">
      <modal :show.sync="showTestConsoleModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("view_item_page.test_runner_console_logs") }}
          </h5>
        </template>
        <div>
          <div class="console-modal">
            <div class="row">
              <div class="col">
                <div v-if="stdout" class="script-first">
                  <h2>{{ $t("view_item_page.console_log_for_stdout") }}</h2>
                  <div class="script">
                    <pre>{{ stdout }}</pre>
                  </div>
                </div>
                <div v-else class="script-first">
                  <h2>
                    {{ $t("view_item_page.no_logs_available_as_script") }}
                  </h2>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div v-if="stderr" class="script-second">
                  <div class="script-heading">
                    <h2>{{ $t("view_item_page.console_log_for_stderr") }}</h2>
                    <i
                      v-if="errCopied"
                      class="fa fa-check"
                      aria-hidden="true"
                      @click="errCopied = false"
                    ></i>
                    <i
                      v-else
                      class="ni ni-single-copy-04"
                      @click="copyStderr"
                    ></i>
                  </div>
                  <div class="script">
                    <pre>{{ renderStderr() }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <!-- Console modal end -->

    <!-- Websocket modal start -->
    <div class="websocket-modal-container">
      <modal :show.sync="showWebSocketLogsModal">
        <template slot="header">
          <h5 id="exampleModalLabel">
            {{ $t("view_item_page.websocket_live_logs") }}
          </h5>
        </template>
        <div>
          <div class="websocket-modal" v-if="webSocketServerData">
            <div class="row">
              <div class="col">
                <div
                  :class="[
                    {
                      'log-status-blink': webSocketLogStatus === 'processing',
                      'log-status': webSocketLogStatus !== 'processing',
                    },
                  ]"
                >
                  <h2 class="text-sm">
                    {{ $t("view_item_page.logs_status") }}
                    <mark v-if="webSocketLogStatus">{{
                      webSocketLogStatus
                    }}</mark>
                  </h2>
                </div>

                <div class="script-cont">
                  <div class="script" id="WebSocketLiveLogs">
                    <pre><div
                      v-for="(val, index) in webSocketServerData"
                      :key="index"
                    >{{ val }}</div></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <!-- Websocket modal end -->

    <div class="add-test-modal">
      <modal :show.sync="rerunTestStepModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            {{ $t("view_item_page.rerun_test_with") }}
          </h5>
        </template>
        <div>
          <div class="add-test-modal">
            <div class="run-name">
              <label class="model-label" for="run_name">
                {{ $t("view_item_page.run_name") }}
              </label>
              <input
                type="text"
                id="run_name"
                name="run_name"
                :placeholder="$t('view_item_page.placeholder_run_name')"
                v-model="run_name"
              />
              <label class="model-label" for="run_description">{{
                $t("view_item_page.description")
              }}</label>
              <textarea
                id="run_description"
                name="run_description"
                rows="2"
                :placeholder="$t('view_item_page.placeholder_run_description')"
                v-model="run_description"
              ></textarea>
              <label class="model-label" for="run_name">
                {{ $t("view_item_page.run_tags") }}
              </label>
              <input
                type="text"
                id="run_tags"
                name="run_tags"
                :placeholder="$t('view_item_page.placeholder_run_tags')"
                v-model="run_tags"
              />
            </div>

            <div class="s-a-tabs">
              <ul class="nav nav-tabs nav-justified">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click.prevent="setActive('settings')"
                    :class="{ active: isActive('settings') }"
                    href="#home"
                    >{{ $t("view_item_page.settings") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click.prevent="setActive('advanced')"
                    :class="{ active: isActive('advanced') }"
                    href="#profile"
                    >{{ $t("view_item_page.advanced") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content py-3" id="myTabContent">
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('settings') }"
                  id="settings"
                >
                  <div class="mt-2">
                    <h4 class="fbb">{{ $t("view_item_page.test_script") }}</h4>
                    <div class="three-input">
                      <div class="tic">
                        <label class="model-label" for="browser_width">{{
                          $t("view_item_page.browser_width")
                        }}</label>
                        <input
                          type="text"
                          id="browser_width"
                          name="browser_width"
                          placeholder="1920"
                          v-model="browser_width"
                        />
                      </div>
                      <div class="tic">
                        <label class="model-label" for="browser_height">{{
                          $t("view_item_page.browser_height")
                        }}</label>
                        <input
                          type="text"
                          id="fname"
                          name="browser_height"
                          placeholder="1980"
                          v-model="browser_height"
                        />
                      </div>

                      <div class="tic">
                        <label class="model-label" for="max_wait_time">{{
                          $t("view_item_page.max_wait")
                        }}</label>
                        <input
                          type="text"
                          id="max_wait_time"
                          name="max_wait_time"
                          placeholder="5.0"
                          v-model="max_wait_time"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('advanced') }"
                  id="advanced"
                >
                  <div class="modal-checks-container">
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="ignore_disabled_elements"
                        name="ignore_disabled_elements"
                        v-model="ignore_disabled_elements"
                      />
                      <label for="ignore_disabled_elements">{{
                        $t("view_item_page.ignore_disabled")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="ignore_invisible_elements"
                        name="ignore_invisible_elements"
                        v-model="ignore_invisible_elements"
                      />
                      <label for="ignore_invisible_elements">{{
                        $t("view_item_page.ignore_invisible")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="collect_page_interaction_data"
                        name="collect_page_interaction_data"
                        v-model="collect_page_interaction_data"
                      />
                      <label for="collect_page_interaction_data">{{
                        $t("view_item_page.collect_interaction")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="extra_element_data"
                        name="extra_element_data"
                        v-model="extra_element_data"
                      />
                      <label for="extra_element_data">{{
                        $t("view_item_page.collect_extra_element")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="all_suggest"
                        name="all_suggest"
                        v-model="all_suggest"
                      />
                      <label for="all_suggest">{{
                        $t("view_item_page.generate_selectors")
                      }}</label>
                    </div>

                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="downweight_strings"
                        name="downweight_strings"
                        v-model="downweight_strings"
                      />
                      <label for="downweight_strings">{{
                        $t("view_item_page.try_to_avoid")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="verify_suggested_selectors"
                        name="verify_suggested_selectors"
                        v-model="verify_suggested_selectors"
                      />
                      <label for="verify_suggested_selectors">{{
                        $t("view_item_page.verify_suggested")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="enable_integrations"
                        name="enable_integrations"
                        v-model="enable_integrations"
                      />
                      <label for="enable_integrations">{{
                        $t("view_item_page.trigger_integrations")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="generate_css_selectors"
                        name="generate_css_selectors"
                        v-model="generate_css_selectors"
                      />
                      <label for="generate_css_selectors">
                        Generate CSS selectors during run (slow, needed for
                        Cypress export)
                      </label>
                    </div>

                    <br />

                    <label class="model-label mt-3" for="testrun_start_url">
                      Environment start URL for test run
                    </label>
                    <input
                      id="testrun_start_url"
                      name="testrun_start_url"
                      placeholder="https://example.com"
                      v-model="testrun_start_url"
                    />

                    <label class="model-label" for="bad_attributes_list">{{
                      $t("view_item_page.avoid_attributes")
                    }}</label>
                    <textarea
                      id="bad_attributes_list"
                      name="bad_attributes_list"
                      rows="2"
                      :placeholder="
                        $t('view_item_page.placeholder_avoid_attributes')
                      "
                      v-model="bad_attributes_list"
                    ></textarea>

                    <label class="model-label" for="good_attributes_list">{{
                      $t("view_item_page.use_attributes")
                    }}</label>
                    <textarea
                      id="good_attributes_list"
                      name="good_attributes_list"
                      rows="2"
                      :placeholder="
                        $t('view_item_page.placeholder_use_attributes')
                      "
                      v-model="good_attributes_list"
                    ></textarea>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="continue_test_suite_on_fail"
                        name="continue_test_suite_on_fail"
                        v-model="continue_test_suite_on_fail"
                      />
                      <label for="continue_test_suite_on_fail">{{
                        $t("view_item_page.continue_test_suite")
                      }}</label>
                    </div>

                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="timeout_cancel_run"
                        name="timeout_cancel_run"
                        v-model="timeout_cancel_run"
                      />
                      <label for="timeout_cancel_run">{{
                        $t("view_item_page.timeout_and_cancel")
                      }}</label>
                      <input
                        class="mod-chk-last-input"
                        type="text"
                        id="timeout_cancel_run_value"
                        name="timeout_cancel_run_value"
                        v-model="timeout_cancel_run_value"
                        :placeholder="
                          $t('view_item_page.placeholder_timeout_and_cancel')
                        "
                      />
                      {{ $t("view_item_page.seconds") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="rerunTestStepModal = false">{{
              $t("view_item_page.close")
            }}</base-button>
            <base-button type="primary" @click="rerunWithCurrentSteps()">{{
              $t("view_item_page.start_rerun")
            }}</base-button>
          </div>
        </template>
      </modal>
    </div>

    <div class="is-scrollable webhook--modal">
      <modal :show.sync="toggleShareModal" v-loading="gettingShareUrl">
        <template slot="header">
          <h5 class="modal-title">
            {{ $t("view_item_page.share_this_item") }}
          </h5>
        </template>
        <div class="">
          <div class="mod--content">
            <el-form
              class="small-spaces"
              @submit.native.prevent="processShare()"
            >
              <label>{{ $t("view_item_page.permissions") }}</label>
              <el-checkbox-group v-model="editedShareValue.permissions">
                <el-checkbox-button
                  v-for="elt in permissionOptions"
                  :label="elt.value"
                  :key="elt.id"
                  >{{ elt.label }}</el-checkbox-button
                >
              </el-checkbox-group>
              <div class="edit-accordion-btns mt-3 ml--2 mb-4">
                <base-button
                  type="primary"
                  native-type="submit"
                  :disabled="!canGenerate"
                >
                  {{ $t("view_item_page.generate_shareable_url") }}
                </base-button>
              </div>
              <template v-if="shareableUrl.length > 0">
                <hr class="my--1" />
                <el-form-item label="Shareable URL" class="mt-3">
                  <el-input
                    :readonly="true"
                    ref="input"
                    placeholder="Shareable url value should be provided here ..."
                    v-model="shareableUrl"
                  >
                    <template slot="append">
                      <el-tooltip content="Copy trigger URL" placement="top">
                        <span
                          class="h--copy"
                          style="cursor: pointer"
                          id="clipboard_copy"
                          data-copytarget="#exampleFormControlTextarea3"
                          @click="handleCopy2()"
                        >
                          <i class="el-icon-copy-document"></i>
                        </span>
                      </el-tooltip>
                    </template>
                  </el-input>
                </el-form-item>
                <el-alert type="warning" show-icon class="mb-3">
                  <template slot="title">
                    Make sure to copy your <code>Shareable url</code> above
                  </template>
                </el-alert>
              </template>
            </el-form>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import { Select, Option } from "element-ui";
import "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import Suggestions from "./Suggestions";
import TestEnhancer from "./TestEnhancer";
import Overview from "./Overview";
import axios from "axios";
import LeftSideBar from "../../../components/SidebarPlugin/LeftSideBar.vue";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export var runActive = false;
export default {
  layout: "DashboardLayout",
  components: {
    Overview,
    TestEnhancer,
    Suggestions,
    BaseButton,
    [Select.name]: Select,
    [Option.name]: Option,
    LeftSideBar,
  },

  mixins: [formMixin],
  data() {
    return {
      // subscription plan info
      subUser: {},
      subPlan: {},
      // For rerun test with current steps start
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      testrun_start_url: "",
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      generate_css_selectors: false,
      enable_integrations: true,
      activeItem: "settings",
      // For rerun test with current steps end
      showRunnerLogButton: true,
      webSocketLogStatus: null,
      hideWebSocketLogBtn: true,
      webSocketServerData: [],
      showWebSocketLogsModal: false,
      rerunTestStepModal: false,
      videoSrc: "",
      errCopied: false,
      stdout: "",
      stderr: "",
      showTestConsoleModal: false,
      showVideoModal: false,
      runActive: this.runActive,
      overviewPieChart: {
        title: {
          text: "Time saved",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["Enhancements", "Auto Heal"],
        },
        series: [
          {
            name: "Traffic Sources",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              {
                value: 335,
                name: "Enhancments",
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 310,
                name: "Auto Heal",
                itemStyle: {
                  color: "#4ac3db",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      },
      colorPalette: "red",
      showSeleniumIcons: false,
      shareableUrl: "",

      log: console.log,
      file: "",
      loading: true,
      showOverview: true,
      showSuggestions: false,
      showTestRunSteps: false,
      showTestEnhancer: false,
      showAllSuggest: false,
      toggleAddStepModal: false,
      toggleReorderStepModal: false,
      date_at: new Date(),
      items: {},
      RLJson: {},
      RLtestcase: {
        sequence: {},
        variables: {},
      },
      RLdatafile: {},
      rl_sequence: {},
      id: "",
      enabled: true,
      list: [
        { name: "Lorem", id: 0 },
        { name: "Ipsum", id: 1 },
        { name: "Dolor", id: 2 },
      ],
      newShareValue: {
        shareableId: "",
        shareableItem: "",
        regenerate: 0,
        permissions: [],
        shareUrl: "",
      },
      editedShareValue: {
        shareableId: "",
        shareableItem: "",
        regenerate: 0,
        permissions: [],
        shareUrl: "",
      },
      permissionOptions: [
        { label: this.$t("view_item_page.read_only"), value: "read" },
        {
          label: this.$t("view_item_page.edit_item_properties"),
          value: "edit",
        },
        { label: this.$t("view_item_page.rerun_this_item"), value: "rerun" },
      ],
      dragging: false,
      canGenerate: true,
      showTestButtons: "test_run_steps",
      disableDwnCoverageBtn: true,
      toggleShareModal: false,
      gettingShareUrl: false,
      lookedSalesforceIntegration: false,
      loading2: false,
      loadingSalesforce: false,
      page_num: 1,
      page_size: 5,
      total_pages: 0,
      total_steps: 0,
    };
  },
  methods: {
    //#region helpers
    handleCopy2() {
      const ipt = this.$refs["input"].$el.children[0];
      ipt.select();
      document.execCommand("copy");
      this.$notify({
        type: "success",
        message: "Copied to the Clipboard!",
      });
    },
    handleShare(val) {
      this.shareableUrl = "";
      this.editedShareValue.shareableItem = val;
      this.editedShareValue.shareableId = this.id;
      this.toggleShareModal = true;
      this.canGenerate = true;
    },
    openWebSocketModal() {
      this.showWebSocketLogsModal = true;
    },
    toggleTestBtn(a) {
      this.showTestButtons = a;
    },
    showRerunModalWithCurrentSteps() {
      this.rerunTestStepModal = true;
      const currentTest = this.items.runOptions;
      this.run_name = currentTest.testSuiteId
        ? currentTest.testSuiteName
        : currentTest.test_run_name;
      this.run_description = currentTest.test_run_description;
      this.ignore_disabled_elements = currentTest.filter_disabled_elements;
      this.ignore_invisible_elements = currentTest.filter_invisible_elements;
      this.run_tags = currentTest.runTags
        .filter((item) => {
          return item !== "test run retry" && item !== "integration enabled";
        })
        .join(", ");

      this.extra_element_data = currentTest.extra_element_data;
      this.all_suggest = currentTest.allsuggest_enabled;
      this.collect_page_interaction_data = currentTest.use_tg_analyze;
      this.handle_failure = currentTest.handle_failure;
      this.enable_integrations = currentTest.runTags.includes(
        "integration enabled"
      );
      this.downweight_strings = !!currentTest["xpath_downweight_strings"];
      this.verify_suggested_selectors =
        !!currentTest["run_selector_verification"];
      this.testrun_start_url = currentTest["testrun_start_url"];
      this.generate_css_selectors = currentTest["generate_css_selectors"];
      this.bad_attributes_list = currentTest["bad_attribute_list"];
      this.good_attributes_list = currentTest["good_attribute_list"];
      if (
        currentTest["user_set_timeout"] !== null &&
        currentTest["user_set_timeout"] !== undefined
      ) {
        this.timeout_cancel_run = true;
        this.timeout_cancel_run_value = currentTest["user_set_timeout"];
      }
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    renderStderr() {
      let txt = this.stderr;
      for (var i = 0; i < txt.length; i++) {
        if (txt.slice(i, i + 5) == "[INFO") {
          // console.log(txt.slice(i, i + 5));
        }
      }
      return txt;
    },
    getOverview() {
      if (this.items) {
        return this.items;
      } else return [];
    },
    formatRequired(row, col, value, index) {
      if (value) {
        return "Y";
      } else {
        return "N";
      }
    },
    goBack() {
      this.$router.push({
        name: "List Items",
      });
    },
    showComponent(name) {
      this.showOverview = false;
      this.showTestRunSteps = false;
      this.showTestEnhancer = false;
      this.showSuggestions = false;
      this.showAllSuggest = false;

      if (this.items.allSuggestResults) {
        this.showAllSuggest = true;
      }

      if (name === "overview") {
        this.showOverview = true;
      } else if (name === "suggestions") {
        this.showSuggestions = true;
      } else if (name === "testenhancer") {
        this.showTestEnhancer = true;
        this.setSalesforceVar();
      }
    },
    removeImage() {
      this.file = null;
    },
    openScriptModal() {
      this.showTestConsoleModal = true;
    },
    copyStderr() {
      navigator.clipboard.writeText(this.stderr).then(() => {
        this.$notify({
          type: "success",
          message: "Copied Successfully.",
        });
      });
      this.errCopied = true;
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.item.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },
    setRunStatus(status) {
      this.item.runStatus = status;
    },
    //#endregion

    //#region async
    async initWebsocketStreaming() {
      const id = this.$route.params.id;
      const auth_token = localStorage.getItem("vue-authenticate.access_token");

      let url = "";
      if (process.env.VUE_APP_WEBSOCKET_API_URL === undefined) {
        url = `wss://${window.location.host}/interceptor/results/v1/logs/${id}`;
      } else {
        url = `${process.env.VUE_APP_WEBSOCKET_API_URL}/results/v1/logs/${id}`;
      }
      const socket = new WebSocket(url);

      const errorMsg = `ERROR while processing log stream for test request ID: ${id}. Internal server error.`;
      const errorMsg2 = `ERROR while processing log stream for test request ID: ${id}. This request ID does not exist or the log stream has expired.`;

      socket.onopen = function (e) {
        socket.send(`AUTH ${auth_token}`);
      };

      socket.onmessage = (event) => {
        if (event.data === errorMsg || event.data === errorMsg2) {
          socket.onclose = function (event) {
            console.log(
              "The connection has been closed successfully View item Page",
              event
            );
          };
          this.hideWebSocketLogBtn = false;
          this.showRunnerLogButton = true;
        } else {
          this.webSocketLogStatus = JSON.parse(event.data).status;
          JSON.parse(event.data).lines.map((item, index) => {
            this.webSocketServerData.push(item.line);
          });
          this.hideWebSocketLogBtn = true;
          this.showRunnerLogButton = false;
        }
      };
    },
    async processShare() {
      this.editedShareValue.permissions =
        this.editedShareValue.permissions.length > 0
          ? this.editedShareValue.permissions.join(",")
          : "";
      const url =
        this.editedShareValue.permissions.length > 0
          ? `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/share/${this.editedShareValue.shareableItem}/${this.editedShareValue.shareableId}?regenerate=${this.editedShareValue.regenerate}&permissions=${this.editedShareValue.permissions}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/share/${this.editedShareValue.shareableItem}/${this.editedShareValue.shareableId}?regenerate=${this.editedShareValue.regenerate}`;
      try {
        this.gettingShareUrl = true;
        await axios
          .get(url)
          .then((res) => {
            if (res.message === "Request failed with status code 404") {
              this.$notify({
                type: "danger",
                message: `An error has occured : ${res.message}`,
              });
            } else {
              if (res.data) {
                this.shareableUrl = res.data.response.shareUrl;
              }
            }
          })
          .catch((e) => {
            this.$notify({
              type: "danger",
              message: `An error has occured : ${e.message}`,
            });
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `An error has occured : ${e.message}`,
        });
      } finally {
        this.canGenerate = false;
        this.gettingShareUrl = false;
        this.editedShareValue = Object.assign({}, this.newShareValue);
      }
    },
    async openVariables() {
      await this.$refs.testEnhancer.toggleVariableModal();
    },
    async downloadEnhancerReport() {
      await this.$refs.testEnhancer.startDownloadingEnhancerReport();
    },
    async openListFileVariables() {
      await this.$refs.testEnhancer.toggleFileVariableModal();
    },
    async openListSalesforceVariables() {
      await this.$refs.testEnhancer.toggleSalesforceVariableModal();
      await this.setSalesforceVar();
    },
    async showRunSteps() {
      await this.$refs.testEnhancer.showRunSteps();
    },
    async rerunWithCurrentSteps() {
      let requestId = this.items.requestId;

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }
      let reRunWithUpdatedFields = {
        runName: this.run_name,
        runDesc: this.run_description,
        runTags: parsedRunTags.join(","),
        browserWidth: this.browser_width,
        browserHeight: this.browser_height,
        stepWait: this.max_wait_time,
        ignoreInvisible: this.ignore_invisible_elements,
        ignoreDisabled: this.ignore_disabled_elements,
        slowHeal: this.extra_element_data,
        allSuggest: this.all_suggest,
        tgAnalyze: this.collect_page_interaction_data,
        runnerUserTimeout: this.timeout_cancel_run_value,
        runSelectorVerification: this.verify_suggested_selectors,
        badAttributeList: this.bad_attributes_list,
        goodAttributeList: this.good_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        generateCssSelectors: this.generate_css_selectors,
      };
      if (this.testrun_start_url && this.testrun_start_url.length > 0) {
        reRunWithUpdatedFields["runStartUrl"] = this.testrun_start_url;
      }

      if (requestId) {
        try {
          await axios
            .post(
              process.env.VUE_APP_API_URL_PREFIX +
                `/testrun/v1/steps/${requestId}/redo`,
              reRunWithUpdatedFields
            )
            .then((response) => {
              if (response.data.requestId && response.status === 202) {
                this.$notify({
                  type: "success",
                  message: `Rerun started successfully ${response.statusText}`,
                });
                this.rerunTestStepModal = false;
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Rerun is unsuccessful`,
          });
        }
      }
    },
    async get() {
      try {
        this.loading2 = true;
        const id = this.$route.params.id;
        await this.$store.dispatch("items/get", id);
        this.items = this.$store.getters["items/item"];
        console.log("items",this.items)

        this.$nextTick(() => {
          this.$refs.suggestions && this.$refs.suggestions.getSuggestions();
          this.$refs.suggestions && this.$refs.suggestions.getAllSuggestions();
          this.$refs.testEnhancer && this.$refs.testEnhancer.initializeState();
        });
        if (this.items.subprocess) {
          this.stdout = this.items.subprocess.stdout;
          this.stderr = this.items.subprocess.stderr;
        }

        this.id = id;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getSubscriptionInfo() {
      try {
        let resp = await axios.get(
          `${process.env.VUE_APP_API_URL_PREFIX}/subscription/v1/plan/user`
        );
        if (resp.status < 400) {
          this.subUser = resp.data.response.user;
          this.subPlan = resp.data.response.user.tierLimits;
        } else {
          console.log("Could not get subscription plan for user");
        }
      } catch (e) {
        console.log(
          "Error when trying to retrieving the subscription plan: ",
          e
        );
      }
    },
    async forToggleCoverageReportBtn() {
      this.loading2 = true;
      this.loading = true;

      const id = this.$route.params.id;
      const params = new URLSearchParams();

      params.append("page_num", this.page_num - 1);
      params.append("page_size", this.page_size);

      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/steps/${id}?${params}`;
      const res = await axios.get(uri);

      if (res.status === 200) {
        this.RLJson = res.data;
        this.RLtestcase = res.data.testcase;
        this.total_pages = res.data.total_pages;
        this.total_steps = res.data.total_steps;
        if (res.data.testcase) {
          if (typeof this.RLtestcase === "undefined") {
            this.RLtestcase = {
              sequence: {},
            };
          }
          this.loading2 = false;
        } else {
          this.disableDwnCoverageBtn = false;
        }
      } else {
        console.error("An error has occured");
      }
      this.loading2 = false;
    },
    async openVideoModal() {
      this.showVideoModal = true;
      const aioToken = localStorage.getItem("vue-authenticate.access_token");
      const requestId = this.items.requestId;
      let videoUrl =
        process.env.VUE_APP_API_BASE_URL +
        `/video-presigned/v1?walServerRequestid=${requestId}&walToken=${aioToken}`;
      await axios
        .get(videoUrl)
        .then((response) => {
          if (response) {
            this.videoSrc = response.data.response;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Video is not available for the test run");
          this.videoSrc = "404";
        });
    },
    async downloadTestFile(type) {
      let message = "";
      if (type === "csv") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testrun/v1/export/${
                this.items.requestId
              }/csv?walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "katalonxml") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "JSON") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-python-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-pypom-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-cypress-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            message = "";
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-sensetalk-script") {
        await axios
            .get(
                process.env.VUE_APP_API_URL_PREFIX +
                `/testscript/v1?requestId=${
                    this.items.requestId
                }&fileType=${type}&walToken=${localStorage.getItem(
                    "vue-authenticate.access_token"
                )}`
            )
            .then((response) => {
              const respdata = response.data;
              const fileName = respdata.fileName;
              const fileURL = respdata.url;
              const fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", fileName);
              document.body.appendChild(fileLink);
              fileLink.click();
              message = ""
            })
            .catch((err) => {
              console.log(err);
            });
      }



      else if (type === "allsuggest-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "verified-cypress-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "verified-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "verified-pypom-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "pdf") {
        return (
          process.env.VUE_APP_API_URL_PREFIX +
          `/testrun/v1/export/${
            this.items.requestId
          }/pdf?walToken=${localStorage.getItem(
            "vue-authenticate.access_token"
          )}`
        );
      } else if (type === "report-pdf") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testrun/v1/export/${
                this.items.requestId
              }/pdf?walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "rebuild-side-file") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=JSON&walToken=${localStorage.getItem(
                "testgold.aioToken"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }

      this.$notify({
        type: "success",
        message: "Downloading file now! " + message,
      });
    },
    async handleSubmit() {
      if (this.$isDemo === 1 && ["1", "2"].includes(this.$route.params.id)) {
        this.$notify({
          type: "danger",
          message: "You are not allowed not change data of default items.",
        });
        return;
      }
      try {
        await this.$store.dispatch("profile/me");
        this.user = {
          ...this.$store.getters["profile/me"],
        };
        if (this.user.roles[0].name === "member") {
          this.$notify({
            type: "danger",
            message: "Oops, you are not authorized to do this action.",
          });
          return;
        }
        if (this.file) {
          await this.$store.dispatch("items/upload", {
            item: this.item,
            image: this.file,
          });
          this.item.image = await this.$store.getters["items/url"];
        }
        this.item.tags = [];
        this.tags.forEach((tag) => {
          this.item.tags.push({
            id: tag,
            type: "tags",
          });
        });
        await this.$store.dispatch("items/update", this.item);
        this.$notify({
          type: "success",
          message: "Item updated successfully.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Oops, cannot update Item. If its persist, please contact support",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    async setSalesforceVar() {
      this.loadingSalesforce = true;
      const isSalesforceTest = await this.items.testStepList.map((item) => {
        if (item.inputValue) {
          if (
            item.inputValue.includes("my.salesforce.com") ||
            item.inputValue.includes("salesforce.com") ||
            item.inputValue.includes("lightning.force.com")
          ) {
            return true;
          }
        }
      });
      if (isSalesforceTest) {
        try {
          await axios
            .get(process.env.VUE_APP_API_URL_PREFIX + `/rl/v1/salesforce`)
            .then((response) => {
              if (response.status === 200) {
                this.lookedSalesforceIntegration = true;
                this.items.salesforcevar = response.data.results;
                this.loadingSalesforce = false;
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `an error occurred while checking the salesforce integration `,
          });
          this.loadingSalesforce = false;
        }
      }
    },
    //#endregion
  },

  watch: {
    webSocketServerData: {
      handler() {
        const container = this.$el.querySelector("#WebSocketLiveLogs");
        this.$nextTick(() => {
          container.scrollTop = container.scrollHeight;
        });
      },
      deep: true,
    },
  },

  async created() {
    await Promise.all([
      this.initWebsocketStreaming(),
      this.get(),
      this.forToggleCoverageReportBtn(),
      this.getSubscriptionInfo(),
    ]);
  },
};
</script>

<style scopped lang="scss">
$pColor: #5e72e4;
$txtColor: #4b4b71;
.italic {
  font-style: italic;
}

.list-group-item {
  margin-bottom: 25px;
  cursor: move;
  border-radius: 0.6rem !important;
  border: solid 0.1px rgba($txtColor, 0.26) !important;

  &:hover {
    box-shadow: 0 10px 30px -10px rgba(#131313, 0.3);
  }
}

.text-small {
  font-size: 0.85rem;
}

.bold {
  font-weight: 600;
  line-height: 0.9rem;
}

.el--flex {
  align-items: center;
}

.trs--container {
  padding: 10px 0 !important;
}

.btn--container {
  margin: 20px 0;
  height: 70px;

  .btn--content {
    float: right;
    border: solid 0.1px rgba($pColor, 0.3);

    .el-card__body {
      padding: 10px !important;

      * {
        font-size: 0.8rem !important;
      }
    }
  }
}

.b {
  border: 1px solid red;
}
.el-table__row {
  cursor: pointer;
}
.button-aligned {
  margin-bottom: 0;
}
.screenshot-div {
  height: auto;
  cursor: pointer;
  overflow: hidden;
}
.click-here {
  cursor: pointer;
}
.click-here a {
  color: black;
  text-decoration: none;
}

.test-run-left,
.test-run-right {
  border: 2px solid gray;
  height: 100%;
  padding: 5px;
}

.test-run-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}

.test-run-row p {
  margin: 0;
  color: black;
  font-size: 13px;
  font-weight: 600;
}

.temp2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.script-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.script-heading i {
  cursor: pointer;
}

.script {
  width: 100%;
  background-color: #464547;
  padding: 0px 5px;
}

.script pre {
  color: #ffffff;
  font-size: 12px;
}
.script-second .script {
  color: #ffffff;
  background-color: #464547;
  font-size: 12px;
  width: 100%;
  height: 350px;
  padding: 0px 5px;
  overflow-y: scroll;
  word-break: break-all;
}

.script-second .script pre {
  /* inline-size: min-content;
  color: red;
  font-size: 12px;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-wrap: break-word; */
  white-space: pre-wrap;
}

.console-modal-container .modal-dialog {
  width: 90vw !important;
}
.console-modal-container .modal-dialog .modal-content {
  height: 600px;
}

.websocket-modal-container .modal-dialog .modal-content {
  max-height: 700px;
}
.websocket-modal .script-cont .script {
  padding: 0px 5px;
  overflow-y: scroll;
  max-height: 350px;
}
.websocket-modal .script-cont .script pre {
  white-space: pre-wrap;
}
.video-loader {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-modal img {
  width: 70px;
  height: 70px;
}

.conditional-download-icons .down-btn-container button {
  background: none;
  border: 0;
  padding-right: 15px;
  outline: none;
}

.conditional-download-icons .dropdown-menu {
  min-width: auto !important;
  clear: both;
  left: -100px;
}

.conditional-download-icons .subdownbtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.conditional-download-icons .dropdown-menu button {
  background: none;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.conditional-download-icons .down-btn-container button:hover {
  opacity: 0.65 !important;
}

.conditional-download-icons .dropdown-toggle::after {
  vertical-align: 0em !important;
}

.top-card .card-body {
  padding: 10px 20px !important;
}

.second-card .card-header {
  padding: 15px 10px !important;
}
.custom--dropdown {
  margin-left: 39vw;
  margin-right: 10px;
}

.custom--dropdown--item {
  padding: 0 !important;
  i {
    margin-right: 0 !important;
  }

  button {
    width: 37px !important;
  }
}

.custom--btn-dropdown {
  width: 39px;
  height: 33px;
}

.second-card-main-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 690px) {
  .second-card-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .down-btn-container {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 815px) {
  .modal-dialog {
    max-width: 80% !important;
    width: 80% !important;
    margin: 1rem 3rem 1rem;
  }
  .modal-dialog img {
    width: 100%;
  }
}

.mid--page {
  .tab--btn {
    padding: 0.8rem 0.6rem;
    border: none;
    border: solid 0.1px rgba($pColor, 0.3);
    color: rgba($pColor, 0.8);
    background: transparent;

    span {
      font-size: 0.9rem !important;
      font-weight: 600;
      color: rgba($pColor, 0.8);
    }

    &:hover {
      background: rgba($pColor, 0.05);
    }

    &.active {
      background: $pColor;
      color: #ffffff;

      span {
        color: #ffffff;
      }
    }

    &.secondary {
      border: solid 0.1px rgba(gray, 0.3);
      color: rgba(gray, 0.8);

      span {
        color: gray;
      }

      &:hover {
        background: rgba(gray, 0.05);
      }

      &.active {
        background: gray;
        color: #ffffff;

        span {
          color: #ffffff;
        }
      }
    }
  }

  &.no-border {
    border: none;
  }
}
.button-menu-item {
  display: flex;
  i{
    margin-left: 25%;
  }
}
.dropdown-menu {
  transform: translateX(-100px);
}

.ic--btn {
  border: solid 0.1px rgba($pColor, 0.5);
  background: transparent;
  padding: 4px 8px;
  border-radius: 0.25rem;

  i {
    color: $pColor;
    font-size: 1.1rem;
  }

  img {
    width: 20px;
  }

  &:hover {
    background: rgba($pColor, 0.05);
  }

  &::after {
    border-top-color: $pColor;
    border-bottom-color: $pColor;
  }

  &.no-border {
    border: none;
    padding: 0;
  }

  &.small {
    i {
      font-size: 0.7rem;
    }

    img {
      width: 15px;
    }
  }
}

.log-status mark,
.log-status-blink mark {
  background: #5f72e4;
  color: #fff;
  border-radius: 3px;
}
.log-status-blink mark {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.modal-body {
  height: auto;
}
</style>
