<template>
  <div class=" suite-list-item-page">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-3">
              <h3 class="mt-3">All Runs</h3>
            </div>
            <div
              class="
                col-9
                text-right
                d-flex
                justify-content-end
                align-items-baseline
              "
            >
              <div class="for-row-selection h-100 mr-2">
                <el-select
                  class="narrow-select select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <text>
                  per page
                </text>
              </div>
              <div class="">
                <base-input
                  v-model="query"
                  type="search"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                  clearable
                />
              </div>
            </div>
          </div>
        </template>

        <div class="re-run-modal-container">
          <modal :show.sync="showReRunModal">
            <template slot="header">
              <h5 class="modal-title" id="exampleModalLabel">
                Retry this test run
              </h5>
            </template>
            <div>
              <div class="re-run-modal">
                <div class="run-name">
                  <label class="model-label" for="run_name"
                    >New run name* :</label
                  >
                  <input
                    type="text"
                    id="run_name"
                    name="run_name"
                    placeholder="Enter a name for this test run"
                    v-model="run_name"
                  /><br />
                  <label class="model-label" for="run_description"
                    >New run description:</label
                  >
                  <textarea
                    id="run_description"
                    name="run_description"
                    rows="2"
                    placeholder="Enter an optional description or comments for this test run"
                    v-model="run_description"
                  ></textarea
                  ><br />
                </div>

                <div class="mt-2">
                  <h4 class="fbb">Test Script Options</h4>
                  <div class="three-input">
                    <div class="tic">
                      <label class="model-label" for="browser_width"
                        >Browser Width</label
                      >
                      <input
                        type="text"
                        id="browser_width"
                        name="browser_width"
                        placeholder="1920"
                        v-model="browser_width"
                      />
                    </div>
                    <div class="tic">
                      <label class="model-label" for="browser_height"
                        >Browser Height</label
                      >
                      <input
                        type="text"
                        id="fname"
                        name="browser_height"
                        placeholder="1980"
                        v-model="browser_height"
                      />
                    </div>

                    <div class="tic">
                      <label class="model-label" for="max_wait_time"
                        >Max Wait Time (sec)</label
                      >
                      <input
                        type="text"
                        id="max_wait_time"
                        name="max_wait_time"
                        placeholder="5.0"
                        v-model="max_wait_time"
                      />
                    </div>
                  </div>
                </div>

                <div class="modal-checks-container">
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="ignore_disabled_elements"
                      name="ignore_disabled_elements"
                      value="Bike"
                      v-model="ignore_disabled_elements"
                    />
                    <label for="ignore_disabled_elements"
                      >Ignore disabled elements</label
                    >
                  </div>
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="ignore_invisible_elements"
                      name="ignore_invisible_elements"
                      value="Bike"
                      v-model="ignore_invisible_elements"
                    />
                    <label for="ignore_invisible_elements"
                      >Ignore invisible elements</label
                    >
                  </div>
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="extra_element_data"
                      name="extra_element_data"
                      value="Bike"
                      v-model="extra_element_data"
                    />
                    <label for="extra_element_data"
                      >Collect extra element data & try to fix broken selectors
                      (slow)</label
                    >
                  </div>
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="all_suggest"
                      name="all_suggest"
                      value="Bike"
                      v-model="all_suggest"
                    />
                    <label for="all_suggest"
                      >Generate selectors for all elements on each visited page
                      (slow)</label
                    >
                  </div>
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="collect_page_interaction_data"
                      name="collect_page_interaction_data"
                      value="Bike"
                      v-model="collect_page_interaction_data"
                    />
                    <label for="collect_page_interaction_data"
                      >Collect page-interaction event data for test case
                      augmentation</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <template slot="footer">
              <base-button type="secondary" @click="showReRunModal = false"
                >Cancel</base-button
              >
              <base-button type="primary" @click="RerunItem"
                >Retry Test Run</base-button
              >
            </template>
          </modal>
        </div>

        <div class="edit-test-modal-container">
          <modal :show.sync="showEditTestModal">
            <template slot="header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update test run name or description
              </h5>
            </template>
            <div>
              <div class="re-run-modal">
                <div class="run-name">
                  <label class="model-label" for="run_name"
                    >New run name*</label
                  >
                  <input
                    type="text"
                    id="run_name"
                    name="run_name"
                    placeholder="Enter a name for this test run"
                    v-model="run_name"
                  /><br />

                  <div class="edit-test-select">
                    <label class="model-label">New run state</label>
                    <el-select
                      class="select-primary w-100 h-100"
                      v-model="editTestOpt.editTestOptCurrent"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in editTestOpt.editTestDropDownOpt"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <br />
                  <label class="model-label" for="run_description"
                    >New run description</label
                  >
                  <textarea
                    id="run_description"
                    name="run_description"
                    rows="5"
                    placeholder="Enter description"
                    v-model="run_description"
                  ></textarea
                  ><br />
                </div>
              </div>
            </div>
            <template slot="footer">
              <base-button type="secondary" @click="showEditTestModal = false"
                >Cancel</base-button
              >
              <base-button type="primary" @click="editItem()"
                >Update</base-button
              >
            </template>
          </modal>
        </div>

        <div class="all-runs-table">
          <!-- {{ log("suite table", suiteData) }} -->
          <el-table
            class="table-responsive table-flush"
            emptyText="No Tests, add them using selenium, cypress, or Katalon/Selenium IDE"
            header-row-class-name="thead-dark-new"
            ref="suiteData"
            :data="suiteData"
            @row-click="onRowClicked"
            @sort-change="sortChange"
            :border="true"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
            <el-table-column
              label="Name"
              min-width="200px"
              prop="runName"
              header-align="center"
              sortable
            >
              <template slot-scope="scope">
                <!-- {{ log("scope.row", scope.row) }} -->
                <span class="test-run-name">{{
                  scope.row.runName ? scope.row.runName : "Test Run"
                }}</span>
                <!-- <span
                    v-if="
                      scope.row.runOptions.run_selector_verification == true
                    "
                    class="test-verified-icon ml-1"
                  >
                    <img
                      src="../../../../assets/images/verified-icon.jpg"
                      alt=""
                      width="18"
                    />
                  </span> -->
                <br />
                <span class="test-descp">{{
                  scope.row.runDesc ? scope.row.runDesc : ""
                }}</span>
                <!-- <div v-if="scope.row.status === 'processing'">
                    <div class="progress-xpath-count">
                      <div>
                        <div class="looping-rhombuses-spinner">
                          <div class="rhombus"></div>
                          <div class="rhombus"></div>
                          <div class="rhombus"></div>
                        </div>
                        <div v-if="scope.row.xpathCount > 0">
                          <span>Processing Path No: </span>
                          <span>{{ scope.row.xpathCount }}</span>
                        </div>
                      </div>
                    </div>
                  </div> -->
                <!-- <div v-else-if="scope.row.status === 'failed'">
                    <span class="failed-status">{{ scope.row.status }}</span>
                  </div> -->
                <!-- <div v-else-if="scope.row.status === 'passed'">
                    <span class="passed-status">{{ scope.row.status }}</span>
                  </div> -->
                <!-- <div v-if="scope.row.runTags">
                    <span class="run-tags-cont"
                      ><i class="ni ni-settings-gear-65 mr-1"></i>Run options
                      &nbsp;
                      <span
                        class="run-tags"
                        v-for="(tags, index) in scope.row.runTags"
                        :key="index"
                      >
                        {{ tags }}</span
                      >
                    </span>
                  </div> -->
                <!-- <div class="test-time-cont">
                    <i class="far fa-clock mr-1"></i>
                    {{ secondsToRealTime(scope.row) }}
                  </div> -->
              </template>
            </el-table-column>
            <el-table-column
              label="Created At"
              prop="testSuiteCreatedAt"
              min-width="150px"
              max-width="250px"
              sortable
              header-align="center"
              :formatter="formatColDate"
            >
            </el-table-column>
            <el-table-column
              label="Total Paths"
              prop="xpathCount"
              min-width="100px"
              sortable
              header-align="center"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Healed Paths"
              prop="healedSelectorCount"
              min-width="100px"
              sortable
              header-align="center"
              align="center"
            >
            </el-table-column>
            <el-table-column label="Actions" min-width="85px" align="center">
              <div slot-scope="{ row }" class="table-actions">
                <el-dropdown trigger="click" class="dropdown" id="btnDrop">
                  <span class="btn btn-sm btn-icon-only" id="btnSpan">
                    <i class="fas fa-ellipsis-v mt-2" id="btnI"></i>
                  </span>
                  <el-dropdown-menu
                    class="dropdown-menu dropdown-menu-arrow show"
                    slot="dropdown"
                    id="btnDropMenu"
                  >
                    <a
                      type="text"
                      @click.stop="editTestModal(row)"
                      class="dropdown-item"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="far fa-edit"></i>
                      Edit
                    </a>
                    <a
                      type="text"
                      class="dropdown-item"
                      data-toggle="tooltip"
                      @click.stop="RerunItemModal(row)"
                      style="cursor: pointer"
                    >
                      <i class="fas fa-redo"></i>
                      Rerun
                    </a>
                    <a
                      type="text"
                      class="dropdown-item"
                      data-toggle="tooltip"
                      @click.stop="CancelrunItem(row)"
                      style="cursor: pointer"
                      v-show="row.status == 'processing'"
                    >
                      <i class="fas fa-ban"></i>
                      Cancel Run
                    </a>
                    <a
                      type="text"
                      @click.stop="deleteItem(row)"
                      class="table-action-delete dropdown-item"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="far fa-trash-alt"></i>
                      Delete
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="
            col-12
            d-flex
            justify-content-center justify-content-sm-between
            flex-wrap
            for-pagination-circle-cursor
          "
        >
          <div class="">
            <p class="card-category">
              Showing {{ total ? from + 1 : 0 }} to {{ to }} of
              {{ total }} entries

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :total="total"
            :page-count="pages"
          />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import axios from "axios";

export default {
  layout: "DashboardLayout",
  props: {
    suiteId: String,
    suiteItems: Object,
    loading: Boolean,
  },
  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      editTestOpt: {
        editTestOptCurrent: "Select",
        editTestDropDownOpt: ["passed", "failed", "finished"],
      },
      forEditTest: "",
      forEditTestRowReqId: "",
      showEditTestModal: false,
      forReRunTest: "",
      showReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "true",
      ignore_invisible_elements: "true",
      collect_page_interaction_data: "true",
      extra_element_data: false,
      downweight_strings: false,
      all_suggest: false,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timout_cancel_run: "",
      timout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      filesList: [],
      addTestModal: false,
      log: console.log,
      query: null,
      selectedRows: [],
      sort: "created_at",
      total: 0,
      filteredTotal: [],
      filteredResults: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      // suiteItems: {},
      suiteData: [],
      user: null,
      polling: 5000,
    };
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "geSuitetList",
      immediate: false,
      deep: true,
    },
  },
  created() {
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },

  methods: {
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      var dis_sec = Math.ceil(secs % 60);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(a) {
      let sec = Number(a.finishedAt - a.startedAt);
      return this.hhmmss(sec);
    },
    editTestModal(row) {
      this.run_name = row.runName;
      this.run_description = row.runDesc;
      this.editTestOpt.editTestOptCurrent = row.status;
      this.forEditTestRowReqId = row.requestId;
      this.showEditTestModal = true;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    // tableRowClassName({ row, rowIndex }) {
    //   if (this.items[0].status === "processing" && rowIndex === 0) {
    //     return "onlyForRunningTestRow";
    //   }
    //   return "";
    // },
    onRowClicked(item, index, event) {
      const element = event.srcElement.id;
      if (element === "") {
        this.$router.push({
          name: "View Item",
          params: {
            id: item.requestId,
          },
        });
      }
    },
    getListDebounced: _.debounce(function() {
      this.geSuitetList();
    }, 300),

    pollData() {
      this.polling = setInterval(() => {
        this.getListDebounced();
      }, 5000);
    },

    formatColDate(row, col, value, index) {
      let date = value.slice(0, 10);
      let time = "";
      for (var i = 0; i < value.length; i++) {
        if (value[i] == "T") {
          time = value.slice(i + 1, i + 6);
        }
      }
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }
      let html = (
        <div>
          <div>{date}</div>
          <div>
            {time} {time_zone}
          </div>
        </div>
      );
      return html;
    },
    async geSuitetList() {
      if (this.suiteItems) {
        this.suiteData = Object.values(this.suiteItems);
        // console.log(Object.values(this.suiteData));
        this.total = this.filteredResultSet.length;
        // Object.values(this.suiteItems).map((v, i) => {
        //   console.log(v);
        //   this.suiteData.push(v);
        // });
      }
      // console.log("suiteItems", this.suiteItems);
      // if (this.suiteId) {
      //   axios
      //     .get(
      //       process.env.VUE_APP_API_URL_PREFIX + `/testsuite/v1/${this.suiteId}`
      //     )
      //     .then((response) => {
      //       console.log(
      //         "getting data fron test suite id",
      //         response.data.response
      //       );
      //       this.suiteItems = response.data.response;
      //       this.total = this.filteredResultSet.length;
      //     })
      //     .catch((error) => {
      //       this.$notify({
      //         type: "danger",
      //         message: "Oops, something went wrong!",
      //       });
      //     });
      // }
      // if (true) {
      //   axios
      //     .get(
      //       `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/testsuiterun/ada8e6068857703915bee60e69cd0f79`
      //     )
      //     .then((response) => {
      //       console.log("getting data fron test suite id", response);
      //       // this.suiteItems = response.data.response;
      //       // this.total = this.filteredResultSet.length;
      //     })
      //     .catch((error) => {
      //       this.$notify({
      //         type: "danger",
      //         message: "Oops, something went wrong!",
      //       });
      //     });
      // }
    },
    parseBrowserWidth(strWidth) {
      const width = parseInt(strWidth, 10);
      if (width === undefined || width === null) return 1920;
      if (width > 1920) return 1920;
      if (width < 800) return 800;
      return width;
    },
    parseBrowserHeight(strHeight) {
      const height = parseInt(strHeight, 10);
      if (height === undefined || height === null) return 1080;
      if (height > 1080) return 1080;
      if (height < 600) return 600;
      return height;
    },
    parseStepWait(strWait) {
      const waitSec = parseFloat(strWait);
      if (waitSec && waitSec <= 30.0 && waitSec >= 0.5) {
        return waitSec;
      }
      return 5.0;
    },
    parseBrowserResolution(browserResolution) {
      const splitValue = browserResolution.split("x");
      return {
        browserWidth: parseInt(splitValue[0], 10),
        browserHeight: parseInt(splitValue[1], 10),
      };
    },
    createRedoRequestBody(states) {
      const browserRes = this.parseBrowserResolution(
        states?.runOptions.runner_resolution ?? "1920x1080"
      );
      const step = parseFloat(states?.runOptions.runner_step_wait ?? "5.0");
      return JSON.stringify({
        runName: states.runName,
        runDesc: states.runDesc,
        browserWidth: this.parseBrowserWidth(browserRes.browserWidth),
        browserHeight: this.parseBrowserHeight(browserRes.browserHeight),
        stepWait: this.parseStepWait(step),
        ignoreInvisible: states?.runOptions.filter_invisible_elements ?? true,
        ignoreDisabled: states?.runOptions.filter_disabled_elements ?? true,
        slowHeal: states?.runOptions.handle_failure === "suggest-xpaths",
        allSuggest: states?.runOptions.allsuggest_enabled ?? false,
        tgAnalyze: states?.runOptions.use_tg_analyze ?? true,
      });
    },

    async RerunItemModal(row) {
      console.log(row);
      this.forReRunTest = row;
      this.showReRunModal = true;
      this.run_name = row.runOptions.test_run_name;
      this.run_description = row.runOptions.test_run_description;
      this.ignore_disabled_elements = row.runOptions.filter_disabled_elements;
      this.ignore_invisible_elements = row.runOptions.filter_invisible_elements;
      this.extra_element_data = true;
      this.all_suggest = row.runOptions.allsuggest_enabled;
      this.collect_page_interaction_data = row.runOptions.use_tg_analyze;
      this.handle_failure = row.runOptions.handle_failure;
    },

    async RerunItem() {
      const row = this.forReRunTest;
      const requestId = row.requestId;
      const headers = {
        "Content-Type": "application/json",
      };
      let reRunWithUpdatedFields = JSON.stringify({
        runName: this.run_name,
        runDesc: this.run_description,
        browserWidth: this.browser_width,
        browserHeight: this.browser_height,
        stepWait: this.max_wait_time,
        ignoreInvisible: this.ignore_invisible_elements,
        ignoreDisabled: this.ignore_disabled_elements,
        slowHeal: this.handle_failure === "suggest-xpaths",
        allSuggest: this.all_suggest,
        tgAnalyze: this.collect_page_interaction_data,
      });
      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + `/testrun/v1/redo/${requestId}`,
          reRunWithUpdatedFields,
          { headers }
        )
        .then((response) => {
          if (response.status != 202) {
            this.$notify({
              type: "warning",
              message: "Could not rerun test!",
            });
          } else {
            this.$notify({
              type: "success",
              message: "Rerunning test!",
            });
            this.forReRunTest = "";
          }
        });
      this.showReRunModal = false;
    },

    async CancelrunItem(row) {
      const requestId = row.requestId;

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX +
            `/testrun/v1/cancel/${requestId}`,
          { headers }
        )
        .then((response) => {
          this.$notify({
            type: "success",
            message: "Stopped test run!",
          });
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            message: "Error stopping test run!",
          });
        });
    },

    async deleteItem(row) {
      const requestId = row.requestId;
      // console.log("requestId: ", requestId);
      // if (this.$isDemo == 1 && ["1", "2"].includes(id)) {
      //   this.$notify({
      //     type: "danger",
      //     message: "You are not allowed not change data of default items.",
      //   });
      //   return;
      // }
      const confirmation = await swal.fire({
        title: "Test Run " + row.runName + " will be removed?",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep the test",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          // TODO: check with backend on permissions to delete
          // await this.$store.dispatch("profile/me");
          // this.user = await { ...this.$store.getters["profile/me"] };
          // if (this.user.roles[0].name === "member") {
          //   this.$notify({
          //     type: "danger",
          //     message: "Oops, you are not authorized to do this action.",
          //   });
          //   return;
          // }
          await this.$store.dispatch("items/destroy", requestId);
          await this.geSuitetList();
          this.$notify({
            type: "success",
            message: "Test deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    viewItem(row) {
      this.$router.push({ name: "View Item", params: { id: row.requestId } });
    },
    async editItem() {
      console.log("editItem is being called");
      let formValues = {
        nameChange: this.run_name,
        statusChange: this.editTestOpt.editTestOptCurrent,
        descChange: this.run_description,
      };

      if (formValues) {
        const params = {
          id: this.forEditTestRowReqId,
          values: formValues,
        };
        this.showEditTestModal = false;
        this.run_name = "";
        this.editTestOpt.editTestOptCurrent = "";
        this.run_description = "";
        await this.$store.dispatch("items/update", params);
        await this.geSuitetList();
        this.$notify({
          type: "success",
          message: this.run_name + " updated successfully.",
        });
      }
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.geSuitetList();
    },
  },
  computed: {
    filteredResultSet() {
      let suitItemForTable = Object.values(this.suiteItems);
      return suitItemForTable.filter((item) => {
        if (this.query) {
          const query = this.query.toLowerCase();

          if (item.runName.toLowerCase().includes(query)) return item;

          let match = null;
          if (item.runTags) {
            item.runTags.filter(function(str) {
              match = str.includes(query);
            });
            if (match) return item;
          }

          if (item.runDesc) {
            if (item.runDesc.toLowerCase().includes(query)) return item;
          }

          if (item.status) {
            if (item.status.toLowerCase().includes(query)) return item;
          }
        } else {
          return item;
        }
      });
    },

    filteredList() {
      // console.log("this.filteredResults", this.filteredResults);
      return this.filteredResults.slice(this.from, this.to);
    },

    pages() {
      return Math.ceil(this.filteredResults.length / this.pagination.perPage);
    },

    from() {
      let lowBound =
        this.pagination.perPage * (this.pagination.currentPage - 1);
      if (this.filteredResults.length < lowBound) {
        lowBound = this.filteredResults.length;
      }
      return lowBound;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.filteredResults.length < highBound) {
        highBound = this.filteredResults.length;
      }
      return highBound;
    },
  },
};
</script>

<style></style>
