import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_URL_PREFIX;

axios.defaults.validateStatus = (status) => {
  return (status === 400) || (status >= 200 && status < 300) || (status === 500) ;
}

function list(params) {
  const options = {
    params: params,
    validateStatus: false,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/results/v1/user`, options).then((response) => {
    if (response && response.status == 404) {
      return {
        list: [],
        meta: {},
      };
    } else
      return {
        list: Object.values(response.data.response),
        meta: response.data.meta,
      };
  });
}

function get_screenshot(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const id = params.id;
  const walserver_request_id = params.walserver_request_id;
  const sent_at = params.sent_at;
  let processing_type = params.processing_type;
  const selector_type = params.selector_type;
  if (processing_type.endsWith("train")) {
    processing_type = "train";
  } else {
    processing_type = "heal";
  }
  let url_selector_type = "xpath";
  if (selector_type.includes("css")) {
    url_selector_type = "css";
  }

  return axios
    .get(
      `${url}/results/v1/${processing_type}/${url_selector_type}/${id}?screenshots=annotated&walServerRequestId=${walserver_request_id}&sentAt=${sent_at}`,
      options
    )
    .then((response) => {
      const data = response.data;
      const pType = response.data.processingType;
      const toReturn = {};

      if (data) {
        if (pType === "xpath-heal" || pType === "css-heal") {
          toReturn.isMultiple = true;
          toReturn.picture = [];
          toReturn.picture.push(data.initialItem.screenshotB64);
          toReturn.picture.push(data.followupItem.screenshotB64);
        } else {
          toReturn.isMultiple = false;
          toReturn.picture = data.screenshotB64;
        }
      }
      return toReturn;
    });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios
    .get(`${url}/results/v1/test/${id}`, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
}

function add(params) {
  params = params["headers"];
  const file = params["file"];
  params["file"] = "";

  let formData = new FormData();
  formData.append("file", file);
  params["Content-Type"] = "multipart/form-data";

  return axios
    .post(`${url}/testscript/v1`, formData, {
      headers: params,
    })
    .then((response) => {
      // return response;
    });
}

function update_test_enhancer(params) {
  const id = params["id"];
  let data = params;
  data["action"] = params["action"];
  // interceptor/rl/v1/a6b6dbc5829c382e1a4d6cc337986a5e

  return axios.post(`${url}/rl/v1/${id}`, data).then((response) => {
    return response;
  });
}

function run_test_enhancer(params) {
  const id = params["id"];
  let data = params;
  let action = params["action"];
  if (action === "start") {
    return axios.post(`${url}/rl/v1/agent/${id}`, data).then((response) => {
      return response;
    });
  } else if (action === "stop") {
    return axios.put(`${url}/rl/v1/agent/${id}`, data).then((response) => {
      return response;
    });
  } else if (action === "delete") {
    return axios
      .delete(`${url}/rl/v1/steps/${id}?index=${params["index"]}`)
      .then((response) => {
        return response;
      });
  } else if (action === "add") {
    return axios.post(`${url}/rl/v1/steps/${id}`, data).then((response) => {
      return response;
    });
  } else if (action === "edit") {
    return axios.put(`${url}/rl/v1/steps/${id}`, data).then((response) => {
      return response;
    });
  }
}

function update(params) {
  const id = params["id"];
  const values = params["values"];

  return axios.put(`${url}/testrun/v1/state/` + id, values);
}

function destroy(id) {
  const options = {
    softDelete: "delete",
  };

  return axios.put(`${url}/testrun/v1/state/${id}`, options);
}

function upload(item, file) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", file);

  return axios
    .post(`${url}/uploads/items/${item.id}/image`, bodyFormData)
    .then((response) => {
      return response.data.url;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  get_screenshot,
  update_test_enhancer,
  run_test_enhancer,
};
