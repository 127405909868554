<template>
  <div class="wrapper-graph">
    <Network class="network" ref="myChart" :nodes="beforeDrawingNodes()" :edges="beforeDrawingEdges()"
      :options="network.options" @click="networkEvent('click')" @double-click="onDoubleClick"
      @oncontext="networkEvent('oncontext')" @hold="networkEvent('hold')" @release="networkEvent('release')"
      @select="networkEvent('select')" @select-node="networkEvent('selectNode')" @select-edge="networkEvent('selectEdge')"
      @deselect-node="networkEvent('deselectNode')" @deselect-edge="networkEvent('deselectEdge')"
      @drag-start="networkEvent('dragStart')" @dragging="networkEvent('dragging')" @drag-end="networkEvent('dragEnd')"
      @hover-node="networkEvent('hoverNode')" @blur-node="networkEvent('blurNode')"
      @hover-edge="networkEvent('hoverEdge')" @blur-edge="networkEvent('blurEdge')" @zoom="networkEvent('zoom')"
      @show-popup="networkEvent('showPopup')" @hide-popup="networkEvent('hidePopup')"
      @start-stabilizing="networkEvent('startStabilizing')"
      @stabilization-progress="networkEvent('stabilizationProgress')" @stabilization-iterations-done="networkEvent('stabilizationIterationsDone')
        " @stabilized="networkEvent('stabilized')" @resize="networkEvent('resize')"
      @init-redraw="networkEvent('initRedraw')" @before-drawing="networkEvent('beforeDrawing')"
      @after-drawing="afterDrawing" @animation-finished="networkEvent('animationFinished')"
      @config-change="networkEvent('configChange')" @nodes-mounted="networkEvent('nodes-mounted')"
      @nodes-add="networkEvent('nodes-add')" @nodes-update="networkEvent('nodes-update')"
      @nodes-remove="networkEvent('nodes-remove')" @edges-mounted="networkEvent('edges-mounted')"
      @edges-add="networkEvent('edges-add')" @edges-update="networkEvent('edges-update')"
      @edges-remove="networkEvent('edges-remove')">
    </Network>

    <hr />
  </div>
</template>

<script>
import { Network } from "vue2vis";
import { DataSet } from "vue2vis";
export default {
  props: {
    items: {
      type: Object,
      default: () => [],
      description: "List of items to show",
    },
    highlight_edges: {
      type: Array,
      default: () => null,
      description: "List of items highlights",
    }
  },
  data: () => ({
    networkEvents: "",
    counterForNodes: 0,
    counterForEdges: 0,
    network: {
      nodes: [],
      edges: [],

      options: {
        height: "",
        layout: { randomSeed: 42 },
        interaction: {
          navigationButtons: true,
        },
        nodes: {
          font: {
            color: "#343434",
            size: 20, // px
            face: "verdana",
            background: "none",
            strokeWidth: 0, // px
            strokeColor: "#ffffff",
            align: "center",
            multi: false,
            vadjust: 0,
            bold: {
              color: "#343434",
              size: 140, // px
              face: "verdana",
              vadjust: 0,
              mod: "bold",
            },
          },
          borderWidth: 4,
          size: 100,
          color: {
            border: "#406897",
            background: "#6AAFFF",
          },
          shapeProperties: {
            useBorderWithImage: true,
          },
        },
        edges: {
          hoverWidth: 2,
          arrows: {
            to: {
              enabled: true,
            },
          },
        },
        physics: {
          enabled: true,
          solver: "repulsion",
          repulsion: {
            centralGravity: 0,
            springLength: 500,
            springConstant: 0.015,
            nodeDistance: 500,
            damping: 1,
          },
        },
      },
    },
    highlight_edgesValue: null,
  }),
  components: {
    Network,
  },
  created() {
    this.highlight_edgesValue = this.$props.highlight_edges
  },
  methods: {
    networkEvent(eventName) {
      if (this.networkEvents.length > 500) this.networkEvents = "";
      this.networkEvents += `${eventName}, `;
      // if (eventName === "afterDrawing") this.drawSwimlanes();
    },
    beforeDrawingNodes() {
      console.log("beforeDrawingNodes", this.$props.items)
      console.log("window", window.innerHeight)
      console.log("canvas", document.getElementsByTagName("canvas"))
      this.network.options.height= (window.innerHeight - 160).toString()
      if (this.counterForNodes < 1) {
        for (let node of this.$props.items.nodes) {
          console.log(node)
          if (node.screenshot !== null) {
            if (node.screenshot.indexOf("data:image/png;base64,") === -1) {
              node.image = `data:image/png;base64, ${node.screenshot}`;
              node.shape = "image";
            }
          }

        }
        let nodes = this.$props.items.nodes;
        const data = new DataSet();
        data.add(nodes);
        this.counterForNodes = this.counterForNodes + 1;
        return data;
      }
    },
    onDoubleClick(ctx) {
      console.log("onDoubleClick", ctx)
      if (ctx.nodes.length > 0) {
        console.log(ctx.nodes);
        const list = [];
        list.push(this.$refs.myChart._data.visData.nodes._data);
        console.log(list[0]);
        const img = list[0][ctx.nodes[0]];
        let imgModal = document.getElementById("imgModal");
        let modalImg = document.getElementById("screenshot");
        imgModal.style.display = "block";
        modalImg.src = img.image;
      }
    },
    beforeDrawingEdges() {

      if (this.counterForEdges < 1) {
        if (this.$props.highlight_edges != null) {
          for (let index = 0; index < this.$props.highlight_edges.length; index++) {
            this.highlight_edgesValue = `${this.$props.highlight_edges[index][0]}-${this.$props.highlight_edges[index][1]}`
          }
        }
        for (let link of this.$props.items.links) {
          link.from = link.source;
          //console.log(link.from);
          link.to = link.target;
          let title = link.description;
          if (!title) {
            title = `${link.command}: ${link.element}`;
          }
          link.title = title;
          // link.smooth = true;
          link.length = 200;
          link.color = {color:"#000000"};
          if (link.users_pct !== undefined) {
            link.value = link.users_pct * 50
          }

          if (this.highlight_edgesValue != null) {
            if (this.highlight_edgesValue.includes(`${link.from}-${link.to}`) || this.highlight_edgesValue.includes(`${link.to}-${link.from}`)) {
              link.color = {color:"#0000ff"};
              console.log("setting highlight")
            }
          }
          if (link.users_pct) {
            title += ` Users: ${(link.users_pct * 100).toFixed(2)} %`
          }
          link.title = title
          if (title.split('').length > 20) {
            let titleShort = title.split('', 20)
            link.label = titleShort.join('') + "..."
          } else {
            link.label = title
          }
        }
        let edges = this.$props.items.links;
        console.log("beforeDrawingEdges", edges)
        const data = new DataSet(edges);
        // data.add(edges);
        this.counterForEdges = this.counterForEdges + 1;
        return data;
      }
      document.getElementsByTagName("canvas")[0].height = window.innerHeight + 100
    },
    afterDrawing(ctx) {
      ctx.strokeStyle = "green";
      ctx.fillStyle = "green";
      this.network.nodes.map(
        function (node) {
          let n = this.$refs.network.getPositions(node.id)[node.id];
          ctx.strokeRect(n.x - 50, n.y - 50, 100, 100);
          return null;
        }.bind(this)
      );
      // ctx.strokeRect(50, 50, 100, 100);
      ctx.stroke();
    },
    updataNetwork() {
      console.log("updataNetwork:", this.$refs.myChart)
      this.counterForEdges = 0
      this.counterForNodes = 0
      const network = this.$refs.myChart.network;

      // Appeler la méthode "updateData" pour recharger le graphique
      network.setData({ nodes: this.beforeDrawingNodes(), edges: this.beforeDrawingEdges() });

    },
  },
};
</script>

<style lang="scss" >
/* @import "~vue-visjs/dist/vue-visjs.css"; */
.network {
  font-family: Verdana;
}

.wrapper-graph {
  // padding: 10px 10px;
  text-align: center;
}

.events {
  text-align: left;
  height: 70px;
}

.network {
  width: 100%;
  border: 1px solid #ccc;
  margin: 5px 0;
}

hr:nth-child(2n){
    display: none;
  }

.vis-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 5px;
  white-space: nowrap;
  font-family: verdana;
  font-size: 14px;
  color: #000000;
  background-color: #f5f4ed;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #808074;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 5;
}
.vis-zoomExtends {
  position: absolute;
  cursor: pointer;
  right: 1%;
  top: 1%;
  width: 50px;
  height: 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWElEQVR4nO1Z224TMRD1f5TMNJTLa4v4CwjrST4FSgV9qUSF+BMe+8ZbyldQKGrK5bkREvB+0DibaLNXL1u667BHGinarD3nrO3xjG1Mjx7dBEfYJcEXtpjpbxMSOMIuC65YADUS/BhM8NCESJ5DEsEF5IMQwRXkOy2CxrjNFvMVUYs5CU4SpE/W/hdc0QhD0xVQhOdJcjzBHguOEs+O3LOECLLYN13B1hh3yeIrCz4sQ2ZagHs2wR4JzvRdbWO6jDwBQYF7AS0j+BEgwUEijB6Y0EC6Nwgu1PR3uyFS8OwmSQwsdtTnrRHuNOqIBA+Wmw9ZfLw2htV+z5c7uu4djcnH8/jMt+0gwogE71nwW40sTsnicQ3fn5JpSW0RafIuPfAsSkjwuiSRO/bpg1NpRy0RGfI1GuuXr8xGPUeC/0ZEptHC4VsNg3nGYzxNLu542pQKYIvpSrDFjuujoH/1nWo7L50JroatIpC1i9UHsPjlUQ/8XL0vLszW9XdZPAJagHddgMWsVlVFgnds8abADtemkMVpnSlEi0LosKh/53u9fXUwyYiosYh1gXqMwCOfvji7Hr0jYTMRguMSAa/+OfmSs51z4wk3EhZTtyYWNvX98gqy+NyIfK6IssVzzWDBZWPyS+jpgRbgN1nDbi1q7H2OsG3awvYT3CfB9yAK+Dyw4GUiCLwwoYFDLym5F9AyghqBocU9svjmksF4x84TENcaM323U5GJkoe7cdqRFpApUbt0uEsjDOser7e6SW3cBcdGXDFtxCXfRlyzronQsBriRXeP/wl/AKqUUR3/pF7WAAAAAElFTkSuQmCC");
}
.vis-zoomExtends:hover {
border: 2px solid #0000ff;
border-radius: 32px;
}
</style>
