<template>
    <div class="right-content mt-2 px-3">
        <div class="c--breadcrumb mb-3 ml-4">
            <router-link to="/">
            <i class="el-icon-house"></i>
            </router-link>
            <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
            <span> Virtualize</span>
      </div>
      <div class="mid--page_container vtza-h mt-4 mb-4">
        <div class="mid--page my-3 pb-3">
          <div class="mp--l">
            <label class="model-label mr-2 " for="Project" > Project</label>
            <div class="flex">
                <el-select id="Project" v-model="selectPoject" placeholder="Select project" @change="getApiCall">
                    <el-option
                    v-for="item in listProject"
                    :key="item.projectId"
                    :label="item.name"
                    :value="item.projectId">
                    </el-option>
                </el-select>
                <el-tooltip   class="item ml-2" effect="light" content="Project settings" placement="top">
                    <el-button :disabled="!selectPoject ? true : false" icon="el-icon-s-tools" size="small" @click="showProject"></el-button>
                </el-tooltip>
            </div>
          </div>
          <div class="mp--r">
            <label class="model-label mr-2 " for="Project" > Status</label>
            <div>
                <el-tag
                    class="mr-2"
                    type="info"
                    effect="plain">
                    {{listProject.length}} <i class="el-icon-coin"></i>
                </el-tag>
                <el-tag
                    type="info"
                    class="mr-2"
                    effect="plain">
                    {{ totalCallCount == 0 ? 'No data received' : `${totalCallCount} data received`}} <i class="el-icon-time"></i>
                </el-tag>
                <el-button v-show="error.length > 0" type="primary" plain size="small" @click="showIssues = !showIssues">{{showIssues == true ? 'Hide issues' : 'Show Issues'}} <i class="el-icon-warning-outline"></i></el-button>
            </div>
          </div>
        </div>
        <div v-show="showIssues">
          <el-alert
              :title="error.toString()"
              type="info"
              :closable="false"
              show-icon
          >
          </el-alert>
        </div>
        <div class="mid--page pb-3 my-3" style="border: none;">
            <div class="mp--l">
                <el-input
                    placeholder="Search"
                    v-model="search"
                >
                    <i slot="suffix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </div>
            <div class="mp--r" style="flex-direction: row; align-items: center;">
                <span class="pag--details mr-3">{{ pagination.perPage ? ((pagination.currentPage *
                pagination.perPage) - pagination.perPage) + 1 : 1 }} {{
                    $t("list_item_page.to")
                    }}
                {{ pagination.currentPage && pagination.perPage ? pagination.currentPage * pagination.perPage :
                    10 }}
                {{ $t("list_item_page.of") }} {{ totalCallCount || 0 }}
                {{ $t("list_item_page.entries") }}
                </span>
                <el-select v-model="pagination.perPage" @input="getApiCall" placeholder="Per page" class="small--select">
                <el-option v-for="(item, id) in pagination.perPageOptions" :key="id" :label="item"
                    :value="item"></el-option>
                </el-select>
            </div>
        </div>
      </div>
      <div class="vtza-h flex py-3">
        <div id="vtza_c_l" class="vtza-c-l">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span style="font-weight: 600"> <i class="fa fa-filter"></i> Filters</span>
                    <el-button style="float: right; " type="danger" plain size="mini">Clear <i class="el-icon-close"></i></el-button>
                </div>
                <el-collapse v-model="activeNames">
                    <el-collapse-item title="Time range"  name="5">
                        <el-button class="mb-4 ml-0" size="mini" style="float: right;" type="primary" plain @click="customDate = !customDate"> {{customDate === false ? 'Custom' : 'Default'}}</el-button>
                        <el-button icon="el-icon-refresh-right" size="mini" plain style="float: left;" class="ml-0" @click="getApiCall"> Refesh </el-button>
                        <div v-if="customDate === false" style=" width: 100%;">
                            <el-select v-model="selectTime" style="height: 35px !important;" placeholder="select a time period" @change="setByFilter" >
                                <el-option v-for="(item, id) in listTime" :key="id" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div v-else>
                            <el-date-picker
                                @change="setByFilter"
                                class="mb-2"
                                style="height: 35px; width: 100%;"
                                v-model="datePikerStart"
                                :disabledDate="disabledDateStart"
                                :picker-options="disabledDateStart"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="Start">
                            </el-date-picker>
                            <el-date-picker
                                @change="setByFilter"
                                style="height: 35px; width: 100%;"
                                v-model="datePikerEnd"
                                @focus="disabledDate"
                                :disabledDate="disabledDate"
                                :picker-options="disabledDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="End">
                            </el-date-picker>
                        </div>
                    </el-collapse-item>
                    <!-- <el-collapse-item title="Hosts" name="1">
                        <div class="flex" style="border: none; justify-content: space-between; align-items: center">
                            <div class="flex" style="align-items: flex-start; justify-content: center" >
                                <el-checkbox ></el-checkbox>
                                <span class="run-tags mx-2 mt-0" style="font-weight: 600; max-width: 160px; min-width: 160px" >https://demos.creative-tim.com/</span>
                            </div>
                            <p>{{ checkedGetValue }}</p>
                        </div>
                    </el-collapse-item> -->
                    <el-collapse-item title="Response Codes" name="2">
                        <div v-for="n in statusArray" :key="n.value" class="flex" style="border: none; justify-content: space-between; align-items: center">
                            <div class="flex" >
                                <el-checkbox v-model="n.status"></el-checkbox>
                                <el-tag
                                    :type="getCodeType(n.value)"
                                    size="small"
                                    class="tag-virsz ml-2"
                                >
                                    {{n.type}}
                                </el-tag>
                            </div>
                            <div class="mp--r">
                                <p>{{ checkedPatchValue }}</p>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="HTTP Methods (5)" name="3">
                        <template slot="title">
                            HTTP Methods <span class="ml-1 danger"> (5)</span>
                        </template>
                        <div class="flex" style="border: none; justify-content: space-between; align-items: center">
                            <div class="flex" >
                                <el-checkbox v-model="checkedGet" @change="getApiCall()"></el-checkbox>
                                <el-tag
                                    type="success"
                                    size="small"
                                    class="tag-virsz ml-2"
                                    effect="dark">
                                    Get
                                </el-tag>
                            </div>
                            <div class="mp--r">
                                <p>{{ checkedGetValue }}</p>
                            </div>
                        </div>
                        <div class="flex" style="border: none; justify-content: space-between; align-items: center">
                            <div class="flex" >
                                <el-checkbox v-model="checkedPost" @change="getApiCall()" ></el-checkbox>
                                <el-tag
                                    size="small"
                                    class="tag-virsz ml-2"
                                    effect="dark">
                                    Post
                                </el-tag>
                            </div>
                            <div class="mp--r">
                                <p>{{ checkedPostValue }}</p>
                            </div>
                        </div>
                        <div class="flex" style="border: none; justify-content: space-between; align-items: center">
                            <div class="flex" >
                                <el-checkbox v-model="checkedPut" @change="getApiCall()" ></el-checkbox>
                                <el-tag
                                    type="warning"
                                    size="small"
                                    class="tag-virsz ml-2"
                                    effect="dark">
                                    Put
                                </el-tag>
                            </div>
                            <div class="mp--r">
                                <p>{{ checkedPutValue }}</p>
                            </div>
                        </div>
                        <div class="flex" style="border: none; justify-content: space-between; align-items: center">
                            <div class="flex" >
                                <el-checkbox v-model="checkedDelete" @change="getApiCall()" ></el-checkbox>
                                <el-tag
                                    type="danger"
                                    size="small"
                                    class="tag-virsz ml-2"
                                    effect="dark">
                                    Delete
                                </el-tag>
                            </div>
                            <div class="mp--r">
                                <p>{{ checkedDeleteValue }}</p>
                            </div>
                        </div>
                        <div class="flex" style="border: none; justify-content: space-between; align-items: center">
                            <div class="flex" >
                                <el-checkbox v-model="checkedPatch" @change="getApiCall()"></el-checkbox>
                                <el-tag
                                    type="info"
                                    size="small"
                                    class="tag-virsz ml-2"
                                    effect="dark">
                                    Patch
                                </el-tag>
                            </div>
                            <div class="mp--r">
                                <p>{{ checkedPatchValue }}</p>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </el-card>
        </div>
        <div class="vtza-c-r ml-3">
            <data-list :tHeader="Header" :loading="loading" :items="apicalls" :customSizes="true" ref="dataList" emptyText="No endpoints found.">
                <template #content>
                    <div v-for="(row, index) in apicalls" :key="index" class="tblb--row mb-2" >
                        <el-card class="trsl--elt" shadow="hover" >
                            <div >
                                <el-row  type="flex" justify="space-between" class="el--flex drag-only-row" >
                                    <el-col style="width: 2%; text-align: start;" class="trsl--txt">
                                    <span class="test-run-name">
                                        {{ forStepNumberRun(index)}}
                                    </span>
                                    </el-col>
                                    <el-col style="width: 48%; text-align: start;" class="trsl--txt test-run-name-callApi mx-2" >
                                        <el-tag
                                            :key="row.method"
                                            :type="getTagType(row.method)"
                                            size="small"
                                            class="api-call-tag"
                                            effect="dark">
                                            {{ row.method }}
                                        </el-tag>
                                        <el-tooltip class="item elt-row" effect="light" :content="row.url" placement="top" style="font-size: .8em ; ">
                                            <span class="elt-row" >{{ row.url }}</span>
                                        </el-tooltip>
                                    </el-col>
                                    <el-col style="width: 12%; text-align: center;" class="trsl--txt">
                                        <el-alert class="no--close elt--banner_overlay_status tags-call-api " show-icon :closable="false" :title="`${row.status_code }`" :type="
                                            row.status_code < 299
                                            ? 'success'
                                            : 'error'
                                        "></el-alert>
                                    </el-col>
                                    <el-col class="elt-row" style="width: 13%;">
                                        <span class="test-time-cont" >
                                            <i class="far fa-clock mr-1"></i>
                                            {{row.response_time }} ms
                                        </span>
                                    </el-col >
                                    <el-col class="trsl--txt" style="width: 15%;">
                                        <span class="elt--txt">
                                            {{ formatDate(row.created_at) }}
                                        </span>
                                    </el-col >
                                    <el-col style="width: 10%; text-align: start;" class="trsl--txt">
                                        <el-button type="success" icon="el-icon-view" plain circle @click="openEditAccordion(index)"></el-button>
                                        <el-button type="primary" style="padding: 9px;" plain circle @click="openReRunApi(row,index)"> 
                                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet">

                                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#5f72e4" stroke="none">
                                                        <path d="M1762 5090 c-590 -75 -1065 -496 -1197 -1062 -18 -75 -20 -78 -69
                                                        -110 -134 -86 -279 -264 -344 -420 -56 -135 -76 -236 -75 -388 0 -151 24 -267
                                                        81 -392 80 -177 227 -341 390 -437 l91 -54 3 -546 3 -546 33 -67 c40 -82 119
                                                        -157 200 -190 56 -23 62 -23 535 -26 444 -3 478 -4 473 -20 -3 -9 -31 -118
                                                        -62 -242 -48 -194 -55 -235 -52 -295 8 -116 71 -211 176 -262 l57 -28 555 0
                                                        555 0 57 28 c105 51 168 146 176 262 3 60 -4 101 -52 295 -31 124 -59 233 -62
                                                        242 -5 16 29 17 473 20 473 3 479 3 535 26 81 33 160 108 200 190 l33 67 3
                                                        557 3 558 67 40 c275 163 456 445 490 763 34 320 -161 702 -463 904 -169 114
                                                        -392 183 -590 183 l-61 0 -26 58 c-91 196 -268 381 -465 484 -183 96 -329 130
                                                        -535 127 l-128 -2 -85 57 c-153 103 -343 180 -523 211 -110 20 -305 27 -400
                                                        15z m359 -225 c174 -30 355 -109 502 -218 88 -65 86 -64 238 -52 373 30 737
                                                        -200 870 -550 46 -122 49 -124 226 -123 173 0 257 -18 393 -83 241 -115 412
                                                        -335 466 -598 47 -236 -67 -527 -270 -692 l-61 -49 -5 312 c-6 349 -9 361 -78
                                                        452 -20 27 -59 64 -85 83 -85 57 -121 63 -412 63 -248 0 -264 -1 -288 -20 -38
                                                        -30 -52 -80 -33 -123 26 -63 45 -67 318 -67 233 0 245 -1 282 -23 22 -12 49
                                                        -38 60 -57 21 -34 21 -48 24 -833 l2 -797 -1710 0 -1710 0 2 798 c3 784 3 798
                                                        24 832 11 19 38 45 60 57 37 22 49 23 282 23 273 0 292 4 318 67 19 43 5 93
                                                        -33 123 -24 19 -40 20 -294 20 -245 0 -273 -2 -323 -20 -111 -42 -190 -125
                                                        -226 -239 -18 -57 -20 -91 -20 -367 0 -167 -4 -304 -8 -304 -19 0 -143 113
                                                        -187 171 -61 81 -118 203 -139 296 -23 103 -21 260 4 357 51 194 180 366 351
                                                        466 72 43 82 59 99 151 86 474 469 857 945 944 97 18 315 18 416 0z m2145
                                                        -3642 c-8 -58 -33 -102 -76 -130 l-33 -23 -1597 0 -1597 0 -34 23 c-40 26 -79
                                                        98 -79 144 l0 33 1711 0 1712 0 -7 -47z m-1268 -400 c23 -24 36 -66 87 -270
                                                        59 -241 59 -242 42 -278 -31 -65 -30 -65 -567 -65 -537 0 -536 0 -567 65 -17
                                                        36 -17 37 42 278 51 204 64 246 87 270 l26 27 412 0 412 0 26 -27z"/>
                                                        <path d="M2691 3994 c-47 -40 -49 -77 -8 -173 l36 -82 -37 8 c-71 14 -243 6
                                                        -327 -16 -293 -77 -515 -289 -606 -581 -21 -66 -24 -95 -24 -235 0 -140 3
                                                        -169 23 -235 44 -139 91 -214 142 -225 58 -13 130 44 130 102 0 10 -15 54 -34
                                                        97 -176 403 96 861 524 883 l85 4 -55 -28 c-72 -37 -98 -78 -81 -133 14 -47
                                                        53 -80 95 -80 44 0 415 164 444 196 13 15 22 38 22 58 0 45 -163 414 -196 444
                                                        -35 31 -93 30 -133 -4z"/>
                                                        <path d="M3158 3365 c-29 -16 -58 -62 -58 -92 0 -10 15 -54 34 -97 107 -245
                                                        57 -515 -129 -701 -110 -110 -246 -172 -395 -181 l-85 -5 55 28 c93 47 113
                                                        121 50 184 -44 44 -75 39 -266 -45 -93 -41 -179 -77 -191 -80 -34 -11 -73 -63
                                                        -73 -99 0 -18 38 -118 87 -231 72 -164 93 -203 118 -218 43 -26 88 -23 124 8
                                                        47 39 49 77 9 171 l-35 78 131 -3 c108 -3 147 0 219 17 316 75 553 311 628
                                                        628 28 118 23 321 -11 428 -27 85 -71 174 -98 198 -27 25 -81 30 -114 12z"/>
                                                    </g>
                                                </svg>
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="edit-accordion" v-show="index === selectedItemId">
                                <el-divider></el-divider>
                                <vue-json-pretty :data="getJsonData(row)" :showSelectController="true" :showIcon="true" :showLineNumber="true" />
                                <div class="py-2" style="display: flex; justify-content: end;">
                                    <base-button type="secondary" @click="openEditAccordion(index)">
                                        {{ $t("list_item_page.close") }}
                                    </base-button>
                                </div>
                            </div>
                        </el-card>
                    </div>
              </template>
            </data-list>
            <el-pagination
                background
                layout="prev, pager, next"
                :total="totalCallCount"
                :page-size="pagination.perPage"
                :page-sizes="pagination.perPageOptions"
                :current-page.sync="pagination.currentPage"
                @current-change="getApiCall"
            >
            </el-pagination>
        </div>
      </div>
      <div class="add-test-project-modal">
          <modal :show.sync="showModalReRun" v-loading="loadingRequest">
              <template slot="header">
                  <h5 class="modal-title" id="addTestModal">
                      reRun calls api {{ showReRunIndex }}
                  </h5>
              </template>
              <div class="body--modale" >
                  <div class="modal-add-containt">
                      <div class="add-test-project-modal">
                          <div class="run-name">
                              <label class="model-label" for="url">
                              Url
                              </label>
                              <input type="text" id="url" name="url" placeholder="Url"
                              v-model="reRunUrl" />
                              <div style="display: flex;">
                                  <div class="mr-2"> 
                                      <label class="model-label" for="request_method">
                                          request method
                                      </label>
                                      <el-select id="request_method" v-model="methodReRun"  collapse-tags
                                      laceholder="request method" style="max-height: 35px;">
                                          <el-option v-for="(item, id) in allMethodReRun" :key="id" :label="item" :value="item">
                                          </el-option>
                                      </el-select>
                                  </div>
                                  <div class="ml-2 mr-2"> 
                                      <label class="model-label" for="connectionTimeout">
                                      Connection timeout
                                      </label>
                                      <el-input-number v-model="connectionTimeout" :precision="1" :step="0.1" ></el-input-number>
                                  </div>
                                  <div class="ml-2"> 
                                      <label class="model-label" for="requestTimeout">
                                      Request timeout
                                      </label>
                                      <el-input-number v-model="requestTimeout" :precision="1" :step="0.1" ></el-input-number>
                                  </div>
                              </div>
                              <el-divider></el-divider>
                              <div class="label-request">
                                  <label class="model-label" for="requestHeaders" style="font-size: 1em;">
                                      Request headers
                                  </label>
                                  <el-tooltip content="Add new column (request headers)" placement="top">
                                      <el-button type="primary" icon="el-icon-plus"  size="mini" @click="addNewColumnRequestHeaders"></el-button>
                                  </el-tooltip>
                              </div>
                              <div class="label-request ">
                                  <label class="model-label" for="requestHeaders">
                                      Name
                                  </label>
                                  <label class="model-label" for="requestHeaders" style="width: 100%;">
                                      Value
                                  </label>
                              </div>
                              <div v-for=" (elt, id) in requestHeaders" :key="`${id}headers`" class="label-request mt-2">
                                  <input class="mr-2" type="text" :id="elt.name" :name="elt.name" placeholder="column request headers name"
                                  v-model="elt.name" />
                                  <input class="ml-2" type="text" :disabled="elt.name.length == ''" :id="elt.value" :name="elt.value" placeholder="column request headers value"
                                  v-model="elt.value" />
  
                                  <el-tooltip class="ml-2" content="Delete column" placement="top">
                                      <el-button type="danger" icon="el-icon-delete-solid"  size="mini" @click="deleteColumnRequestHeaders(id)"></el-button>
                                  </el-tooltip>
                              </div>
                              <el-divider></el-divider>
                              <div class="label-request">
                                  <label class="model-label" for="requestHeaders" style="font-size: 1em;">
                                      Request body
                                  </label>
                                  <el-tooltip content="Add new column (request body)" placement="top">
                                      <el-button type="primary" icon="el-icon-plus"  size="mini" @click="addNewColumnRequestBody"></el-button>
                                  </el-tooltip>
                              </div>
                              <div class="label-request ">
                                  <label class="model-label" for="requestHeaders" style="width: 32%; text-align: left;">
                                      Name
                                  </label>
                                  <label class="model-label" for="requestHeaders" style="width: 35%; text-align: left;">
                                      Value
                                  </label>
                              </div>
                              <div v-for=" (elt, id) in requestBody" :key="`${id}body`" style="width: 100%;">
                                  <div class="label-request mt-2 mb-2" >
                                      <input class="mr-2" type="text"  placeholder="column request body name"
                                      v-model="elt.name" />
                                      <el-select :id="`body-type${id}`" v-model="elt.type"  collapse-tags
                                          laceholder="request method"
                                          :disabled="elt.name.length == ''"
                                      >
                                          <el-option v-for="(item, id) in bodyType" :key="id" :label="item" :value="item">
                                          </el-option>
                                      </el-select>
                                      <input v-if="elt.type ==='text' || elt.type ==='number'" class="ml-2" :disabled="elt.name.length == ''" :type="elt.type"   placeholder="column request body value"
                                      v-model="elt.value" />
                                      <el-tooltip class="ml-2" content="Delete column" placement="top">
                                          <el-button type="danger" icon="el-icon-delete-solid"  size="mini" @click="deleteColumnRequestBody(id)"></el-button>
                                      </el-tooltip>
                                  </div>
                                  <vue-json-editor v-if="elt.type ==='array' || elt.type ==='object'" v-model="elt.value" :show-btns="false" :expandedOnStart="false"  ></vue-json-editor>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <template slot="footer">
                  <div class="add-test-footer-btn">
                      <base-button type="secondary" @click="showModalReRun = false;">
                      {{ $t("list_item_page.close") }}
                      </base-button>
                      <span>
                          <el-tooltip :disabled="!(reRunUrl === '' || methodReRun === '')" 
                              :content="`${reRunUrl === '' ? 'Run url cannot be empty;' : ''} ${methodReRun === '' ? 'methon dReRun cannot be empty;' : ''} `" 
                              placement="top">
                          <base-button :disabled="reRunUrl === '' || methodReRun === '' " type="primary"
                          @click="reRun(true)">reRun</base-button>
                          </el-tooltip>
                      </span>
                  </div>
              </template>
          </modal>
      </div>
      <div class="add-test-project-modal">
            <modal :show.sync="responseRequestModal" >
                <template slot="header">
                    <h5 class="modal-title" id="addTestModal">
                        reRun calls api response
                    </h5>
                </template>
                <div class="ctb--body mb-3 " style="width:100%;" v-if="loadingRequest">
                    <div class="ctbb--row mb-2">
                        <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
                    </div>
                </div>
                <div v-else class="body--modale mb-4" >
                    <vue-json-pretty :data="responseRequest" :showSelectController="true" :showIcon="true" :showLineNumber="true"/>
                </div>
            </modal>
        </div>
    </div>
</template>
<script>
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";
import VueJsonPretty from 'vue-json-pretty';
import moment from "moment";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
    layout: "Virtualize",
    components: {
      DataList,
      VueJsonPretty,
    },
    data(){
        return{
            selectedItemId:null,
            selectPoject:null,
            listProject: [],
            apicalls:[],
            loadingApicalls:false,
            limitCallApi:10,
            totalCallCount:0,
            activeNames: ['3'],
            search:"",
            customDate:false,
            showIssues: false,
            checkedGet:false,
            checkedGetValue:0,
            checkedPost:false,
            checkedPostValue:0,
            checkedPut:false,
            checkedPutValue:0,
            checkedDelete:false,
            checkedDeleteValue:0,
            checkedPatch:false,
            checkedPatchValue:0,
            statusArray : [
                { type: "100 -> 199", value: 100, status: false },
                { type: "200 -> 299", value: 200, status: false },
                { type: "300 -> 399", value: 300, status: false },
                { type: "400 -> 499", value: 400, status: false },
                { type: "500 -> 599", value: 500, status: false }
            ],
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
            },
            listTime:[
                {label:"Last 1 day",value:1},
                {label:"Last 3 day",value:3},
                {label:"Last 6 day",value:6},
                {label:"Last 12 day",value:12},
                {label:"Last 24 day",value:24},
                {label:"Last 1 month",value:30},
            ],
            Header: [
                {
                label: "",
                key: "_id",
                width: "2%",
                sortable: true,
                sortDetails: { asc: true, desc: false, type: "string" },
                },
                {
                label: "Methode|Url".toUpperCase(),
                key: "",
                width: "48%",
                sortable: false,
                },
                {
                label: "Status code".toUpperCase(),
                key: "",
                width: "12%",
                sortable: false,
                },
                {
                label: "respTime".toUpperCase(),
                key: "",
                width: "13%",
                sortable: false,
                },
                {
                label: "created".toUpperCase(),
                key: "",
                width: "15%",
                sortable: false,
                },
                { label: "".toUpperCase(), key: "", width: "10%", sortable: false },
            ],
            loading:true,
            selectTime:null,
            datePikerStart:"",
            datePikerEnd:"",
            error:[],
            lastProject: [],
            filterByTime:false,
            filterByStatusCode:false,
            filterByMethode:false,
            originatingPageUrl:"",
            loadingRequest:false,
            responseRequest:[],
            responseRequestModal:false,
            selectedItemId:null,
            showModalReRun: false,
            showReRunId: "",
            showReRunIndex: 0,
            reRunUrl:"",
            allMethodReRun: ["","get", "post", "delete", "put", "connect", "options", "head", "patch", "trace"],
            methodReRun: "",
            requestHeaders:[],
            requestBody:[],
            metadata: [],
            responseHeaders:[],
            responseBody:[],
            bodyType:["text","array","object","number"],
            showModalAddApi: false,
            responseType: ["html", "str", "json", "none"],
            responseTypeValue: "",
            statusAddApi: "",
            ResponseTime:0,
            editApi:false,
            isActiveApi: false,
            connectionTimeout: 0.0,
            requestTimeout:0.0,
            reRunEditData: false,
            editor:"ui",
            setEditEl: [],
            listStatusAddApi: ["200 - OK","201 - Created","204 - No Content","400 - Bad Request","401 - Unauthorized","403 - Forbidden","404 - Not Found","500 - Internal Server Error","502 - Bad Gateway","503 - Service Unavailable"]
        }
    },
    async created() {
        if(localStorage.getItem("latest_projects"))
            this.lastProject = JSON.parse(localStorage.getItem("latest_projects"))
            this.selectPoject = this.lastProject[0].projectId
        await this.getProject();
        this.getApiCall()
    },
    methods:{
        async openReRunApi(el,index){
            const confirmation = await swal.fire({
                title: "Modify request ?",
                text: "You had the option of modifying the request (method, url, request header, request body).",
                type: "question",
                buttonsStyling: true,
                showCancelButton: true,
                confirmButtonText: "Yes, modify!",
                cancelButtonText: "No, do not modify!",
                confirmButtonClass: "btn btn-primary",
                cancelButtonClass: "btn btn-warning",
            });
            this.reRunUrl = el.url
            this.methodReRun = el.method
            this.showReRunId = el.apicall_uid
            this.showReRunIndex = index + 1
            this.requestHeaders = []
            Object.entries(el.request_headers).map(item => {
                this.requestHeaders.push({ name:item[0], value:item[1]})
            })
            this.requestBody = []
            if(el.response_body && Array.isArray(el.response_body)){
                Object.entries(el.response_body).map(item => {
                    this.requestBody.push({ name:item[0],type: this.getType(item[1]) ,value:item[1]})
                })
            }
            console.log("yesss",el)
            if (confirmation.value === true){
                this.editApi = false
                this.showModalReRun = true
                this.responseRequestModal = false
                this.reRunEditData = true
            }else{
                this.showReRunId = el.apicall_uid
                this.reRunEditData = false
                this.responseRequestModal = true
                this.reRun(false)
            }
        },
        forStepNumberRun(val) {
            return ((this.pagination.currentPage * this.pagination.perPage) - this.pagination.perPage) + (val + 1)
        },
        openEditAccordion(id) {
            console.log("yessss",id)
            this.selectedItemId = this.selectedItemId !== id ? id : null;
        },
        formatDate(a) {
            return moment(a).format("MMM DD YYYY, h:mm:ss a");
        },
        showProject(){
            this.$router.push({
                name: "List Sessions",
                params: {
                    id: this.selectPoject,
                },
            });
        },
        setByFilter(){
            this.filterByTime = true
            if(this.customDate === false){
                let today = new Date();
                let sevenDaysAgo = new Date(today.getTime() - (this.selectTime * 24 * 60 * 60 * 1000));
                if(this.selectTime){
                    this.datePikerStart = moment(sevenDaysAgo).format("YYYY-MM-DD")
                    this.datePikerEnd = moment(today).format("YYYY-MM-DD")
                    this.getApiCall()
                }
            }else if( this.customDate === true && this.datePikerStart.length > 0 && this.datePikerEnd.length > 0 ){
                this.getApiCall()
            }
        },
        setByFilterStatusCode(){

        },
        clearFilter(){
            this.filterByTime = false
            this.selectTime = false
            this.datePikerStart = ""
            this.datePikerEnd = ""
            this.checkedGet = false
            this.checkedGetValue = 0
            this.checkedPost = false
            this.checkedPostValue = 0
            this.checkedPut = false
            this.checkedPutValue = 0
            this.checkedDelete = false
            this.checkedDeleteValue = 0
            this.checkedPatch = false
            this.checkedPatchValue = 0
            this.statusArray = [
                { type: "100 -> 199", value: 100, status: false },
                { type: "200 -> 299", value: 200, status: false },
                { type: "300 -> 399", value: 300, status: false },
                { type: "400 -> 499", value: 400, status: false },
                { type: "500 -> 599", value: 500, status: false }
            ]
        },
        getTagType(method){
            if(method.toLowerCase() == "get"){
                return "success"
            }
            if(method.toLowerCase() == "post"){
                return ""
            }
            if(method.toLowerCase() == "put"){
                return "warning"
            }
            if(method.toLowerCase() == "delete"){
                return "danger"
            }
            if(method.toLowerCase() == "patch"){
                return "info"
            }

        },
        getCodeType(method){
            if(method == 200){
                return "success"
            }
            if(method == 400){
                return ""
            }
            if(method == 300){
                return "warning"
            }
            if(method == 500){
                return "danger"
            }
            if(method == 100){
                return "info"
            }

        },
        elementscan(){
            console.log("hellooo")
            document.addEventListener("scroll" , () => {
                let firstContent = document.getElementById("vtza_c_l");
                let firstContentPosition = firstContent.getBoundingClientRect().top + window.pageYOffset;
                let firstContentheight = firstContent.getBoundingClientRect().height + firstContentPosition;
                let scrollPosition = document.documentElement.scrollTop;
                console.log("yesssssss",firstContentPosition,firstContentheight)
                if (scrollPosition >= firstContentPosition && scrollPosition < firstContentheight){
                }
            })
        },
        getJsonData(el){
            let dat = el
            delete dat.session_uid
            delete dat.testrun_requestid
            delete dat.pageviews_project_id
            return dat
        },
        async getProject() {
            const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
            try {
                axios.defaults.validateStatus = (status) => {
                // return status >= 200 && status < 401; // to catch 400 error
                return status === 400 || (status >= 200 && status < 500);
                };
                const res = await axios.get(url);
                if ((res.data && res.data.status == "success") || res.data.status == 200) {
                    this.listProject = res.data.response;
                    if (this.lastProject.length === 0)
                        this.lastProject.push(this.listProject[0])
                        this.selectPoject = this.lastProject[0].projectId
                } else {
                    this.listProject = [];
                    this.error.push(es.data.message)
                }
            } catch (error) {
                this.$notify({
                type: "danger",
                duration: 5000,
                message: "an error has occurred ",
                });
            } finally {
                this.loading = false;
            }
        },
        async getApiCall() {
            this.loading = true;
            let filter = `&page=${this.pagination.currentPage}&count=${this.pagination.perPage}`
            if(this.filterByTime === true && this.datePikerStart.length > 0 && this.datePikerEnd.length > 0){
                filter = `${filter}&startDate=${this.datePikerStart}&endDate=${this.datePikerEnd}`
            }
            if(this.checkedGet === true){
                filter = `${filter}&methode=Get`
            }
            if(this.checkedPost === true){
                filter = `${filter}&methode=Post`
            }
            if(this.checkedPut === true){
                filter = `${filter}&methode=Put`
            }
            if(this.checkedDelete === true){
                filter = `${filter}&methode=Delete`
            }
            if(this.checkedPatch === true){
                filter = `${filter}&methode=Patch`
            }
            const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/apicallsdata/v1?pageviewsProjectId=${this.selectPoject}${filter}`;
            try {
                axios.defaults.validateStatus = (status) => {
                // return status >= 200 && status < 401; // to catch 400 error
                return status === 400 || (status >= 200 && status < 500);
                };
                const res = await axios.get(url);
                if ((res.data && res.data.status == "success") || res.data.status == 200) {
                    this.apicalls = res.data.response.apicalls;
                    this.totalCallCount = res.data.response.totalCallCount
                    this.pagination.currentPage = res.data.response.currentPage
                    this.pagination.perPage = res.data.response.perPageCount
                    this.loading = false;
                } else {
                    this.apicalls = [];
                    this.totalCallCount = 0
                    this.error.push(es.data.message)
                    this.loading = false;
                }
            } catch (error) {
                this.totalCallCount = 0
                console.log(`an error has occurred : ${error}`)
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },
        addNewColumnRequestHeaders(el){
            if(el === "response"){
                this.responseHeaders.push({name:"",value:""})
            }else{
                this.requestHeaders.push({name:"",value:""})
            }
        },
        deleteColumnRequestHeaders(index,el){
            if(el === "response"){
                if(this.responseHeaders.length == 1 ){
                    if(this.editApi == false)
                        return
                    this.responseHeaders = []
                }else{
                    this.responseHeaders.splice(index,1)
                }
            }else{
                if(this.requestHeaders.length == 1){
                    if(this.editApi == false)
                        return
                    this.requestHeaders = []
                }else{
                    this.requestHeaders.splice(index,1)
                }
            }
        },
        addNewColumnRequestBody(el){
            if(el === "response"){
                this.responseBody.push({name:"",type:"text",value:""})
            }else if(el === "metadata"){
                this.metadata.push({name:"",value:""})
            }else{
                this.requestBody.push({name:"",type:"text",value:""})
            }
        },
        deleteColumnRequestBody(index,el){
            if(el === "response"){
                if(this.responseBody.length == 1){
                    this.responseBody = []
                }else{
                    this.responseBody.splice(index,1)
                }
            }else if(el === "metadata"){
                if(this.metadata.length == 1){
                    this.metadata = []
                }else{
                    this.metadata.splice(index,1)
                }
            }else{
                if(this.requestBody.length == 1){
                    this.requestBody = []
                }else{
                    this.requestBody.splice(index,1)
                }
            }
        },
        getType(el){
            let type = typeof el
            if(type === "string")
                return "text"
            return type
        },
        async reRun(type){
            let apiCallId = this.showReRunId
            this.loadingRequest = true
            let data = { }
            if (this.reRunUrl != "" && this.reRunUrl.length > 0){
                data.url = this.reRunUrl
            }
            if (this.requestTimeout > 0.1){
                data.requestTimeout = this.requestTimeout
            }
            if (this.connectionTimeout > 0.1){
                data.connectionTimeout = this.connectionTimeout
            }
            if (this.methodReRun != "" && this.methodReRun.length > 0){
                data.method = this.methodReRun
            }
            if (this.requestHeaders.length > 0){
                let finalyHeader = {}
                this.requestHeaders.map( item => {
                    if(item.name != ""){
                        finalyHeader[item.name] = item.value
                    }
                })
                data.requestHeaders = finalyHeader

            }else{
                data.requestHeaders = null
            }
            if (this.requestBody.length > 0){
                let finalyBody = {}
                this.requestBody.map( item => {
                    if(item.type === "number" && item.name != "")
                        finalyBody[item.name] = Number(item.value )
                    if(item.type === "array" && item.name != "")
                        finalyBody[item.name] = item.value
                    if(item.type === "object" && item.name != "")
                        finalyBody[item.name] = item.value
                    if(item.type === "text" && item.name != "")
                        finalyBody[item.name] = item.value
                })
                data.requestBody = finalyBody

            }else{
                data.requestBody = null
            }
            axios.defaults.validateStatus = (status) => {
                return status === 400 || status === 500 || (status >= 200 && status < 300);
            };
            let resp = null
            if(this.reRunEditData == true){
                resp = await axios.post(process.env.VUE_APP_API_URL_PREFIX + "/apicalls/v1/replay/" + apiCallId,data,{ headers: {'Content-Type': 'application/json'}})
            }else{
                resp = await axios.post(process.env.VUE_APP_API_URL_PREFIX + "/apicalls/v1/replay/" + apiCallId)
            }
            if (resp.data.status === "success"){
                this.responseRequest = resp.data.response
                delete this.responseRequest.apicall_uid
                delete this.responseRequest.session_uid
                delete this.responseRequest.testrun_requestid
                delete this.responseRequest.updated_at
                delete this.responseRequest.created_at
                delete this.responseRequest.pageviews_project_id
                this.loadingRequest = false
                this.responseRequestModal = true
                this.showModalReRun = false
            }else if(resp.data.status === "failed"){
                if(resp.data.response){
                    this.responseRequest = resp.data.response
                    delete this.responseRequest.apicall_uid
                    delete this.responseRequest.session_uid
                    delete this.responseRequest.testrun_requestid
                    delete this.responseRequest.updated_at
                    delete this.responseRequest.created_at
                    delete this.responseRequest.pageviews_project_id
                    this.responseRequestModal = true
                    this.showModalReRun = false
                    this.loadingRequest = false
                }else{
                    this.$notify({
                    type: "danger",
                        message: `an error has occurred! (${resp.data.message})`,
                    });
                    this.responseRequest = []
                    this.loadingRequest = false
                }
            }else{
                this.responseRequest = []
                this.loadingRequest = false
            }
        },
    },
    computed:{
        disabledDate(date) {
            let esl = this.datePikerStart
            return {
                disabledDate(time) {
                    return time.getTime() < new Date(esl) || time.getTime() > Date.now();
                }
            }
        },
        disabledDateStart(date) {
            let esl = this.datePikerEnd
            return {
                disabledDate(time) {
                    return time.getTime() > new Date(esl) || time.getTime() > Date.now();
                }
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.vtza-h{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 10px;
    padding: 8px 16px;
    border-bottom: none !important;
    // max-height: 95vh;
    .vtza-c-l{
        width: 20%;
    }
    .vtza-c-r{
        width: 80%;
        // overflow: auto;
    }
}
.mp--l{
    flex-direction: column;
    align-items: start;
}
.mp--r{
    flex-direction: column;
    align-items: end;
}
.el-input--prefix .el-input__inner {
    padding-left: 30px !important;
}
.el-button--primary.is-plain {
    color: #5f72e4;
    g{
        fill: #5f72e4;
    }
    background: #d7dbf8;
    border-color: #9ba9ee;
}
.el-button--primary.is-plain:hover{
    color: #fff;
    g{
        fill:#fff;
    }
    background: #5f72e4;
}
.el-collapse-item__header{
    font-weight: 700 !important;
}
</style>