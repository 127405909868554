<template>
  <div class="list-item-page px-4 right-content">
    <div class="xs:hidden sm:hidden c--breadcrumb mb-2" >
      <router-link to="/details/test-explorer/list-tests" class=""><i class="el-icon-house"></i></router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span>{{ $t("list_item_page.test_explorer") }}</span>
    </div>
    <div class="mid--page_container">
      <div class="mid--page pb-4 mb-3">
        <div class="mp--l">
          <span @click="saveDataView('list')" id="listItemPageTableListe" :class="{ active: dataView === 'list' }"><i
              class="el-icon-s-operation"></i></span>
          <span @click="saveDataView('thumb')" id="listItemPageTableThumb" :class="{ active: dataView === 'thumb' }"
            class="mx-1"><i class="el-icon-tickets"></i></span>
          <span @click="saveDataView('card')" id="listItemPageTableCard" :class="{ active: dataView === 'card' }"><i
              class="el-icon-menu"></i></span>
          <el-button id="listItemPageTableFilter" @click="toggleShowFilters = !toggleShowFilters" icon="el-icon-guide"
            class="ml-3 small_btn dashed--btn" plain size="small">
            {{ $t("list_item_page.filter") }}
          </el-button>
          <el-button id="listItemPageTableAddtest" @click="addTestModal = true" icon="el-icon-plus" class="ml-3 small_btn"
            plain size="small">
            {{ $t("list_item_page.add_test") }}
          </el-button>
          <!-- <el-button
              id="listItemPageTableGenerateTest"
              @click="goToGenerateTest()"
              icon="el-icon-plus"
              class="ml-3 small_btn"
              plain
              size="small"
            >
              Generate Test
            </el-button> -->
        </div>
        <div class="mp--r">
          <span class="pag--details mr-3">{{ total ? from + 1 : 1 }} {{ $t("list_item_page.to") }}
            {{ to > total ? total : to }} {{ $t("list_item_page.of") }} {{ total }}
            {{ $t("list_item_page.entries") }}
          </span>
          <el-select id="listItemPageTableNumberElement" v-model="pagination.perPage" @change="savePagination"
            placeholder="Per page" class="small--select" v-loading="filterLoading">
            <el-option v-for="(item, id) in pagination.perPageOptions" :key="id" :label="item" :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="my-2 py-2" v-if="filteredElt">
        <h3 class="text-xs">Active Filter</h3>
        <div style="display: inline-flex; flex-wrap: wrap; gap: .5rem">
          <p class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0" v-for="f in filteredValues" :key="f.id">
            <b class="text-xs"><u>{{ f[0] }}</u>: </b>
            <em>{{ f[1].value }}</em>
          </p>
        </div>
      </div>
      <div class="my-2 py-2 border-t border-solid border-gray" v-if="viewFilterValues">
        <h3 class="text-xs">Current Filter</h3>
        <div style="display: inline-flex; flex-wrap: wrap; gap: .5rem">
          <p class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0" v-for="f in filterValues" :key="f.id">
            <b class="text-xs"><u>{{ f[0] }}</u>: </b>
            <em>{{ f[1].value }}</em>
          </p>
        </div>
      </div>
      <div class="mb-2 p-4 mp--filter_container" v-if="toggleShowFilters">
        <div class="mod--content lite">
          <el-form class="small-spaces" @submit.native.prevent="processFilter" v-loading="filterLoading">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Run name">
                  <el-input v-model="editedFilter.name.value" placeholder="Add test run name on filter query"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Run tags">
                  <el-input v-model="editedFilter.tags.value" placeholder="Add test run tags on filter query"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Origin">
                  <el-input v-model="editedFilter.origin.value" placeholder="Add origin on filter query"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Accepted date">
                  <el-date-picker v-model="editedFilter.acceptedAt.value" type="date" style="width: 100%"
                    placeholder="Accepted on"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Started date">
                  <el-date-picker v-model="editedFilter.startedAt.value" type="date" style="width: 100%"
                    placeholder="Started on"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Finished date">
                  <el-date-picker v-model="editedFilter.finishedAt.value" type="date" style="width: 100%"
                    placeholder="Finished on"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Status">
                  <el-select v-model="editedFilter.status.value" placeholder="filter by status">
                    <el-option v-for="(status, id) in filterStatus" :key="id" :label="status.label"
                      :value="status.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Is active">
                  <el-select v-model="editedFilter.isActive.value" placeholder="Yes / No">
                    <el-option label="Yes" value="true"></el-option>
                    <el-option label="No" value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Run description">
                  <el-input v-model="editedFilter.description.value" type="textarea"
                    placeholder="Add test run description on filter"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-collapse>
                  <el-collapse-item name="1">
                    <template slot="title">
                      <i class="header-icon el-icon-setting"></i>
                      <span class="ml-2">Advanced ...</span>
                    </template>
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item label="Test suite">
                          <el-select :disabled="true" v-model="editedFilter.testSuiteId.value"
                            placeholder="filter by test suite">
                            <el-option label="Zone one" value="shanghai"></el-option>
                            <el-option label="Zone two" value="beijing"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="Test suite run">
                          <el-select :disabled="true" v-model="editedFilter.testSuiteRunId.value"
                            placeholder="filter by test suite run">
                            <el-option label="Zone one" value="shanghai"></el-option>
                            <el-option label="Zone two" value="beijing"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="Project">
                          <el-select :disabled="true" v-model="editedFilter.projectId.value"
                            placeholder="filter by Project">
                            <el-option label="Zone one" value="shanghai"></el-option>
                            <el-option label="Zone two" value="beijing"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Team">
                          <el-select :disabled="true" v-model="editedFilter.teamId.value" placeholder="filter by Team">
                            <el-option label="Zone one" value="shanghai"></el-option>
                            <el-option label="Zone two" value="beijing"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Organization">
                          <el-select :disabled="true" v-model="editedFilter.orgId.value"
                            placeholder="filter by Organization">
                            <el-option label="Zone one" value="shanghai"></el-option>
                            <el-option label="Zone two" value="beijing"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Browser url">
                          <el-input v-model="editedFilter.browserUrl.value"
                            placeholder="Add browser url on filter query"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Selector">
                          <el-input v-model="editedFilter.selector.value"
                            placeholder="Add test test selector on filter query"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Step action">
                          <el-input v-model="editedFilter.stepAction.value"
                            placeholder="Add test step action on filter query"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="3">
                        <el-form-item label="Step count">
                          <el-input v-model="editedFilter.stepCount.value"
                            placeholder="Add test step number on filter query"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="3">
                        <el-form-item label="Limit">
                          <el-input v-model="filterLimit" placeholder="Set the result max number on query"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                </el-collapse>
              </el-col>
            </el-row>
            <div class="edit-accordion-btns mt-4">
              <base-button type="primary" native-type="submit">
                Process filter
              </base-button>
              <base-button type="info" native-type="reset" @click="resetFilter(false)">
                Reset
              </base-button>
              <base-button type="danger" native-type="reset" @click="resetFilter(true)">
                Reset All
              </base-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <div class="eff--page" :key="componentKey">
      <data-list v-if="dataView === 'list'" :tHeader="tHeader" :loading="loading" :items="toShow" ref="dataList">
        <template #content>
          <div v-for="elt in toShow" :key="elt.id" class="tblb--row mb-2"
            :class="elt.status === 'processing' && 'tblb--processing'" @click="_onRowClicked(elt)">
            <div v-if="elt.status === 'processing'" class="tblb--over">
              <div class="tblbo--img" v-if="elt.thumbnail">
                <img :src="`data:image/png;base64,${elt.thumbnail}`" alt="screenshot of current step" />
              </div>
              <div class="mx-5 fl--center">
                <span class="s--icon mr-4">
                  <i class="el-icon-loading"></i>
                </span>
                <span class="s--name">{{ elt.runName }}</span>
              </div>
              <div class="tblo--step">
                <span class="s--txt">
                  Processing Step:
                  <u class="h2">{{ elt.xpathCount }}</u>
                </span>
              </div>
              <el-button type="danger" @click.stop="cancelRunItem(elt)" plain size="small" class="mr-5">
                Cancel
                <i class="el-icon-scissors ml-2"></i>
              </el-button>
            </div>
            <div class="tblb--inner">
              <div class="elt-row" :style="'width: calc(100% /' + tHeader.length + ')'">
                <span class="test-run-name">{{ elt.runName }}</span>
                <br />
                <span v-if="elt.status === 'passed'" class="test-time-cont">
                  <i class="far fa-clock mr-1"></i>
                  {{ secondsToRealTime(elt) }}
                </span>
              </div>
              <div class="elt-row" :style="'width: calc(100% /' + tHeader.length + ')'">
                <span v-if="elt.runDesc" class="run-tags">
                  {{ elt.runDesc }}
                </span>

                <span v-if="elt.runTags" class="run-tags-cont">
                  <span class="run-tags" v-for="(tags, index) in elt.runTags" :key="index">
                    {{ tags }}
                  </span>
                </span>
              </div>
              <div class="elt-row" :style="'width: calc(100% /' + tHeader.length + ')'">
                <el-alert class="no--close" :title="elt.status" :type="
                  elt.status === 'passed'
                    ? 'success'
                    : elt.status === 'failed'
                      ? 'error'
                      : 'danger'
                " show-icon></el-alert>
              </div>
              <div class="elt-row" :style="'width: calc(100% /' + tHeader.length + ')'">
                <span class="elt--txt">
                  {{ formatDate(elt.acceptedAt) }}
                </span>
              </div>
              <div class="elt-row" :style="'width: calc(100% /' + tHeader.length + ')'">
                <span class="elt--value">
                  {{ elt.stepCount }}
                </span>
              </div>
              <div class="elt-row flex--elt flex-wrap" :style="'width: calc(100% /' + tHeader.length + ')'">
                <el-tooltip id="listItemPageTableEditTestBtn" class="item" effect="light" content="Edit this test"
                  placement="top">
                  <el-button class="ml-2" type="primary" icon="el-icon-edit" @click.stop="editTestModal(elt)" circle plain
                    size="small"></el-button>
                </el-tooltip>

                <el-tooltip id="listItemPageTableRerunTestBtn" class="item" effect="light" content="Rerun this test"
                  placement="top">
                  <el-button type="success" icon="el-icon-refresh" @click.stop="rerunItemModal(elt)" circle plain
                    size="small"></el-button>
                </el-tooltip>

                <el-tooltip id="listItemPageTableDeleteTestBtn" class="item" effect="light" content="Delete this test"
                  placement="top">
                  <el-button type="danger" icon="el-icon-delete" @click.stop="deleteItem(elt)" circle plain
                    size="small"></el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </template>
      </data-list>
      <div v-if="dataView === 'card'" class="bpsd_ctb">
        <div class="ctb--body mb-3" v-if="loading">
          <div class="ctbb--row mb-2">
            <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
          </div>
        </div>
        <div class="ctb--body mb-3" v-else>
          <template v-if="toShow.length > 0">
            <div v-for="elt in toShow" :key="elt.id" class="ctbb--row mb-2"
              :class="elt.status === 'processing' && 'ctbb--processing'" @click="_onRowClicked(elt)">
              <div v-if="elt.status === 'processing'" class="ctbb--over">
                <img v-if="elt.thumbnail" :src="`data:image/png;base64,${elt.thumbnail}`"
                  alt="screenshot of current step" />
                <img v-else src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
                <span class="s--icon"><i class="el-icon-loading"></i></span>
                <span class="s--name">{{ elt.runName }}</span>
                <span class="s--txt">
                  Processing Step:
                  <u class="h2">{{ elt.xpathCount }}</u>
                </span>
                <el-button type="danger" @click.stop="cancelRunItem(elt)" plain size="small">
                  Cancel
                  <i class="el-icon-scissors ml-2"></i>
                </el-button>
              </div>
              <div class="ctbb--inner">
                <div class="elt--banner" v-if="elt.thumbnail">
                  <img :src="`data:image/png;base64,${elt.thumbnail}`" alt="screenshot of current step"
                    class="elt--banner_img" />
                  <div class="elt--banner_overlay"></div>
                  <el-alert class="no--close elt--banner_overlay_status" show-icon :title="elt.status" :type="
                    elt.status === 'passed'
                      ? 'success'
                      : elt.status === 'failed'
                        ? 'error'
                        : 'danger'
                  "></el-alert>
                  <span v-if="elt.status === 'passed'" class="elt--banner_overlay_duration">
                    <i class="far fa-clock mr-1"></i>
                    {{ secondsToRealTime(elt) }}
                  </span>
                  <span class="elt--banner_overlay_created">
                    {{ formatDate(elt.acceptedAt) }}
                  </span>
                </div>
                <div v-else-if="elt.thumbnail !== null">
                  <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px"
                    v-if="elt.status !== 'processing'" />
                </div>
                <div v-else class="mt-5">
                  <i class="el-icon-warning ml-2"></i>
                  <span class="elt--banner_not_img ml-1">{{  $t("list_item_page.screenshot_not_availabe") }}</span>
                </div>
                <div class="elt--content">
                  <span class="test-run-name"> {{ elt.runName }} </span>
                  <span class="test-desc my-1">
                    {{ elt.runDesc }}
                  </span>

                  <span v-if="elt.runTags" class="run-tags-cont">
                    <span class="run-tags" v-for="(tags, index) in elt.runTags" :key="index">
                      {{ tags }}
                    </span>
                  </span>
                  <span class="elt--value">
                    {{ elt.stepCount }}
                    <em>
                      <small>
                        <u> Total Steps </u>
                      </small>
                    </em>
                  </span>
                  <el-divider></el-divider>
                  <div>
                    <el-tooltip class="item" effect="light" content="Edit this test" placement="top">
                      <el-button type="primary" icon="el-icon-edit" @click.stop="editTestModal(elt)" rounded plain
                        size="small"></el-button>
                    </el-tooltip>

                    <el-tooltip class="item" effect="light" content="Rerun this test" placement="top">
                      <el-button type="success" icon="el-icon-refresh" @click.stop="rerunItemModal(elt)" rounded plain
                        size="small"></el-button>
                    </el-tooltip>

                    <el-tooltip class="item" effect="light" content="Delete this test" placement="top">
                      <el-button type="danger" icon="el-icon-delete" @click.stop="deleteItem(elt)" rounded plain
                        size="small"></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="ctbb--row mb-2" v-else>
            {{ $t("list_item_page.no_items_found") }}
          </div>
        </div>
      </div>
      <div v-if="dataView === 'thumb'" class="bpsd_dtb">
        <table class="bpsd--table">
          <thead class="tbl--head mb-3">
            <tr>
              <th v-for="elt in tHeader2" :key="elt.id">
                <span class="th--label">{{ elt.label }}</span>
                <span v-if="elt.sortable" class="th--sort ml-2">
                  <i class="el-icon-caret-top"></i>
                </span>
              </th>
            </tr>
          </thead>
        </table>
        <div class="tbl--body mb-3" v-if="loading">
          <div class="tblb--row mb-2">
            <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
          </div>
        </div>
        <div class="tbl--body mb-3" v-else>
          <template v-if="toShow.length > 0">
            <div v-for="elt in toShow" :key="elt.id" class="tblb--row mb-2"
              :class="elt.status === 'processing' && 'tblb--processing'" @click="_onRowClicked(elt)">
              <div v-if="elt.status === 'processing'" class="tblb--over b">
                <div class="tblbo--img" v-if="elt.thumbnail">
                  <img :src="`data:image/png;base64,${elt.thumbnail}`" alt="screenshot of current step" />
                </div>
                <div class="mx-5 fl--center">
                  <span class="s--icon mr-4"><i class="el-icon-loading"></i></span>
                  <span class="s--name">{{ elt.runName }}</span>
                </div>
                <div class="tblo--step">
                  <span class="s--txt">
                    Processing Step:
                    <u class="h2">{{ elt.xpathCount }}</u>
                  </span>
                </div>
                <el-button type="danger" @click.stop="cancelRunItem(elt)" plain size="small" class="mr-5">
                  Cancel
                  <i class="el-icon-scissors ml-2"></i>
                </el-button>
              </div>
              <div class="tblb--inner">
                <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                  <img v-if="elt.thumbnail" :src="`data:image/png;base64,${elt.thumbnail}`"
                    alt="screenshot of current step" class="elt--banner_img" />
                  <img v-else-if="elt.thumbnail !== null" src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
                  <div v-else class="mt-2">
                    <i class="el-icon-warning ml-2"></i>
                    <span class="elt--banner_not_img ml-1">{{  $t("list_item_page.screenshot_not_availabe") }}</span>
                  </div>
                </div>
                <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                  <span class="test-run-name">{{ elt.runName }}</span><br />
                  <span v-if="elt.status === 'passed'" class="test-time-cont">
                    <i class="far fa-clock mr-1"></i>
                    {{ secondsToRealTime(elt) }}
                  </span>
                </div>
                <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                  <span>
                    {{ elt.runDesc }}
                  </span>

                  <span v-if="elt.runTags" class="run-tags-cont">
                    <span class="run-tags" v-for="(tags, index) in elt.runTags" :key="index">
                      {{ tags }}
                    </span>
                  </span>
                </div>
                <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                  <el-alert class="no--close" :title="elt.status" :type="
                    elt.status === 'passed'
                      ? 'success'
                      : elt.status === 'failed'
                        ? 'error'
                        : 'danger'
                  " show-icon></el-alert>
                </div>
                <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                  <span class="elt--txt">
                    {{ formatDate(elt.acceptedAt) }}
                  </span>
                </div>
                <div class="elt-row" :style="'width: calc(100% /' + tHeader2.length + ')'">
                  <span class="elt--value">
                    {{ elt.stepCount }}
                  </span>
                </div>
                <div class="elt-row flex--elt" :style="'width: calc(100% /' + tHeader2.length + ')'">
                  <el-tooltip class="item" effect="light" content="Edit this test" placement="top">
                    <el-button type="primary" icon="el-icon-edit" @click.stop="editTestModal(elt)" circle plain
                      size="small"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="light" content="Rerun this test" placement="top">
                    <el-button type="success" icon="el-icon-refresh" @click.stop="rerunItemModal(elt)" circle plain
                      size="small"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="light" content="Delete this test" placement="top">
                    <el-button type="danger" icon="el-icon-delete" @click.stop="deleteItem(elt)" circle plain
                      size="small"></el-button>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
          <div class="tblb--row mb-2" v-else>
            {{ $t("list_item_page.no_items_found") }}
          </div>
        </div>
      </div>
      <!-- <el-pagination
          background
          layout="prev, pager, next"
          class="pagination-no-border"
          :total="total"
          :page-size="pagination.perPage"
          :page-sizes="pagination.perPageOptions"
          :current-page.sync="pagination.currentPage"
          @current-change="handleCurrentChange(pagination.currentPage, true)"
          @size-change="handleSizeChange"
        /> -->
      <el-button-group >
        <el-button type="primary" icon="el-icon-arrow-left" :disabled="pagination.currentPage === 1"
          @click="handleNavigation('prev')">Previous Page</el-button>
        <el-button type="primary" @click="handleNavigation('next')"
          :disabled="pagination.currentPage === this.pagination.lastPage" >Next Page<i
            class="el-icon-arrow-right el-icon-right"></i></el-button>
      </el-button-group>
    </div>

    <div class="re-run-modal-container">
      <modal :show.sync="toggleReRunModal">
        <template slot="header">
          <h5 class="modal-title" id="rerunModal">Retry this test run</h5>
        </template>
        <div>
          <div class="re-run-modal">
            <div class="run-name">
              <label class="model-label" for="run_name"> New run name (required): </label>
              <input type="text" id="run_name" name="run_name" placeholder="Enter a name for this test run"
                v-model="run_name" />
              <label class="model-label" for="run_description">
                New run description:
              </label>
              <textarea id="run_description" name="run_description" rows="2"
                placeholder="Enter an optional description or comments for this test run"
                v-model="run_description"></textarea>
              <label class="model-label" for="run_tags"> New run tags: </label>
              <input type="text" id="run_tags" name="run_tags"
                placeholder="Enter optional tags for this test run, separated by commas" v-model="run_tags" />
            </div>

            <div class="mt-3">
              <h4 class="fbb">Test Script Options</h4>
              <div class="three-input">
                <div class="tic">
                  <label class="model-label" for="browser_width">Browser Width</label>
                  <input type="text" id="browser_width" name="browser_width" placeholder="1920" v-model="browser_width" />
                </div>
                <div class="tic">
                  <label class="model-label" for="browser_height">Browser Height</label>
                  <input type="text" id="fname" name="browser_height" placeholder="1980" v-model="browser_height" />
                </div>

                <div class="tic">
                  <label class="model-label" for="max_wait_time">Max Wait Time (sec)</label>
                  <input type="text" id="max_wait_time" name="max_wait_time" placeholder="5.0" v-model="max_wait_time" />
                </div>
              </div>
            </div>

            <div class="all-options-container">
              <el-collapse v-model="collapseActive" accordion>
                <el-collapse-item title="More test options" name="1" class="options-accordion">
                  <div class="modal-checks-container">
                    <div class="modal-checks">
                      <input type="checkbox" id="ignore_disabled_elements" name="ignore_disabled_elements"
                        v-model="ignore_disabled_elements" />
                      <label for="ignore_disabled_elements">Ignore disabled elements</label>
                    </div>
                    <div class="modal-checks">
                      <input type="checkbox" id="ignore_invisible_elements" name="ignore_invisible_elements"
                        v-model="ignore_invisible_elements" />
                      <label for="ignore_invisible_elements">Ignore invisible elements</label>
                    </div>
                    <div class="modal-checks">
                      <input type="checkbox" id="extra_element_data" name="extra_element_data"
                        v-model="extra_element_data" />
                      <label for="extra_element_data">Collect extra element data & try to fix broken selectors
                        (slow)</label>
                    </div>
                    <div class="modal-checks">
                      <input type="checkbox" id="all_suggest" name="all_suggest" v-model="all_suggest" />
                      <label for="all_suggest">Generate selectors for all elements on each visited page
                        (slow)</label>
                    </div>
                    <div class="modal-checks">
                      <input type="checkbox" id="collect_page_interaction_data" name="collect_page_interaction_data"
                        v-model="collect_page_interaction_data" />
                      <label for="collect_page_interaction_data">Collect page-interaction event data for test case
                        augmentation</label>
                    </div>
                    <div class="modal-checks">
                      <input type="checkbox" id="enable_integrations" name="enable_integrations"
                        v-model="enable_integrations" />
                      <label for="enable_integrations">Trigger integrations (GitHub, GitLab, Jira) on test
                        failure</label>
                    </div>
                    <div class="modal-checks">
                      <input type="checkbox" id="downweight_strings" name="downweight_strings"
                        v-model="downweight_strings" />
                      <label for="downweight_strings">Try to avoid string/text methods when generating XPath
                        selectors</label>
                    </div>
                    <div class="modal-checks">
                      <input type="checkbox" id="verify_suggested_selectors" name="verify_suggested_selectors"
                        v-model="verify_suggested_selectors" />
                      <label for="verify_suggested_selectors">Verify suggested selectors</label>
                    </div>
                    <div class="modal-checks">
                      <input type="checkbox" id="generate_css_selectors" name="generate_css_selectors"
                        v-model="generate_css_selectors" />
                      <label for="generate_css_selectors">
                        Generate CSS selectors during run (slow, needed for Cypress export)
                      </label>
                    </div>

                    <label class="model-label mt-3" for="testrun_start_url">
                      Environment start URL for test run
                    </label>
                    <input id="testrun_start_url" name="testrun_start_url" placeholder="https://example.com"
                      v-model="testrun_start_url" />

                    <label class="model-label" for="bad_attributes_list">Avoid these attributes when generating
                      selectors</label>
                    <textarea id="bad_attributes_list" name="bad_attributes_list" rows="2"
                      placeholder="Enter attributes to avoid, separated by spaces, e.g. id class href"
                      v-model="bad_attributes_list"></textarea>

                    <label class="model-label" for="good_attributes_list">Use these attributes when generating
                      selectors</label>
                    <textarea id="good_attributes_list" name="good_attributes_list" rows="2"
                      placeholder="Enter attributes to use, separated by spaces, e.g. id class href"
                      v-model="good_attributes_list"></textarea>
                    <div class="modal-checks">
                      <input type="checkbox" id="timeout_cancel_run" name="timeout_cancel_run"
                        v-model="timeout_cancel_run" />
                      <label for="timeout_cancel_run">Time out and cancel this run in</label>
                      <input class="mod-chk-last-input" type="text" id="timeout_cancel_run_value"
                        name="timeout_cancel_run_value" v-model="timeout_cancel_run_value" placeholder="max 3600.0" />
                      seconds
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="
              toggleReRunModal = false;
            cleanUpState();
                              ">Cancel</base-button>
            <base-button type="primary" @click="rerunItem">Retry Test Run</base-button>
          </div>
        </template>
      </modal>
    </div>
    <div class="edit-test-modal-container">
      <modal :show.sync="toggleEditTestModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            Update test run name or description
          </h5>
        </template>
        <div class="re-run-modal">
          <div class="run-name">
            <label class="model-label" for="run_name">New run name*</label>
            <input type="text" id="run_name" name="run_name" placeholder="Enter a name for this test run"
              v-model="run_name" /><br />

            <div class="edit-test-select">
              <label class="model-label">New run state</label>
              <el-select class="select-primary w-100 h-100" v-model="editTestOpt.editTestOptCurrent"
                placeholder="Per page">
                <el-option class="select-primary" v-for="item in editTestOpt.editTestDropDownOpt" :key="item"
                  :label="item" :value="item">
                </el-option>
              </el-select>
            </div>
            <br />
            <label class="model-label" for="run_description">New run description</label>
            <textarea id="run_description" name="run_description" rows="5" placeholder="Enter description"
              v-model="run_description"></textarea><br />
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="toggleEditTestModal = false">Cancel</base-button>
            <base-button type="primary" @click="editItem()">Update</base-button>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-modal">
      <modal :show.sync="addTestModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            {{ $t("list_item_page.run_katalon_selenium") }}
          </h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-modal">
              <div class="run-name">
                <label class="model-label" for="run_name">
                  {{ $t("list_item_page.run_name") }}
                </label>
                <input type="text" id="run_name" name="run_name" :placeholder="$t('list_item_page.placeholder_run_name')"
                  v-model="run_name" />
                <label class="model-label" for="run_description">{{
                  $t("list_item_page.description")
                }}</label>
                <textarea id="run_description" name="run_description" rows="2"
                  :placeholder="$t('list_item_page.placeholder_run_description')" v-model="run_description"></textarea>
                <label class="model-label" for="run_name">
                  {{ $t("list_item_page.run_tags") }}
                </label>
                <input type="text" id="run_tags" name="run_tags" :placeholder="$t('list_item_page.placeholder_run_tags')"
                  v-model="run_tags" />
              </div>

              <div class="s-a-tabs">
                <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item">
                    <a class="nav-link" @click.prevent="setActive('settings')" :class="{ active: isActive('settings') }"
                      href="#home">{{ $t("list_item_page.settings") }}</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" @click.prevent="setActive('advanced')" :class="{ active: isActive('advanced') }"
                      href="#profile">{{ $t("list_item_page.advanced") }}</a>
                  </li>
                </ul>
                <div class="tab-content py-3" id="myTabContent">
                  <div class="tab-pane fade" :class="{ 'active show': isActive('settings') }" id="settings">
                    <div class="mt-2">
                      <h4 class="fbb">{{ $t("list_item_page.test_script") }}</h4>
                      <div class="three-input">
                        <div class="tic">
                          <label class="model-label" for="browser_width">{{
                            $t("list_item_page.browser_width")
                          }}</label>
                          <input type="text" id="browser_width" name="browser_width" placeholder="1920"
                            v-model="browser_width" />
                        </div>
                        <div class="tic">
                          <label class="model-label" for="browser_height">{{
                            $t("list_item_page.browser_height")
                          }}</label>
                          <input type="text" id="fname" name="browser_height" placeholder="1980"
                            v-model="browser_height" />
                        </div>

                        <div class="tic">
                          <label class="model-label" for="max_wait_time">{{
                            $t("list_item_page.max_wait")
                          }}</label>
                          <input type="text" id="max_wait_time" name="max_wait_time" placeholder="5.0"
                            v-model="max_wait_time" />
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <h4 class="fbb mt-1">
                        {{ $t("list_item_page.test_script_files") }}
                      </h4>
                      <div class="upload-craglist upload-file-div-wrapper">
                        <div class="drop-down-container" @dragover="dragover" @dragleave="dragleave" @drop="drop"
                          ref="dropDownContainer">
                          <input type="file" multiple="multiple" name="fields[assetsFieldHandle][]" id="swal-test-files"
                            class="w-px h-px opacity-0 overflow-hidden absolute fileInput" @change="onChange" ref="file"
                            accept=".xml, .side, .wring" />

                          <label for="assetsFieldHandle" class="block cursor-pointer">
                            <div>
                              {{ $t("list_item_page.upload_test_script") }}<br />
                              {{ $t("list_item_page.up_to_50") }}
                            </div>
                          </label>
                        </div>
                      </div>

                      <div class="file-lists-container mt-4" v-if="filesList.length !== 0">
                        <div class="file-head-clearall">
                          <div class="list-heading">
                            {{ $t("list_item_page.uploaded_files") }}
                          </div>
                          <div class="clearall" @click="removeAllFiles">
                            {{ $t("list_item_page.clear_all") }}
                          </div>
                        </div>

                        <div class="craglist-files">
                          <ul class="mt-1">
                            <li class="text-sm p-2" v-for="(file, index) in filesList" :key="index">
                              {{ file.name }}
                              <i @click="remove(filesList.indexOf(file))" class="ni ni-fat-remove rmv-btn"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" :class="{ 'active show': isActive('advanced') }" id="advanced">
                    <div class="modal-checks-container">
                      <div class="modal-checks">
                        <input type="checkbox" id="ignore_disabled_elements" name="ignore_disabled_elements"
                          v-model="ignore_disabled_elements" />
                        <label for="ignore_disabled_elements">{{
                          $t("list_item_page.ignore_disabled")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="ignore_invisible_elements" name="ignore_invisible_elements"
                          v-model="ignore_invisible_elements" />
                        <label for="ignore_invisible_elements">{{
                          $t("list_item_page.ignore_invisible")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="collect_page_interaction_data" name="collect_page_interaction_data"
                          v-model="collect_page_interaction_data" />
                        <label for="collect_page_interaction_data">{{
                          $t("list_item_page.collect_interaction")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="extra_element_data" name="extra_element_data"
                          v-model="extra_element_data" />
                        <label for="extra_element_data">{{
                          $t("list_item_page.collect_extra_element")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="all_suggest" name="all_suggest" v-model="all_suggest" />
                        <label for="all_suggest">{{
                          $t("list_item_page.generate_selectors")
                        }}</label>
                      </div>

                      <div class="modal-checks">
                        <input type="checkbox" id="downweight_strings" name="downweight_strings"
                          v-model="downweight_strings" />
                        <label for="downweight_strings">{{
                          $t("list_item_page.try_to_avoid")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="verify_suggested_selectors" name="verify_suggested_selectors"
                          v-model="verify_suggested_selectors" />
                        <label for="verify_suggested_selectors">{{
                          $t("list_item_page.verify_suggested")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="enable_integrations" name="enable_integrations"
                          v-model="enable_integrations" />
                        <label for="enable_integrations">{{
                          $t("list_item_page.trigger_integrations")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="generate_css_selectors" name="generate_css_selectors"
                          v-model="generate_css_selectors" />
                        <label for="generate_css_selectors">
                          Generate CSS selectors during run (slow, needed for Cypress export)
                        </label>
                      </div>
                      <br />
                      <label class="model-label mt-3" for="testrun_start_url">
                        Environment start URL for test run
                      </label>
                      <input id="testrun_start_url" name="testrun_start_url" placeholder="https://example.com"
                        v-model="testrun_start_url" />

                      <label class="model-label" for="bad_attributes_list">{{
                        $t("list_item_page.avoid_attributes")
                      }}</label>
                      <textarea id="bad_attributes_list" name="bad_attributes_list" rows="2"
                        :placeholder="$t('list_item_page.placeholder_avoid_attributes')"
                        v-model="bad_attributes_list"></textarea>

                      <label class="model-label" for="good_attributes_list">{{
                        $t("list_item_page.use_attributes")
                      }}</label>
                      <textarea id="good_attributes_list" name="good_attributes_list" rows="2"
                        :placeholder="$t('list_item_page.placeholder_use_attributes')"
                        v-model="good_attributes_list"></textarea>
                      <div class="modal-checks">
                        <input type="checkbox" id="continue_test_suite_on_fail" name="continue_test_suite_on_fail"
                          v-model="continue_test_suite_on_fail" />
                        <label for="continue_test_suite_on_fail">{{
                          $t("list_item_page.continue_test_suite")
                        }}</label>
                      </div>

                      <div class="modal-checks">
                        <input type="checkbox" id="timeout_cancel_run" name="timeout_cancel_run"
                          v-model="timeout_cancel_run" />
                        <label for="timeout_cancel_run">{{
                          $t("list_item_page.timeout_and_cancel")
                        }}</label>
                        <input class="mod-chk-last-input" type="text" id="timeout_cancel_run_value"
                          name="timeout_cancel_run_value" v-model="timeout_cancel_run_value" :placeholder="
                            $t('list_item_page.placeholder_timeout_and_cancel')
                          " />
                        {{ $t("list_item_page.seconds") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="
              addTestModal = false;
            cleanUpState();
                              ">{{ $t("list_item_page.close") }}</base-button>

            <el-tooltip :disabled="!(filesList.length === 0 || run_name === '')" :content="`${run_name === '' ? 'Run name cannot be empty' : ''} ${filesList.length === 0 ? 'Please upload at least one file' : ''
              }`" placement="top">
              <span>
                <base-button :disabled="filesList.length === 0 || run_name === ''" type="primary" @click="addTest">{{
                  $t("list_item_page.start_run") }}</base-button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-modal">
      <modal :show.sync="generateTestModal">
        <template slot="header">
          <h5 class="modal-title" id="generateTestModal">
            Generate test given natural language instruction
          </h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-modal" v-if="genLoading">
              <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
            </div>
            <div v-else class="add-test-modal">
              <div class="run-name">
                <label class="model-label" for="g_run_name">
                  {{ $t("list_item_page.run_name") }}
                </label>
                <input type="text" id="g_run_name" name="run_name"
                  :placeholder="$t('list_item_page.placeholder_run_name')" v-model="run_name" />
                <label class="model-label" for="g_run_url">
                  Start URL (required)
                </label>
                <input type="text" id="g_run_url" name="run_url" placeholder="https://www.amazon.com/"
                  v-model="testrun_start_url" />
                <label class="model-label" for="g_run_description">
                  Instruction (required)
                </label>
                <textarea id="g_run_description" name="run_description" rows="2"
                  placeholder="Enter a natural language instruction for an AI agent (e.g. Add a milk item in the cart)"
                  v-model="run_description"></textarea>
                <div v-for="(item, id) in personas" :key="id" style="width:100%">
                  <label class="model-label">
                    {{ "Scenario #" + id }}
                  </label>
                  <textarea :name="'run_description_' + id" rows="2" v-model="item.scenario"></textarea>

                </div>
              </div>



              <div class="s-a-tabs">
                <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item">
                    <a class="nav-link" @click.prevent="setActive('settings')" :class="{ active: isActive('settings') }"
                      href="#home">{{ $t("list_item_page.settings") }}</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" @click.prevent="setActive('advanced')" :class="{ active: isActive('advanced') }"
                      href="#profile">{{ $t("list_item_page.advanced") }}</a>
                  </li>
                </ul>
                <div class="tab-content py-3" id="myTabContent">
                  <div class="tab-pane fade" :class="{ 'active show': isActive('settings') }" id="settings">
                    <div class="mt-2">
                      <h4 class="fbb">{{ $t("list_item_page.test_script") }}</h4>
                      <div class="three-input">
                        <div class="tic">
                          <label class="model-label" for="browser_width">{{
                            $t("list_item_page.browser_width")
                          }}</label>
                          <input type="text" id="browser_width" name="browser_width" placeholder="1920"
                            v-model="browser_width" />
                        </div>
                        <div class="tic">
                          <label class="model-label" for="browser_height">{{
                            $t("list_item_page.browser_height")
                          }}</label>
                          <input type="text" id="fname" name="browser_height" placeholder="1980"
                            v-model="browser_height" />
                        </div>

                        <div class="tic">
                          <label class="model-label" for="max_wait_time">{{
                            $t("list_item_page.max_wait")
                          }}</label>
                          <input type="text" id="max_wait_time" name="max_wait_time" placeholder="5.0"
                            v-model="max_wait_time" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" :class="{ 'active show': isActive('advanced') }" id="advanced">
                    <div class="modal-checks-container">
                      <div class="modal-checks">
                        <input type="checkbox" id="ignore_disabled_elements" name="ignore_disabled_elements"
                          v-model="ignore_disabled_elements" />
                        <label for="ignore_disabled_elements">{{
                          $t("list_item_page.ignore_disabled")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="ignore_invisible_elements" name="ignore_invisible_elements"
                          v-model="ignore_invisible_elements" />
                        <label for="ignore_invisible_elements">{{
                          $t("list_item_page.ignore_invisible")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="collect_page_interaction_data" name="collect_page_interaction_data"
                          v-model="collect_page_interaction_data" />
                        <label for="collect_page_interaction_data">{{
                          $t("list_item_page.collect_interaction")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="extra_element_data" name="extra_element_data"
                          v-model="extra_element_data" />
                        <label for="extra_element_data">{{
                          $t("list_item_page.collect_extra_element")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="all_suggest" name="all_suggest" v-model="all_suggest" />
                        <label for="all_suggest">{{
                          $t("list_item_page.generate_selectors")
                        }}</label>
                      </div>

                      <div class="modal-checks">
                        <input type="checkbox" id="downweight_strings" name="downweight_strings"
                          v-model="downweight_strings" />
                        <label for="downweight_strings">{{
                          $t("list_item_page.try_to_avoid")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="verify_suggested_selectors" name="verify_suggested_selectors"
                          v-model="verify_suggested_selectors" />
                        <label for="verify_suggested_selectors">{{
                          $t("list_item_page.verify_suggested")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="enable_integrations" name="enable_integrations"
                          v-model="enable_integrations" />
                        <label for="enable_integrations">{{
                          $t("list_item_page.trigger_integrations")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input type="checkbox" id="generate_css_selectors" name="generate_css_selectors"
                          v-model="generate_css_selectors" />
                        <label for="generate_css_selectors">
                          Generate CSS selectors during run (slow, needed for Cypress export)
                        </label>
                      </div>
                      <br />
                      <label class="model-label" for="bad_attributes_list">{{
                        $t("list_item_page.avoid_attributes")
                      }}</label>
                      <textarea id="bad_attributes_list" name="bad_attributes_list" rows="2"
                        :placeholder="$t('list_item_page.placeholder_avoid_attributes')"
                        v-model="bad_attributes_list"></textarea>

                      <label class="model-label" for="good_attributes_list">{{
                        $t("list_item_page.use_attributes")
                      }}</label>
                      <textarea id="good_attributes_list" name="good_attributes_list" rows="2"
                        :placeholder="$t('list_item_page.placeholder_use_attributes')"
                        v-model="good_attributes_list"></textarea>
                      <div class="modal-checks">
                        <input type="checkbox" id="continue_test_suite_on_fail" name="continue_test_suite_on_fail"
                          v-model="continue_test_suite_on_fail" />
                        <label for="continue_test_suite_on_fail">{{
                          $t("list_item_page.continue_test_suite")
                        }}</label>
                      </div>

                      <div class="modal-checks">
                        <input type="checkbox" id="timeout_cancel_run" name="timeout_cancel_run"
                          v-model="timeout_cancel_run" />
                        <label for="timeout_cancel_run">{{
                          $t("list_item_page.timeout_and_cancel")
                        }}</label>
                        <input class="mod-chk-last-input" type="text" id="timeout_cancel_run_value"
                          name="timeout_cancel_run_value" v-model="timeout_cancel_run_value" :placeholder="
                            $t('list_item_page.placeholder_timeout_and_cancel')
                          " />
                        {{ $t("list_item_page.seconds") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="
              generateTestModal = false;
            cleanUpState();
                              ">{{ $t("list_item_page.close") }}</base-button>
            <el-tooltip :disabled="!(run_description === '')" :content="`${run_description === '' ? 'Instruction cannot be empty' : ''
              }`" placement="top">
              <span>
                <base-button :disabled="run_description === ''" type="secondary" @click="refinePrompt">Refine
                  prompt</base-button>
              </span>
            </el-tooltip>
            <el-tooltip :disabled="!(run_name === '' || testrun_start_url === '' || run_description === '')" :content="`${run_name === '' ? 'Run name cannot be empty;' : ''} ${testrun_start_url === '' ? 'Start URL cannot be empty;' : ''
              } ${run_description === '' ? 'Instruction cannot be empty' : ''
              }`" placement="top">
              <span>
                <base-button :disabled="run_name === '' || testrun_start_url === '' || run_description === ''"
                  type="primary" @click="generateTest">Generate</base-button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";
export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DataList,
  },

  data() {
    return {
      stepsOnboarding: {
        step: [
          {
            titel: "Type table list",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "right",
            element: "listItemPageTableListe",
          },
          {
            titel: "Type table card",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "right",
            element: "listItemPageTableCard",
          },
          {
            titel: "Type table thumb",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "right",
            element: "listItemPageTableThumb",
          },
          {
            titel: "Filter",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "right",
            element: "listItemPageTableFilter",
          },
          {
            titel: "Add test",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "right",
            element: "listItemPageTableAddtest",
          },
          {
            titel: "Number of elements ",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "bottom",
            element: "listItemPageTableNumberElement",
          },
          {
            titel: "Edit test",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "bottom",
            element: "listItemPageTableEditTestBtn",
          },
          {
            titel: "Rerun test",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "bottom",
            element: "listItemPageTableRerunTestBtn",
          },
          {
            titel: "Delete test",
            desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
            position: "bottom",
            element: "listItemPageTableDeleteTestBtn",
          },
        ],
        page: "listItemPage"
      },
      componentKey: 0,
      singleTestId: true,
      editTestOpt: {
        editTestOptCurrent: "Select",
        editTestDropDownOpt: ["passed", "failed", "finished"],
      },
      forEditTestRowReqId: "",
      forReRunTest: "",
      toggleEditTestModal: false,
      toggleReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      generate_css_selectors: false,
      testrun_start_url: "",
      personas: [],
      filesList: [],
      dataView: "card",
      addTestModal: false,
      generateTestModal: false,
      log: console.log,
      query: null,
      selectedRows: [],
      sort: "created_at",
      total: 0,
      filteredTotal: [],
      filteredResults: [],
      allScreenshots: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        lastPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      toShow: [],
      to: 0,
      from: 0,
      items: [],
      loading: true,
      user: null,
      polling: 5000,
      pollStatus: false,
      showIntegrationModal: false,
      collapseActive: null,
      tHeader: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: this.$t("list_item_page.tags"), key: "runDesc", sortable: false },
        { label: this.$t("list_item_page.status"), key: "", sortable: false },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: this.$t("list_item_page.total_steps"), key: "", sortable: false },
        { label: "", key: "", sortable: false },
      ],
      tHeader2: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: "", key: "", sortable: false },
        { label: this.$t("list_item_page.tags"), key: "runDesc", sortable: false },
        { label: this.$t("list_item_page.status"), key: "", sortable: false },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: this.$t("list_item_page.total_steps"), key: "", sortable: false },
        { label: "", key: "", sortable: false },
      ],
      toggleShowFilters: false,
      editedFilter: {
        name: { value: "", operator: "lk" },
        tags: { value: "", operator: "ct" },
        origin: { value: "", operator: "lk" },
        acceptedAt: { value: "", operator: "ge" },
        startedAt: { value: "", operator: "ge" },
        finishedAt: { value: "", operator: "ge" },
        status: { value: "", operator: "lk" },
        isActive: { value: "", operator: "eq" },
        description: { value: "", operator: "lk" },
        testSuiteId: { value: "", operator: "eq" },
        testSuiteRunId: { value: "", operator: "eq" },
        projectId: { value: "", operator: "eq" },
        teamId: { value: "", operator: "eq" },
        orgId: { value: "", operator: "eq" },
        browserUrl: { value: "", operator: "lk" },
        selector: { value: "", operator: "lk" },
        stepAction: { value: "", operator: "lk" },
        stepCount: { value: "", operator: "eq" },
      },
      newFilter: {
        name: { value: "", operator: "lk" },
        tags: { value: "", operator: "ct" },
        origin: { value: "", operator: "lk" },
        acceptedAt: { value: "", operator: "ge" },
        startedAt: { value: "", operator: "ge" },
        finishedAt: { value: "", operator: "ge" },
        status: { value: "", operator: "lk" },
        isActive: { value: "", operator: "eq" },
        description: { value: "", operator: "lk" },
        testSuiteId: { value: "", operator: "eq" },
        testSuiteRunId: { value: "", operator: "eq" },
        projectId: { value: "", operator: "eq" },
        teamId: { value: "", operator: "eq" },
        orgId: { value: "", operator: "eq" },
        browserUrl: { value: "", operator: "lk" },
        selector: { value: "", operator: "lk" },
        stepAction: { value: "", operator: "lk" },
        stepCount: { value: "", operator: "eq" },
      },
      filterStatus: [
        { value: "finished", label: "Finished" },
        { value: "passed", label: "Passed" },
        { value: "queued", label: "Queued" },
        { value: "failed", label: "Failed" },
        { value: "processing", label: "Processing" },
        { value: "cancelled", label: "Cancelled" },
        { value: "timed out", label: "Timed out" },
      ],
      filteredElt: undefined,
      filterLimit: undefined,
      filterLoading: false,
      oldCursor: undefined,
      currentCursor: undefined,
      genLoading: false,
    };
  },
  async created() {
    this.pagination.perPage =
      sessionStorage.getItem("listItemPageNbr") !== null
        ? parseInt(sessionStorage.getItem("listItemPageNbr"))
        : 10;
    this.dataView =
      sessionStorage.getItem("data-view") !== null
        ? sessionStorage.getItem("data-view")
        : "list";

    await Promise.all([this.getList(), this.initWebsocketStreaming()]);
  },

  methods: {
    //#region helpers
    goToGenerateTest() {
      this.$router.push({
        name: "Generate Test",
      });
    },
    isEmptyObj(obj) {
      return (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      );
    },
    removeEmpty(obj, key) {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) =>
          key
            ? v[key] !== null && v[key] !== ""
            : v !== null && v !== ""
        )
      );
    },
    convertDateStringToSeconds(dateString) {
      const date = new Date(dateString);
      const seconds = date.getTime() / 1000;
      return seconds;
    },
    pollSingleTest(a) {
      const index = this.items.findIndex((el) => el.requestId === a.runRequestId);

      if (index === -1) {
        if (a.runCurrentStatus === "processing") {
          this.items.unshift({
            requestId: a.runRequestId,
            runName: a.runName,
            xpathCount: a.runStepCount ? a.runStepCount : "0",
            runTags: a.runTags,
            acceptedAt: this.convertDateStringToSeconds(a.runAcceptedAt),
            stepCount: a.runStepCount ? a.runStepCount : "0",
            status: a.runCurrentStatus,
            runDesc: a.runDescription,
            runOptions: a.runOptions,
            finishedAt: this.convertDateStringToSeconds(a.runFinishedAt),
            startedAt: this.convertDateStringToSeconds(a.runStartedAt),
            thumbnail: a.runCurrentThumbnail,
            runTestSuiteName: a.runTestSuiteName,
            testSuiteId: a.runSuiteId,
            testSuiteRunId: a.runSuiteRunId,
            testSuiteName: a.runTestSuiteName,
          });
        }
      } else if (index > -1) {
        if (this.items[index].status === "processing") {
          this.items[index] = {
            requestId: a.runRequestId,
            runName: a.runName,
            xpathCount: a.runStepCount ? a.runStepCount : "0",
            runTags: a.runTags,
            acceptedAt: this.convertDateStringToSeconds(a.runAcceptedAt),
            stepCount: a.runStepCount ? a.runStepCount : "0",
            status: a.runCurrentStatus,
            runDesc: a.runDescription,
            runOptions: a.runOptions,
            finishedAt: this.convertDateStringToSeconds(a.runFinishedAt),
            startedAt: this.convertDateStringToSeconds(a.runStartedAt),
            thumbnail: a.runCurrentThumbnail,
            runTestSuiteName: a.runTestSuiteName,
            testSuiteId: a.runSuiteId,
            testSuiteRunId: a.runSuiteRunId,
            testSuiteName: a.runTestSuiteName,
          };
          this.filteredResults = this.pollSingleTestfilteredResultSet();
          this.toShow = this.filteredResults;
        }
        this.componentKey += 1;
      }
    },
    savePagination() {
      sessionStorage.setItem("listItemPageNbr", this.pagination.perPage);
      // this.getList();
      this.processFilter();
    },
    saveDataView(val) {
      this.dataView = val;
      sessionStorage.setItem("data-view", this.dataView);
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      let dis_sec = Math.ceil(secs % 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(a) {
      let sec = Number(a.finishedAt - a.startedAt);
      return this.hhmmss(sec);
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    remove(i) {
      this.filesList.splice(i, 1);
    },
    removeAllFiles() {
      this.filesList = [];
    },
    formatDate(val) {
      let day_display = "";
      let time_display = "";
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (time_zone.toLowerCase().includes("america")) {
        day_display = moment(val * 1000).format("MMM Do YYYY");
        time_display = moment(val * 1000).format("LT");
      } else {
        day_display = moment(val * 1000).format("Do MMM YYYY");
        time_display = moment(val * 1000).format("HH:mm");
      }

      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }

      return day_display + " " + time_display + " " + time_zone;
    },
    formatDateToISO(val) {
      return moment(val * 1000).toISOString();
    },
    /**
     * Resets the state of the forms to their default values
     * Apparently required because when a modal is closed, the state isn't reset
     */
    cleanUpState() {
      this.run_name = "";
      this.run_description = "";
      this.run_tags = "";
      this.browser_width = "1920";
      this.browser_height = "1080";
      this.max_wait_time = "5.0";
      this.ignore_disabled_elements = "1";
      this.ignore_invisible_elements = "1";
      this.collect_page_interaction_data = "1";
      this.extra_element_data = 0;
      this.downweight_strings = 0;
      this.all_suggest = 0;
      this.try_to_avoid = false;
      this.bad_attributes_list = "";
      this.good_attributes_list = "";
      this.continue_test_suite_on_fail = "true";
      this.timeout_cancel_run = "";
      this.timeout_cancel_run_value = null;
      this.handle_failure = "";
      this.verify_suggested_selectors = false;
      this.enable_integrations = true;
      this.generate_css_selectors = false;
      this.testrun_start_url = "";
      this.collapseActive = null;
    },
    handleSizeChange(val) {
      this.pagination.perPage = val;
      this.handleCurrentChange(this.pagination.currentPage);
    },
    //#endregion

    //#region test
    editTestModal(row) {
      this.run_name = row.runName;
      this.run_description = row.runDesc;
      this.editTestOpt.editTestOptCurrent = row.status;
      this.forEditTestRowReqId = row.requestId;
      this.toggleEditTestModal = true;
    },
    addTest() {
      const ifMultipleFiles = this.filesList.length > 1;
      const files = this.filesList;
      let scriptType = "";
      if (files[0].type === "text/xml") {
        scriptType = "katalonxml";
      } else {
        scriptType = "JSON";
      }
      var single = false;
      var double = false;
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail ? "continue" : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        generateCssSelectors: this.generate_css_selectors,
        runTags: parsedRunTags.join(","),
      };

      if (this.testrun_start_url && this.testrun_start_url.length > 0) {
        runOptions["runStartUrl"] = this.testrun_start_url;
      }

      const formData = new FormData();
      let reqHeaders = {};
      if (ifMultipleFiles) {
        double = true;
        reqHeaders = {
          "x-test-script-type": scriptType,
          "Content-Type": "multipart/form-data;",
        };
        formData.append("suiteName", this.run_name);
        formData.append("suiteDesc", this.run_description);
        formData.append("suiteRunName", `Test suite run - ${this.run_name}`);
        formData.append("suiteRunOptions", JSON.stringify(runOptions));
        formData.append("suiteRunTags", JSON.stringify(parsedRunTags));

        const suiteFiles = JSON.stringify(
          files.map((item, index) => {
            formData.append(
              "attachedFile",
              this.filesList[index],
              this.filesList[index].name
            );
            return {
              fileName: item.name,
              fileType: item.type,
              position: index + 1,
            };
          })
        );
        formData.append("suiteFiles", suiteFiles);
        this.addTestModal = false;
      } else {
        single = true;
        reqHeaders = {
          "Content-Type": "multipart/form-data;",
          "x-test-script-type": scriptType,
          "x-Rp-launch": this.run_name,
          "x-rp-description": this.run_description,
          "x-filter-displayed": this.ignore_disabled_elements,
          "x-filter-enabled": this.ignore_invisible_elements,
          "x-runner-resolution": `${this.browser_width}x${this.browser_height}`,
          "x-runner-step-wait": this.max_wait_time,
          "x-interceptor-failure": x_interceptor_failure,
          "x-neighbor-maxdist": x_neighbor_maxdist,
          "x-all-suggest-enabled": this.all_suggest,
          "x-use-tg-analyze": this.collect_page_interaction_data,
          "X-Good-Attributes": this.good_attributes_list,
          "X-Bad-Attributes": this.bad_attributes_list,
          "X-Downweight-Strings": this.downweight_strings ? "1" : "0",
          "X-Generate-Css-Selectors": this.generate_css_selectors ? "1" : "0",
          "X-Run-Tags": runOptions.runTags,
          "X-Runner-Verify-Selectors": this.verify_suggested_selectors ? "1" : "0",
        };

        if (runOptions["runStartUrl"] !== null && runOptions["runStartUrl"] !== undefined) {
          reqHeaders["X-Start-Url"] = runOptions["runStartUrl"];
        }

        let runnerTimeoutSec = null;
        runnerTimeoutSec = parseFloat(this.timeout_cancel_run_value);
        if (
          !Number.isNaN(runnerTimeoutSec) &&
          runnerTimeoutSec > 0 &&
          runnerTimeoutSec < 3600
        ) {
          reqHeaders["X-Runner-Timeout"] = `${runnerTimeoutSec}`;
        }

        formData.append("attachedFile", this.filesList[0], this.filesList[0].name);
        this.addTestModal = false;
      }
      if (single) {
        axios
          .post(process.env.VUE_APP_API_URL_PREFIX + "/testscript/v1", formData, {
            headers: reqHeaders,
          })
          .then((response) => {
            if (response) {
              this.$notify({
                type: "success",
                message: this.run_name + " added successfully.",
              });
              this.run_name = "";
              this.run_description = "";
              this.filesList = [];
              this.addTestModal = false;
              this.pollStatus = true;
              this.singleTestId = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              type: "error",
              message: this.run_name + " was not added successfully. Details: ", error,
            });
          });
      }
      if (double) {
        axios
          .post(process.env.VUE_APP_API_URL_PREFIX + "/testsuite/v1", formData, {
            headers: reqHeaders,
          })
          .then((response) => {
            if (response) {
              this.$notify({
                type: "success",
                message: this.run_name + " added successfully.",
              });
              this.run_name = "";
              this.run_description = "";
              this.filesList = [];
              this.addTestModal = false;
              this.pollStatus = true;
              this.singleTestId = true;
            }
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: this.run_name + " was not added successfully. Details: ", error,
            });
          });
      }
    },
    refinePrompt() {
      this.genLoading = true;
      const payload = {
        "instruction": this.run_description,
      }

      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/generate_scenarios", payload)
        .then((response) => {
          if (response) {
            console.log(response)
            this.testrun_start_url = response.data.url;
            this.run_description = response.data.scenario;
            this.personas = response.data.personas
            this.genLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "error",
            message: "Cannot refine prompt. Details: ", error,
          });
          this.genLoading = false;
        });
    },
    generateTest() {
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail ? "continue" : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        generateCssSelectors: this.generate_css_selectors,
        runTags: parsedRunTags.join(","),
      };



      let instructions = [this.run_description].concat(this.personas.map(p => p.scenario))
      const payload = {
        "run_name": this.run_name,
        "url": this.testrun_start_url,
        "instructions": instructions,
        "run_options": runOptions
      }

      this.generateTestModal = false;

      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/generate_test", payload)
        .then((response) => {
          if (response) {
            this.$notify({
              type: "success",
              message: this.run_name + " added successfully.",
            });
            this.run_name = "";
            this.run_description = "";
            this.testrun_start_url = "";
            this.personas = []
            this.generateTestModal = false;
            this.pollStatus = true;
            this.singleTestId = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "error",
            message: this.run_name + " was not added successfully. Details: ", error,
          });
        });
    },
    onChange() {
      let fileFromState = this.$refs.file.files;
      Object.values(fileFromState).map((value, index) => {
        this.filesList.push(value);
      });
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        this.$refs.dropDownContainer.parentElement.classList.add("file-hover");
      }
    },
    dragleave() {
      this.$refs.dropDownContainer.parentElement.classList.remove("file-hover");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      if (event.srcElement.files.length) {
        let fileFromState = this.$refs.file.files;
        Object.values(fileFromState).map((value, index) => {
          this.filesList.push(value);
        });
      }
      this.$refs.dropDownContainer.parentElement.classList.remove("file-hover");
    },
    _onRowClicked(elt) {
      if (elt.testSuiteId) {
        this.$router.push({
          name: "Suite View Item",
          params: {
            suiteId: elt.testSuiteId,
            id: elt.testSuiteRunId,
          },
        });
      } else {
        this.$router.push({
          name: "View Item",
          params: {
            id: elt.requestId,
          },
        });
      }
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    viewItem(row) {
      this.$router.push({ name: "View Item", params: { id: row.requestId } });
    },
    //#endregion

    //#region async
    async handleCurrentChange(val, withCursor) {
      const old = this.from;

      this.from = (val - 1) * this.pagination.perPage;
      this.to = parseInt(this.from) + parseInt(this.pagination.perPage);

      // if there's an active filter, make sure to add it to the URL
      let filterFragment = "";
      if (this.filteredElt !== undefined) {
        filterFragment = Object.keys(this.filteredElt)
          .map((e) => [`${e}~${this.filteredElt[e].operator}~${this.filteredElt[e].value}`])
          .join("&filter=");
      }
      if (filterFragment.length > 0 && !filterFragment.startsWith("&filter=")) {
        filterFragment = "&filter=" + filterFragment;
      }

      // console.log(
      //     `handleCurrentChange: ` +
      //     `filters enabled = ${this.filteredElt !== undefined}, ` +
      //     `filter URL fragment = ${filterFragment}`
      // );

      // console.log("Withcursor: ", withCursor)
      // console.log("Current cursor: ", this.currentCursor)

      if (withCursor) {
        this.loading = true;
        // console.log("Current cursor: ", this.currentCursor)
        const isNext = old < this.from;
        const searchUrl = isNext
          ? `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.currentCursor}&limit=${this.pagination.perPage}${filterFragment}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.oldCursor}&limit=${this.pagination.perPage}${filterFragment}`;

        console.log(`handleCurrentChange: final composed search URL: ${searchUrl}`);

        try {
          const res = await axios.get(searchUrl);
          if (res.status === 200) {
            this.items = Object.values(res.data.response);
            this.filteredResults = this.filteredResultSet;
            this.toShow = this.filteredResults;
            this.total = res.data.statistics.total_tests;
            this.oldCursor = this.currentCursor ?? undefined;
            this.currentCursor = res.data.cursor ?? undefined;
          } else {
            this.$notify({
              type: "danger",
              message:
                `Error while filtering: ${res.data.message}. Contact support if this error persists.`,
            });
          }
        } catch (err) {
          if (err.response && err.response.status && err.response.status === 404) {
            this.$notify({
              type: "warning",
              message: "There are no more results for this filter and items-per-page combination",
            });
          } else {
            this.$notify({
              type: "danger",
              message: "Error while filtering, likely no more results for this filter and items-per-page combination.",
            });
          }
        }
        this.loading = false;
      } else {
        this.toShow = this.filteredResults.slice(this.from, this.to);
      }
    },
    async handleNavigation(target) {
      this.loading = true;
      this.pagination.currentPage =
        target === "next"
          ? this.pagination.currentPage + 1
          : this.pagination.currentPage - 1;

      // if there's an active filter, make sure to add it to the URL
      let filterFragment = "";
      if (this.filteredElt !== undefined) {
        filterFragment = Object.keys(this.filteredElt)
          .map((e) => [`${e}~${this.filteredElt[e].operator}~${this.filteredElt[e].value}`])
          .join("&filter=");
      }
      if (filterFragment.length > 0 && !filterFragment.startsWith("&filter=")) {
        filterFragment = "&filter=" + filterFragment;
      }
      console.log(
        `handleNavigation: pagination target: ${target}, ` +
        `filters enabled = ${this.filteredElt !== undefined}, ` +
        `filter URL fragment = ${filterFragment}`
      );

      const searchUrl =
        target === "next"
          ? `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.currentCursor}&limit=${this.pagination.perPage}${filterFragment}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.oldCursor}&limit=${this.pagination.perPage}${filterFragment}`;

      console.log(`handleNavigation: final composed search URL: ${searchUrl}`);

      try {
        const res = await axios.get(searchUrl);
        if (res.status === 200) {
          this.items = Object.values(res.data.response);
          this.filteredResults = this.filteredResultSet;
          this.toShow = this.filteredResults;
          this.from = (this.pagination.currentPage - 1) * this.pagination.perPage;
          this.to = parseInt(this.from) + parseInt(this.pagination.perPage);
          this.total = res.data.statistics.total_tests;
          this.oldCursor = res.data.back_cursor;
          this.currentCursor = res.data.cursor;
        } else {
          this.$notify({
            type: "danger",
            message: `Error while filtering: ${res.data.message}. Contact support if this error persists.`,
          });
        }
      } catch (err) {
        if (err.response && err.response.status && err.response.status === 404) {
          this.$notify({
            type: "warning",
            message: "There are no more results for this filter and items-per-page combination",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Error while filtering, likely no more results for this filter and items-per-page combination.",
          });
        }
      }
      this.loading = false;
    },
    async processFilter() {
      let parsedFilters = this.removeEmpty({ ...this.editedFilter }, "value");

      // fix the format for the provided datestring in the date columns
      if (parsedFilters.acceptedAt !== undefined && parsedFilters.acceptedAt.value !== undefined) {
        parsedFilters.acceptedAt.value = moment(parsedFilters.acceptedAt.value).toISOString();
      }
      if (parsedFilters.startedAt !== undefined && parsedFilters.startedAt.value !== undefined) {
        parsedFilters.startedAt.value = moment(parsedFilters.startedAt.value).toISOString();
      }
      if (parsedFilters.finishedAt !== undefined && parsedFilters.finishedAt.value !== undefined) {
        parsedFilters.finishedAt.value = moment(parsedFilters.finishedAt.value).toISOString();
      }
      console.log(parsedFilters);

      let usedFilterLimit = this.pagination.perPage;
      if (this.filterLimit !== undefined
        && this.filterLimit !== null
        && !Number.isNaN(this.filterLimit)
        && this.filterLimit > 0) {
        usedFilterLimit = this.filterLimit;
      }

      let limitFrag = `limit=${usedFilterLimit}`;
      if (this.isEmptyObj(parsedFilters)) {
        limitFrag = "?" + limitFrag;
      } else {
        limitFrag = "&" + limitFrag;
      }

      console.log(
        `processFilter: this.pagination.perPage = ${this.pagination.perPage}, ` +
        `this.filterLimit = ${this.filterLimit}, limit expression = ${limitFrag}`
      );
      const searchUrlFrag = this.isEmptyObj(parsedFilters)
        ? limitFrag
        : "?filter=" +
        Object.keys(parsedFilters)
          .map((e) => [`${e}~${parsedFilters[e].operator}~${parsedFilters[e].value}`])
          .join("&filter=")
          .concat(limitFrag);
      console.log(`processFilter: search URL = ${searchUrlFrag}`);
      try {
        this.filterLoading = true;
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query${searchUrlFrag}`
        );
        // console.log("Got res: ", res)
        if (res.status === 200) {
          this.items = Object.values(res.data.response);
          this.filteredResults = this.filteredResultSet;
          this.from = 0;
          this.total = res.data.statistics.num_tests;
          this.oldCursor = this.currentCursor ?? undefined;
          this.currentCursor = res.data.cursor ?? undefined;

          if (searchUrlFrag) {
            this.filteredElt = Object.assign({}, parsedFilters)
          }
          // console.log("this.newFilter: ", this.newFilter);
          this.handleCurrentChange(this.pagination.currentPage, false);
        }

        else if (res && res.response && res.response.status === 404) {
          this.$notify({
            type: "info",
            message: "No search results found for the filter, removing filter",
          });
        }

      } catch (e) {
        this.$notify({
          type: "danger",
          message: "An error occurred while filtering, if it persists, please contact support",
        });
      } finally {
        this.filterLoading = false;
        this.filterLimit = undefined;

        this.editedFilter = JSON.parse(JSON.stringify(this.newFilter))
        // console.log("Edited filter is: ", this.editedFilter)
        this.toggleShowFilters = false;
      }
    },
    async initWebsocketStreaming() {
      const auth_token = localStorage.getItem("vue-authenticate.access_token");
      let url = "";
      if (process.env.VUE_APP_WEBSOCKET_API_URL === undefined) {
        url = `wss://${window.location.host}/interceptor/runstream/v1`;
      } else {
        url = `${process.env.VUE_APP_WEBSOCKET_API_URL}/runstream/v1`;
      }

      try {
        const socket = new WebSocket(url);
        socket.onopen = function (e) {
          socket.send(`AUTH ${auth_token}`);
        };
        socket.onmessage = (event) => {
          const response = JSON.parse(event.data);
          if (Object.keys(response.events).length === 0) {
            return;
          } else {
            const events = response.events;
            const uniqueValues = [...new Set(Object.values(events))];
            for (event in uniqueValues) {
              if (uniqueValues[0] && uniqueValues[0].eventType === "test run event") {
                this.pollSingleTest(uniqueValues[0]);
              }
            }
          }
        };
      } catch {
        console.log("error?");
      }
    },
    async getScreenShot(id) {
      if (id) {
        await axios
          .get(`${process.env.VUE_APP_API_URL_PREFIX}/results/v1/test/${id}`)
          .then((response) => {
            const b = Object.values(response.data.xpathEncounters);
            this.allScreenshots.push(
              `data:image/png;base64,${b[b.length - 1].initialPageScreenshot}`
            );
          })
          .catch((error) => {
            this.allScreenshots.push("");
          });
      }
    },
    async getList() {
      this.loading = this.pollStatus === false;
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?limit=${this.pagination.perPage}`
        );

        if (res.status === 200) {
          this.items = Object.values(res.data.response);
          this.filteredResults = this.filteredResultSet;
          this.from = 0;
          this.total = res.data.statistics.total_tests;
          this.pagination.lastPage = Math.ceil(this.total / this.pagination.perPage);
          this.oldCursor = res.data.back_cursor;
          this.currentCursor = res.data.cursor;

          if (this.$refs.dataList) this.$refs.dataList.handleFilter("parentCall");
          else this.handleCurrentChange(this.pagination.currentPage, false);
        }
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   message: "Cannot load user Test... Try refresh",
        // });
      } finally {
        this.loading = false;
      }
    },
    async resetFilter(all) {
      this.editedFilter = JSON.parse(JSON.stringify(this.newFilter))

      console.log(this.newFilter)

      if (all) {
        this.filteredElt = undefined
        await this.getList()
      }
    },
    async rerunItemModal(row) {
      this.forReRunTest = row;
      this.run_name = row.testSuiteId ? row.testSuiteName : row.runOptions.test_run_name;
      this.run_description = row.runOptions.test_run_description;
      this.ignore_disabled_elements = row.runOptions.filter_disabled_elements;
      this.ignore_invisible_elements = row.runOptions.filter_invisible_elements;
      this.run_tags = row.runTags
        .filter((item) => {
          return item !== "test run retry" && item !== "integration enabled";
        })
        .join(", ");
      this.extra_element_data = row.extra_element_data;
      this.all_suggest = row.runOptions.allsuggest_enabled;
      this.collect_page_interaction_data = row.runOptions.use_tg_analyze;
      this.handle_failure = row.runOptions.handle_failure;
      this.enable_integrations = row.runTags.includes("integration enabled");
      this.downweight_strings = !!row.runOptions["xpath_downweight_strings"];
      this.verify_suggested_selectors = !!row.runOptions["run_selector_verification"];
      this.bad_attributes_list = row.runOptions["bad_attribute_list"];
      this.good_attributes_list = row.runOptions["good_attribute_list"];
      this.testrun_start_url = row.runOptions["testrun_start_url"];
      this.generate_css_selectors = row.runOptions["generate_css_selectors"];
      if (
        row.runOptions["user_set_timeout"] !== null &&
        row.runOptions["user_set_timeout"] !== undefined
      ) {
        this.timeout_cancel_run = true;
        this.timeout_cancel_run_value = row.runOptions["user_set_timeout"];
      }

      if (this.run_tags.includes("ai-generated")) {
        this.generateTestModal = true;
      }
      else {
        this.toggleReRunModal = true;
      }

    },
    async rerunItem() {
      const row = this.forReRunTest;
      const requestId = row.requestId;
      const headers = {
        "Content-Type": "application/json",
      };

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      let reRunTestSuite = false;
      let reRunForTestSuiteData = {
        suiteRunName: this.run_name,
        suiteRunDesc: this.run_description,
        suiteRunOptions: {
          filterDisplayed: this.ignore_invisible_elements,
          filterEnabled: this.ignore_disabled_elements,
          allSuggestEnabled: this.all_suggest,
          useTgAnalyze: this.collect_page_interaction_data,
          runnerResolution: `${this.browser_width}x${this.browser_height}`,
          runnerStepWait: this.max_wait_time,
          runnerUserTimeout: this.timeout_cancel_run_value,
          runSelectorVerification: this.verify_suggested_selectors,
          badAttributeList: this.bad_attributes_list,
          goodAttributeList: this.good_attributes_list,
          xpathDownweightStrings: this.downweight_strings,
          generateCssSelectors: this.generate_css_selectors,
          runStartUrl: this.testrun_start_url,
        },
        suiteRunTags: parsedRunTags,
        suiteFailType: this.continue_test_suite_on_fail ? "continue" : "fail-on-test",
      };

      if (row.testSuiteId) {
        reRunTestSuite = true;
      } else {
        let reRunWithUpdatedFields = JSON.stringify({
          runName: this.run_name,
          runDesc: this.run_description,
          browserWidth: this.browser_width,
          browserHeight: this.browser_height,
          stepWait: this.max_wait_time,
          ignoreInvisible: this.ignore_invisible_elements,
          ignoreDisabled: this.ignore_disabled_elements,
          slowHeal: this.extra_element_data,
          allSuggest: this.all_suggest,
          tgAnalyze: this.collect_page_interaction_data,
          runTags: parsedRunTags.join(","),
          runnerUserTimeout: this.timeout_cancel_run_value,
          runSelectorVerification: this.verify_suggested_selectors,
          badAttributeList: this.bad_attributes_list,
          goodAttributeList: this.good_attributes_list,
          xpathDownweightStrings: this.downweight_strings,
          generateCssSelectors: this.generate_css_selectors,
          runStartUrl: this.testrun_start_url,
        });
        axios
          .post(
            process.env.VUE_APP_API_URL_PREFIX + `/testrun/v1/redo/${requestId}`,
            reRunWithUpdatedFields,
            { headers: headers }
          )
          .then((response) => {
            if (response.status !== 202) {
              this.$notify({
                type: "warning",
                message: "Could not rerun test!",
              });
            } else {
              this.$notify({
                type: "success",
                message: "Rerunning test!",
              });
              this.forReRunTest = "";
              this.pollStatus = true;
            }
          });
        this.toggleReRunModal = false;
        this.cleanUpState();
      }

      if (reRunTestSuite) {
        axios
          .post(
            process.env.VUE_APP_API_URL_PREFIX + `/testsuite/v1/${row.testSuiteId}/run`,
            reRunForTestSuiteData,
            { headers: headers }
          )
          .then((response) => {
            if (response.status !== 202) {
              this.$notify({
                type: "warning",
                message: "Could not rerun test!",
              });
            } else {
              this.$notify({
                type: "success",
                message: "Rerunning test!",
              });
              this.forReRunTest = "";
              this.pollStatus = true;
            }
          });
        this.toggleReRunModal = false;
        this.cleanUpState();
      }
    },
    async cancelRunItem(row) {
      const requestId = row.requestId;
      const headers = {
        "Content-Type": "application/json",
      };

      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, cancel it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post(
                process.env.VUE_APP_API_URL_PREFIX + `/testrun/v1/cancel/${requestId}`,
                { headers }
              )
              .then((response) => {
                this.$notify({
                  type: "success",
                  message: "Stopped test run!",
                });
                this.getList();
              })
              .catch((err) => {
                this.$notify({
                  type: "danger",
                  message: "Error stopping test run!",
                });
              });
          }
        });
    },
    async deleteItem(row) {
      const requestId = row.requestId;
      const confirmation = await swal.fire({
        title: "Test run: '" + row.runName + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep the test.",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        this.loading = true;
        if (confirmation.value === true) {
          // TODO: check with backend on permissions to delete
          await this.$store.dispatch("items/destroy", requestId);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Test deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Cannot delete this test. If its persist, contact support",
        });
      } finally {
        this.loading = false;
      }
    },
    async editItem() {
      let formValues = {
        nameChange: this.run_name,
        statusChange: this.editTestOpt.editTestOptCurrent,
        descChange: this.run_description,
      };

      if (formValues) {
        const params = {
          id: this.forEditTestRowReqId,
          values: formValues,
        };
        this.toggleEditTestModal = false;
        this.run_name = "";
        this.editTestOpt.editTestOptCurrent = "";
        this.run_description = "";
        await this.$store.dispatch("items/update", params);
        await this.getList();
        this.$notify({
          type: "success",
          message: this.run_name + " updated successfully.",
        });
      }
    },
    //#endregion
    pollSingleTestfilteredResultSet() {
      return this.items.map((item) => {
        if (item.testSuiteId && !item.runName.includes('Test Suite -')) {
          item.runName = `Test Suite - ${item.testSuiteName} | ${item.runName}`;
          item.runDesc = item.testSuiteRunDesc;
          return item;
        } else {
          return item;
        }
      });
      // let suitesEncountered = new Set();
      // return this.items.filter((item) => {
      //   if (item.testSuiteId && !suitesEncountered.has(item.testSuiteId)) {
      //       suitesEncountered.add(item.testSuiteId);
      //       item.runName = `Test Suite - ${item.testSuiteName}`;
      //       item.runDesc = item.testSuiteRunDesc;
      //     return item;
      //   }else if(item.testSuiteId && suitesEncountered.has(item.testSuiteId)) {
      //     return ;
      //   }

      //   // Filter out suites first
      //   if (this.query) {
      //     const query = this.query.toLowerCase();

      //     if (item.runName.toLowerCase().includes(query)) return item;

      //     let match = null;
      //     if (item.runTags) {
      //       item.runTags.filter(function (str) {
      //         match = str.includes(query);
      //       });
      //       if (match) return item;
      //     }

      //     if (item.runDesc) {
      //       if (item.runDesc.toLowerCase().includes(query)) return item;
      //     }

      //     if (item.status) {
      //       if (item.status.toLowerCase().includes(query)) return item;
      //     }
      //   } else {
      //     return item;
      //   }
      // });
    },
  },
  computed: {
    filteredResultSet() {
      return this.items.map((item) => {
        if (item.testSuiteId) {
          item.runName = `Test Suite - ${item.testSuiteName} | ${item.runName}`;
          item.runDesc = item.testSuiteRunDesc;
          return item;
        } else {
          return item;
        }
      });
      // let suitesEncountered = new Set();
      // return this.items.filter((item) => {
      // if (item.testSuiteId ) {
      //     // suitesEncountered.add(item.testSuiteId);
      //     item.runName = `Test Suite - ${item.testSuiteName} | ${item.runName}`;
      //     item.runDesc = item.testSuiteRunDesc;
      //     return item;
      // }else{
      //   return item;
      // }

      // Filter out suites first
      // if (this.query) {
      //   const query = this.query.toLowerCase();

      //   if (item.runName.toLowerCase().includes(query)) return item;

      //   let match = null;
      //   if (item.runTags) {
      //     item.runTags.filter(function (str) {
      //       match = str.includes(query);
      //     });
      //     if (match) return item;
      //   }

      //   if (item.runDesc) {
      //     if (item.runDesc.toLowerCase().includes(query)) return item;
      //   }

      //   if (item.status) {
      //     if (item.status.toLowerCase().includes(query)) return item;
      //   }
      // } else {
      //   return item;
      // }
      // });
    },
    viewFilterValues() {
      let tmp = Object.entries(this.removeEmpty(this.editedFilter, "value"))
      return tmp.length > 0
    },
    filterValues() {
      return Object.entries(this.removeEmpty(this.editedFilter, "value"));
    },
    filteredValues() {
      return Object.entries(this.removeEmpty(this.filteredElt, "value"));
    }
  },
  watch: {
    filteredResults(val) {
      const test = val.filter((elt) => elt.status === "processing");
      if (test.length === 0) {
        this.pollStatus = false;
      }
    },
  },
};
</script>
<style lang="scss">
$pColor: #525f7f;
$color: #5e72e4;

.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-test-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .modal-body {
    height: 60vh;
    overflow: auto;
  }
}

.run-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.model-label {
  display: block;
  font-size: 13px ;
  font-weight: 600;
  margin-top: 0.5em;
}

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.modal-checks input {
  outline: none;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  padding: 7px;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.upload-craglist label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.craglist-files {
  width: 100%;
}

.craglist-files ul {
  border-top: 1px solid gray;
  padding: 0;
  margin: 0;
  width: 100%;
}

.craglist-files ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.rmv-btn {
  font-size: 20px;
  cursor: pointer;
}

.file-lists-container {
  width: 100%;
}

.file-head-clearall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.file-head-clearall .clearall {
  cursor: pointer;
}

.upload-file-div-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ddf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(5) .cell {
  justify-content: center;
}

.down-btn-container a {
  color: #000000;
}

.down-btn-container a:hover {
  opacity: 0.6;
}

.down-btn-container a .fa-file-pdf,
.down-btn-container .fa-file-csv {
  font-weight: 600;
}

.progress-xpath-count {
  font-weight: bold;
  color: #4ac3db;
}

.onlyForRunningTestRow {
  background-color: #c4f1de !important;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}

.test-run-name {
  color: rgb(94, 114, 228);
  font-weight: bold;
}

.test-run-name-for-suite {
  color: #2dce89;
  font-weight: bold;
}

.passed-status {
  font-weight: bold;
  font-size: 12px;
  color: #8a8d90;
  text-transform: capitalize;
}

.failed-status {
  background-color: #d93b3b;
  color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}

.run-tags-cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.run-tags {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 3px;
  padding: 3px;
  font-size: 0.65rem;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
}

.test-time-cont {
  display: inline-block;
  max-width: 70px;
  font-size: 0.6rem;
  padding: 0.15rem 0.35rem;
  text-align: center;
  border: solid 0.1px rgba(#5e72e4, 0.75);
  background: rgba(#5e72e4, 0.15);
  margin-top: 5px;
  border-radius: 0.25rem;
  color: #5e72e4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.looping-rhombuses-spinner,
.looping-rhombuses-spinner * {
  box-sizing: border-box;
}

.looping-rhombuses-spinner {
  width: 50%;
  height: 15px;
  position: relative;
  margin-left: 10px !important;
}

.add-test-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: 500px !important;
  height: 10vh;
}

.show-integration-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: 500px !important;
}

.looping-rhombuses-spinner .rhombus {
  height: 15px;
  width: 15px;
  background-color: #4ac3db;
  left: calc(15px * 1);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}
.elt--banner_not_img{

}

.b {
  border: 1px solid red !important;
}

.for-row-selection .el-input {
  height: 100%;
  padding: 3px;
}

.test-descp {
  height: 10px;
  color: #9ea1a5;
  width: 50px;
}

.edit-test-select {
  width: 100%;
}

.edit-test-select .el-input {
  width: 100%;
}

.edit-test-modal-container .edit-test-select .el-select .el-input .el-input__inner {
  text-transform: capitalize !important;
}

.el-select-dropdown__item {
  text-transform: capitalize !important;
}

.add-test-modal button:disabled {
  cursor: not-allowed;
}

.for-pagination-circle-cursor .page-link {
  cursor: pointer;
}

.btn-gray {
  background-color: #172b4d !important;
}

.processing-screenshot-img {
  width: 150px;
  height: 100px;
  overflow: hidden;
}

.processing-screenshot-img img {
  /* border-radius: 10px; */
  /* border: 2px solid #fbb140; */
  width: 150px;
  height: 100px;
  object-fit: contain !important;
}

.add-test-footer-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.file-hover {
  background-color: #adfad9 !important;
}

.pakhpalle {
  background-color: red !important;
}

.looping-rhombuses-spinner .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}

.narrow-select .el-input {
  height: 52px;
  width: 80px;
  padding: 3px;
}

.options-accordion .el-collapse-item__header {
  font-size: 16px;
}

.small--select {
  max-width: 80px !important;
}

.c--breadcrumb {
  align-items: center;
  font-size: 1rem;
  font-weight: 300;
  color: rgba($pColor, 0.6);
  // transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: rgba($color, 0.6);
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}

.mid--page {
  border-bottom: solid 0.1px rgba($pColor, 0.25);

  .el-input__inner {
    font-size: 0.75rem;
    padding: 0.35rem;
    background: #f7f9fc;

    &:focus {
      outline: none !important;
    }
  }

  .el-input__suffix {
    .el-input__suffix-inner {
      display: flex;
      justify-content: center;
      align-items: center;

      .el-input__icon {
        font-size: 0.8rem;
        transform: translateY(-3px);
      }
    }
  }

  .mp--l {
    span {
      color: rgba($pColor, 0.65);
      cursor: pointer;
      font-size: 1.25rem;

      &.active {
        color: #5e72e4;
      }
    }

    .small_btn {
      background: rgba($color, 0.1);
      border: solid 0.1px $color;

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }

  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}

.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: $color;
    color: #ffffff;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}

.ctb--body {
  display: flex;
  flex-wrap: wrap;

  .ctbb--row {
    width: 31%;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;

        .elt--banner_img {
          display: inline-block;
          width: 100%;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color, 0.3);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 100px;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }

        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}

.tbl--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;

      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00ff6214;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}

.modal-body {
  height: auto;
  padding-top: 40px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mp--filter_container {
  animation: slideDown 0.3s;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: auto;
    opacity: 1;
  }
}
.el-button-group{
  margin-left: 0;
  width: 100%;
  justify-content: center;
  display: flex;
}
</style>
